import type {
  ReactionPanelProps,
  ReactionPanelActions,
} from '../ReactionPanel/ReactionPanel.types';
import {
  CommentsPanelActions,
  CommentsPanelData,
  CommentsPanelUser,
} from '../CommentsPanel/CommentsPanel.types';
import { PostCardHeaderProps } from '../PostCardHeader/PostCardHeader.types';
import {
  PostContent,
  PostType,
} from '../PostCardContent/PostCardContent.types';

export type ParentCommentType = {
  commentId: string;
  commentUserId: number;
};

export type PostUserProfile = {
  id: string;
  avatar?: string;
  url: string;
  name: string;
  isVerified: boolean;
};

export enum PostVisibility {
  PublicPost = 'public',
  MembersOnly = 'members_only',
}

export type PostCardComponentProps = {
  defaultAvatar?: string;
  className?: string;
  isDisabled?: boolean;
};

export type Post = {
  id: string;
  user: PostUserProfile & CommentsPanelUser;
  author: PostUserProfile;
  time: Date;
  type: PostType;
  visibility: PostVisibility;
  content: Omit<PostContent, 'type'>;
  url: string;
};

type PostCardReactionsProps = {
  reactions?: ReactionPanelProps;
};

type PostCardCommentsProps = {
  comments?: Omit<CommentsPanelData, 'user'>;
  isCommentsLoading?: boolean;
  isCommentingAllowed?: boolean;
  isCommentEditingAllowed?: boolean;
  isReplyingAllowed?: boolean;
};

export type PostCardProps = Post &
  PostCardComponentProps &
  PostCardHeaderProps &
  PostCardCommentsProps &
  PostCardReactionsProps &
  ReactionPanelActions &
  CommentsPanelActions;
