import React from 'react';
import { LazyMotion, m } from 'framer-motion';

// we can pass a dynamic import function to features that will fetch features
// only after we've performed our initial render.
const loadFeatures = () => import('./framer-motion-features').then(res => res.domMax);

export function FramerMotion(props: { children: React.ReactNode }) {
  return (
    <LazyMotion
      // https://www.framer.com/docs/guide-reduce-bundle-size/#developer-tools
      // we use the strict prop to ensure that we import the correct, lazy loadable, motion components
      strict
      // https://www.framer.com/docs/guide-reduce-bundle-size/#lazy-loading
      features={loadFeatures}
    >
      {props.children}
    </LazyMotion>
  );
}

// eslint-disable-next-line no-restricted-imports
export * from 'framer-motion';
export { m as motion };
