import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Comment } from '../Comment/Comment';
import {
  CommentActions,
  CommentComponentProps,
  CommentDataWithReplies,
} from '../Comment/Comment.types';
import { CommentInput } from '../CommentInput/CommentInput';
import * as S from './CommentsPanel.styles';
import type { CommentsPanelProps } from './CommentsPanel.types';

export const CommentsPanel = (props: CommentsPanelProps) => {
  const { formatMessage } = useIntl();

  const showCommentInput =
    !!props.isCommentingAllowed || props.isCommentingAllowed === undefined;

  const [newComment, setNewComment] = useState('');
  const [isActive, setActive] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleNewCommentChange = (value: string) => setNewComment(value);

  const handleNewComment = async (value: string) => {
    setSubmitting(true);
    props.onComment && (await props.onComment(value));
    setNewComment('');
    setSubmitting(false);
  };

  const handleCommentReply = async (commentId: string, value: string) => {
    props.onCommentReply && (await props.onCommentReply(commentId, value));
  };

  const handleLoadPastComments = async (e: React.MouseEvent) => {
    e.preventDefault();
    await setLoading(true);
    props.onCommentsLoadPast && (await props.onCommentsLoadPast());
    setLoading(false);
  };

  const isReplyingAllowed =
    props.isReplyingAllowed === undefined ? true : props.isReplyingAllowed;

  const hasPastComments =
    props.onCommentsLoadPast && props.commentsCount > props.comments.length;

  const handleOnHearts = async (commentId: string, state: boolean) =>
    props.onCommentHearts && (await props.onCommentHearts(commentId, state));

  const handleViewReplies = async (commentId: string) =>
    props.onCommentViewReplies && (await props.onCommentViewReplies(commentId));

  const handleCommentInputFocus = async () => setActive(true);
  const handleCommentInputBlur = async () => setActive(false);

  const handleCommentEdit = async (commentId: string, comment: string) => {
    props.onCommentEdit && (await props.onCommentEdit(commentId, comment));
  };

  const renderComment = makeCommentRenderer({
    user: props.user,
    isReplyingAllowed,
    isEditingAllowed: props.isCommentEditingAllowed,
    defaultAvatar: props.defaultAvatar,
    onHearts: handleOnHearts,
    onViewReplies: handleViewReplies,
    onReply: handleCommentReply,
    onEdit: handleCommentEdit,
    onDelete: props.onCommentDelete,
  });

  return (
    <S.CommentsWrapper>
      {/* Input for new Comment */}
      {showCommentInput && (
        <CommentInput
          value={newComment}
          user={props.user}
          isAvatarVisible={true}
          defaultAvatar={props.defaultAvatar}
          isActive={isActive}
          isSubmitting={isSubmitting}
          isLoading={isLoading}
          onComment={handleNewComment}
          onFocus={handleCommentInputFocus}
          onBlur={handleCommentInputBlur}
          onChange={handleNewCommentChange}
        />
      )}

      {/* List of comments */}
      {props.comments.map(renderComment)}

      {/* Load more comments */}
      {hasPastComments && (
        <S.LoadPastComments onClick={handleLoadPastComments}>
          {formatMessage({ id: 'component.post_card.see_more_comments' })}
          {isLoading && <S.LoadingIcon size={'12px'} />}
        </S.LoadPastComments>
      )}
    </S.CommentsWrapper>
  );
};

const makeCommentRenderer = (props: CommentComponentProps & CommentActions) => (
  comment: CommentDataWithReplies
) => {
  return <Comment key={comment.id} {...comment} {...props} />;
};
