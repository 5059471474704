import { createContext, useReducer } from 'react';

import { AuthActionType } from 'components/contexts/authProvider/authReducer/authReducer.types';
import { useLocalStorageSync } from 'hooks/useLocalStorageSync/useLocalStorageSync';
import { Flags, useFlags } from 'hooks/useFlags/useFlags';

import { AuthProviderProps, AuthDispatch, AuthState } from './AuthProvider.types';
import { authReducer } from './authReducer/authReducer';
import { getCurrentStoredToken, TOKEN_LOCAL_STORAGE_KEY } from './AuthToken';

export const AuthStateContext = createContext<AuthState | null>(null);
export const AuthDispatchContext = createContext<AuthDispatch | null>(null);

export const USER_LOCAL_STORAGE_KEY = 'cfUser';

export const AuthProvider = (props: AuthProviderProps) => {
  const { initialUserData, children } = props;
  const { user } = initialUserData || {};

  const token = getCurrentStoredToken();

  const [USE_GRAPHQL_AUTH] = useFlags(Flags.USE_GRAPHQL_AUTH);

  const [authState, dispatch] = useReducer(authReducer, {
    user: user || null,
    token: token || null,
    isAuthorized: Boolean(user),
    USE_GRAPHQL_AUTH: Boolean(USE_GRAPHQL_AUTH),
  });

  useLocalStorageSync({
    key: USER_LOCAL_STORAGE_KEY,
    value: authState.user,
    compareFn: (value, storedValue) => value?.identification.id === storedValue?.identification.id,
    onUpdate: updatedUser => {
      if (updatedUser) {
        dispatch({ type: AuthActionType.REFRESH_USER, user: updatedUser });
      } else {
        dispatch({ type: AuthActionType.LOGOUT });
      }
    },
  });

  useLocalStorageSync({
    key: TOKEN_LOCAL_STORAGE_KEY,
    value: authState.token,
    compareFn: (value, storedValue) => value === storedValue,
    onUpdate: updatedToken => {
      if (updatedToken) {
        dispatch({ type: AuthActionType.REFRESH_TOKEN, token: updatedToken });
      } else {
        dispatch({ type: AuthActionType.LOGOUT });
      }
    },
  });

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
