import { styled } from '../../elements';

export const Card = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  boxShadow: theme.shadows[1],
}));

export const Detail = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.625),
  lineHeight: 1.7,
}));

export const Body = styled('div')<{ size?: 'regular' | 'small' }>(
  ({ theme, size = 'regular' }) => {
    return {
      padding: size === 'regular' ? theme.spacing(2.5) : theme.spacing(1.25),
    };
  }
);
