import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { GlobalStyles, Theme as MUITheme } from '@mui/material';
import { Theme as EmotionTheme } from '@emotion/react';
import { Interpolation } from '@emotion/styled';

import { theme } from 'components/contexts/themeProvider/theme/theme';
import { getUserAgentMatchMedia } from 'components/contexts/themeProvider/theme/utils';
import { ThemeProviderProps } from 'components/contexts/themeProvider/ThemeProvider.types';
import { useMounted } from 'hooks/useMounted/useMounted';

const MUIBaseStyles = (MuiTheme: MUITheme) => ({
  body: {
    color: MuiTheme.palette.text.primary,
    ...MuiTheme.typography.body1,
    backgroundColor: MuiTheme.palette.background.default,
    '&::backdrop': {
      backgroundColor: MuiTheme.palette.background.default,
    },
    '@media print': {
      backgroundColor: MuiTheme.palette.common.white,
    },
  },
  a: {
    cursor: 'pointer',
  },
});
const EmotionGlobalStyles = (MUIBaseStyles as unknown) as Interpolation<EmotionTheme>;

export const ThemeProvider = (props: ThemeProviderProps) => {
  const { children, ssrMatchMedia } = props;
  const isMounted = useMounted();
  let userAgentMatchMedia = ssrMatchMedia;

  // Use same media matching during hydration as used during SSR
  if (!isMounted && typeof navigator !== 'undefined') {
    const userAgent = navigator.userAgent;

    userAgentMatchMedia = getUserAgentMatchMedia(userAgent);
  }

  const patchedTheme = {
    ...theme,
    components: {
      ...theme.components,
      MuiUseMediaQuery: {
        ...theme.components?.MuiUseMediaQuery,
        defaultProps: {
          ...theme.components?.MuiUseMediaQuery?.defaultProps,
          ssrMatchMedia: userAgentMatchMedia,
        },
      },
    },
  };

  return (
    <MuiThemeProvider theme={patchedTheme}>
      <GlobalStyles styles={EmotionGlobalStyles} />
      {children}
    </MuiThemeProvider>
  );
};
