import React from 'react';
import { useIntl } from 'react-intl';

import { Grid, Typography, Box } from '../../elements';
import { ModalProps } from './Modal.types';
import * as S from './Modal.styles';

export const Modal = ({
  className,
  isOpened,
  children,
  onClose,
  title,
  actions,
  errorText,
  successText,
  hasCloseButton = true,
  contentProps = {},
  ...props
}: ModalProps) => {
  const { formatMessage } = useIntl();

  const renderTopBar = () => {
    if (!errorText && !successText) {
      return null;
    }

    const message = errorText
      ? `${formatMessage({ id: 'common.error' })}: ${errorText}`
      : successText;
    const severity = errorText ? 'error' : 'success';

    return (
      <S.ModalAlert severity={severity}>
        <Box dangerouslySetInnerHTML={{ __html: message as string }} />
      </S.ModalAlert>
    );
  };

  const handleCloseClick = () => {
    if (onClose) {
      onClose();
    }
  };

  // FIXME: this breaks password managers credentials pasting in forms inside the modal. Possibly fixed in Material UI v5
  const disableEnforceFocus = true;

  return (
    <S.Modal
      className={className}
      onClose={onClose}
      open={isOpened}
      disableEnforceFocus={disableEnforceFocus}
      scroll="body"
      BackdropProps={{
        'data-testid': 'modal-backdrop',
      }}
      {...props}
    >
      {hasCloseButton && (
        <S.ModalClose
          icon="times"
          onClick={handleCloseClick}
          color="inherit"
          data-testid="modal-close"
        />
      )}
      {renderTopBar()}
      {title && (
        <S.Title>
          <Typography
            display="block"
            variant="h2"
            align="center"
            component="span"
          >
            {title}
          </Typography>
        </S.Title>
      )}
      {children && <S.Content {...contentProps}>{children}</S.Content>}
      {actions && (
        <S.Actions disableSpacing>
          {Array.isArray(actions) ? (
            <Grid container direction="column" spacing={2}>
              {actions.map((child, index) => (
                <Grid key={index} item>
                  {child}
                </Grid>
              ))}
            </Grid>
          ) : (
            actions
          )}
        </S.Actions>
      )}
    </S.Modal>
  );
};
