import { Truthy } from 'types/utils';

// here the typing only allows 2 arrays, although this function does support infinite arrays.
// i was not just able to type it with typescript 4.4.
export function produceAlternatingArray<T1, T2>(...arrays: [Array<T1>, Array<T2>]): Array<T1 | T2> {
  // find the longest array, so when we do a loop we know we aren't missing any items
  const largestLength = Math.max(...arrays.map(a => a.length));
  const longestIndex = arrays.map(a => a.length).indexOf(largestLength);
  const longest = arrays[longestIndex];

  return (
    longest
      // make an alternating 2d array of our arrays
      // (we map over the longest, so we might have some undefined values here)
      .map((_, i) => {
        return arrays.map(array => array[i]);
      })
      // flatten that 2d array to a 1d array
      .flat()
      // remove those undefined values
      .filter(Boolean)
  );
}

export function unArray(value: string | string[] | undefined) {
  return Array.isArray(value) ? value[0] : value;
}

export function truthyBoolean<T>(value: T): value is Truthy<T> {
  return Boolean(value);
}

export function replaceLast(string: string, searchValue: string, toReplace: string) {
  const index = string.lastIndexOf(searchValue);

  if (index === -1) {
    return string;
  }

  return string.substring(0, index) + toReplace + string.substring(index + searchValue.length);
}

export function stringContains(string: string, needle: string) {
  return string.indexOf(needle) > -1;
}

type NestedStringObject = {
  [key: string]: string | NestedStringObject;
};

export function deepFlattenToObject(obj: NestedStringObject, prefix = ''): Record<string, string> {
  return Object.keys(obj).reduce((acc: Record<string, string>, k: string) => {
    const pre = prefix.length ? prefix + '.' : '';

    if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
      Object.assign(acc, deepFlattenToObject(obj[k] as NestedStringObject, pre + k));
    } else {
      acc[pre + k] = obj[k] as string;
    }

    return acc;
  }, {});
}
