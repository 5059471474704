import React, { SyntheticEvent, useState } from 'react';

import { AlertProps } from './Alert.types';
import * as S from './Alert.styles';
import { IconButton } from '../button/iconButton/IconButton';
import { Icon } from '../icon/Icon';

export const Alert = ({
  action,
  icon,
  hasCloseButton,
  onClose,
  ...props
}: AlertProps) => {
  const [isOpened, setOpened] = useState(true);

  const handleClose = (event: SyntheticEvent<Element, Event>) => {
    if (onClose) {
      onClose(event);
    }

    setOpened(false);
  };

  if (!isOpened) {
    return null;
  }

  let actionComponent = action;

  if (hasCloseButton) {
    actionComponent = (
      <>
        {action}
        <IconButton
          className="icon"
          icon="times"
          onClick={handleClose}
          iconColor="white.main"
        />
      </>
    );
  }

  const iconComponent = icon && (
    <Icon
      className="icon"
      icon={icon}
      data-testid="alert-icon"
      color="white.main"
    />
  );

  return (
    <S.Alert
      action={actionComponent}
      icon={iconComponent || false}
      variant="filled"
      {...props}
    />
  );
};
