import React from 'react';

import * as S from './CoinBalanceProgressBar.styles';
import { CoinBalanceProgressBarProps } from './CoinBalanceProgressBar.types';

export const CoinBalanceProgressBar = ({
  icon,
  current,
  max,
  footer,
}: CoinBalanceProgressBarProps) => {
  const clampedCurrent = Math.min(current, max);

  const progress = Math.round((clampedCurrent / max) * 100) || 0;

  return (
    <>
      <S.Progress value={current} max={max} />
      <S.Bar progress={progress}>
        <S.BarIcon progress={progress}>{icon}</S.BarIcon>
      </S.Bar>
      <S.Footer>{footer}</S.Footer>
    </>
  );
};
