import { fetcher } from 'api/fetcher';
import { ApiEndpoint, BackendErrorListData } from 'api/fetcher.types';
import {
  UpdateUserFetcherParams,
  UserData,
  UpdateLanguageAndCurrency,
  CurrencyAndLanguageChange,
} from 'api/requests/user/user.types';
import { nonceFetcher } from 'api/requests/nonce/nonce';
import { NonceType } from 'api/requests/nonce/nonce.types';

export const updateUserFetcher = async (params: UpdateUserFetcherParams) => {
  const { id, properties, cookieString, onError } = params;
  const { nonce } = await nonceFetcher({ nonceType: NonceType.DEFAULT, cookieString });

  return fetcher<UserData & BackendErrorListData>({
    url: `${ApiEndpoint.USERS}/${id}?_wpnonce=${nonce}`,
    options: {
      method: 'PUT',
      body: JSON.stringify(properties),
      headers: { 'Content-Type': 'application/json' },
    },
    onError,
  });
};

export const updateLanguageAndCurrencyFetcher = async (params: UpdateLanguageAndCurrency) => {
  const { properties, cookieString, onError } = params;
  const { nonce } = await nonceFetcher({ nonceType: NonceType.CURRENCY_SWITCH, cookieString });
  const body = { ...properties, security: nonce };

  return fetcher<CurrencyAndLanguageChange & BackendErrorListData>({
    url: `${ApiEndpoint.SWITCH_CURRENCY}`,
    options: {
      method: 'POST',
      body: JSON.stringify(body),
    },
    onError,
  });
};
