import * as Sentry from '@sentry/nextjs';

import { ignoreErrors, denyUrls } from './src/utilities/sentry.utils';

// null disables event sending
//Disable sentry for now
//const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN || null;

// 10% of events are sent (1% for production)
//const tracesSampleRate = parseFloat(process.env.NEXT_PUBLIC_CLIENT_SENTRY_TRACE_SAMPLE_RATE);
const tracesSampleRate = 0;

Sentry.init({
  tracesSampleRate,
  ignoreErrors,
  denyUrls,
});
