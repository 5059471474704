export enum NonceType {
  DEFAULT = 'wp_rest',
  LOGIN = 'woocommerce-login',
  REGISTER = 'woocommerce-register',
  RESET = 'lost_password',
  LOGOUT = 'customer-logout',
  UNDO_SUBSCRIPTION_CANCELLATION = 'undo_subscription_cancellation_nonce_field',
  FAVORITE = 'favorite_product_action_nonce_field',
  CURRENCY_SWITCH = 'user_nonce_field',
  UPLOADER = 'admin_nonce_field',
  DISCOUNT_SECOND_YEAR = 'discount_second_year_nonce',
  RENEW_FREE_TRIAL = 'renew_free_trial_subscription_nonce',
}

export type NonceFetcherParams = { nonceType: NonceType; cookieString?: string };

export type NonceData = {
  nonce: string;
};
