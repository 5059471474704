import React from 'react';

import * as S from './Card.styles';
import { CardProps, CardDetailProps } from './Card.types';

const CardRoot = ({ children }: CardProps) => {
  return <S.Card>{children}</S.Card>;
};

const CardDetail = ({ icon, label }: CardDetailProps) => {
  return (
    <S.Detail>
      {icon}
      <span>{label}</span>
    </S.Detail>
  );
};

export const Card = Object.assign(CardRoot, {
  Detail: CardDetail,
  Body: S.Body,
});
