import amplitude from 'amplitude-js';

import { AmplitudeLogEvent, AmplitudeClientAugmented } from 'components/app/analytics/amplitude/Amplitude.types';

let AMPLITUDE_INITIALIZED = false;

export const initAmplitude = (deviceId?: string) => {
  if (AMPLITUDE_INITIALIZED) {
    return;
  }

  if (deviceId) {
    amplitude.getInstance().setDeviceId(deviceId);
  }

  amplitude.getInstance().init(`${process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY}`, undefined, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    saveParamsReferrerOncePerSession: true,
    unsetParamsReferrerOnNewSession: true,
  });

  AMPLITUDE_INITIALIZED = true;
};

export const sendAmplitudeLog: AmplitudeLogEvent = (name, properties) => {
  const amplitudeInstance = amplitude.getInstance() as AmplitudeClientAugmented;

  if (!amplitudeInstance._isInitialized) {
    return;
  }
  const defaultProperties = {
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: document.title,
    url: window.location.href,
  };

  amplitude.getInstance().logEvent(name, { ...defaultProperties, ...properties }, function () {});
};

export const setAmplitudeUser = (userId: string) => {
  const amplitudeInstance = amplitude.getInstance() as AmplitudeClientAugmented;

  if (!amplitudeInstance._isInitialized) {
    return;
  }
  amplitude.getInstance().setUserId(userId);
};

export const getAmplitudeSessionId = () => {
  const amplitudeInstance = amplitude.getInstance() as AmplitudeClientAugmented;

  if (!amplitudeInstance._isInitialized) {
    return;
  }

  return amplitude.getInstance().getSessionId();
};

export const getAmplitudeDeviceId = () => {
  const amplitudeInstance = amplitude.getInstance() as AmplitudeClientAugmented;

  if (!amplitudeInstance._isInitialized) {
    return;
  }

  return amplitude.getInstance().getDeviceId();
};

export const setAmplitudeDeviceId = (id: string) => {
  const amplitudeInstance = amplitude.getInstance() as AmplitudeClientAugmented;

  if (!amplitudeInstance._isInitialized) {
    return;
  }
  amplitude.getInstance().setDeviceId(id);
};
