import React from 'react';

import { IconButtonProps } from './IconButton.types';
import * as S from './IconButton.styles';
import { Icon } from '../../icon/Icon';

export const IconButton = ({
  className,
  icon,
  iconColor,
  color = 'primary',
  size = 'medium',
  ...props
}: IconButtonProps) => (
  <S.IconButton className={className} color={color} size={size} {...props}>
    <Icon className="icon" icon={icon} color={iconColor} />
  </S.IconButton>
);
