import NextLink from 'next/link';
import classNames from 'classnames';

import { LinkColor, LinkUnderline, LinkProps } from './Link.types';

const mapUnderlineToTailwindClass: Record<LinkUnderline, string> = {
  none: 'no-underline',
  hover: 'hover:underline',
  always: 'underline',
};

const mapColorToTailwindClass: Record<LinkColor, string> = {
  standard: 'text-blue-500 hover:text-secondary-500',
  black: 'text-black-500 hover:text-secondary-500',
  secondary: 'text-secondary-500 hover:text-secondary-700',
  primary: 'text-primary-500 hover:text-secondary-500',
  white: 'text-white',
  inherit: 'text-inherit',
};

export const Link = (props: LinkProps) => {
  const {
    href,
    as,
    prefetch,
    replace,
    scroll,
    shallow,
    locale,
    targetBlank,
    external,
    children,
    underline = 'always',
    color = 'standard',
    className,
    behaviour = 'client-side-navigation',
    ...linkProps
  } = props;
  const target = targetBlank || external ? '_blank' : undefined;
  const rel = external ? 'noreferrer noopener' : undefined;

  const link = (
    <a
      className={classNames(
        'decoration-inherit',
        className,
        mapUnderlineToTailwindClass[underline],
        color !== 'inherit' && [mapColorToTailwindClass[color]],
      )}
      target={target}
      rel={rel}
      href={href}
      {...linkProps}
    >
      {children}
    </a>
  );

  if (!href || targetBlank || external || behaviour === 'static') {
    return link;
  }

  return (
    <NextLink
      passHref
      href={href}
      as={as}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      locale={locale}
    >
      {link}
    </NextLink>
  );
};
