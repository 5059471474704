import { AmplitudeClient } from 'amplitude-js';

export enum AmplitudeEvent {
  PAGE_VIEW = 'Loaded a Page',
  NOTIFICATION_CLICK = 'Web Notification Clicked',
  CHECKOUT_START = 'Checkout Started',
  LOGIN_MODAL_OPENED = 'Opened Login Modal',
  REGISTRATION_MODAL_OPENED = 'Opened Register Modal',
  IMAGEMIX_UPLOAD_FAILED = 'ImageMix Upload Failed',
  IMAGEUPSCAPER_UPLOAD_FAILED = 'Image Upscaler Upload Failed',
  CLICKED_BANNER_ABOVE_NAV = 'Clicked Banner Above Nav',
  PRODUCT_VIEWED = 'Product Viewed',
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
  DOWNLOADED_PRODUCT = 'Downloaded Product',
  PRODUCTS_SEARCHED = 'Products Searched',
  EXPERIMENT_VIEWED = 'Experiment Viewed',
}

export enum AuthModalLocations {
  SITE_HEADER = 'nav menu',
  COINS_PAGE_HEADING = 'coins page heading',
  COINS_PAGE_CTA = 'coins page call to action',
  LOGIN_MODAL = 'login modal',
  REGISTRATION_MODAL = 'registration modal',
  SPARK_PAGE_ADD_FAVORITE = 'spark page add favorite',
  SPARK_PAGE_SUBMIT_PROMPT = 'spark page submit prompt',
  SPARK_PAGE_PERSONAL_FEED_CLICK = 'spark page personal feed click',
  SUBSCRIPTIONS_PAGE_FREE_CTA = 'subscriptions page free cta button click',
  PROD_PAGE_FREE_CTA = 'product page download free product',
}

type AmplitudeProperty = string | number | string[] | number[];

export type AmplitudeLogEvent = (
  name: AmplitudeEvent,
  properties: Record<
    string,
    AmplitudeProperty | Record<string, AmplitudeProperty> | Record<string, AmplitudeProperty>[]
  >,
) => void;
export interface AmplitudeClientAugmented extends AmplitudeClient {
  _isInitialized: boolean;
}
