import { GoogleAnalyticsEventName, GoogleAnalyticsFieldsObject } from './GoogleAnalytics.types';

export const googleAnalyticsEvent = (options: GoogleAnalyticsEventName | GoogleAnalyticsFieldsObject) => {
  if (!window.ga) {
    return;
  }

  window.ga('send', options);
};

export const googleTrackHomePageView = () => {
  googleAnalyticsEvent({
    hitType: 'event',
    eventCategory: 'Homepage',
    eventAction: 'viewed homepage',
    eventLabel: null,
    eventValue: null,
    nonInteraction: true,
  });
};

export const googleTrackViewedCheckoutWithPromoApplied = (eventLabel: string) => {
  googleAnalyticsEvent({
    hitType: 'event',
    eventCategory: 'Checkout',
    eventAction: 'promo active in checkout',
    eventLabel: eventLabel,
    eventValue: null,
    nonInteraction: true,
  });
};

export const googleTrackLangAndCurrencyClick = (currency: string, lang: string) => {
  googleAnalyticsEvent({
    hitType: 'event',
    eventCategory: 'Header',
    eventAction: 'Language/currency clicked',
    eventLabel: `${currency},${lang.toUpperCase()}`,
    eventValue: null,
    nonInteraction: false,
  });
};

export const googleTrackLangAndCurrencyChange = (
  currency: string,
  newCurrency: string,
  lang: string,
  newLang: string,
) => {
  googleAnalyticsEvent({
    hitType: 'event',
    eventCategory: 'Header',
    eventAction: 'Language/currency changed',
    eventLabel: `${currency}->${newCurrency},${lang.toUpperCase()}->${newLang.toUpperCase()}`,
    eventValue: null,
    nonInteraction: false,
  });
};

export const googleTrackProductDownloadClick = (eventLabel: string) => {
  googleAnalyticsEvent({
    hitType: 'event',
    eventCategory: 'Prod Page',
    eventAction: 'Clicked download - triggers file download',
    eventLabel: eventLabel,
    eventValue: null,
    nonInteraction: true,
  });
};

export const googleTrackProductFreeTrialClick = () => {
  googleAnalyticsEvent({
    hitType: 'event',
    eventCategory: 'Prod Page',
    eventAction: 'Clicked Activate Free Trial',
    eventLabel: null,
    eventValue: null,
    nonInteraction: true,
  });
};
