export enum AvatarSize {
  SMALL = '32px',
  MEDIUM = '40px',
  LARGE = '60px',
}

export type AvatarProps = {
  src?: string | null;
  size: AvatarSize;
  alt?: string;
};
