import React, { createContext, ReactNode, useState } from 'react';

import { SnackbarProps } from 'components/elements/atoms/snackbar/Snackbar.types';

import { PartialSnackbar, SnackbarContextValue } from './SnackbarProvider.types';

export const SnackbarManagerContext = createContext<SnackbarProps | null>(null);
export const SnackbarContext = createContext<SnackbarContextValue | null>(null);

export const SnackbarProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<PartialSnackbar | null>(null);

  const handleOpen = (snackbarProps: PartialSnackbar) => {
    setSnackbar(snackbarProps);
    setIsOpen(true);
  };

  const handleClose: PartialSnackbar['onClose'] = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);

    snackbar?.onClose?.(event, reason);
  };

  const snackbarProps = snackbar
    ? {
        ...snackbar,
        open: isOpen,
        onClose: handleClose,
      }
    : null;

  return (
    <SnackbarContext.Provider value={handleOpen}>
      <SnackbarManagerContext.Provider value={snackbarProps}>{children}</SnackbarManagerContext.Provider>
    </SnackbarContext.Provider>
  );
};
