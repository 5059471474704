import { ReactNode } from 'react';

import { LoginProvider } from 'components/contexts/requestStateProviders/loginProvider/LoginProvider';
import { RegisterProvider } from 'components/contexts/requestStateProviders/registerProvider/RegisterProvider';
import { ResetProvider } from 'components/contexts/requestStateProviders/resetProvider/ResetProvider';
import { LogoutProvider } from 'components/contexts/requestStateProviders/logoutProvider/LogoutProvider';

export const RequestStateProviders = (props: { children: ReactNode }) => {
  const { children } = props;

  return (
    <LoginProvider>
      <RegisterProvider>
        <ResetProvider>
          <LogoutProvider>{children}</LogoutProvider>
        </ResetProvider>
      </RegisterProvider>
    </LoginProvider>
  );
};
