import React, { createContext, useContext } from 'react';

import * as S from './SidebarMenu.styles';
import {
  SidebarMenuProps,
  SidebarMenuSelectedId,
  SidebarMenuItemProps,
} from './SidebarMenu.types';

const SidebarMenuContext = createContext<SidebarMenuSelectedId>(null);

const SidebarMenuRoot = ({
  selectedId,
  children,
  ...rest
}: SidebarMenuProps) => {
  return (
    <S.SidebarMenuWrapper {...rest}>
      <SidebarMenuContext.Provider value={selectedId}>
        {children}
      </SidebarMenuContext.Provider>
    </S.SidebarMenuWrapper>
  );
};

function SidebarMenuItem({
  id,
  icon,
  children,
  selected: selectedFromProps,
  ...rest
}: SidebarMenuItemProps) {
  const selectedId = useContext(SidebarMenuContext);
  const selected = selectedFromProps || id === selectedId;

  return (
    <S.SidebarMenuItem selected={selected}>
      <S.SidebarMenuAnchor selected={selected} {...rest}>
        <S.SidebarMenuItemIconWrapper>
          {typeof icon === 'function' ? icon({ selected }) : icon}
        </S.SidebarMenuItemIconWrapper>
        {children}
      </S.SidebarMenuAnchor>
    </S.SidebarMenuItem>
  );
}

export const SidebarMenu = Object.assign(SidebarMenuRoot, {
  Item: SidebarMenuItem,
});
