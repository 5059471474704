import { useContext } from 'react';

import { Snackbar } from 'components/elements/atoms/snackbar/Snackbar';
import { SnackbarManagerContext } from 'components/contexts/snackbarProvider/SnackbarProvider';

export const SnackbarManager = () => {
  const snackbar = useContext(SnackbarManagerContext);

  if (!snackbar) {
    return null;
  }

  return <Snackbar {...snackbar} />;
};
