import React from 'react';
import { useIntl } from 'react-intl';
import { getChildrenByTypeDeep } from 'react-nanny';

import coinStar from 'assets/icons/coin_star.svg';

import * as S from './TransactionHistory.styles';
import {
  TransactionHistoryItemProps,
  TransactionHistoryRootProps,
  TransactionHistoryViewAllLinkProps,
} from './TransactionHistory.types';

export const TransactionHistoryRoot = ({
  children,
}: TransactionHistoryRootProps) => {
  const { formatMessage } = useIntl();

  // if there are no items, we show the "empty state" text
  // otherwise, if there are items, and there is a view all link present, we render that
  const items = getChildrenByTypeDeep(children, TransactionHistoryItem);
  const viewAllLink = getChildrenByTypeDeep(
    children,
    TransactionHistoryViewAll
  );

  return (
    <S.List>
      {items}
      {items.length === 0 ? (
        <S.ListFooter>
          {formatMessage({ id: 'coin.transaction_history.list.empty' })}
        </S.ListFooter>
      ) : (
        viewAllLink
      )}
    </S.List>
  );
};

const TransactionHistoryViewAll = (
  props: TransactionHistoryViewAllLinkProps
) => {
  const { formatMessage } = useIntl();

  return (
    <S.ListFooterLink {...props}>
      {formatMessage({ id: 'coin.transaction_history.view_all' })}
    </S.ListFooterLink>
  );
};

const TransactionHistoryItem = ({
  text,
  value,
}: TransactionHistoryItemProps) => {
  const { formatNumber, formatMessage } = useIntl();

  return (
    <S.Item>
      <S.ItemText>{text}</S.ItemText>
      <S.ItemValue as="div">
        {formatMessage(
          {
            id:
              value > 0
                ? 'coin.transaction_history.item.value.positive'
                : 'coin.transaction_history.item.value.negative',
          },
          // when sending in the value, we strip the value of any
          // prefixes, as the prefix will be added in intl. so that
          // is why Math.abs is used
          { value: formatNumber(Math.abs(value)) }
        )}
        <S.Icon src={coinStar} fill alt="" />
      </S.ItemValue>
    </S.Item>
  );
};

export const TransactionHistory = Object.assign(TransactionHistoryRoot, {
  Item: TransactionHistoryItem,
  ItemLink: S.ItemLink,
  ViewAll: TransactionHistoryViewAll,
});
