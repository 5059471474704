import { ShortBackendError } from 'api/fetcher.types';
import {
  getCurrentStoredToken,
  setCurrentStoredToken,
  unsetCurrentStoredToken,
} from 'components/contexts/authProvider/AuthToken';
import { DEVICE_ID_COOKIE_NAME } from 'hooks/useDeviceId/useDeviceId';

import { ErrorCode } from './graphql';

const captureException = (_err: unknown) => {
  // TODO: send exception to Sentry
};

const DEVICE_ID_UNDEFINED = '__notSet__';
const cookieName = `${DEVICE_ID_COOKIE_NAME}=`;

export const getDeviceId = () => {
  if (typeof document === 'undefined') return DEVICE_ID_UNDEFINED;

  const deviceId =
    document && document.cookie ? document.cookie.split(';').find(c => c.startsWith(cookieName)) : undefined;

  return deviceId ? deviceId.split(cookieName).join('') : DEVICE_ID_UNDEFINED;
};

async function api<Data, Variables>(query: string, variables?: Variables, initialHeaders?: HeadersInit) {
  const headers = new Headers(initialHeaders);
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  const token = getCurrentStoredToken();

  if (Boolean(token)) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  const deviceId = getDeviceId();

  if (deviceId !== undefined) {
    headers.append('device_id', deviceId);
  }

  if (process.env.NEXT_PUBLIC_GRAPHQL_STAGING_ACCESS_KEY) {
    headers.append('staging-access-key', process.env.NEXT_PUBLIC_GRAPHQL_STAGING_ACCESS_KEY);
  }

  const res = await fetch(`${process.env.NEXT_PUBLIC_LEGACY_GRAPHQL_URL}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  if (res.headers.has('token-refresh')) {
    const freshToken = `${res.headers.get('token-refresh')}`;
    setCurrentStoredToken(freshToken);
  }

  if (res.status === 401) {
    throw new Error('auth_error');
  }

  const json = await res.json();

  if (json.errors) {
    const captured = new Set<string>();
    for (const error of json.errors) {
      if (captured.has(error.message)) continue;
      captured.add(error.message);

      // eslint-disable-next-line no-console
      console.error(error.message);
      captureException(error);
    }

    if (['expired token', 'invalid token'].find(errorType => captured.has(errorType))) {
      unsetCurrentStoredToken();
    }
  }

  return json.data as Data;
}

export function mutationFetcher<Data, Variables>(query: string, variables?: Variables, initialHeaders?: HeadersInit) {
  return function mutationFetcherInner() {
    return api<Data, Variables>(query, variables, initialHeaders);
  };
}

export function queryFetcher<Data, Variables>(query: string, variables?: Variables, initialHeaders?: HeadersInit) {
  return function queryFetcherInner() {
    return api<Data, Variables>(query, variables, initialHeaders);
  };
}

export const toShortBackendErrors = (
  errors?: { code: ErrorCode; message: string }[],
): ({ errorCode: ErrorCode } & ShortBackendError)[] =>
  (errors || []).map(({ code, message }) => ({ code, message, errorCode: code }));

export const toHTTPErrorCode = (code: ErrorCode): number => {
  switch (code) {
    case 'badRequest':
      return 400;
    case 'forbidden':
      return 403;
    case 'internal':
      return 500;
    case 'notFound':
      return 404;
    case 'unauthorized':
      return 401;
  }

  return 500;
};
