import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, useContext } from 'react';
import { useIntl } from 'react-intl';
import { getChildrenByTypeDeep } from 'react-nanny';

import * as S from './CoinEarning.styles';
import {
  CoinEarningButtonProps,
  CoinEarningContextType,
  CoinEarningFreebiesButtonProps,
  CoinEarningHeaderProps,
  CoinEarningItemProps,
  CoinEarningRootProps,
  CoinEarningState,
} from './CoinEarning.types';

function decodeString(html: string) {
  if (typeof document === 'undefined') {
    return html;
  }

  const txt = document.createElement('textarea');
  txt.innerHTML = html;

  return txt.value;
}

const hasFreebieButtonStates = new Set<CoinEarningState>([
  CoinEarningState.NothingToReview,
  CoinEarningState.SharedEverything,
]);

const CoinEarningContext = createContext<CoinEarningContextType>(null!);

const useCoinEarningContext = () => {
  const context = useContext(CoinEarningContext);

  if (!context) {
    throw new Error(
      '`useCoinEarningContext` must be used within <CoinEarning /> component'
    );
  }

  return context;
};

const CoinEarningRoot = ({
  children,
  header,
  downloadHistoryUrl,
  state,
}: CoinEarningRootProps) => {
  const freebiesButton = getChildrenByTypeDeep(
    children,
    CoinEarningFreebiesLink
  );
  const items = getChildrenByTypeDeep(children, CoinEarningItem);
  const { formatMessage } = useIntl();

  return (
    <CoinEarningContext.Provider value={{ state }}>
      <S.Content>
        <CoinEarningHeader>{header}</CoinEarningHeader>
        {hasFreebieButtonStates.has(state) && freebiesButton}
        {items.length > 0 && (
          <S.ItemsWrapper>
            {items}
            <S.ViewDownloadHistoryLinkWrapper>
              <S.ViewDownloadHistoryLink href={downloadHistoryUrl}>
                {formatMessage({ id: 'coin_earning.view_download_history' })}
              </S.ViewDownloadHistoryLink>
            </S.ViewDownloadHistoryLinkWrapper>
          </S.ItemsWrapper>
        )}
      </S.Content>
    </CoinEarningContext.Provider>
  );
};

export const CoinEarningFreebiesLink = (
  props: CoinEarningFreebiesButtonProps
) => {
  const { formatMessage } = useIntl();

  return (
    <S.FreebiesButton {...props}>
      {formatMessage({ id: 'coin_earning.explore_freebies.button' })}
    </S.FreebiesButton>
  );
};

const CoinEarningHeader = ({ children }: CoinEarningHeaderProps) => {
  const { state } = useCoinEarningContext();
  const { formatMessage } = useIntl();

  if (state === CoinEarningState.DailyLimitReached) {
    return (
      <S.HeaderWrapper>
        <S.Title as="h3">{formatMessage({ id: 'coin_earning.title' })}</S.Title>
        <S.HeaderWarning>{children}</S.HeaderWarning>
      </S.HeaderWrapper>
    );
  }

  return (
    <S.HeaderWrapper>
      <S.Title as="h3">{formatMessage({ id: 'coin_earning.title' })}</S.Title>
      <S.HeaderMessage>{children}</S.HeaderMessage>
    </S.HeaderWrapper>
  );
};

const CoinEarningItem = ({
  image,
  title,
  href,
  tag,
  actions,
}: CoinEarningItemProps) => {
  const parsedTitle = title ? decodeString(title) : '';

  return (
    <S.ItemWrapper>
      <S.ItemContent>
        <S.ItemLink href={href}>
          <S.ItemImage src={image} alt={parsedTitle} />
        </S.ItemLink>
        <S.ItemDetails>
          <S.ItemTitleLink href={href}>{parsedTitle}</S.ItemTitleLink>
          <S.Category>
            <FontAwesomeIcon icon={faTag} />
            {tag}
          </S.Category>
        </S.ItemDetails>
      </S.ItemContent>
      {actions ? <S.ItemActions>{actions}</S.ItemActions> : null}
    </S.ItemWrapper>
  );
};

const CoinEarningItemButton = (props: CoinEarningButtonProps) => {
  const { state } = useCoinEarningContext();

  return (
    <S.Button
      {...props}
      disabled={state === CoinEarningState.DailyLimitReached}
    />
  );
};

export const CoinEarning = Object.assign(CoinEarningRoot, {
  Item: CoinEarningItem,
  Button: CoinEarningItemButton,
  FreebiesLink: CoinEarningFreebiesLink,
});
