import { useContext } from 'react';

import { AuthStateContext } from 'components/contexts/authProvider/AuthProvider';

export const useAuthState = () => {
  const context = useContext(AuthStateContext);

  if (!context) {
    throw new Error('useAuthState should be used inside a component');
  }

  return context;
};

export function useUser() {
  const user = useOptionalUser();

  if (process.env.NODE_ENV !== 'production') {
    if (!user) {
      throw new Error(
        'No user value is available inside `AuthStateContext`, if the user is optional use `useOptionalUser` instead of `useUser`',
      );
    }
  }

  return user;
}

export function useOptionalUser() {
  const { user } = useAuthState();

  return user;
}
