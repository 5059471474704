type Maybe<T> = T | null | undefined;

interface CallBack<Params extends unknown[], ReturnValue> {
  (...args: Params): ReturnValue;
}

export function callAll<Params extends unknown[]>(
  ...fns: Array<CallBack<Params, void> | undefined>
) {
  return (...args: Params) => fns.forEach((fn) => fn?.(...args));
}

export function callAllAsync<Params extends unknown[]>(
  ...fns: Array<Maybe<CallBack<Params, Promise<void> | void>>>
) {
  return async (...args: Params) => {
    for (const fn of fns) {
      await fn?.(...args);
    }
  };
}
