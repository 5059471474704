import { AppRoute } from 'components/app/App.types';

export const featureFlags = {
  ENABLE_TRENDING_WORDS_GRID: false,
};

export const contentSearchPages = [
  AppRoute.BACKGROUNDS_SEARCH,
  AppRoute.BORDERS_AND_CORNERS_SEARCH,
  AppRoute.CARTOONS_SEARCH,
  AppRoute.CLIP_ART_SEARCH,
  AppRoute.DRAWINGS_SEARCH,
  AppRoute.FRAMES_SEARCH,
  AppRoute.MOCKUPS_SEARCH,
  AppRoute.PATTERNS_SEARCH,
  AppRoute.QUOTES_SEARCH,
  AppRoute.SILHOUETTES_SEARCH,
  AppRoute.STICKERS_SEARCH,
  AppRoute.SKETCHES_SEARCH,
  AppRoute.SVGS_SEARCH,
  AppRoute.TEXTURES_SEARCH,
  AppRoute.PNG_SEARCH,
  AppRoute.STOCKPHOTOS_SEARCH,
];

export type ContentCategory =
  | 'stock photos'
  | 'PNGs'
  | 'Kawaiis'
  | 'Mockups'
  | 'Backgrounds'
  | 'Sketches'
  | 'Patterns'
  | 'Border and Corners'
  | 'Frames'
  | 'Cartoons'
  | 'Textures'
  | 'Silhouettes'
  | 'Drawings'
  | 'Clip Art'
  | 'Quotes'
  | 'Stickers'
  | 'SVGs'
  | 'Coloring pages';

export type CategoryPageType =
  | 'png'
  | 'kawaii'
  | 'mockups'
  | 'backgrounds'
  | 'sketches'
  | 'patterns'
  | 'borders-and-corners'
  | 'frames'
  | 'cartoons'
  | 'textures'
  | 'silhouettes'
  | 'drawings'
  | 'clip-art'
  | 'quotes'
  | 'stickers'
  | 'svgs'
  | 'coloring-pages';

export const mapCategoryPageTypeToCategoryDetails: Record<
  CategoryPageType,
  {
    authorId: string;
    parentId: string;
    categoryName: string;
    homepage: AppRoute;
    searchPage: AppRoute;
    linkBlockId: string;
    contentCategory: ContentCategory;
    pdpLinkBlockId: string;
    categoryTranslationKey: string;
  }
> = {
  png: {
    authorId: 'pngs',
    parentId: 'lohUUQUWL',
    categoryName: 'PNG',
    homepage: AppRoute.PNG,
    searchPage: AppRoute.PNG_SEARCH,
    linkBlockId: '2UkAsWo7xarSJh12khpdwGZeHjX',
    contentCategory: 'PNGs',
    pdpLinkBlockId: '2UTOCOIGFrZzpBz8CmgQVvM2K5s',
    categoryTranslationKey: 'pages.contentApi.categories.png',
  },
  kawaii: {
    authorId: 'kawaii',
    parentId: 'NQiC1yItM',
    categoryName: 'KAWAII',
    homepage: AppRoute.KAWAII,
    searchPage: AppRoute.KAWAII_SEARCH,
    linkBlockId: '2eUIxbkbZxlFnbw6OJWKqRz7LIY',
    contentCategory: 'Kawaiis',
    pdpLinkBlockId: '2eUIxbkbZxlFnbw6OJWKqRz7LIY',
    categoryTranslationKey: 'pages.contentApi.categories.kawaii',
  },
  mockups: {
    authorId: 'mockups',
    parentId: 'FYK7LrRLd',
    categoryName: 'Mockups',
    homepage: AppRoute.MOCKUPS,
    searchPage: AppRoute.MOCKUPS_SEARCH,
    linkBlockId: '2bAnSE9wB9snbgNZ3yCmJe1z0Ws',
    contentCategory: 'Mockups',
    pdpLinkBlockId: '2bAnSE9wB9snbgNZ3yCmJe1z0Ws',
    categoryTranslationKey: 'pages.contentApi.categories.mockups',
  },
  backgrounds: {
    authorId: 'backgrounds',
    parentId: 'fBU2UowC9',
    categoryName: 'Backgrounds',
    homepage: AppRoute.BACKGROUNDS,
    searchPage: AppRoute.BACKGROUNDS_SEARCH,
    linkBlockId: '2bAnvR3aqCUQ5ThgmgAAhreiDjj',
    contentCategory: 'Backgrounds',
    pdpLinkBlockId: '2bAnvR3aqCUQ5ThgmgAAhreiDjj',
    categoryTranslationKey: 'pages.contentApi.categories.backgrounds',
  },
  sketches: {
    authorId: 'sketches',
    parentId: 'BmQ1JekeE',
    categoryName: 'Sketches',
    homepage: AppRoute.SKETCHES,
    searchPage: AppRoute.SKETCHES_SEARCH,
    linkBlockId: '2bAplIMp17Q1zx3njSnxQlc3Cya',
    contentCategory: 'Sketches',
    pdpLinkBlockId: '2bAplIMp17Q1zx3njSnxQlc3Cya',
    categoryTranslationKey: 'pages.contentApi.categories.sketches',
  },
  patterns: {
    authorId: 'patterns',
    parentId: 'p2DaL9jrp',
    categoryName: 'Patterns',
    homepage: AppRoute.PATTERNS,
    searchPage: AppRoute.PATTERNS_SEARCH,
    linkBlockId: '2bApolMhV9LLs172rUOJCMmyRuS',
    contentCategory: 'Patterns',
    pdpLinkBlockId: '2bApolMhV9LLs172rUOJCMmyRuS',
    categoryTranslationKey: 'pages.contentApi.categories.patterns',
  },
  'borders-and-corners': {
    authorId: 'borders-and-corners',
    parentId: 'Grrz4AP6O',
    categoryName: 'Border and Corners',
    homepage: AppRoute.BORDERS_AND_CORNERS,
    searchPage: AppRoute.BORDERS_AND_CORNERS_SEARCH,
    linkBlockId: '2bAptEIH4XrhDcYxpdwDHhwjKQf',
    contentCategory: 'Border and Corners',
    pdpLinkBlockId: '2bAptEIH4XrhDcYxpdwDHhwjKQf',
    categoryTranslationKey: 'pages.contentApi.categories.borders_and_corners',
  },
  frames: {
    authorId: 'frames',
    parentId: '7otheGtCW',
    categoryName: 'Frames',
    homepage: AppRoute.FRAMES,
    searchPage: AppRoute.FRAMES_SEARCH,
    linkBlockId: '2bApwy5ffNdH459TST4NvUPt5Md',
    contentCategory: 'Frames',
    pdpLinkBlockId: '2bApwy5ffNdH459TST4NvUPt5Md',
    categoryTranslationKey: 'pages.contentApi.categories.frames',
  },
  cartoons: {
    authorId: 'cartoons',
    parentId: 'sD6j3z7oS',
    categoryName: 'Cartoons',
    homepage: AppRoute.CARTOONS,
    searchPage: AppRoute.CARTOONS_SEARCH,
    linkBlockId: '2bAq0G61pM4iQqqM8MYBIT8KEKa',
    contentCategory: 'Cartoons',
    pdpLinkBlockId: '2bAq0G61pM4iQqqM8MYBIT8KEKa',
    categoryTranslationKey: 'pages.contentApi.categories.cartoons',
  },
  textures: {
    authorId: 'textures',
    parentId: 'KAQenoRt3',
    categoryName: 'Textures',
    homepage: AppRoute.TEXTURES,
    searchPage: AppRoute.TEXTURES_SEARCH,
    linkBlockId: '2bAq3X1uA6DCI2AHLmO1WvM29K1',
    contentCategory: 'Textures',
    pdpLinkBlockId: '2bAq3X1uA6DCI2AHLmO1WvM29K1',
    categoryTranslationKey: 'pages.contentApi.categories.textures',
  },
  silhouettes: {
    authorId: 'silhouettes',
    parentId: 'gFrC94WAl',
    categoryName: 'Silhouettes',
    homepage: AppRoute.SILHOUETTES,
    searchPage: AppRoute.SILHOUETTES_SEARCH,
    linkBlockId: '2bAqHc67idFxrCZ6SaRsAr1fJZJ',
    contentCategory: 'Silhouettes',
    pdpLinkBlockId: '2bAqHc67idFxrCZ6SaRsAr1fJZJ',
    categoryTranslationKey: 'pages.contentApi.categories.silhouettes',
  },
  drawings: {
    authorId: 'drawings',
    parentId: '0cisT9V5O',
    categoryName: 'Drawings',
    homepage: AppRoute.DRAWINGS,
    searchPage: AppRoute.DRAWINGS_SEARCH,
    linkBlockId: '2bAqLD9saUy5PlGmT326Ngz1zgm',
    contentCategory: 'Drawings',
    pdpLinkBlockId: '2bAqLD9saUy5PlGmT326Ngz1zgm',
    categoryTranslationKey: 'pages.contentApi.categories.drawings',
  },
  'clip-art': {
    authorId: 'clip-art',
    parentId: 'mUe4UzBk9',
    categoryName: 'Clip Art',
    homepage: AppRoute.CLIP_ART,
    searchPage: AppRoute.CLIP_ART_SEARCH,
    linkBlockId: '2bAqOs2ifKRzYvyfQ3ndVe8ADKA',
    contentCategory: 'Clip Art',
    pdpLinkBlockId: '2bAqOs2ifKRzYvyfQ3ndVe8ADKA',
    categoryTranslationKey: 'pages.contentApi.categories.clip_art',
  },
  quotes: {
    authorId: 'quotes',
    parentId: 'tV06AU87u',
    categoryName: 'Quotes',
    homepage: AppRoute.QUOTES,
    searchPage: AppRoute.QUOTES_SEARCH,
    linkBlockId: '2crqOOER9SCamphCuej13Se8CgC',
    contentCategory: 'Quotes',
    pdpLinkBlockId: '2crqOOER9SCamphCuej13Se8CgC',
    categoryTranslationKey: 'pages.contentApi.categories.quotes',
  },
  stickers: {
    authorId: 'stickers',
    parentId: 'qIn5Sdma7',
    categoryName: 'Stickers',
    homepage: AppRoute.STICKERS,
    searchPage: AppRoute.STICKERS_SEARCH,
    linkBlockId: '2bAqTFL3bJGRdhFOAfwn7CeNygI',
    contentCategory: 'Stickers',
    pdpLinkBlockId: '2bAqTFL3bJGRdhFOAfwn7CeNygI',
    categoryTranslationKey: 'pages.contentApi.categories.stickers',
  },
  svgs: {
    authorId: 'svgs',
    parentId: 'L9LeUsIul',
    categoryName: 'SVGs',
    homepage: AppRoute.SVGS,
    searchPage: AppRoute.SVGS_SEARCH,
    linkBlockId: '2bAqWpkZ18ktBEMdObeHsbSP9CO',
    contentCategory: 'SVGs',
    pdpLinkBlockId: '2bAqWpkZ18ktBEMdObeHsbSP9CO',
    categoryTranslationKey: 'pages.contentApi.categories.svgs',
  },
  'coloring-pages': {
    authorId: 'coloring-pages',
    parentId: 'CJOftDuBI',
    categoryName: 'Coloring pages',
    homepage: AppRoute.COLORING_PAGES,
    searchPage: AppRoute.COLORING_PAGES_SEARCH,
    linkBlockId: '2crskwmH5Rj792DXQPJUWQuQv5U',
    contentCategory: 'Coloring pages',
    pdpLinkBlockId: '2crskwmH5Rj792DXQPJUWQuQv5U',
    categoryTranslationKey: 'pages.contentApi.categories.coloring_pages',
  },
};
