import { useEffect } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';

type UseLocalStorageSync = <A>(props: {
  key: string;
  value: A;
  compareFn: (value: A, storedValue: A | null) => boolean;
  onUpdate: (updatedValue: A | null) => void;
}) => void;

export const useLocalStorageSync: UseLocalStorageSync = props => {
  const { key, value, compareFn, onUpdate } = props;
  const [storedValue, setStoredValue] = useLocalStorage(key, value);
  const isEqual = compareFn(value, storedValue);

  useEffect(() => {
    if (isEqual) {
      return;
    }

    onUpdate(storedValue);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedValue]);

  useEffect(() => {
    if (isEqual) {
      return;
    }

    setStoredValue(value);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
};
