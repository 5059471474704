import React from 'react';
import { useIntl } from 'react-intl';
import { faLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useOnHover } from 'hooks/useOnHover';

import { IconLockOpen, IconLockOutlined } from '../../elements';
import * as S from './PostCardHeader.styles';
import { ActionMenu } from '../ActionMenu/ActionMenu';
import { Avatar } from '../Avatar/Avatar';
import { AvatarSize } from '../Avatar/Avatar.types';
import { PostVisibility } from '../PostCard/PostCard.types';
import { PostType } from '../PostCardContent/PostCardContent.types';
import { PostCardHeaderProps } from './PostCardHeader.types';

const T1m = 60;
const T1h = T1m * 60;
const T1d = T1h * 24;
const T7d = T1d * 7;
const T30d = T1d * 30;
const T1y = T30d * 12;

export const PostCardHeader = ({
  author,
  user,
  url,
  id,
  visibility,
  time,
  showPostVisibility,
  showPostActionType,
  type,
  defaultAvatar,
  onPostDelete,
}: PostCardHeaderProps) => {
  const { formatMessage } = useIntl();
  const [hoverRef, isHovered] = useOnHover();

  const visibilityLabel =
    visibility === PostVisibility.MembersOnly
      ? formatMessage({ id: `component.post_card.members_only` })
      : formatMessage({ id: `component.post_card.public_post` });

  const visibilityIcon =
    visibility === PostVisibility.MembersOnly ? (
      <IconLockOutlined />
    ) : (
      <IconLockOpen />
    );

  const postActionForType = (postType: string): string => {
    switch (postType) {
      case PostType.Article:
        return formatMessage({
          id: 'component.post_card.action.published_an_article',
        });
      case PostType.Class:
        return formatMessage({
          id: 'component.post_card.action.published_a_class',
        });
      case PostType.Project:
        return formatMessage({
          id: 'component.post_card.action.added_a_project',
        });
    }

    return formatMessage({ id: 'component.post_card.action.added_a_post' });
  };

  const timeAgo = (date: Date): string => {
    const timeInMillis = +date;
    const seconds = (+new Date() - timeInMillis) / 1000;

    const roundTo = (secondsValue: number, period: number) =>
      Math.floor(secondsValue / period);

    switch (true) {
      case seconds < T1m:
        return formatMessage({ id: 'component.post_card.time.just_now' });
      case seconds < T1h:
        return formatMessage(
          { id: 'component.post_card.time.minutes_ago' },
          { minutes: roundTo(seconds, T1m) }
        );
      case seconds < T1d:
        return formatMessage(
          { id: 'component.post_card.time.hours_ago' },
          { hours: roundTo(seconds, T1h) }
        );
      case seconds < T7d:
        return formatMessage(
          { id: 'component.post_card.time.days_ago' },
          { days: roundTo(seconds, T1d) }
        );
      case seconds < T30d:
        return formatMessage(
          { id: 'component.post_card.time.weeks_ago' },
          { weeks: roundTo(seconds, T7d) }
        );
      case seconds < T1y:
        return formatMessage(
          { id: 'component.post_card.time.months_ago' },
          { months: roundTo(seconds, T30d) }
        );
      default:
        return formatMessage(
          { id: 'component.post_card.time.years_ago' },
          { years: roundTo(seconds, T1y) }
        );
    }
  };

  /*
  TODO: Add Pinned Post
  TODO: Add Menu
  */

  return (
    <S.HeaderWrapper ref={hoverRef}>
      <Avatar
        size={AvatarSize.MEDIUM}
        src={author?.avatar || defaultAvatar}
        alt={author.name}
      />

      <S.ActionMenu>
        {isHovered && (
          <ActionMenu>
            {user.id === author.id && onPostDelete ? (
              <ActionMenu.Button
                onClick={() => onPostDelete(id)}
                icon={<FontAwesomeIcon icon={faTrash} />}
                variant="warning"
              >
                {formatMessage({
                  id: 'component.post_card.action_menu.delete_post',
                })}
              </ActionMenu.Button>
            ) : null}
            <ActionMenu.Link
              href={url}
              icon={<FontAwesomeIcon icon={faLink} />}
            >
              {formatMessage({
                id: 'component.post_card.action_menu.view_post',
              })}
            </ActionMenu.Link>
          </ActionMenu>
        )}
      </S.ActionMenu>

      <S.HeaderWrapperInfo>
        <S.HeaderWrapperMainColumn>
          <S.SingleLine>
            <S.AuthorName variant="h6">
              <S.AuthorAnchor href={author.url}>{author?.name}</S.AuthorAnchor>
            </S.AuthorName>

            {showPostActionType && (
              <S.PostAction variant="subtext">
                {postActionForType(type)}
              </S.PostAction>
            )}
          </S.SingleLine>

          <S.SingleLine>
            {time && <S.TimeLabel>{timeAgo(time)}</S.TimeLabel>}
          </S.SingleLine>
        </S.HeaderWrapperMainColumn>

        <S.HeaderWrapperRightColumn>
          {/* TODO: !disable && !isPinned && <PostMenu> */}
          {showPostVisibility && (
            <S.VisibilityInfo>
              {visibilityIcon}
              {visibilityLabel}
            </S.VisibilityInfo>
          )}
        </S.HeaderWrapperRightColumn>
      </S.HeaderWrapperInfo>
    </S.HeaderWrapper>
  );
};
