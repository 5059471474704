import { ApiEndpoint } from 'api/fetcher.types';
import { fetcher } from 'api/fetcher';

import { NonceData, NonceFetcherParams } from './nonce.types';

export const nonceFetcher = (params: NonceFetcherParams) => {
  const { nonceType, cookieString } = params;

  const body = JSON.stringify({
    nonce: nonceType,
  });

  const fetchData = fetcher<NonceData>({
    url: ApiEndpoint.NONCE,
    options: {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' },
    },
    cookieString,
  });

  return fetchData;
};
