import { User } from 'api/requests/user/user.types';
import { RequestErrorHandler } from 'hooks/useRequestErrorHandler/useRequestErrorHandler.types';

export enum RegisterChallenge {
  ONE_TIME_PASSWORD = 'otp',
}

export type RegisterFetcherParams = {
  email: string;
  password: string;
  terms: boolean;
  otp?: string;
  onError?: RequestErrorHandler;
};

export type RegisterData = {
  user: User;
};

export type VerificationData = {
  challenge: RegisterChallenge;
  otp?: string;
  text: string;
};
