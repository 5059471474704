import { Box, styled } from '@mui/material';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { LoadingOverlayProps } from './LoadingOverlay.types';

export const BoxRoot = styled(Box)({
  position: 'relative',
});

export const BoxContent = styled(Box, {
  shouldForwardProp: (prop) => !['isLoading'].includes(prop as string),
})<LoadingOverlayProps>(({ isLoading }) => ({
  filter: 'none',
  ...(isLoading && {
    filter: 'blur(1.5px)',
  }),
}));

export const BoxOverlay = styled(Box, {
  shouldForwardProp: (prop) => !['isLoading'].includes(prop as string),
})<{ isLoading?: boolean }>(({ theme, isLoading }) => {
  const spinnerSvg = icon(faSpinner, {
    styles: { color: encodeURIComponent(theme.palette.black.main) },
  }).html[0];

  return {
    display: 'none',
    ...(isLoading && {
      position: 'absolute',
      display: 'block',
      top: '-2px',
      right: '-2px',
      bottom: '-2px',
      left: '-2px',
      borderRadius: '2px',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '0 0 2px rgba(255, 255, 255, 0.6)',
      zIndex: 2,
      '&::after': {
        content: `url('data:image/svg+xml;utf8,${spinnerSvg}')`,
        position: 'absolute',
        height: theme.spacing(4),
        width: theme.spacing(4),
        top: '50%',
        left: '50%',
        marginLeft: theme.spacing(-2),
        marginTop: theme.spacing(-2),
        animation: 'spin .75s linear infinite',
        opacity: 0.6,
        zIndex: 3,
      },
      '@keyframes spin': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(359deg)',
        },
      },
    }),
  };
});
