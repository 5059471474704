import { NextPage } from 'next';
import { ReactElement, ReactNode } from 'react';
import { AppProps } from 'next/app';
import { EmotionCache } from '@emotion/react';
import type { AnyObject, NullableObject } from 'types/utils';
import { DehydratedState } from '@tanstack/react-query';

import { UserData } from 'api/requests/user/user.types';
import { ServerRequestsLog } from 'utilities/serverRequestsLog';
import { CurrencyData, SiteInfoData } from 'api/requests/site/site.types';

export enum AppRoute {
  CF_STUDIO = 'http://studio.creativefabrica.com/',

  REACT_HOME = '/react/homepage/',
  REACT_SEARCH = '/react/search/',
  REACT_PRODUCT = '/react/product/',
  REACT_CART = '/react/cart/',
  REACT_CHECKOUT = '/react/checkout/',
  REACT_PS_CHECKOUT = '/react/ps_checkout/',

  HOME = '/',

  STOCKPHOTOS = '/stockphotos/',
  STOCKPHOTOS_SEARCH = '/stockphotos/search/',
  STOCKPHOTOS_LICENCE = '/stockphoto-license/',
  PNG = '/png/',
  PNG_SEARCH = '/png/search/',
  KAWAII = '/kawaii/',
  KAWAII_SEARCH = '/kawaii/search/',
  MOCKUPS = '/mockups/',
  MOCKUPS_SEARCH = '/mockups/search/',
  BACKGROUNDS = '/backgrounds/',
  BACKGROUNDS_SEARCH = '/backgrounds/search/',
  SKETCHES = '/sketches/',
  SKETCHES_SEARCH = '/sketches/search/',
  PATTERNS = '/patterns/',
  PATTERNS_SEARCH = '/patterns/search/',
  BORDERS_AND_CORNERS = '/borders-and-corners/',
  BORDERS_AND_CORNERS_SEARCH = '/borders-and-corners/search/',
  FRAMES = '/frames/',
  FRAMES_SEARCH = '/frames/search/',
  CARTOONS = '/cartoons/',
  CARTOONS_SEARCH = '/cartoons/search/',
  TEXTURES = '/textures/',
  TEXTURES_SEARCH = '/textures/search/',
  SILHOUETTES = '/silhouettes/',
  SILHOUETTES_SEARCH = '/silhouettes/search/',
  DRAWINGS = '/drawings/',
  DRAWINGS_SEARCH = '/drawings/search/',
  CLIP_ART = '/clip-art/',
  CLIP_ART_SEARCH = '/clip-art/search/',
  QUOTES = '/quotes/',
  QUOTES_SEARCH = '/quotes/search/',
  STICKERS = '/stickers/',
  STICKERS_SEARCH = '/stickers/search/',
  SVGS = '/svgs/',
  SVGS_SEARCH = '/svgs/search/',
  COLORING_PAGES = '/coloring-pages/',
  COLORING_PAGES_SEARCH = '/coloring-pages/search/',

  CREATE = '/create/',
  TEMPLATES = '/templates/',

  SEARCH = '/search/',

  PRODUCT = '/product/',
  PROD = '/prod/',

  IDEAS_WEDDING = '/ideas/wedding/',
  IDEAS_EASTER = '/ideas/easter/',
  IDEAS_HALLOWEEN = '/ideas/halloween/',
  IDEAS_MOTHERS_DAY = '/ideas/mothers-day/',
  IDEAS_FOURTH_OF_JULY = '/ideas/fourth-of-july/',
  IDEAS_CHRISTMAS = '/ideas/christmas/',
  EVENTS_CHRISTMAS = '/events/christmas-2023/',

  FONTS = '/fonts/',
  FONTS_SCRIPT_AND_HANDWRITTEN = '/fonts/script-fonts/',
  FONTS_DISPLAY = '/fonts/display-fonts/',
  FONTS_SANS_SERIF = '/fonts/sans-serif-fonts/',
  FONTS_SERIF = '/fonts/serif-fonts/',
  FONTS_BLACKLETTER = '/fonts/blackletter-fonts/',
  FONTS_SLAB_SERIF = '/fonts/slab-serif-fonts/',
  FONTS_DECORATIVE = '/fonts/decorative-fonts/',
  FONTS_DINGBATS = '/fonts/dingbats-fonts/',
  FONTS_COLOR_FONTS = '/fonts/color-fonts/',
  FONTS_PROMOTIONS = '/promotions/fonts/',

  FREE_TRIAL = '/p/free-trial/',

  B_FREE_TRIAL = '/b/free-trial/',
  B_MONTHLY = '/b/monthly/',
  B_YEARLY = '/b/yearly/',
  B_YEARLY_PROMO = '/b/yearly-promo/',

  GRAPHICS = '/graphics/',
  GRAPHICS_3D_SVG = '/subscriptions/graphics/3d-svg-graphics/',
  GRAPHICS_HOME_CRAFTS = '/subscriptions/graphics/crafts-graphics/',
  GRAPHICS_COLORING_BOOKS_ADULTS = '/subscriptions/graphics/coloring-pages-books/adults/',
  GRAPHICS_COLORING_BOOKS_KIDS = '/subscriptions/graphics/coloring-pages-books/kids-coloring-pages-books/',
  GRAPHICS_TEACHING_MATERIALS = '/subscriptions/graphics/teaching-materials/',
  GRAPHICS_PATTERNS = '/subscriptions/graphics/patterns/',
  GRAPHICS_NEEDLE_WORK = '/subscriptions/graphics/needle-arts/',
  GRAPHICS_CROCHET_PATTERS = '/subscriptions/graphics/needle-arts/crochet/',
  GRAPHICS_KNITTING_PATTERNS = '/subscriptions/graphics/needle-arts/knitting/',
  GRAPHICS_CROSS_STITCH_PATTERNS = '/subscriptions/graphics/needle-arts/cross-stitch-patterns/',
  GRAPHICS_QUILT_PATTERNS = '/subscriptions/graphics/needle-arts/quilt-patterns/',
  GRAPHICS_SEWING_PATTERS = '/subscriptions/graphics/needle-arts/sewing-patterns/',
  GRAPHICS_PRODUCT_MOCKUPS = '/subscriptions/graphics/product-mockups/',
  GRAPHICS_LOGOS = '/subscriptions/graphics/graphic-templates/logos/',
  GRAPHICS_ILLUSTRATIONS = '/subscriptions/graphics/illustrations/',
  GRAPHICS_BACKGROUNDS = '/subscriptions/graphics/backgrounds/',
  GRAPHICS_KDP_INTERIORS = '/subscriptions/graphics/kdp-interiors/',
  GRAPHICS_ACTIONS_AND_PRESETS = '/subscriptions/graphics/add-ons/actions-presets/',
  GRAPHICS_SCENE_GENERATORS = '/subscriptions/graphics/graphic-templates/scene-generators/',
  GRAPHICS_UX_AND_UI_KITS = '/subscriptions/graphics/graphic-templates/ux-and-ui-kits/',
  GRAPHICS_PROMOTIONS = '/promotions/graphics/',

  THREE_D = '/3d-svg/',
  THREE_D_HOLIDAYS = '/subscriptions/3d-svg/holidays-3d-svg/',
  THREE_D_FLOWERS = '/subscriptions/3d-svg/flowers-3d-svg/',
  THREE_D_KIDS_CRAFTS = '/subscriptions/3d-svg/kids-crafts-3d-svg/',
  THREE_D_PAPER_SCULPTURES = '/subscriptions/3d-svg/paper-sculptures-3d-svg/',
  THREE_D_SEASONS = '/subscriptions/3d-svg/seasons-3d-svg/',
  THREE_D_BABY = '/subscriptions/3d-svg/baby-3d-svg/',
  THREE_D_RELIGIOUS = '/subscriptions/3d-svg/religious-3d-svg/',
  THREE_D_HOME_DECOR = '/subscriptions/3d-svg/home-decor-3d-svg/',
  THREE_D_LIFE_EVENTS_AND_CELEBRATIONS = '/subscriptions/3d-svg/life-events-and-celebrations-3d-svg/',
  THREE_D_COUNTRIES_AND_TRAVEL = '/subscriptions/3d-svg/countries-and-travel-3d-svg/',
  THREE_D_REMEMBRANCE = '/subscriptions/3d-svg/remembrance-3d-svg/',
  THREE_D_ANIMALS = '/subscriptions/3d-svg/animals-3d-svg/',
  THREE_D_LIFESTYLE_AND_HOBBIES = '/subscriptions/3d-svg/lifestyle-and-hobbies-3d-svg/',
  THREE_D_KIDS = '/subscriptions/3d-svg/kids-3d-svg/',
  THREE_D_WEDDING = '/subscriptions/3d-svg/wedding-3d-svg/',

  CRAFTS = '/crafts/',
  CRAFT_CLUB = '/the-craft-club/',
  CRAFT_CLUB_DEALS = '/the-craft-club/deals/',
  CRAFTS_HALLOWEEN = '/subscriptions/crafts/halloween/',
  CRAFTS_CHRISTMAS = '/subscriptions/crafts/christmas/',
  CRAFTS_THANKSGIVING = '/subscriptions/crafts/thanksgiving/',
  CRAFTS_KIDS = '/subscriptions/crafts/kids/',
  CRAFTS_FALL = '/subscriptions/crafts/fall/',
  CRAFTS_EASTER = '/subscriptions/crafts/easter/',
  CRAFTS_SPRING = '/subscriptions/crafts/spring/',

  ARTISTRY = '/the-artistry/',

  EMBROIDERY = '/embroidery/',

  ETSY_FEE_CALCULATOR = '/etsy-fee-calculator/',

  SPARK = '/spark/',
  SPARK_LICENSE = '/spark-license',
  SPARK_ART_GENERATOR = '/spark/tools/art-generator/',
  SPARK_PATTERNS = '/spark/tools/pattern-generator/',
  SPARK_TEXT_GENERATOR = '/spark/tools/text-generator/',
  SPARK_COLORING_PAGE_GENERATOR = '/spark/tools/coloring-page-generator/',
  SPARK_TEXT_GENERATOR_LP_ETSY_BIO_GENERATOR = '/spark/etsy-bio-generator/',
  SPARK_TEXT_GENERATOR_LP_ETSY_LISTING_DESCRIPTION_GENERATOR = '/spark/etsy-listing-description-generator/',
  SPARK_TEXT_GENERATOR_LP_ETSY_SHOP_NAME_LP = '/spark/etsy-shop-name-generator/',
  SPARK_TEXT_GENERATOR_ETSY_LISTING_TITLE_LP = '/spark/etsy-listing-title-generator/',
  SPARK_TEXT_GENERATOR_ETSY_STORE_NAME = '/spark/tools/text-generator/ai-tools/etsy-store-name/',
  SPARK_TEXT_GENERATOR_ETSY_LISTING_DESCRIPTION = '/spark/tools/text-generator/ai-tools/etsy-product-description/',
  SPARK_TEXT_GENERATOR_ETSY_PRODUCT_TITLE = '/spark/tools/text-generator/ai-tools/etsy-product-title/',
  SPARK_TEXT_GENERATOR_ETSY_BIO_GENERATOR = '/spark/tools/text-generator/ai-tools/etsy-store-bio/',
  SPARK_TEXT_GENERATOR_COLD_EMAIL_TOOL = '/spark/tools/text-generator/ai-tools/cold-email-tool/',
  SPARK_TEXT_GENERATOR_AD_COPY_TOOL = '/spark/tools/text-generator/ai-tools/ad-copy-tool/',
  SPARK_TEXT_GENERATOR_HEADLINE_TOOL = '/spark/tools/text-generator/ai-tools/headline-tool/',
  SPARK_TEXT_GENERATOR_PARAGRAPH_TOOL = '/spark/tools/text-generator/ai-tools/paragraph-tool/',
  SPARK_TEXT_GENERATOR_COPYWRITE_TOOL = '/spark/tools/text-generator/ai-tools/copywriting-pas-problem-agitate-solve-tool/',
  SPARK_TEXT_GENERATOR_ARTICLE_OUTLINE_TOOL = '/spark/tools/text-generator/ai-tools/article-outline-tool/',
  SPARK_TEXT_GENERATOR_BOOK_IDEA_TITLE_TOOL = '/spark/tools/text-generator/ai-tools/book-idea-title-tool/',
  SPARK_TEXT_GENERATOR_FACEBOOK_AD_TOOL = '/spark/tools/text-generator/ai-tools/facebook-ad-tool/',
  SPARK_TEXT_GENERATOR_STARTUP_IDEA_TOOL = '/spark/tools/text-generator/ai-tools/startup-idea-tool/',
  SPARK_TEXT_GENERATOR_FULL_ARTICLE_GENERATOR_TOOL = '/spark/tools/text-generator/ai-tools/full-article-generator-tool/',
  SPARK_TEXT_GENERATOR_ESSAY_WRITER_TOOL = '/spark/tools/text-generator/ai-tools/essay-writer/',
  SPARK_TEXT_GENERATOR_BOOK_CHAPTER_TOOL = '/spark/tools/text-generator/ai-tools/book-chapter-tool/',
  SPARK_TEXT_GENERATOR_WEBSITE_SEO_META_DESCRIPTION_TOOL = '/spark/tools/text-generator/ai-tools/website-seo-meta-description-tool/',
  SPARK_TEXT_GENERATOR_REWRITER_TOOL = '/spark/tools/text-generator/ai-tools/rewriter-tool/',
  SPARK_TEXT_GENERATOR_GREETING_CARD_TOOL = '/spark/tools/text-generator/ai-tools/greeting-card-seasonal-wishes-writer-tool/',
  SPARK_TEXT_GENERATOR_BOOK_PARAGRAPH_TOOL = '/spark/tools/text-generator/ai-tools/book-paragraph-tool/',
  SPARK_VIDEO_GENERATOR = '/spark/tools/video-generator',
  SPARK_IMAGEMIX = '/spark/tools/imagemix',
  SPARK_COLORING_PAGE = '/spark/tools/coloring-page-generator/',
  SPARK_IMAGEMIX_SHARE = '/spark/tools/imagemix/share/{id}/',
  SPARK_CRYSTALLINE = '/spark/tools/crystalline/',
  SPARK_TRANSPARENT_PNG_MAKER = '/spark/transparent-png-maker/',
  SPARK_SKETCH_GENERATOR = '/spark/tools/sketch-generator/',
  SPARK_AI_GENERATOR = '/spark/ai-sketch-generator/',
  SPARK_COLORING_PAGES = '/spark/coloring-pages/',
  SPARK_FLOW = '/spark/flow/',
  SPARK_IMAGE_UPSCALER = '/spark/tools/image-upscaler/',
  SPARK_BROWSER_EXTENSION = 'https://chrome.google.com/webstore/detail/cf-spark-browser-plugin/fffiooegpamkcknjfhemelbggljkegpj',
  PROMPT_BUILDER = '/spark/tools/prompt-builder/',
  PROMPT_BOOK = '/spark/prompt-book/',
  HELP_CENTER = '/spark/help-center/',
  PERSONAL_FEED = '/spark/personal-feed/',
  COMMUNITY_FEED = '/spark/community-feed/',
  MY_CREATIONS = '/creator/',
  MY_PRIVATE_DOWNLOADS = '/spark/private-downloads/',
  COMMUNITY = '/community/',
  COMMUNITY_SEARCH = '/community/search/',
  COMMUNITY_ART = '/community/graphics-community/',
  COMMUNITY_PATTERNS = '/community/patterns-community/',
  COMMUNITY_TRANSPARENT_PNGS = '/community/community-transparent-pngs/',
  COMMUNITY_SKETCHES = '/community/sketches/',
  COMMUNITY_COLORING_PAGES = '/community/community-coloring-pages/',
  AI_IMAGE_GENERATOR = '/spark/ai-image-generator/',
  AI_PATTERN_GENERATOR = '/spark/ai-pattern-generator/',
  SUBSCRIPTION_SPARK = '/subscription/spark/',

  CLASSES = '/classes/',
  CLASSES_CRICUT = '/subscriptions/classes/cricut/',
  CLASSES_GRAPHIC_DESIGN = '/subscriptions/classes/graphic-design/',
  CLASSES_ILLUSTRATING = '/subscriptions/classes/illustrating/',
  CLASSES_PRINTING_SUBLIMATION = '/subscriptions/classes/printing-sublimation/',
  CLASSES_PAPER_CRAFTING = '/subscriptions/classes/paper-crafting/',
  CLASSES_ORGANIZING_PLANNING = '/subscriptions/classes/organizing-planning/',
  CLASSES_BUSINESS = '/subscriptions/classes/craft-business/',
  CLASSES_ETSY = '/subscriptions/classes/etsy/',

  TEACHERS = '/teachers/',
  TEACHERS_CLUB_CARD = '/teachers/',

  CF_PREMIUM = '/premium/',
  CF_PREMIUM_ANNE_STOKES = '/brand/anne-stokes/',
  CF_PREMIUM_FRIDA_KAHLO = '/brand/frida-kahlo/',
  CF_PREMIUM_MOOMIN = '/brand/moomin/',
  CF_PREMIUM_STRANGER_THINGS = '/brand/stranger-things/',
  CF_PREMIUM_THE_SCOUTS = '/brand/the-scouts/',
  CF_PREMIUM_THE_SMURFS = '/brand/the-smurfs/',
  TOOLS = '/tools/',
  TOOLS_FONT_GENERATOR = '/tools/font-generator/',
  TOOLS_WEBDINGS_TRANSLATOR = '/tools/webdings-translator/',
  TOOLS_GLITCH_TEXT_GENERATOR = '/tools/glitch-text-generator/',
  TOOLS_SMALL_TEXT_GENERATOR = '/tools/small-text-generator/',
  TOOLS_CURSED_TEXT_GENERATOR = '/tools/cursed-text-generator/',
  TOOLS_BOLD_TEXT_GENERATOR = '/tools/bold-text-generator/',
  TOOLS_ZALGO_TEXT_GENERATOR = '/tools/zalgo-text-generator/',
  TOOLS_INSTAGRAM_FONTS_GENERATOR = '/tools/instagram-fonts-generator/',
  TOOLS_COOL_FONTS_GENERATOR = '/tools/cool-fonts-generator/',
  TOOLS_CREEPY_TEXT_GENERATOR = '/tools/creepy-text-generator/',
  TOOLS_CURSIVE_FONT_GENERATOR = '/tools/cursive-font-generator/',
  TOOLS_ITALICS_TEXT_GENERATOR = '/tools/italics-text-generator/',
  TOOLS_WINGDINGS_TRANSLATOR = '/tools/wingdings-translator/',
  TOOLS_UPSIDE_DOWN_TEXT_GENERATOR = '/tools/upside-down-text-generator/',
  TOOLS_GOTHIC_FONT_GENERATOR = '/tools/gothic-font-generator/',
  TOOLS_AESTHETIC_FONTS_GENERATOR = '/tools/aesthetic-fonts-generator/',
  TOOLS_TWITTER_FONTS_GENERATOR = '/tools/twitter-fonts-generator/',
  TOOLS_DISCORD_FONTS_GENERATOR = '/tools/discord-fonts-generator/',
  TOOLS_STRIKE_THROUGH_TEXT_GENERATOR = '/tools/strike-through-text-generator/',
  TOOLS_CURVE_TEXT_GENERATOR = '/tools/curve-text-generator/',
  TOOLS_FACEBOOK_FONTS_GENERATOR = '/tools/facebook-fonts-generator/',
  TOOLS_BIG_TEXT_GENERATOR = '/tools/big-text-generator/',
  TOOLS_STYLISH_TEXT_GENERATOR = '/tools/stylish-text-generator/',
  TOOLS_TYPEWRITER_FONT_GENERATOR = '/tools/typewriter-font-generator/',
  TOOLS_SPECIAL_TEXT_GENERATOR = '/tools/special-text-generator/',
  TOOLS_VAPORWAVE_TEXT_GENERATOR = '/tools/vaporwave-text-generator/',
  TOOLS_MIRROR_TEXT_GENERATOR = '/tools/mirror-text-generator/',
  TOOLS_HACKED_TEXT_GENERATOR = '/tools/hacked-text-generator/',
  TOOLS_BUBBLE_WORDS_GENERATOR = '/tools/bubble-words-generator/',
  TOOLS_GREEK_TEXT_GENERATOR = '/tools/greek-text-generator/',
  TOOLS_CALLIGRAPHY_FONT_GENERATOR = '/tools/calligraphy-font-generator/',
  TOOLS_ASCII_ART_GENERATOR = '/tools/ascii-art-generator/',
  TOOLS_UNDERLINE_TEXT_GENERATOR = '/tools/underline-text-generator/',
  TOOLS_TIKTOK_FONT_GENERATOR = '/tools/tiktok-font-generator/',
  TOOLS_SCARY_TEXT_GENERATOR = '/tools/scary-text-generator/',
  TOOLS_NUMBER_FONT_GENERATOR = '/tools/number-font-generator/',
  TOOLS_WIDE_TEXT_GENERATOR = '/tools/wide-text-generator/',
  TOOLS_BLOCK_FONT_GENERATOR = '/tools/block-font-generator/',
  TOOLS_FUTURISTIC_FONT_GENERATOR = '/tools/futuristic-font-generator/',

  FEATURES = '/studio/features/',
  FEATURES_BACKGROUND_REMOVER = '/studio/features/background-remover/',
  FEATURES_VECTORIZE_IMAGE = '/studio/features/vectorize-image/',
  FEATURES_JPG_TO_SVG_CONVERTER = '/studio/features/jpg-to-svg-converter/',
  FEATURES_PNG_TO_SVG_CONVERTER = '/studio/features/png-to-svg-converter/',
  FEATURES_ROTATE_IMAGE = '/studio/features/rotate-image/',
  FEATURES_CROP_IMAGE = '/studio/features/crop-image/',
  FEATURES_TRANSPARENT_BACKGROUND_MAKER = '/studio/features/transparent-background-maker/',
  FEATURES_MIRROR_IMAGE = '/studio/features/mirror-image/',
  FEATURES_JPG_TO_PDF_CONVERTER = '/studio/features/jpg-to-pdf-converter/',
  FEATURES_JPG_TO_PNG_CONVERTER = '/studio/features/jpg-to-png-converter/',
  FEATURES_CHANGE_COLOR_OF_IMAGE = '/studio/features/change-color-of-image/',
  FEATURES_JPEG_TO_JPG_CONVERTER = '/studio/features/jpeg-to-jpg-converter/',
  FEATURES_BLACK_AND_WHITE_FILTER = '/studio/features/black-and-white-filter/',
  FEATURES_CHANGE_PHOTO_BACKGROUND_COLOR = '/studio/features/change-photo-background-color/',
  FEATURES_ADD_TEXT_ON_IMAGE = '/studio/features/add-text-on-image/',
  FEATURES_ADD_EMOJI_TO_PHOTO = '/studio/features/add-emoji-to-photo/',
  FEATURES_PNG_TO_JPG_CONVERTER = '/studio/features/png-to-jpg-converter/',
  FEATURES_CIRCLE_CROP = '/studio/features/circle-crop/',
  FEATURES_OVERLAY_IMAGES = '/studio/features/overlay-images/',
  FEATURES_COMBINE_IMAGES = '/studio/features/combine-images/',
  FEATURES_REMOVE_BACKGROUND_FROM_LOGO = '/studio/features/remove-background-from-logo/',
  FEATURES_RESIZE_PHOTO_FOR_INSTAGRAM = '/studio/features/resize-photo-for-instagram/',
  FEATURES_CROP_PNG = '/studio/features/crop-png/',
  FEATURES_RESIZE_JPEG = '/studio/features/resize-jpeg/',
  FEATURES_RESIZE_ICON = '/studio/features/resize-icon/',
  FEATURES_RESIZE_PNG = '/studio/features/resize-png/',
  FEATURES_CROP_JPG = '/studio/features/crop-jpg/',
  FEATURES_IMAGE_ENHANCER = '/studio/features/image-enhancer/',
  FEATURES_BLUR_IMAGE = '/studio/features/blur-image/',
  FEATURES_DARKEN_IMAGE = '/studio/features/darken-image/',
  FEATURES_EDIT_PDF = '/studio/features/edit-pdf/',
  FEATURES_SHARPEN_IMAGE = '/studio/features/sharpen-image/',
  FEATURES_PHOTO_FILTER_EFFECTS = '/studio/features/photo-filter-effects/',
  FEATURES_WATERMARK_TO_PHOTOS = '/studio/features/watermark-to-photos/',
  FEATURES_BLUR_BACKGROUND = '/studio/features/blur-background/',
  FEATURES_UNBLUR_IMAGE = '/studio/features/unblur-image/',
  FEATURES_AI_INPAINTING_TOOL = '/studio/features/ai-inpainting-tool/',
  FEATURES_ANIMATE_PICTURES = '/studio/features/animate-pictures/',

  TOOLS_FONTCLOUD = '/fontcloud/',
  TOOLS_SHAPECLOUD = '/shapecloud/',
  TOOLS_WEBFONT_GENERATOR = '/webfont-generator/',

  POD = '/pod/',

  BUNDLES = '/bundles/',

  PICK_N_MIX_BUNDLE = '/pick-n-mix-bundle/',

  FANS = 'https://fans.creativefabrica.com/',
  FANS_STAGING = 'https://fans-staging.creativefabrica.com/',

  SUBSCRIPTION = '/subscription/',

  FREEBIES = '/freebies/',
  FREEBIES_FONTS = '/freebies/free-fonts/',
  FREEBIES_SVGS = '/freebies/free-crafts/',
  FREEBIES_GRAPHICS = '/freebies/free-graphics/',
  FREEBIES_EMBROIDERY = '/freebies/free-embroidery-designs/',
  FREEBIES_CLASSES = '/search/?type=Classes&category=Freebies',

  COINS = '/coins/',
  COINS_TRANSACTION_HISTORY = '/coins/transactions/',

  DISCOUNT_DEALS = '/discount-deals/',

  DAILY_GIFTS = '/daily-gifts/',

  SIGN_UP = '/signup/',
  LOGIN = '/login/',
  LOGOUT = '/my-account/customer-logout/',
  NOTIFICATIONS = '/feed/notifications/',
  CART = '/cart/',
  CHECKOUT = '/checkout/',
  PS_CHECKOUT = '/ps_checkout/',

  MY_STORE = '/designer/',
  MY_STORE_DASHBOARD = '/dashboard/',
  MY_STORE_BOOST_STORE = '/dashboard/manage/boost/',
  MY_STORE_SEARCH_ANALYTICS = '/dashboard/manage/search-analytics/',
  MY_STORE_STORE_SETTINGS = '/dashboard/personal-data/',
  MY_STORE_ADD_FONT = '/dashboard/product-add/font/',
  MY_STORE_ADD_GRAPHIC = '/dashboard/product-add/graphic/',
  MY_STORE_ADD_EMBROIDERY = '/dashboard/product-add/embroidery/',

  VIEW_MY_PROFILE = '/crafter/',
  STORE_CREDIT = '/store-credit/',
  ABOUT = '/about/',
  HIRING = 'https://careers.creativefabrica.com/',
  ARTISTRY_BLOG = '/the-artistry/',

  ULTIMATE_CRICUT_GUIDE = '/the-ultimate-cricut-guide/',
  ULTIMATE_FONT_GUIDE = '/the-ultimate-font-guide/',
  ULTIMATE_SILHOUETTE_GUIDE = '/the-ultimate-silhouette-guide/',
  ULTIMATE_CANVAS_WORKSPACE_GUIDE = '/the-ultimate-brother-canvas-workspace-guide/',
  ULTIMATE_POD_GUIDE = '/the-ultimate-pod-guide/',
  ULTIMATE_GRAPHIC_DESIGN_GUIDE = '/the-ultimate-graphic-design-guide-2/',
  ULTIMATE_EMBROIDERY_GUIDE = '/the-ultimate-machine-embroidery-guide/',
  ULTIMATE_INSTALLATION_FONTS_WINDOWS = '/the-ultimate-font-guide/installing-fonts-on-windows/',
  ULTIMATE_INSTALLATION_FONTS_MAC = '/the-ultimate-font-guide/installing-fonts-on-mac/',

  LICENSE = '/license/',
  SUBSCRIPTION_LICENSE = '/subscription-license/',
  SINGLE_SALE_LICENSE = '/single-sales-license/',
  TERMS_OF_SERVICE = '/terms-of-service/',
  TERMS_AND_CONDITIONS_SPARK = '/terms-conditions-cf-spark/',
  MULTI_USER_LICENSE = '/multi-user/',
  CANCELLATION_POLICY = '/cancellation-policy/',
  AFFILIATES = '/affiliates/',
  OPEN_STORE = '/open-store/',

  LICENSE_KEY_VALIDATOR = '/license-key-validator/',
  REVIEWS = '/reviews/',
  TAGS = '/tag/',
  POPULAR_SEARCHES = '/popular-searches/',

  MASTERFEED = '/masterfeed/',

  MY_ACCOUNT = '/my-account/',
  MY_ACCOUNT_EDIT = '/my-account/edit-account/',
  MY_FAVORITES = '/my-favorites/',
  MY_REFERRALS = '/referrals/',
  MY_FEED = '/following/',
  MY_CLASSES = '/my-account/my-classes/',
  MY_ARTICLES = '/my-account/my-articles/',
  MY_PROFILE = '/my-account/my-profile/',
  MY_SOCIAL_ACCOUNTS = '/my-account/social-accounts/',
  MY_LICENSE_KEYS = '/my-account/license-keys-overview/',
  MY_SUBSCRIPTIONS = '/my-account/subscriptions/',
  MY_ORDERS = '/my-account/orders/',
  MY_EMAIL_SETTINGS = '/my-account/email-settings/',
  MY_PAYMENT_METHODS = '/my-account/payment-methods/',

  AFFILIATE_DASHBOARD = '/affiliate-area/?tab=urls',
  AFFILIATE_REFERRAL_URL_GENERATOR = '/affiliate-area/referral-url-generator/',
  AFFILIATE_STATISTICS = '/affiliate-area/?tab=stats',
  AFFILIATE_REFERRALS = '/affiliate-area/?tab=referrals',
  AFFILIATE_VISITS = '/affiliate-area/?tab=visits',
  AFFILIATE_BANNERS_AND_CREATIVES = '/affiliate-area/?tab=creatives',
  AFFILIATE_SETTINGS = '/affiliate-area/?tab=settings',
}

export type PageProps = AnyObject &
  NullableObject<InitialAppProps> & {
    serverRequestsLog?: ServerRequestsLog;
    dehydratedState?: DehydratedState;
  };

export type NextPageWithLayout<Props = AnyObject> = NextPage<PageProps & Props> & {
  getLayout?: (page: ReactElement, layoutData?: PageProps & Props) => ReactNode;
};

type LocaleMessagesData = Record<string, string>;

export type EnhancedAppProps = Omit<AppProps, 'pageProps' | 'Component'> & {
  emotionCache?: EmotionCache;
  // we can't always promise that InitialAppProps and LayoutData objects
  // will be passed into our AppProps, since the value of pageProps
  // is controlled on a per page basis.
  // we set it first to AnyObject since technically pageProps can be any object,
  // we don't have control over it.
  pageProps: PageProps;
  Component: NextPageWithLayout;
};

export type InitialAppProps = {
  initialCookies?: string;
  initialUserData?: UserData;
  initialCurrencyData?: CurrencyData | null;
  initialLocaleData?: LocaleMessagesData | null;
  initialSiteInfoData?: SiteInfoData | null;
  userAgent?: string;
};
