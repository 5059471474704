import React from 'react';
import { Snackbar as MuiSnackbar } from '@mui/material';

import { SnackbarProps } from 'components/elements/atoms/snackbar/Snackbar.types';
import { Alert } from 'components/elements/atoms/alert/Alert';
import { IconButton } from 'components/elements/atoms/iconButton/IconButton';

const HIDE_DURATION = 4000; // ms
const HIDE_DURATION_WITH_ACTION = 7000; // ms

export const Snackbar = ({
  children,
  severity,
  action,
  icon,
  hasCloseButton,
  className,
  hasHideDuration = true,
  anchorOrigin = { vertical: 'top', horizontal: 'right' },
  onClose,
  ...props
}: SnackbarProps) => {
  const hideDuration = action ? HIDE_DURATION_WITH_ACTION : HIDE_DURATION;

  let actionComponent = action;

  if (hasCloseButton) {
    actionComponent = (
      <>
        {action}
        <IconButton iconColor="white.main" icon="times" onClick={e => onClose?.(e, 'clickaway')} aria-label="close" />
      </>
    );
  }

  const noSeverityStyles = {
    '& .MuiAlert-root.MuiAlert-root': {
      backgroundColor: 'black.main',
    },
  };

  return (
    <MuiSnackbar
      autoHideDuration={hasHideDuration ? hideDuration : null}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      {...props}
      className={className}
      sx={!severity ? noSeverityStyles : undefined}
    >
      <div>
        <Alert action={actionComponent} severity={severity} icon={icon}>
          {children}
        </Alert>
      </div>
    </MuiSnackbar>
  );
};
