import { createContext, useState } from 'react';
import { useRouter } from 'next/router';

import { RegisterChallenge } from 'api/requests/register/register.types';
import { registerFetcher } from 'api/requests/register/register';
import { AuthActionType } from 'components/contexts/authProvider/authReducer/authReducer.types';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { trackRegistration } from 'components/app/analytics/Analytics.utils';
import { useRegisterMutation } from 'generated/graphql';
import { toHTTPErrorCode, toShortBackendErrors } from 'generated/codegen.fetcher';
import { Flags, useFlags } from 'hooks/useFlags/useFlags';
import { gqlUserToApiUser } from 'components/contexts/requestStateProviders/mappings/gqlUserToApiUser';
import { unsetCurrentStoredToken } from 'components/contexts/authProvider/AuthToken';

import { RegisterContextValue, RegisterProviderProps, RegisterHandler, RegisterState } from './RegisterProvider.types';

export const RegisterContext = createContext<RegisterContextValue | null>(null);

const initialRegisterState = {
  isLoading: false,
  errors: undefined,
  isOTPRequested: false,
  debugOTP: undefined,
  verificationText: undefined,
};

export const RegisterProvider = (props: RegisterProviderProps) => {
  const { children } = props;
  const dispatch = useAuthDispatch();
  const [registerState, setRegisterState] = useState<RegisterState>(initialRegisterState);
  const router = useRouter();
  const [USE_GRAPHQL_AUTH] = useFlags(Flags.USE_GRAPHQL_AUTH);

  const registerMutation = useRegisterMutation();

  const registerWithWP: RegisterHandler = async ({ email, password, otp, terms, onError, postLoginBehaviour }) => {
    setRegisterState(prevState => ({ ...prevState, isLoading: true }));
    const response = await registerFetcher({ email, password, otp, terms, onError });

    // only refresh the page if we are do not have any login challenges to go through.
    // in short, is this the end of the line? has the user finished logging in?
    // if so, then refresh the page.
    const willRefreshPage =
      response.challenge !== RegisterChallenge.ONE_TIME_PASSWORD && postLoginBehaviour === 'refresh';

    setRegisterState({
      isLoading: false,
      errors: response.errors,
      isOTPRequested: response.challenge === RegisterChallenge.ONE_TIME_PASSWORD,
      debugOTP: response.otp,
      verificationText: response.text,
    });

    if (response.user) {
      const updatedUser = await trackRegistration(response.user);
      dispatch({ type: AuthActionType.LOGIN, user: updatedUser?.user ? updatedUser.user : response.user });

      if (willRefreshPage) {
        window.location.reload();
      } else {
        router.replace(router.asPath);
      }
    }
  };

  const registerWithGraphql: RegisterHandler = async ({ email, password, otp, terms, onError }) => {
    setRegisterState(prevState => ({ ...prevState, isLoading: true }));

    unsetCurrentStoredToken();

    const { register } = await registerMutation.mutateAsync({ input: { email, password, otp, terms } });

    register &&
      register.errors &&
      register.errors.length &&
      onError &&
      onError({ status: toHTTPErrorCode(register.errors[0].code), ...new Error(register.errors[0].message) });

    setRegisterState({
      isLoading: false,
      errors: toShortBackendErrors(register?.errors || []),
      isOTPRequested: register?.challenge?.type === 'challengeOtp',
      debugOTP: register?.challenge?.otp || undefined,
      verificationText: register?.challenge?.text || undefined,
    });

    if (register?.user) {
      const user = gqlUserToApiUser(register.user);
      const token = register.token || undefined;

      if (!!user) {
        const updatedUser = await trackRegistration(user);
        dispatch({ type: AuthActionType.REFRESH_USER, user: updatedUser?.user ? updatedUser.user : user });
        dispatch({ type: AuthActionType.REFRESH_TOKEN, token });
      }
    }
  };

  const register = USE_GRAPHQL_AUTH ? registerWithGraphql : registerWithWP;

  const clearRegisterState = () => setRegisterState(initialRegisterState);

  const value = {
    registerState,
    register,
    clearRegisterState,
  };

  return <RegisterContext.Provider value={value}>{children}</RegisterContext.Provider>;
};
