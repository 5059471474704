import * as Sentry from '@sentry/nextjs';
import { Transaction, SpanContext } from '@sentry/types';

//https://docs.sentry.io/platforms/javascript/configuration/filtering/
export const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'atomicFindClose',
  // ignore webpack errors
  'ChunkLoadError',
  'Loading chunk',
  // error from the Instagram browser that can be safely ignored
  'ResizeObserver loop limit exceeded',
  // https://sentry.io/organizations/creative-fabrica/issues/3796952163/
  'The password you entered for the email address',
  // https://sentry.io/organizations/creative-fabrica/issues/3797099330/
  'This email is already taken',
  // https://sentry.io/organizations/creative-fabrica/issues/3808408480/
  'There is already an account with this email address',
  // https://sentry.io/organizations/creative-fabrica/issues/3797103761/
  'Invalid or expired OTP',
  // Ignore errors from amplitude
  'api.amplitude',
  // Ignore errors from google analytics
  'google-analytics',
  // Ignore errors from google tag manager
  'googletagmanager',
  // webpack errors
  'webpack.cache',
  // custom errors
  'There was an issue generating this prompt',
  // i18n errors
  '@formatjs/intl',
];

export const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // attempt to fix https://sentry.io/organizations/creative-fabrica/issues/3728633626
  /api.amplitude/i,
  /miscellaneous_bindings/i,
  /jid1-ejhbjdxm9zr4tq/i,
];

export const sentryUtils = (transactionName = '') => {
  let transaction: Transaction | undefined = undefined;

  if (transactionName === '') {
    transaction = Sentry.getCurrentHub()?.getScope()?.getTransaction();
  } else {
    const currentTransactionName = Sentry.getCurrentHub()?.getScope()?.getTransaction()?.name || '';
    transaction = Sentry.startTransaction({
      name: `${currentTransactionName}/${transactionName}`,
    });
    Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction));
  }

  const log = (
    options?: Pick<SpanContext, Exclude<keyof SpanContext, 'spanId' | 'sampled' | 'traceId' | 'parentSpanId'>>,
  ) => {
    if (!transaction) {
      return {
        finish: () => {
          if (process.env.NEXT_PUBLIC_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log('Sentry transaction not defined!');
          }
        },
      };
    }

    const span = transaction.startChild(options);

    return span;
  };

  return { log };
};
