import React from 'react';

import * as S from './Icon.styles';
import { IconProps } from './Icon.types';

export const Icon = ({
  icon,
  color,
  size,
  className,
  style,
  ...props
}: IconProps) => (
  <S.StyledIcon
    icon={icon}
    size={size}
    className={className}
    style={style}
    sx={color ? { color } : {}}
    {...props}
  />
);
