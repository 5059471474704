import Script from 'next/script';

import { useOptionalUser } from 'hooks/useAuthState/useAuthState';

const globalAdsTagId = process.env.NEXT_PUBLIC_GLOBAL_SITE_TAG_ID;
const GA4SiteTagId = process.env.NEXT_PUBLIC_GA4_SITE_TAG_ID;

export const GlobalSiteTag = () => {
  const user = useOptionalUser();

  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA4SiteTagId}`} strategy="afterInteractive" />
      <Script id="global-site-tag-script" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());

          ${process.env.NEXT_PUBLIC_ENV === 'production' ? `gtag('config', '${globalAdsTagId}');` : ''}
          gtag('config', '${GA4SiteTagId}', {
            ${user ? `'user_id': '${user.identification.id}'` : ''}
          });
        `}
      </Script>
    </>
  );
};
