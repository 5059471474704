import { styled, Typography } from '../../elements';
import { Button as BaseButton } from '../atoms/button/Button';
import { Card } from '../Card/Card';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  boxShadow: theme.shadows[1],
}));

export const LinkImageWrapper = styled('a')({
  display: 'block',
  position: 'relative',
  height: 0,
  paddingBottom: `${(233 / 400) * 100}%`,
});

export const ImageWrapper = styled('span')({
  display: 'block',
  position: 'relative',
  height: 0,
  paddingBottom: `${(233 / 400) * 100}%`,
});

export const Image = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
});

export const Contents = styled(Card.Body)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  flexDirection: 'column',
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    // matches padding above
    left: theme.spacing(2.5),
    right: theme.spacing(2.5),
    height: 1,
    backgroundColor: theme.palette.gray.light,
  },
}));

export const BundleTitle = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontSize: 20,
  fontWeight: theme.typography.fontWeightBold,
}));

export const Button = styled(BaseButton)(() => ({}));

export const DownloadButton = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

export const BundleTitleLink = styled('a')(({ theme }) => ({
  margin: 0,
  textDecoration: 'none',
  color: theme.palette.black.main,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  flexWrap: 'wrap',
  minHeight: theme.spacing(7.25),
}));

export const Detail = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  display: 'flex',
  gap: theme.spacing(0.75),
  alignItems: 'flex-start',
}));

export const Footer = Card.Body;

export const DownloadButtonContainer = styled(Footer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  paddingTop: theme.spacing(0),
}));

export const DownloadButtonWrapper = styled('div')<{ showborder: string }>(
  ({ theme, showborder }) => ({
    borderTop:
      showborder === 'true' ? `1px solid ${theme.palette.gray.light}` : 'none',
    paddingTop: theme.spacing(2.5),
  })
);

export const Text = styled(Typography)({});
