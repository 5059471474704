import { useState } from 'react';
import NextImage from 'next/future/image';

import { FutureImageProps } from './Image.types';
import * as S from './Image.styles';

const isTestEnvironment = process.env.NODE_ENV === 'test';

export const FutureImage = (props: FutureImageProps) => {
  const { className, imageClassName, testid, enableLazyImage = false, enableAnimation = false, ...rest } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <S.BoxRoot className={className} data-testid={testid} isLoaded={isLoaded} enableAnimation={enableAnimation}>
      <NextImage
        className={imageClassName}
        onLoadingComplete={() => setIsLoaded(true)}
        unoptimized={true}
        loading={enableLazyImage && !isTestEnvironment ? 'lazy' : 'eager'}
        {...rest}
      />
    </S.BoxRoot>
  );
};
