import React from 'react';

import { LoadingOverlayProps } from './LoadingOverlay.types';
import * as S from './LoadingOverlay.styles';

export const LoadingOverlay = (props: LoadingOverlayProps) => {
  const { children, isLoading, ...restProps } = props;

  return (
    <S.BoxRoot {...restProps}>
      <S.BoxContent isLoading={isLoading}>{children}</S.BoxContent>
      <S.BoxOverlay isLoading={isLoading} />
    </S.BoxRoot>
  );
};
