import Script from 'next/script';

import { useAuthState } from 'hooks/useAuthState/useAuthState';

const trackingId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID;

export const GoogleAnalytics = () => {
  const { user } = useAuthState();

  if (!trackingId) {
    return null;
  }

  return (
    <>
      <Script src="https://www.google-analytics.com/analytics.js" strategy="afterInteractive" />
      <Script id="google-analytics-script" strategy="afterInteractive">
        {`
          window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;

          ga('create', '${trackingId}', 'auto');
          ga('set', 'forceSSL', true);
          ${user ? `ga('set', 'userId', ${user.identification.id} );` : ''}
          ga('require', 'ec');

          ga('send', 'pageview');
        `}
      </Script>
    </>
  );
};
