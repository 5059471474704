export enum ProductCardType {
  Product = 'product',
  Class = 'class',
}

export type ProductCardProps = {
  products: ProductCardItem[];
};

export type ProductCardItem = {
  image: string;
  title: string;
  type: ProductCardType;
  author: {
    id: string;
    name: string;
    url: string;
  };
  category: string;
  url: string;
};
