import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { BaseIcon } from '../baseIcon/BaseIcon';

export const IconIdeaOutlined = (props: SvgIconProps) => (
  <BaseIcon
    {...props}
    width="16"
    viewBox="0 0 16 24"
    d="M11.035 20.258v1.134l-.92 1.629c-.159.237-.426.379-.712.379h0-2.805c-.286 0-.554-.142-.712-.38h0l-.777-1.161-.144-1.601h6.07zM7.977.6c2.049-.006 3.905.816 5.248 2.148C14.57 4.081 15.4 5.924 15.4 7.96c0 1.855-.691 3.547-1.83 4.84-.722.819-1.811 2.452-2.356 3.934h0l-6.423.031c-.548-1.51-1.639-3.146-2.36-3.966C1.29 11.507.6 9.815.6 7.96c0-1.99.808-3.866 2.171-5.227C4.073 1.432 5.882.606 7.977.6z"
    transform="translate(-849 -1323) translate(426 552) translate(0 74) translate(16 687) translate(314 10) translate(93)"
  />
);
