import React from 'react';
import classNames from 'classnames';

import { LinkProps } from './Link.types';
import * as S from './Link.styles';

export const Link = (props: LinkProps) => {
  const {
    href,
    targetBlank,
    external,
    children,
    underline = 'always',
    color = 'standard',
    className,
    ...linkProps
  } = props;
  const target = targetBlank || external ? '_blank' : undefined;
  const rel = external ? 'noreferrer noopener' : undefined;

  return (
    <S.Link
      className={classNames(className, {
        [`${color}Color`]: color !== 'inherit',
      })}
      target={target}
      rel={rel}
      underline={underline}
      href={href}
      {...linkProps}
    >
      {children}
    </S.Link>
  );
};
