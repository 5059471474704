import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';

import coinStar from 'assets/icons/coin_star.svg';

import { Card } from '../Card/Card';
import * as S from './CardCoinSpending.styles';
import {
  CardCoinSpendingClassProps,
  CardCoinSpendingCraftProps,
} from './CardCoinSpending.types';

const CardCoinSpendingCraft = ({
  amount,
  title,
  image,
  href,
}: CardCoinSpendingCraftProps) => {
  const { formatNumber, formatMessage } = useIntl();

  return (
    <S.Wrapper>
      <S.ImageWrapper href={href}>
        <S.StyledImage src={image} />
      </S.ImageWrapper>
      <S.Body>
        <S.TitleLink href={href}>{title}</S.TitleLink>
        <S.Details>
          <Card.Detail
            icon={<FontAwesomeIcon icon={faTag} fontSize={18} />}
            label={formatMessage({ id: 'card_coin_spending.craft.label' })}
          />
          <Card.Detail
            icon={<S.Icon src={coinStar} fill alt="" aria-hidden="true" />}
            label={formatNumber(amount)}
          />
        </S.Details>
      </S.Body>
    </S.Wrapper>
  );
};

const CardCoinSpendingClass = ({
  amount,
  title,
  image,
  href,
}: CardCoinSpendingClassProps) => {
  const { formatNumber, formatMessage } = useIntl();

  return (
    <S.Wrapper>
      <S.ImageWrapper href={href}>
        <S.StyledImage src={image} width={266} height={200} alt={title} />
      </S.ImageWrapper>
      <S.Body>
        <S.TitleLink href={href}>{title}</S.TitleLink>
        <S.Details>
          <Card.Detail
            icon={<FontAwesomeIcon icon={faTag} fontSize={18} />}
            label={formatMessage({ id: 'card_coin_spending.class.label' })}
          />
          <Card.Detail
            icon={
              <S.Icon
                src={coinStar}
                width={18}
                height={18}
                alt=""
                aria-hidden="true"
              />
            }
            label={formatNumber(amount)}
          />
        </S.Details>
      </S.Body>
    </S.Wrapper>
  );
};

export const CardCoinSpending = Object.assign(
  {},
  {
    Class: CardCoinSpendingClass,
    Craft: CardCoinSpendingCraft,
  }
);
