export const TOKEN_LOCAL_STORAGE_KEY = 'cfTokenMain';

export const getCurrentStoredToken = (): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const value = window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);

  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};

export const unsetCurrentStoredToken = (): void => {
  if (typeof window === 'undefined') {
    return;
  }

  window.localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
};

export const setCurrentStoredToken = (token?: string): void => {
  if (typeof window === 'undefined') {
    return;
  }

  if (!token) {
    unsetCurrentStoredToken();

    return;
  }

  window.localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(token));
};
