import { createTheme } from '@mui/material';

import { palette } from './palette';
import { typography } from './typography';
import { shadows } from './shadows';

const intermediateTheme = createTheme();

export const theme = createTheme({
  palette,
  typography,
  shadows,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1220,
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          paddingLeft: intermediateTheme.spacing(2.5),
          paddingRight: intermediateTheme.spacing(2.5),
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
