import { FutureImage } from 'components/elements/atoms/image/FutureImage';

import { styled, Typography } from '../../elements';

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.spacing(1.25),
  backgroundColor: theme.palette.gray.extraLight,
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(5),
    paddingTop: theme.spacing(6.375),
  },
}));

export const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontSize: 20,
  marginBottom: 10,
  lineHeight: '140%',
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
    marginBottom: 20,
    lineHeight: '130%',
  },
}));

export const Balance = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontSize: 36,
  lineHeight: '130%',
  fontWeight: theme.typography.fontWeightBold,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: 54,
    lineHeight: '70px',
  },
}));

export const Icon = styled(FutureImage)(({ theme }) => ({
  width: 31,
  height: 31,
  marginRight: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
  },
}));

// grid and spacing of progress bars start
export const ProgressBarsWrapper = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  [theme.breakpoints.up('md')]: {
    backgroundColor: 'transparent',
    overflow: 'visible',
    flexDirection: 'row',
    borderRadius: theme.spacing(1),
    gap: theme.spacing(5),
  },
}));

export const ProgressBarsItem = styled('div')(({ theme }) => ({
  background: theme.palette.white.main,
  boxShadow: theme.shadows[1],
  flexGrow: 1,
  flexBasis: 0,
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('md')]: {
    flexBasis: 0,
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(3.75)}`,
  },
  // using `.down` here as otherwise we would need to reset
  // these styles in an `.up` which could get very cumbersome.
  [theme.breakpoints.down('md')]: {
    // i will explain this next part visually. as we need to have 1 or more items
    // in a column, and the borders need to be on the _outer_ edge of them.

    // if there is only one child, it should have all it's corners rounded..
    // ╭─────╮
    // │ box │
    // ╰─────╯
    '&:only-child': {
      borderRadius: theme.spacing(1),
    },
    // ..otherwise the first child has the top corners rounded..
    // ╭─────╮
    // │ box │
    // └─────┘
    '&:first-of-type': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    // ..and the last child has the bottom corners rounded:
    // ┌─────┐
    // │ box │
    // ╰─────╯
    '&:last-child': {
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
    // and all the others are okay being square (if there are more than 2)
  },
}));
// grid and spacing of progress bars end

export const CoinProgressIcon = styled(FutureImage)(({ theme }) => ({
  // looks scary but it's just moving the icon up, relative to itself.
  // since the icons are the same, and the icon in the svg is not
  // totally centered, we can get away with a generic
  // solution here that only moves the icons up so it's visually centered
  // within the box.
  transform: 'translateX(-50%) translateY(-45%)',
  minWidth: 60,
  width: 60,
  height: 42,
  [theme.breakpoints.up('md')]: {
    width: 67,
    height: 49,
  },
}));

export const CoinProgressFooterIcon = styled(FutureImage)(({ theme }) => ({
  width: 12,
  height: 12,
  marginRight: theme.spacing(0.5),
  display: 'inline',
}));
