import { styled, Typography, Link } from '../../elements';
import { Button as ButtonBase } from '../../components/atoms/button/Button';

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  margin: 0,
  fontSize: theme.typography.h3.fontSize,
  lineHeight: 1.3,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.h2.fontSize,
  },
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.25),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(2.5),
  },
}));

export const HeaderMessage = styled(Typography)({
  lineHeight: 1.7,
});

export const HeaderWarning = styled(HeaderMessage)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: theme.typography.fontWeightBold,
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
}));

export const Category = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  lineHeight: 1.7,
  display: 'flex',
  gap: theme.spacing(0.625),
  alignItems: 'center',
}));

export const ItemsWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
}));

export const ItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  flexDirection: 'column',
  boxSizing: 'border-box',
  '&:not(:last-child)': {
    paddingBottom: theme.spacing(2.5),
  },
  '&:not(:first-of-type)': {
    paddingTop: theme.spacing(2.5),
    borderTop: `1px solid ${theme.palette.border.main}`,
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(3.75),
    justifyContent: 'space-between',
  },
}));

export const ItemContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2.5),
  [theme.breakpoints.up('md')]: {
    width: 'max-content',
  },
}));

export const ItemTitleLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.blue.main,
  fontSize: 16,
  lineHeight: 1.4,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const ItemLink = styled(Link)({
  flexShrink: 0,
});

export const ItemDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const ItemImage = styled('img')(({ theme }) => ({
  width: 84,
  height: 63,
  objectFit: 'cover',
  borderRadius: theme.spacing(0.5),
  display: 'block',
}));

export const ItemActions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    flex: 1,
    justifyContent: 'flex-end',
  },
}));

export const ViewDownloadHistoryLinkWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1.25),
  borderTop: `1px solid ${theme.palette.border.main}`,
}));

export const ViewDownloadHistoryLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  textDecoration: 'none',
  color: theme.palette.primary.light,
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  ':hover': {
    color: theme.palette.secondary.main,
  },
}));

export const Button = styled(ButtonBase)({});

export const FreebiesButton = styled(Button)({
  alignSelf: 'start',
});
