export enum OneSignalEventName {
  LISTENER_NOTIFICATION_OPEN = 'addListenerForNotificationOpened',
}

export type OneSignalEventData = {
  id: string;
  heading: string;
  content: string;
  icon: string;
  url: string;
  data: string;
  action: string;
};

export type OneSignalEventCallback = (data: OneSignalEventData) => void;

type OneSignalEventEntry = [OneSignalEventName, OneSignalEventCallback];

declare global {
  interface Window {
    OneSignal?: OneSignalEventEntry[];
  }
}
