import {
  styled,
  Box,
  Typography,
  CircularProgress,
  Input,
} from '../../elements';

export const CommentInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '0 0 auto',
  marginBottom: theme.spacing(1),
}));

export const CommentInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginLeft: theme.spacing(1),
}));

export const PressLabel = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontSize: 10,
  color: theme.palette.gray.main,
}));

export const CommentInputField = styled(Input)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(2.5),
  border: `solid 1px ${theme.palette.gray.main}`,
  backgroundColor: theme.palette.gray.extraLight,
  color: theme.palette.black.main,
  padding: theme.spacing(1, 2),
  wordBreak: 'break-word',
}));

export const LoadingIcon = styled(CircularProgress)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));
