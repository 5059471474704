export enum ProviderCode {
  PAYPAL = 'paypal-express',
  STORE_CREDIT = 'store-credit',
  STRIPE = 'stripe',
  NULL = 'null', //used when order has value 0
}

export type BasePaymentMethod = {
  id: string;
  isDefault: boolean;
  isSubscriptionPayment: boolean;
  providerCode: ProviderCode;
  providerName: string;
};

export type PaypalPaymentMethod = BasePaymentMethod & {
  providerCode: ProviderCode.PAYPAL;
  paymentMethodInfo: null;
};

export type StripePaymentMethod = BasePaymentMethod & {
  providerCode: ProviderCode.STRIPE;
  paymentMethodInfo: {
    token: string;
    last4: string;
    exp_month: string;
    exp_year: string;
    brand: string;
  };
};

export type StoreCreditPaymentMethod = BasePaymentMethod & {
  providerCode: ProviderCode.STORE_CREDIT;
  paymentMethodInfo: {
    amount: number;
  };
};

export type PaymentMethod = PaypalPaymentMethod | StripePaymentMethod | StoreCreditPaymentMethod;

export type PaymentMethodsData = {
  isBanned: boolean;
  paymentMethods: PaymentMethod[];
};

type PaypalPaymentDetails = {
  providerCode: ProviderCode.PAYPAL;
  providerData?: {
    token: string;
    payerId: string;
  };
};

type SavedMethodPaymentDetails = {
  id: string;
};

type StripePaymentDetails = {
  providerCode: ProviderCode.STRIPE;
  providerData: {
    token?: string;
    intentClientSecret?: string;
    verifyIntent?: boolean;
  };
};

type StoreCreditPaymentDetails = {
  providerCode: ProviderCode.STORE_CREDIT;
};

type OrderValueZeroPaymentDetails = {
  providerCode: ProviderCode.NULL;
};

export type PaymentDetails =
  | PaypalPaymentDetails
  | SavedMethodPaymentDetails
  | StripePaymentDetails
  | StoreCreditPaymentDetails
  | OrderValueZeroPaymentDetails;

export type PaymentMethodsFetcherParams = { cookieString?: string };
