export const LOCALES = {
  de: 'Deutsche',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  nl: 'Nederlands',
  pl: 'Polski',
  pt: 'Português',
};

export const DEFAULT_LOCALE = 'en';
