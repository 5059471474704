import Script from 'next/script';

const appId = process.env.NEXT_PUBLIC_ONE_SIGNAL_SDK_APP_ID;
const safariWebId = process.env.NEXT_PUBLIC_ONE_SIGNAL_SDK_SAFARI_WEB_ID;

export const OneSignalSDK = () => {
  if (!appId) {
    return null;
  }

  return (
    <>
      <Script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" strategy="lazyOnload" />
      <Script
        id="one-signal-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
          window.OneSignal = window.OneSignal || [];

          OneSignal.push(function() {
            OneSignal.SERVICE_WORKER_PARAM = { scope: '/static/' };
            OneSignal.SERVICE_WORKER_PATH = 'static/OneSignalSDKWorker.js'
            OneSignal.SERVICE_WORKER_UPDATER_PATH = 'static/OneSignalSDKUpdaterWorker.js'

            OneSignal.init({
              appId: "${appId}",
              safari_web_id: "${safariWebId}",
              persistNotification: {
                enable: true,
              },
              allowLocalhostAsSecureOrigin: true,
              promptOptions: {
                slidedown: {
                  prompts: [
                    {
                      type: "push",
                      autoPrompt: true,
                      text: {
                        actionMessage: "We'd like to show you notifications for the latest news and updates.",
                        acceptButton: "Allow",
                        cancelButton: "Cancel"
                      },
                      delay: {
                        pageViews: 1,
                        timeDelay: 1,
                      }
                    }
                  ]
                }
              },
            });
          });
            `,
        }}
      />
    </>
  );
};
