import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import coinStar from 'assets/icons/coin_star.svg';

import {
  PayWithCoinsCardContext,
  usePayWithCoinsCard,
} from './PayWithCoinsCardContext';
import {
  PayWithCoinsCardProps,
  PayWithCoinsCardStateProps,
  PayWithCoinsCardSpendingState,
} from './PayWithCoinsCard.types';
import * as S from './PayWithCoinsCard.styles';
import { Spinner } from '../atoms/spinner/Spinner';

export const PayWithCoinsCardRoot = ({
  onSpend,
  onCancel,
  userBalance,
  bundleValue,
  state,
  canCancel,
  title,
}: PayWithCoinsCardProps) => {
  const { formatMessage } = useIntl();

  return (
    <PayWithCoinsCardContext.Provider
      value={{
        bundleValue,
        userBalance,
        onCancel,
        onSpend,
        canCancel,
      }}
    >
      {state === PayWithCoinsCardSpendingState.insufficient_coins && (
        <PayWithCoinsCard.InsufficientCoins
          title={
            title?.insufficientCoins ||
            formatMessage({
              id: 'card_coin_spending_bundle.ready_to_spend.title',
            })
          }
        />
      )}
      {state === PayWithCoinsCardSpendingState.ready_to_spend && (
        <PayWithCoinsCard.ReadyToSpend
          title={
            title?.readyToSpend ||
            formatMessage({
              id: 'card_coin_spending_bundle.ready_to_spend.title',
            })
          }
        />
      )}
      {state === PayWithCoinsCardSpendingState.confirmed && (
        <PayWithCoinsCard.Confirmed
          title={
            title?.confirmed ||
            formatMessage({
              id: 'card_coin_spending_bundle.confirmed.title',
            })
          }
        />
      )}
      {state === PayWithCoinsCardSpendingState.cancelled && (
        <PayWithCoinsCard.Cancelled
          title={
            title?.canceled ||
            formatMessage({
              id: 'card_coin_spending_bundle.cancelled.title',
            })
          }
        />
      )}
    </PayWithCoinsCardContext.Provider>
  );
};

const PayWithCoinsCardInsuficientCoins = ({
  title,
}: PayWithCoinsCardStateProps) => {
  const { onSpend, bundleValue } = usePayWithCoinsCard();
  const { formatMessage, formatNumber } = useIntl();

  return (
    <S.PayWithCoinsCardInsufficientCoins>
      <S.PayWithCoinsCardTitle component="span">
        {title}
      </S.PayWithCoinsCardTitle>
      <S.PayWithCoinsCardText component="span">
        {formatMessage(
          {
            id: 'card_coin_spending_bundle.insufficient_coins',
          },
          {
            link: (str: React.ReactNode) => (
              <S.PayWithCoinsCardLink href="/coins">
                {str}
              </S.PayWithCoinsCardLink>
            ),
          }
        )}
      </S.PayWithCoinsCardText>
      <S.InsufficientCoinsButtonWrapper>
        <S.CoinBundleValue component="span">
          <S.CoinIcon src={coinStar} fill alt="" aria-hidden="true" />
          {formatNumber(bundleValue)}
        </S.CoinBundleValue>
        <S.Button onClick={onSpend}>
          {formatMessage({
            id: 'card_coin_spending_bundle.button.spend_coins',
          })}
        </S.Button>
      </S.InsufficientCoinsButtonWrapper>
    </S.PayWithCoinsCardInsufficientCoins>
  );
};

const PayWithCoinsCardReadyToSpend = ({
  title,
}: PayWithCoinsCardStateProps) => {
  const [isPending, setIsPending] = useState(false);
  const { onSpend, bundleValue } = usePayWithCoinsCard();
  const { formatMessage, formatNumber } = useIntl();

  async function onClick() {
    setIsPending(true);
    try {
      await onSpend();
    } catch {}
    setIsPending(false);
  }

  return (
    <S.PayWithCoinsCardReadyToSpend>
      <S.PayWithCoinsCardTitle component="span">
        {title}
      </S.PayWithCoinsCardTitle>
      <S.CoinBundleValue component="span">
        <S.CoinIcon src={coinStar} fill alt="" aria-hidden="true" />
        {formatNumber(bundleValue)}
      </S.CoinBundleValue>
      <S.Button disabled={isPending} onClick={onClick}>
        {isPending ? (
          <>
            <S.FloatingSpinner>
              <Spinner />
            </S.FloatingSpinner>
            <S.HiddenText>
              {formatMessage({
                id: 'card_coin_spending_bundle.button.spend_coins',
              })}
            </S.HiddenText>
          </>
        ) : (
          formatMessage({
            id: 'card_coin_spending_bundle.button.spend_coins',
          })
        )}
      </S.Button>
    </S.PayWithCoinsCardReadyToSpend>
  );
};

const PayWithCoinsCardConfirmed = ({ title }: PayWithCoinsCardStateProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const [isPendingCancel, setIsPendingCancel] = useState(false);
  const {
    canCancel,
    onCancel,
    bundleValue,
    userBalance,
  } = usePayWithCoinsCard();

  async function onCancelProxy() {
    setIsPendingCancel(true);
    try {
      await onCancel();
    } catch {}
    setIsPendingCancel(false);
  }

  return (
    <S.PayWithCoinsCardConfirmed>
      <S.PayWithCoinsCardContentWrapper>
        <S.PayWithCoinsCardTitle component="span">
          {title}
        </S.PayWithCoinsCardTitle>
        <div>
          {formatMessage(
            { id: 'card_coin_spending_bundle.users_coin_balance.title' },
            {
              count: formatNumber(userBalance),
              icon: (
                <S.CoinIconSmall
                  src={coinStar}
                  fill
                  alt=""
                  aria-hidden="true"
                />
              ),
            }
          )}
        </div>
        {canCancel && (
          <div>
            {isPendingCancel ? (
              <S.Cancel component="span">
                {formatMessage({
                  id: 'card_coin_spending_bundle.button.cancelling',
                })}
              </S.Cancel>
            ) : (
              <S.Cancel component="span" onClick={onCancelProxy} role="button">
                {formatMessage({
                  id: 'card_coin_spending_bundle.button.cancel',
                })}
              </S.Cancel>
            )}
            <S.CancelText component="span">
              {formatMessage(
                {
                  id: 'card_coin_spending_bundle.cancel.text',
                },
                {
                  count: formatNumber(bundleValue),
                  icon: (
                    <S.CoinIconSmall
                      src={coinStar}
                      fill
                      alt=""
                      aria-hidden="true"
                    />
                  ),
                }
              )}
            </S.CancelText>
          </div>
        )}
      </S.PayWithCoinsCardContentWrapper>
    </S.PayWithCoinsCardConfirmed>
  );
};

const PayWithCoinsCardCancelled = ({ title }: PayWithCoinsCardStateProps) => {
  const { bundleValue } = usePayWithCoinsCard();
  const { formatMessage, formatNumber } = useIntl();

  return (
    <S.PayWithCoinsCardCancelled>
      <S.PayWithCoinsCardTitle component="span">
        {title}
      </S.PayWithCoinsCardTitle>
      <S.PayWithCoinsCardText component="span">
        {formatMessage(
          {
            id: 'card_coin_spending_bundle.cancelled.copy',
          },
          {
            link: (str: React.ReactNode) => (
              <S.PayWithCoinsCardLink href="/coins">
                {str}
              </S.PayWithCoinsCardLink>
            ),
            count: formatNumber(bundleValue),
            icon: (
              <S.CoinIconSmall src={coinStar} fill alt="" aria-hidden="true" />
            ),
          }
        )}
      </S.PayWithCoinsCardText>
    </S.PayWithCoinsCardCancelled>
  );
};

export const PayWithCoinsCard = Object.assign(PayWithCoinsCardRoot, {
  InsufficientCoins: PayWithCoinsCardInsuficientCoins,
  ReadyToSpend: PayWithCoinsCardReadyToSpend,
  Confirmed: PayWithCoinsCardConfirmed,
  Cancelled: PayWithCoinsCardCancelled,
});
