import { styled, Box, Typography } from '../../elements';

export const SidebarWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const AvatarAnchor = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  padding: theme.spacing(0),
}));

export const ProfileNameWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'left',
}));

export const ProfileName = styled('h4')(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(0),
  margin: theme.spacing(0),
}));

export const ProfileNameAnchor = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  padding: theme.spacing(0),
  color: theme.palette.black.main,
  ':hover': {
    color: theme.palette.secondary.light,
  },
}));

export const ProfileDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  color: theme.palette.gray.main,
}));
