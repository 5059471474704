import { User } from 'api/requests/user/user.types';
import { RequestErrorHandler } from 'hooks/useRequestErrorHandler/useRequestErrorHandler.types';

export enum LoginChallenge {
  ONE_TIME_PASSWORD = 'otp',
  TWO_FACTOR = '2fa',
}

export enum LoginChallengeGQL {
  ONE_TIME_PASSWORD = 'challengeOtp',
  TWO_FACTOR = 'challenge2fa',
}

export type LoginFetcherParams = {
  email: string;
  password: string;
  code?: string;
  otp?: string;
  remember?: boolean;
  onError?: RequestErrorHandler;
};

export type LoginData = {
  user: User;
};

export type TwoFAData = {
  challenge: LoginChallenge;
};

export type VerificationData = {
  challenge: LoginChallenge;
  otp?: string;
  text: string;
};
