import { fetcher } from 'api/fetcher';
import { ResetPasswordData, ResetPasswordFetcherParams } from 'api/requests/resetPassword/resetPassword.types';
import { nonceFetcher } from 'api/requests/nonce/nonce';
import { NonceType } from 'api/requests/nonce/nonce.types';
import { ApiEndpoint, BackendErrorListData } from 'api/fetcher.types';

export const resetPasswordFetcher = async (params: ResetPasswordFetcherParams) => {
  const { email, onError } = params;
  const { nonce } = await nonceFetcher({ nonceType: NonceType.RESET });
  const body = JSON.stringify({ user_login: email, 'woocommerce-lost-password-nonce': nonce });

  return fetcher<ResetPasswordData & BackendErrorListData>({
    url: ApiEndpoint.RESET_PASSWORD,
    options: {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' },
    },
    onError,
  });
};
