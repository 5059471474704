// https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits
export enum GoogleAnalyticsEventName {
  PAGE_VIEW = 'pageview',
}

export type GoogleAnalyticsFieldsObject = Record<string, string | boolean | null>;

export type GoogleAnalyticsEventFunction = (
  method: 'send',
  param: GoogleAnalyticsEventName | GoogleAnalyticsFieldsObject,
) => void;

export type GoogleItemListJsonProps = {
  products: {
    name: string;
    url: string;
    position: number;
  }[];
};

export type GoogleBreadcrumbsListJsonProps = {
  items: {
    name: string;
    item: string | undefined;
    position: number;
  }[];
};

export type GoogleItemListElement = {
  '@type': 'ListItem';
  position: number;
  url?: string;
  name?: string;
  item?: string;
};

export type GoogleItemList = {
  '@context': 'https://schema.org';
  '@type': 'ItemList';
  itemListElement: GoogleItemListElement[];
};

export type GoogleBreadcrumbsList = {
  '@context': 'https://schema.org';
  '@type': 'BreadcrumbList';
  itemListElement: GoogleItemListElement[];
};

export type GooglePaginationProps = {
  currentPage: number;
  totalPages: number;
};

declare global {
  interface Window {
    ga?: GoogleAnalyticsEventFunction;
  }
}
