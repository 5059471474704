import { Button as MuiButton, styled } from '../../../elements';

export const Button = styled(MuiButton)(({ theme }) => ({
  height: theme.spacing(4.25),
  fontSize: 14,
  lineHeight: 1,
  fontWeight: theme.typography.fontWeightBold,
  color: 'inherit',
  letterSpacing: '.9px',
  padding: theme.spacing(0, 2.5),
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon > *:first-of-type': {
    fontSize: 16,
  },
  '& .MuiButton-endIcon > *:first-of-type': {
    fontSize: 16,
  },
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
    'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
    'border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,' +
    'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&.hideLabel span': {
    visibility: 'hidden',
  },
  '& .overlay': {
    position: 'absolute',
    visibility: 'visible',
  },
  '&.MuiButton-sizeSmall': {
    height: theme.spacing(3.75),
  },
  '&.MuiButton-sizeLarge': {
    height: theme.spacing(5.625),
    fontWeight: theme.typography.fontWeightBold,
  },
  '&.MuiButton-contained': {
    color: theme.palette.common.white,
    '&.primaryColor': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.loading': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.secondaryColor': {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&.loading': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '&.primaryLightColor': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.extraLight,
      '&:hover': {
        backgroundColor: theme.palette.black.light,
      },
      '&.loading': {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.extraLight,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.border.main,
      color: theme.palette.gray.main,
    },
  },
  '&.MuiButton-outlined': {
    border: '1px solid currentColor',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'currentColor',
    },
    '&.primaryColor': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&.loading': {
        color: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
    '&.secondaryColor': {
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.dark,
      },
      '&.loading': {
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      },
    },
    '&.primaryLightColor': {
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&.loading': {
        color: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.gray.main,
      borderColor: theme.palette.border.main,
    },
  },
  '&.MuiButton-text': {
    padding: theme.spacing(0, 1.25),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.primaryColor': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&.loading': {
        color: theme.palette.primary.light,
      },
    },
    '&.secondaryColor': {
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.dark,
      },
      '&.loading': {
        color: theme.palette.secondary.light,
      },
    },
    '&.primaryLightColor': {
      color: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&.loading': {
        color: theme.palette.primary.extraLight,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.gray.main,
      backgroundColor: 'transparent',
    },
  },
}));
