import { FutureImage } from 'components/elements/atoms/image/FutureImage';

import { styled, Typography } from '../../elements';

export const ImageWrapper = styled('a')({
  position: 'relative',
  height: 0,
  paddingBottom: `75%`,
});

export const StyledImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: theme.spacing(0.5),
}));

export const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.625),
}));

export const Title = styled(Typography)(({ theme }) => ({
  lineHeight: 1.4,
  fontSize: 16,
  fontWeight: theme.typography.fontWeightBold,
  textDecoration: 'none',
  color: theme.palette.blue.main,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const TitleLink = Title.withComponent('a');

export const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2.5),
}));

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
}));

export const Icon = styled(FutureImage)({
  width: 18,
  height: 18,
});
