import { User } from 'api/requests/user/user.types';
import { updateUserFetcher } from 'api/requests/user/user';
import { AmplitudeEvent, AuthModalLocations } from 'components/app/analytics/amplitude/Amplitude.types';
import {
  sendAmplitudeLog,
  setAmplitudeUser,
  getAmplitudeSessionId,
  setAmplitudeDeviceId,
  getAmplitudeDeviceId,
} from 'components/app/analytics/amplitude/Amplitude.utils';
import { Order } from 'api/requests/order/order.types';
import { getCookie } from 'utilities/getCookie';

export const AMPLITUDE_DEVICE_ID_COOKIE_NAME = 'cfAmpAnonymousId';
const SESSION_TIMEOUT = 30 * 60 * 60 * 1000; // 30 min

export const cfTrackingInit = (user: User | null) => {
  const deviceId = getCookie(AMPLITUDE_DEVICE_ID_COOKIE_NAME);

  if (deviceId && deviceId !== getAmplitudeDeviceId()) {
    setAmplitudeDeviceId(deviceId);
  }

  const sessionId = getAmplitudeSessionId();
  const sessionTimeoutDate = new Date(Date.now() + SESSION_TIMEOUT).toUTCString();
  document.cookie = `cfAmpSessionId=${sessionId};path=/;expires=${sessionTimeoutDate};`;

  cfTrackingIdentifyUser(user);
};

export const cfTrackingIdentifyUser = (user: User | null) => {
  if (!user) {
    return;
  }
  setAmplitudeUser(`user-${user?.identification.id}`);
};

export const cfTrackingPageView = () => {
  sendAmplitudeLog(AmplitudeEvent.PAGE_VIEW, {});
};

export const cfTrackingInitialLogin = (user: User | null) => {
  if (user?.identifyUser) {
    updateUserFetcher({
      id: user?.identification.id,
      properties: {
        identifyUser: false,
      },
    });
  }
};

export const cfTrackingCheckoutStart = (order: Order | undefined) => {
  if (!order) {
    return;
  }

  const amplitudeProperties = orderToAnalyticsData(order);
  sendAmplitudeLog(AmplitudeEvent.CHECKOUT_START, amplitudeProperties);
};

const orderToAnalyticsData = (order: Order) => {
  return {
    cart_id: order.cartId,
    currency: order.currency,
    coupon: order.coupons?.map(({ code }) => code).join(',') || '',
    products: order.items.map(cartItem => ({
      productId: cartItem.productId,
      product_id: cartItem.productId,
      sku: cartItem.productId,
      name: cartItem.name,
      price: cartItem.price,
      quantity: cartItem.quantity,
      url: cartItem.href,
      image_url: cartItem.image,
      brand: cartItem.brand,
      category: cartItem.category,
      designer: cartItem.designer,
      designer_id: cartItem.designerId,
      promo: cartItem.promo || '',
      subcategory: cartItem.subcategory,
    })),
  };
};

export const analyticsTrackLoginModalOpen = (location: AuthModalLocations = AuthModalLocations.SITE_HEADER) => {
  sendAmplitudeLog(AmplitudeEvent.LOGIN_MODAL_OPENED, { modal_type: 'login', location: location });
};

export const analyticsTrackRegistrationModalOpen = (location: AuthModalLocations = AuthModalLocations.SITE_HEADER) => {
  sendAmplitudeLog(AmplitudeEvent.REGISTRATION_MODAL_OPENED, { modal_type: 'registration', location: location });
};
