import { styled } from '../../../../elements';
import { Button } from '../Button';

export const ButtonGoogle = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.red.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover': {
    backgroundColor: theme.palette.red.main,
    opacity: 0.8,
  },
}));
