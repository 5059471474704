import { ProductCardItem } from '../ProductCard/ProductCard.types';
import { PostContentDetails } from './PostCardContentDetails.types';

export enum PostType {
  Text = 'text',
  Class = 'class',
  Article = 'article',
  Image = 'image',
  Poll = 'poll',
  Download = 'download',
  Video = 'video',
  Project = 'project',
}

export type PostContent = {
  type: PostType;
  description: string;
  images?: string[];
  details?: Omit<PostContentDetails, 'type'>;
  products?: ProductCardItem[];
};

export type PostCardContentComponentProps = {
  defaultAvatar?: string;
};

export type PostCardContentProps = PostContent & PostCardContentComponentProps;
