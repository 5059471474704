import { ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import { OnErrorFn } from '@formatjs/intl/src/types';
import { Maybe } from 'types/utils';
import mergeWith from 'lodash.mergewith';

import { deepFlattenToObject } from 'utilities/js';

const messageLoader = {
  en: () => import('translations/en.json'),
  es: () => import('translations/es.json'),
  de: () => import('translations/de.json'),
  pt: () => import('translations/pt.json'),
  fr: () => import('translations/fr.json'),
  it: () => import('translations/it.json'),
  nl: () => import('translations/nl.json'),
  pl: () => import('translations/pl.json'),
};

type Locales = 'en' | 'es' | 'de' | 'pt' | 'fr' | 'it' | 'nl' | 'pl';

function isValidLocale(locale?: string): locale is Locales {
  if (!locale) {
    return false;
  }

  return locale in messageLoader;
}

export async function getLocaleData(locale: string) {
  if (!isValidLocale(locale)) {
    return {};
  }

  const { data, en } = await Promise.all([messageLoader[locale](), locale !== 'en' ? messageLoader.en() : null])
    .then(([loadedData, loadedEn]) => {
      return {
        data: loadedData?.default,
        en: loadedEn?.default,
      };
    })
    .catch(() => {
      return {
        data: {},
        en: {},
      };
    });

  // flatten the data to a single level object (cf_menu property is not a flat object, so we need to flatten it before passing it to the provider)
  const flattenedData = deepFlattenToObject(data);
  const flattenedEn = deepFlattenToObject(en ?? {});

  // if there are empty strings in the target locale, we want to use the english fallback values
  const value = mergeWith(flattenedData, flattenedEn, (localeValue, enValue) => {
    if (!localeValue) {
      return enValue;
    }

    return localeValue;
  });

  return value;
}

export const LocaleProvider = ({
  children,
  initialMessages,
}: {
  children: ReactNode;
  initialMessages?: Maybe<Record<string, string>>;
}) => {
  const { locale, pathname } = useRouter();
  const [messages, setMessages] = useState(initialMessages ?? {});

  useEffect(() => {
    async function loadMessages() {
      if (isValidLocale(locale)) {
        const nextMessages = await getLocaleData(locale);

        setMessages(nextMessages);
      }
    }

    loadMessages();
  }, [locale, pathname]);

  const handleTranslationError: OnErrorFn = error => {
    if (process.env.NEXT_PUBLIC_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <IntlProvider locale="en" defaultLocale="en" messages={messages} onError={handleTranslationError}>
      {children}
    </IntlProvider>
  );
};
