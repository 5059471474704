import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { initAmplitude } from './Amplitude.utils';

const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
export const AMPLITUDE_DEVICE_ID_COOKIE_NAME = 'cfAmpAnonymousId';

export const Amplitude = () => {
  const [cookies] = useCookies([AMPLITUDE_DEVICE_ID_COOKIE_NAME]);
  const deviceIdCookie = cookies[AMPLITUDE_DEVICE_ID_COOKIE_NAME];

  useEffect(() => {
    if (amplitudeApiKey) {
      initAmplitude(deviceIdCookie);
    }
  }, [deviceIdCookie]);

  return null;
};
