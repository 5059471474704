export enum BingEventName {
  COMPLETE_REGISTRATION = 'signup',
}

export type BingEventOptions = { [key: string]: string | number } | void;

declare global {
  interface Window {
    uetq?:
      | {
          push: (type: 'event', name: BingEventName, options: BingEventOptions) => void;
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | Array<any>;
  }
}
