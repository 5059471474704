import { useCookies } from 'react-cookie';
import { customAlphabet } from 'nanoid';

import { getMainDomain } from 'api/fetcher.utils';

export const DEVICE_ID_COOKIE_NAME = 'cfDeviceId';
export const DEVICE_ID_NOT_SET = '__notSet__';

const DEVICE_ID_ALLOWED_CHARS = '0123456789abcdefghijklmnopqrstuvwxyz';

export const useDeviceId = () => {
  const [cookies, setCookie] = useCookies([DEVICE_ID_COOKIE_NAME]);

  const deviceId = cookies[DEVICE_ID_COOKIE_NAME] || DEVICE_ID_NOT_SET;

  const generateDeviceId = () => {
    const nanoid = customAlphabet(DEVICE_ID_ALLOWED_CHARS, 10);
    const newDeviceId = nanoid();

    setCookie(DEVICE_ID_COOKIE_NAME, newDeviceId, {
      path: '/',
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10), // 10 years
      domain: getMainDomain(),
    });

    return newDeviceId;
  };

  return { deviceId, generateDeviceId };
};
