import { createContext } from 'react';

import { AuthActionType } from 'components/contexts/authProvider/authReducer/authReducer.types';
import { nonceFetcher } from 'api/requests/nonce/nonce';
import { NonceType } from 'api/requests/nonce/nonce.types';
import { AppRoute } from 'components/app/App.types';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useLogoutMutation } from 'generated/graphql';
import { Flags, useFlags } from 'hooks/useFlags/useFlags';

import { LogoutProviderProps, LogoutContextValue } from './LogoutProvider.types';

export const LogoutContext = createContext<LogoutContextValue | null>(null);

export const LogoutProvider = (props: LogoutProviderProps) => {
  const { children } = props;
  const dispatch = useAuthDispatch();
  const logoutMutation = useLogoutMutation();

  const [USE_GRAPHQL_AUTH] = useFlags(Flags.USE_GRAPHQL_AUTH);

  const logout = async () => {
    if (USE_GRAPHQL_AUTH) {
      await logoutMutation.mutateAsync({});
    }

    dispatch({ type: AuthActionType.LOGOUT });

    const { nonce } = await nonceFetcher({ nonceType: NonceType.LOGOUT });

    window.location.assign(`${AppRoute.LOGOUT}?_wpnonce=${nonce}`);
  };

  const value = {
    logout,
  };

  return <LogoutContext.Provider value={value}>{children}</LogoutContext.Provider>;
};
