import Script from 'next/script';

const appId = process.env.NEXT_PUBLIC_FACEBOOK_SDK_APP_ID;

export const FacebookSDK = () => {
  if (process.env.NEXT_PUBLIC_ENABLE_ANALYTICS !== 'true') {
    return null;
  }

  if (!appId) {
    return null;
  }

  return (
    <>
      <Script strategy="lazyOnload" crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />
      <Script
        id="facebook-sdk-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
              window.fbAsyncInit = function() {
                FB.init({
                  appId            : '${appId}',
                  autoLogAppEvents : true,
                  xfbml            : true,
                  version          : 'v11.0'
                });
              };
            `,
        }}
      />
    </>
  );
};
