import { createContext, useState } from 'react';

import { resetPasswordFetcher } from 'api/requests/resetPassword/resetPassword';
import { useLostPasswordMutation } from 'generated/graphql';
import { toShortBackendErrors } from 'generated/codegen.fetcher';
import { Flags, useFlags } from 'hooks/useFlags/useFlags';
import { unsetCurrentStoredToken } from 'components/contexts/authProvider/AuthToken';

import { ResetContextValue, ResetProviderProps, ResetHandler, ResetState } from './ResetProvider.types';

export const ResetContext = createContext<ResetContextValue | null>(null);

const initialResetState = {
  isLoading: false,
  errors: undefined,
  status: undefined,
};

export const ResetProvider = (props: ResetProviderProps) => {
  const { children } = props;
  const [resetState, setResetState] = useState<ResetState>(initialResetState);
  const lostPasswordMutation = useLostPasswordMutation();

  const [USE_GRAPHQL_AUTH] = useFlags(Flags.USE_GRAPHQL_AUTH);

  const resetWithWP: ResetHandler = async ({ email, onError }) => {
    setResetState(prevState => ({ ...prevState, isLoading: true }));
    const response = await resetPasswordFetcher({ email, onError });
    setResetState({
      isLoading: false,
      errors: response.errors,
      status: response.status,
    });
  };

  const resetWithGraphql: ResetHandler = async ({ email, onError }) => {
    unsetCurrentStoredToken();

    setResetState(prevState => ({ ...prevState, isLoading: true }));
    const { lostPassword } = await lostPasswordMutation.mutateAsync({ input: { email } });

    lostPassword &&
      lostPassword.errors &&
      lostPassword.errors.length &&
      onError &&
      onError({
        ...new Error(lostPassword.errors[0].message),
      });

    setResetState({
      isLoading: false,
      errors: toShortBackendErrors(lostPassword?.errors || undefined),
      status: lostPassword?.success ? 1 : 0,
    });
  };

  const reset = USE_GRAPHQL_AUTH ? resetWithGraphql : resetWithWP;

  const value = {
    resetState,
    reset,
  };

  return <ResetContext.Provider value={value}>{children}</ResetContext.Provider>;
};
