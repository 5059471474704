const preloadImagesCache = new Map<string, Promise<unknown>>();

export function preloadImage(src: string): Promise<unknown> {
  const cache = preloadImagesCache.get(src);

  if (cache) {
    return cache;
  }

  const loader = new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => resolve(null);
    image.onerror = reject;
  });

  preloadImagesCache.set(src, loader);

  return loader;
}

export function addDomEvent(
  target: EventTarget | Window,
  eventName: string,
  handler: EventListener,
  options?: AddEventListenerOptions,
) {
  target.addEventListener(eventName, handler, options);

  return () => target.removeEventListener(eventName, handler, options);
}

export function isElementInViewport(element: Element) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

type WidthAndHeight = {
  width: number;
  height: number;
};

export async function getWidthAndHeightFromImageUrl(url: string): Promise<WidthAndHeight> {
  const img = document.createElement('img');

  const promise = new Promise<WidthAndHeight>((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      resolve({ width, height });
    };

    img.onerror = reject;
  });

  img.src = url;

  return promise;
}

export function bookmark(title: string, url: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w = window as any;

  return new Promise<null>((resolve, reject) => {
    if (w.sidebar) {
      w.sidebar.addPanel(title, url, '');
      resolve(null);
    } else if (w.opera && w.print) {
      const elem = document.createElement('a');
      elem.setAttribute('href', url);
      elem.setAttribute('title', title);
      elem.setAttribute('rel', 'sidebar');
      elem.click();
      resolve(null);
    } else if (document.all) {
      w.external.AddFavorite(url, title);
      resolve(null);
    } else {
      reject('no options');
    }
  });
}
