import { useEffect } from 'react';
import { useRouter } from 'next/router';
import algoliaAnalytics from 'search-insights';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { oneSignalDataToAnalyticsData, oneSignalEvent } from 'components/app/oneSignalSDK/OneSignalSDK.utils';
import { OneSignalEventName } from 'components/app/oneSignalSDK/OneSignalSDK.types';
import { sendAmplitudeLog } from 'components/app/analytics/amplitude/Amplitude.utils';
import { facebookEvent } from 'components/app/analytics/facebookPixel/FacebookPixel.utils';
import { FacebookEventName } from 'components/app/analytics/facebookPixel/FacebookPixel.types';
import { ABTestUserInfo } from 'components/app/analytics/abTestUserInfo/ABTestUserInfo';
import { GoogleAnalytics } from 'components/app/analytics/googleAnalytics/GoogleAnalytics';
import {
  googleAnalyticsEvent,
  googleTrackHomePageView,
} from 'components/app/analytics/googleAnalytics/GoogleAnalytics.utils';
import { GoogleAnalyticsEventName } from 'components/app/analytics/googleAnalytics/GoogleAnalytics.types';
import { FacebookPixel } from 'components/app/analytics/facebookPixel/FacebookPixel';
import { GlobalSiteTag } from 'components/app/analytics/globalSiteTag/GlobalSiteTag';
import { Amplitude } from 'components/app/analytics/amplitude/Amplitude';
import { Bing } from 'components/app/analytics/bing/Bing';
import {
  cfTrackingIdentifyUser,
  cfTrackingInit,
  cfTrackingInitialLogin,
  cfTrackingPageView,
} from 'components/app/analytics/cfTracking/CfTracking.utils';
import { AppRoute } from 'components/app/App.types';
import {
  contentAlgoliaApiKey,
  contentAlgoliaAppId,
} from 'components/contexts/instantSearchProvider/InstantSearchProvider.utils';
import { mapCategoryPageTypeToCategoryDetails, CategoryPageType } from 'components/pages/contentApi/pages/config';

import { AmplitudeEvent } from './amplitude/Amplitude.types';

export const CF_ANALYTICS_INIT_EVENT_NAME = 'initCfTracking';

const homePageRoutes: string[] = [AppRoute.HOME, AppRoute.REACT_HOME];
const skipLoadedAPageAmplitudeCall = [
  AppRoute.PRODUCT,
  AppRoute.REACT_PRODUCT,
  AppRoute.PROD,
  `${AppRoute.PROD}[...slug]/`,
  AppRoute.STOCKPHOTOS,
  `${AppRoute.STOCKPHOTOS}[...slug]/`,
];

export const Analytics = () => {
  const router = useRouter();
  const { user } = useAuthState();

  useEffect(() => {
    const routeWithTrailingSlash = router.route.endsWith('/') ? router.route : `${router.route}/`;
    const mainCategory: CategoryPageType = router.asPath.split('/')[1] as CategoryPageType;
    const pageDetails = mapCategoryPageTypeToCategoryDetails[mainCategory];
    const skipPageView = pageDetails !== undefined || skipLoadedAPageAmplitudeCall.includes(routeWithTrailingSlash);

    cfTrackingInit(user);

    if (!skipPageView) {
      cfTrackingPageView();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    algoliaAnalytics('init', {
      appId: contentAlgoliaAppId,
      apiKey: contentAlgoliaApiKey,
    });
  }, []);

  useEffect(() => {
    oneSignalEvent(OneSignalEventName.LISTENER_NOTIFICATION_OPEN, data => {
      const analyticsProperties = oneSignalDataToAnalyticsData(data);

      sendAmplitudeLog(AmplitudeEvent.NOTIFICATION_CLICK, analyticsProperties);
    });
  }, []);

  useEffect(() => {
    const routeWithTrailingSlash = router.route.endsWith('/') ? router.route : `${router.route}/`;
    const isHomepage = homePageRoutes.includes(routeWithTrailingSlash);
    const mainCategory: CategoryPageType = router.asPath.split('/')[1] as CategoryPageType;
    const pageDetails = mapCategoryPageTypeToCategoryDetails[mainCategory];
    const skipPageView = pageDetails !== undefined || skipLoadedAPageAmplitudeCall.includes(routeWithTrailingSlash);

    if (isHomepage) {
      googleTrackHomePageView();
    }

    const trackPageView = (path: string) => {
      // This function gets triggered when the url changes, meaning it gets triggered when query params change as well.
      // On search page this happens a lot so we do not want to send analytics when this happens.
      // Checking only for search page because we might redirect to other pages with query params and we want to track those.
      if (path.indexOf('search') !== -1) {
        return;
      }

      facebookEvent(FacebookEventName.PAGE_VIEW);
      googleAnalyticsEvent(GoogleAnalyticsEventName.PAGE_VIEW);

      if (!skipPageView) {
        cfTrackingPageView();
      }

      if (isHomepage) {
        googleTrackHomePageView();
      }
    };

    router.events.on('routeChangeComplete', trackPageView);

    return () => {
      router.events.off('routeChangeComplete', trackPageView);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events, router.route]);

  useEffect(() => {
    cfTrackingIdentifyUser(user);
    cfTrackingInitialLogin(user);

    if (user?.identification.id) {
      algoliaAnalytics('setUserToken', `user-${user?.identification.id}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (process.env.NEXT_PUBLIC_ENABLE_ANALYTICS !== 'true') {
    return null;
  }

  return (
    <>
      <ABTestUserInfo />
      <GoogleAnalytics />
      <GlobalSiteTag />
      <FacebookPixel />
      <Amplitude />
      <Bing />
    </>
  );
};
