import { createContext, useContext } from 'react';

import { PayWithCoinsCardContextType } from './PayWithCoinsCard.types';

export const PayWithCoinsCardContext = createContext<PayWithCoinsCardContextType>(
  null!
);

export const usePayWithCoinsCard = () => {
  const context = useContext(PayWithCoinsCardContext);

  if (!context) {
    throw new Error(
      '`usePayWithCoinsCard` must be used within a <PayWithCoinsCard /> component'
    );
  }

  return context;
};
