export type PayWithCoinsCardProps = {
  onSpend(): Promise<void>;
  onCancel(): Promise<void>;
  bundleValue: number;
  userBalance: number;
  state: PayWithCoinsCardSpendingState;
  canCancel: boolean;
  title?: PayWithCoinsCardTitleProps;
};

export type PayWithCoinsCardTitleProps = {
  insufficientCoins?: string;
  readyToSpend?: string;
  confirmed?: string;
  canceled?: string;
};

export type PayWithCoinsCardContextType = {
  onSpend(): Promise<void>;
  onCancel(): Promise<void>;
  bundleValue: number;
  userBalance: number;
  canCancel: boolean;
};

export enum PayWithCoinsCardSpendingState {
  'insufficient_coins',
  'ready_to_spend',
  'confirmed',
  'cancelled',
}

export type PayWithCoinsCardStateProps = {
  title: string;
};
