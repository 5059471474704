import { styled } from '@mui/material';
import { IconButton as MuiIconButton } from '@mui/material';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  width: theme.spacing(4.25),
  height: theme.spacing(4.25),
  padding: theme.spacing(0.625),
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiIconButton-colorPrimary': {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  '&.MuiIconButton-colorSecondary': {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  '&.Mui-disabled': {
    color: theme.palette.gray.main,
    '&:hover': {
      color: theme.palette.gray.main,
    },
  },
  '&.MuiIconButton-sizeSmall': {
    width: theme.spacing(3.75),
    height: theme.spacing(3.75),
    padding: theme.spacing(0.75),
  },
  '&.MuiIconButton-sizeXsmall': {
    width: 28,
    height: 28,
    padding: 0,
    fontSize: '1rem',
  },
}));
