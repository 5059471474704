const primary = '#493675';
const primaryDark = '#332551';
const primaryLight = '#a399b8';
const primaryExtraLight = '#e5def5';
const secondary = '#3ab561';
const secondaryLight = '#00d990';
const secondaryDark = '#248142';
const tertiary = '#f3ca7f';
const tertiaryLight = 'rgba(243,202,127,0.58)';
const black = '#2a1f3f';
const blackLight = '#dbd3f1';
const blackExtraLight = '#dfe2e7';
const white = '#ffffff';
const blue = '#0a5ea8';
const blueDark = '#1877f2';
const blueExtraDark = '#3b5998';
const blueLight = '#29abe2';
const blueExtraLight = '#c5e0ff';
const gray = '#a0b2b8';
const grayLight = '#cfd7e0';
const grayExtraLight = '#f5f6fa';
const grayDark = '#9093b2';
const grayExtraDark = '#7b898d';
const yellow = '#fee100';
const yellowDark = '#ffb400';
const yellowExtraDark = '#d19400';
const yellowLight = '#FFD99D';
const yellowExtraLight = '#FEF9F4';
const redLight = '#ffa0a8';
const red = '#ff4554';
const redDark = '#c7254e';
const redExtraDark = '#861933';
const pink = '#ee2a7b';
const pinkDark = '#b21e5b';
const orange = '#f7931e';
const orangeDark = '#c46900';
const violet = '#7f42f6';
const violetDark = '#5930a9';
const violetExtraDark = '#4929a0';
const plum = '#541156';
const plumDark = '#260427';
const border = '#e3e1e9';
const borderLight = '#e0e2ea';
const grayPurple = '#5a608e';
const grayPurpleLight = '#9895a5';
const grayPurpleDark = '#696278';
const flow = '#5b9ff6';

export const palette = {
  primary: {
    dark: primaryDark,
    main: primary,
    light: primaryLight,
    extraLight: primaryExtraLight,
  },
  secondary: {
    dark: secondaryDark,
    main: secondary,
    light: secondaryLight,
  },
  tertiary: {
    main: tertiary,
    light: tertiaryLight,
  },
  black: {
    main: black,
    light: blackLight,
    extraLight: blackExtraLight,
  },
  white: {
    main: white,
  },
  gray: {
    extraDark: grayExtraDark,
    dark: grayDark,
    main: gray,
    light: grayLight,
    extraLight: grayExtraLight,
  },
  grayPurple: {
    light: grayPurpleLight,
    main: grayPurple,
    dark: grayPurpleDark,
  },
  yellow: {
    extraDark: yellowExtraDark,
    dark: yellowDark,
    main: yellow,
    light: yellowLight,
    extraLight: yellowExtraLight,
  },
  red: {
    extraDark: redExtraDark,
    dark: redDark,
    main: red,
    light: redLight,
  },
  pink: {
    dark: pinkDark,
    main: pink,
  },
  orange: {
    dark: orangeDark,
    main: orange,
  },
  violet: {
    extraDark: violetExtraDark,
    dark: violetDark,
    main: violet,
  },
  plum: {
    dark: plumDark,
    main: plum,
  },
  blue: {
    dark: blueDark,
    extraDark: blueExtraDark,
    main: blue,
    light: blueLight,
    extraLight: blueExtraLight,
  },
  background: {
    default: white,
  },
  text: {
    primary: black,
  },
  success: {
    main: secondary,
  },
  error: {
    main: red,
  },
  border: {
    main: border,
    light: borderLight,
  },
  flow: {
    main: flow,
  },
};
