import { User } from 'api/requests/user/user.types';
import { AuthState } from 'components/contexts/authProvider/AuthProvider.types';

export enum AuthActionType {
  LOGIN = 'login',
  LOGOUT = 'logout',
  REFRESH_TOKEN = 'refresh_token',
  REFRESH_USER = 'refresh_user',
}

export type AuthAction = {
  type: AuthActionType;
  user?: User;
  token?: string;
};

export type AuthReducer = (state: AuthState, action: AuthAction) => AuthState;
