import { useEffect } from 'react';
import { CacheProvider } from '@emotion/react';
import { setup } from 'cf-ui';
import { Provider } from 'jotai';
import Head from 'next/head';
import { useState } from 'react';
import { CookiesProvider, Cookies } from 'react-cookie';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'components/contexts/themeProvider/theme/global.css';
import 'components/contexts/themeProvider/theme/normalize.css';
import 'components/contexts/themeProvider/theme/reset.css';
import 'styles/global.css';
import 'cf-web/packages/styles/scroller.css';

import { GoogleOneTapLogin } from 'components/app/oneTapLogin/OneTapLogin';
import { Analytics } from 'components/app/analytics/Analytics';
import { AppRoute, EnhancedAppProps } from 'components/app/App.types';
import { printServerRequests } from 'components/app/App.utils';
import { createEmotionCache } from 'components/app/createEmotionCache/createEmotionCache';
import { FacebookSDK } from 'components/app/facebookSDK/FacebookSDK';
import { OneSignalSDK } from 'components/app/oneSignalSDK/OneSignalSDK';
import { AuthProvider } from 'components/contexts/authProvider/AuthProvider';
import { CurrencyProvider } from 'components/contexts/currenciesProvider/CurrencyProvider';
import { LocaleProvider } from 'components/contexts/localeProvider/LocaleProvider';
import { RequestStateProviders } from 'components/contexts/requestStateProviders/RequestStateProviders';
import { SnackbarManager } from 'components/contexts/snackbarProvider/SnackbarManager';
import { SnackbarProvider } from 'components/contexts/snackbarProvider/SnackbarProvider';
import { getUserAgentMatchMedia } from 'components/contexts/themeProvider/theme/utils';
import { ThemeProvider } from 'components/contexts/themeProvider/ThemeProvider';
import { useRouteWhitelistRedirect } from 'hooks/useRouteWhitelistRedirect/useRouteWhitelistRedirect';
import { FramerMotion } from 'lib/framer-motion';

setup();

if (process.env.NODE_ENV === 'development' && process.env.NEXT_API_MOCKING === 'true') {
  if (typeof window !== 'undefined') {
    import('mocks/browser').then(({ register }) => register());
  } else {
    import('mocks/node').then(({ register }) => register());
  }
}

const clientSideEmotionCache = createEmotionCache();

const handleSubscriptionComplete = (
  event: CustomEvent<{
    intent_status: string;
  }>,
) => {
  //After payment is successful, we refresh the page to update the user's subscription status
  if (event.detail.intent_status === 'succeeded') {
    //refresh page
    window.location.reload();
  }
};

const App = (props: EnhancedAppProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
          },
        },
      }),
  );

  useRouteWhitelistRedirect();

  const { Component, pageProps, emotionCache = clientSideEmotionCache, router } = props;
  const { userAgent, initialCookies, initialUserData, initialLocaleData, serverRequestsLog } = pageProps;
  const getLayout = Component.getLayout || (page => page);

  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    printServerRequests(serverRequestsLog ?? []);
  }

  const ssrMatchMedia = getUserAgentMatchMedia(userAgent);

  const path = router?.asPath;
  const pathWithTrailingSlash = path.endsWith('/') ? path : `${path}/`;
  const canonicalPathname = pathWithTrailingSlash.split('ref/')[0];

  const showNoIndexMeta =
    process.env.NEXT_PUBLIC_ENV !== 'production' || router.query?.text !== undefined || router.query?.pt !== undefined;

  const hideMetaTagsToAvoidDupplicates =
    [
      AppRoute.PNG,
      AppRoute.STOCKPHOTOS,
      AppRoute.PNG_SEARCH,
      AppRoute.STOCKPHOTOS_SEARCH,
      AppRoute.BACKGROUNDS,
      AppRoute.BACKGROUNDS_SEARCH,
      AppRoute.BORDERS_AND_CORNERS,
      AppRoute.BORDERS_AND_CORNERS_SEARCH,
      AppRoute.CARTOONS,
      AppRoute.CARTOONS_SEARCH,
      AppRoute.CLIP_ART,
      AppRoute.CLIP_ART_SEARCH,
      AppRoute.DRAWINGS,
      AppRoute.DRAWINGS_SEARCH,
      AppRoute.FRAMES,
      AppRoute.FRAMES_SEARCH,
      AppRoute.MOCKUPS,
      AppRoute.MOCKUPS_SEARCH,
      AppRoute.PATTERNS,
      AppRoute.PATTERNS_SEARCH,
      AppRoute.QUOTES,
      AppRoute.QUOTES_SEARCH,
      AppRoute.SILHOUETTES,
      AppRoute.SILHOUETTES_SEARCH,
      AppRoute.STICKERS,
      AppRoute.STICKERS_SEARCH,
      AppRoute.SKETCHES,
      AppRoute.SKETCHES_SEARCH,
      AppRoute.SVGS,
      AppRoute.SVGS_SEARCH,
      AppRoute.TEXTURES,
      AppRoute.TEXTURES_SEARCH,
    ].includes(path as AppRoute) || path.startsWith(AppRoute.PROD);

  useEffect(() => {
    //Listen for  a successful purchase of an all access subscription
    document.addEventListener('cf_intent_status_updated', handleSubscriptionComplete as EventListener);

    return () => {
      document.removeEventListener('cf_intent_status_updated', handleSubscriptionComplete as EventListener);
    };
  }, []);

  return (
    <Provider>
      <FramerMotion>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Creative Fabrica - Premium Crafting Fonts, Graphics &amp; More</title>
            <link rel="icon" href="/static/favicon.ico" key="icon" />
            <meta key="twitter:card" name="twitter:card" content="summary" />
            <meta
              key="twitter:description"
              name="twitter:description"
              content="On Creative Fabrica you will find the best font &amp; graphic deals at the best prices. Want more? Sign up for on of our subscription and get unlimited access."
            />
            <meta
              key="twitter:title"
              name="twitter:title"
              content="Creative Fabrica - Your number #1 source for premium design elements"
            />
            <meta
              key="twitter:image"
              name="twitter:image"
              content="https://www.creativefabrica.com/wp-content/uploads/2016/05/Facebook-Cover.png"
            />

            {hideMetaTagsToAvoidDupplicates ? null : (
              <>
                <meta
                  key="og:url$og:url"
                  property="og:url"
                  content={`${process.env.NEXT_PUBLIC_API_URL}${
                    router?.locale !== 'en' ? `/${router?.locale}` : ''
                  }${pathWithTrailingSlash}`}
                />
                <link
                  key="canonicalTag$canonical"
                  rel="canonical"
                  href={`${process.env.NEXT_PUBLIC_API_URL}${
                    router?.locale !== 'en' ? `/${router?.locale}` : ''
                  }${canonicalPathname}`}
                />
                <link
                  key="alternate$x-default"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}${pathWithTrailingSlash}`}
                  hrefLang="x-default"
                />
                <link
                  key="alternate$en"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}${pathWithTrailingSlash}`}
                  hrefLang={'en'}
                />
                <link
                  key="alternate$de"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}/de${pathWithTrailingSlash}`}
                  hrefLang={'de'}
                />
                <link
                  key="alternate$es"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}/es${pathWithTrailingSlash}`}
                  hrefLang={'es'}
                />
                <link
                  key="alternate$fr"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}/fr${pathWithTrailingSlash}`}
                  hrefLang={'fr'}
                />
                <link
                  key="alternate$it"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}/it${pathWithTrailingSlash}`}
                  hrefLang={'it'}
                />
                <link
                  key="alternate$nl"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}/nl${pathWithTrailingSlash}`}
                  hrefLang={'nl'}
                />
                <link
                  key="alternate$pl"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}/pl${pathWithTrailingSlash}`}
                  hrefLang={'pl'}
                />
                <link
                  key="alternate$pt"
                  rel="alternate"
                  href={`${process.env.NEXT_PUBLIC_API_URL}/pt${pathWithTrailingSlash}`}
                  hrefLang={'pt'}
                />
              </>
            )}
            <meta
              property="og:viewport"
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta
              name="robots"
              content={`${
                showNoIndexMeta ? 'noindex, ' : ''
              }max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Creative Fabrica" />
            <meta property="fb:app_id" content="166866887053973" />
            <meta name="google-site-verification" content="2vxXdXPAP64CqxfFiq-doLdEYXzHnGwboweufMKQCPQ" />
            <meta name="referrer" content="unsafe-url" />
            <link
              rel="alternate"
              type="application/json+oembed"
              href="https://www.creativefabrica.com/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwww.creativefabrica.com%2F"
            />
            <link
              rel="alternate"
              type="text/xml+oembed"
              href="https://www.creativefabrica.com/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwww.creativefabrica.com%2F&amp;format=xml"
            />
          </Head>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <ThemeProvider ssrMatchMedia={ssrMatchMedia}>
                <LocaleProvider initialMessages={initialLocaleData}>
                  <SnackbarProvider>
                    <CookiesProvider cookies={new Cookies(initialCookies)}>
                      <AuthProvider initialUserData={initialUserData}>
                        <CurrencyProvider>
                          <RequestStateProviders>
                            <Analytics />
                            <OneSignalSDK />
                            <FacebookSDK />
                            <SnackbarManager />
                            <GoogleOneTapLogin />
                            {getLayout(<Component {...pageProps} />, {})}
                          </RequestStateProviders>
                        </CurrencyProvider>
                      </AuthProvider>
                    </CookiesProvider>
                  </SnackbarProvider>
                </LocaleProvider>
              </ThemeProvider>
            </Hydrate>
          </QueryClientProvider>
        </CacheProvider>
      </FramerMotion>
    </Provider>
  );
};

export default App;
