import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { palette } from 'components/contexts/themeProvider/theme/palette';

const { breakpoints } = createTheme();

export const fontWeight = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const typography: TypographyOptions = {
  fontFamily: 'Poppins, Arial, sans-serif',
  fontWeightRegular: fontWeight.regular,
  fontWeightMedium: fontWeight.medium,
  fontWeightSemiBold: fontWeight.semibold,
  fontWeightBold: fontWeight.bold,
  h1: {
    fontSize: 36,
    fontWeight: fontWeight.bold,
    lineHeight: 1.3,
    letterSpacing: '0.3px',
    [breakpoints.down('xs')]: {
      fontSize: 28,
      lineHeight: 1.3,
    },
  },
  h2: {
    fontSize: 24,
    fontWeight: fontWeight.bold,
    lineHeight: 1.4,
  },
  h3: {
    fontSize: 18,
    fontWeight: fontWeight.bold,
    lineHeight: 1.5,
  },
  h4: {
    fontSize: 16,
    fontWeight: fontWeight.medium,
    lineHeight: 1.4,
  },
  body1: {
    fontSize: 14,
    fontWeight: fontWeight.regular,
    lineHeight: 1.6,
  },
  body2: {
    fontSize: 14,
    fontWeight: fontWeight.semibold,
    lineHeight: 1.6,
  },
  subtitle1: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: fontWeight.bold,
  },
  subheading: {
    fontSize: 16,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    color: palette.secondary.main,
    fontWeight: fontWeight.bold,
  },
  subtext: {
    color: palette.gray.dark,
    fontSize: 12,
    lineHeight: 1.3,
  },
};
