import { styled } from '../../elements';

export const SidebarMenuWrapper = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyleType: 'none',
  margin: theme.spacing(0),
  padding: theme.spacing(0),
}));

export const SidebarMenuItem = styled('li')<{
  selected?: boolean;
}>(({ theme, selected }) => ({
  fontSize: theme.spacing(1.75),
  padding: theme.spacing(1, 2.75),
  margin: theme.spacing(0.5, 0),
  textTransform: 'capitalize',

  ...(selected && {
    boxShadow: theme.shadows[7],
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 2.75),
  }),
}));

export const SidebarMenuAnchor = styled('a')<{
  selected?: boolean;
}>(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  cursor: 'pointer',
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.black.main,
  ...(!selected && {
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  }),
}));

export const SidebarMenuItemIconWrapper = styled('span')(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(1),
  color: theme.palette.gray.main,
}));
