export enum ReactionType {
  Heart = 'heart',
  Idea = 'idea',
  Claps = 'claps',
}

export const ReactionTypes = [
  ReactionType.Heart,
  ReactionType.Idea,
  ReactionType.Claps,
];

export type ReactionIconTheme = {
  markedColor: string;
  unmarkedColor: string;
  disabledColor: string;
};

export type ReactionPanelActions = ReactionButtonActions;

export type ReactionPanelProps = ReactionPanelActions & {
  isDisabled?: boolean;

  heartsVisible?: boolean;
  heartsCount?: number;
  heartsMarked?: boolean;
  heartsTheme?: ReactionIconTheme;

  ideasVisible?: boolean;
  ideasCount?: number;
  ideasMarked?: boolean;
  ideasTheme?: ReactionIconTheme;

  clapsVisible?: boolean;
  clapsCount?: number;
  clapsMarked?: boolean;
  clapsTheme?: ReactionIconTheme;
};

export type ReactionTypeData = {
  count: number;
  marked: boolean;
};

export type ReactionButtonActions = {
  onReaction?: (
    type: ReactionType.Heart | ReactionType.Idea | ReactionType.Claps,
    value: boolean
  ) => Promise<void>;
};

export type ReactionButtonProps = ReactionButtonActions & {
  type: ReactionType;
  count?: number;
  isMarked?: boolean;
  isDisabled?: boolean;
  theme?: ReactionIconTheme;
};

export type ReactionButtonSet = {
  marked: JSX.Element;
  unmarked: JSX.Element;
  disabled: JSX.Element;
};
