import { FutureImage } from 'components/elements/atoms/image/FutureImage';

import { styled, Typography } from '../../elements';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));

export const Icon = styled(FutureImage)(({ theme }) => ({
  minWidth: 94,
  width: 94,
  height: 79,
  objectFit: 'cover',
  [theme.breakpoints.up('md')]: {
    width: 133,
    height: 112,
  },
}));

export const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  // need to reset font weight of h4, since it matches
  // the styles we need apart from font weight
  fontWeight: theme.typography.fontWeightRegular,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}));

export const Strong = styled('strong')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

export const CoinStar = styled(FutureImage)(({ theme }) => ({
  display: 'inline-flex',
  verticalAlign: 'middle',
  width: 15,
  height: 15,
  marginRight: theme.spacing(0.375),
  transform: 'translateY(-1px)',
  [theme.breakpoints.up('md')]: {
    width: 17,
    height: 17,
    marginRight: theme.spacing(0.5),
  },
}));
