import { forwardRef } from 'react';
import classNames from 'classnames';

import { Icon } from 'components/elements/atoms/icon/Icon';
import { Spinner } from 'components/elements/atoms/spinner/Spinner';

import { ButtonProps } from './Button.types';
import * as S from './Button.styles';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      icon,
      variant = 'contained',
      iconPosition = 'start',
      color = 'primary',
      children,
      className,
      disabled,
      loading,
      href,
      ...props
    },
    ref,
  ) => {
    let iconComponent;

    if (icon) {
      iconComponent = loading ? <Spinner /> : <Icon icon={icon} />;
    }

    const hasSpinnerOverlay = loading && !icon;

    return (
      <S.Button
        ref={ref}
        className={classNames(className, color !== 'inherit' && `${color}Color`, {
          loading,
          hideLabel: hasSpinnerOverlay,
        })}
        disableElevation
        variant={variant}
        disabled={disabled || loading}
        startIcon={iconPosition === 'start' && iconComponent}
        endIcon={iconPosition === 'end' && iconComponent}
        href={href}
        {...props}
      >
        {hasSpinnerOverlay && <Spinner className="overlay" />}
        <span>{children}</span>
      </S.Button>
    );
  },
);
