import { styled } from '../../../../elements';
import { Button } from '../Button';

export const ButtonFacebook = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.blue.extraDark,
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover': {
    backgroundColor: theme.palette.blue.extraDark,
    opacity: 0.8,
  },
}));
