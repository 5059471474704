import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { styled, Box, Button, Typography } from '../../elements';

export const Wrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  background: theme.palette.white.main,
  width: '100%',
  height: theme.spacing(12.5),
  boxShadow: theme.shadows[1],
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0),
  display: 'flex',
  flexDirection: 'row',
}));

export const ImageWrapper = styled('a')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  width: theme.spacing(18.75),
  minWidth: theme.spacing(18.75),
  height: '100%',
  display: 'flex',
  cursor: 'pointer',
  position: 'relative',
}));

export const Image = styled('img')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  objectFit: 'cover',
  width: '100%',
  height: '100%',
}));

export const ClassIconWrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0),
  width: theme.spacing(2),
  height: theme.spacing(2),
  background: theme.palette.white.main,
  color: theme.palette.black.main,
  borderRadius: theme.spacing(0, 0.25, 0.25, 0),
  position: 'absolute',
  top: theme.spacing(1.75),
  right: theme.spacing(0),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: theme.spacing(1),
  transform: 'scale(-1)',
}));

export const Content = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(1),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
}));

export const Title = styled('a')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0),
  textDecoration: 'none',
  cursor: 'pointer',
  color: theme.palette.black.main,
  fontSize: theme.spacing(1.75),
  fontWeight: 700,
  lineHeight: 1.25,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const AuthorWrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0),
}));

export const Author = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0, 0, 1),
  color: theme.palette.black.main,
  fontSize: theme.spacing(1.5),
  maxWidth: theme.spacing(20),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const AuthorAnchor = styled('a')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0),
  fontSize: theme.spacing(1.5),
  color: theme.palette.blue.main,
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const ProductCategory = styled(Typography)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0.25, 0.75),
  margin: theme.spacing(0),
  color: theme.palette.black.main,
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  fontSize: theme.spacing(1.25),
  fontWeight: 700,
  backgroundColor: '#e6f0f2',
  textTransform: 'capitalize',
  width: 'fit-content',
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0, 2.5),
  height: theme.spacing(3.75),
  lineHeight: 1,
  alignSelf: 'flex-end',
  fontWeight: 700,
  textAlign: 'center',
  textDcoration: 'none',
  outline: 'none',
  textTransform: 'uppercase',
  border: 'none',
  borderRadius: theme.spacing(0.5),
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: '0.9px',
  background: theme.palette.primary.extraLight,
  color: theme.palette.primary.main,
  fontSize: theme.spacing(1.75),
  whiteSpace: 'nowrap',
  '&:hover': {
    background: theme.palette.black.light,
  },
}));

export const Pagination = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0),
  display: 'flex',
  width: theme.spacing(6.125),
  justifyContent: 'space-between',
}));

export const ArrowButton = styled(Button)<{
  disabled: boolean;
}>(({ theme, disabled }) => ({
  boxSizing: 'border-box',
  width: theme.spacing(2.75),
  borderRadius: 0,
  height: theme.spacing(2.5),
  minWidth: 'auto',
  minHeight: 'auto',
  color: disabled ? theme.palette.gray.main : theme.palette.black.main,
  background: '#eeedf1',
}));

export const ArrowButtonIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.spacing(1.375),
}));

export const ContentRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  boxSizing: 'border-box',
  margin: theme.spacing(0),
  justifyContent: 'space-between',
}));
