import React, { Children } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getChildrenByType } from 'react-nanny';

import coinStar from 'assets/icons/coin_star.svg';
import coinIconPlay from 'assets/icons/coin_icon-play.svg';
import coinIconScissors from 'assets/icons/coin_icon-scissors.svg';

import { Spacer } from '../atoms/Spacer/Spacer';
import { CoinBalanceProgressBar } from '../CoinBalanceProgressBar/CoinBalanceProgressBar';
import { CoinBalanceProgressBarProps } from '../CoinBalanceProgressBar/CoinBalanceProgressBar.types';
import * as S from './CoinBalance.styles';
import { CoinBalanceProps } from './CoinBalance.types';

export const CoinBalanceRoot = ({ children, balance }: CoinBalanceProps) => {
  const i18n = useIntl();

  // does the component have any coin balance progress bars?
  // then we need to render a spacing between the heading and the progress bars
  const validChildren = getChildrenByType(children, [
    CoinBalanceProgressCraft,
    CoinBalanceProgressClasses,
    CoinBalanceProgressBundles,
  ]);

  return (
    <S.Wrapper>
      <S.Heading>
        {i18n.formatMessage({ id: 'coin_wallet.balance.heading' })}
      </S.Heading>
      <S.Balance as="div">
        <S.Icon src={coinStar} fill alt="" />
        {i18n.formatNumber(balance)}
      </S.Balance>
      {validChildren.length > 0 ? (
        <>
          <Spacer size={20} when={{ lg: 51 }} />
          <S.ProgressBarsWrapper>
            {Children.toArray(children).map((child, index) => (
              <S.ProgressBarsItem key={index}>{child}</S.ProgressBarsItem>
            ))}
          </S.ProgressBarsWrapper>
        </>
      ) : null}
    </S.Wrapper>
  );
};

const CoinBalanceProgressCraft = ({
  current,
  max,
}: Pick<CoinBalanceProgressBarProps, 'current' | 'max'>) => {
  const { formatNumber } = useIntl();

  return (
    <CoinBalanceProgressBar
      icon={<S.CoinProgressIcon src={coinIconScissors} fill alt="" />}
      current={current}
      max={max}
      footer={
        current !== 0 && current >= max ? (
          <FormattedMessage
            id="coin_wallet.progress.crafts.complete.title"
            values={{
              strong: (...chunks: string[]) => <strong children={chunks} />,
            }}
          />
        ) : (
          <>
            <S.CoinProgressFooterIcon src={coinStar} fill alt="" />
            <FormattedMessage
              id="coin_wallet.progress.crafts.during.title"
              values={{
                count: formatNumber(max - current),
                strong: (...chunks: string[]) => <strong children={chunks} />,
              }}
            />
          </>
        )
      }
    />
  );
};

const CoinBalanceProgressClasses = ({
  current,
  max,
}: Pick<CoinBalanceProgressBarProps, 'current' | 'max'>) => {
  const { formatNumber } = useIntl();

  return (
    <CoinBalanceProgressBar
      icon={<S.CoinProgressIcon src={coinIconPlay} fill alt="" />}
      current={current}
      max={max}
      footer={
        current !== 0 && current >= max ? (
          <FormattedMessage
            id="coin_wallet.progress.classes.complete.title"
            values={{
              strong: (...chunks: string[]) => <strong children={chunks} />,
            }}
          />
        ) : (
          <>
            <S.CoinProgressFooterIcon src={coinStar} fill alt="" />
            <FormattedMessage
              id="coin_wallet.progress.classes.during.title"
              values={{
                count: formatNumber(max - current),
                strong: (...chunks: string[]) => <strong children={chunks} />,
              }}
            />
          </>
        )
      }
    />
  );
};

const CoinBalanceProgressBundles = ({
  current,
  max,
}: Pick<CoinBalanceProgressBarProps, 'current' | 'max'>) => {
  const { formatNumber } = useIntl();

  return (
    <CoinBalanceProgressBar
      icon={<S.CoinProgressIcon src={coinIconPlay} fill alt="" />}
      current={current}
      max={max}
      footer={
        current !== 0 && current >= max ? (
          <FormattedMessage
            id="coin_wallet.progress.bundles.complete.title"
            values={{
              strong: (...chunks: string[]) => <strong children={chunks} />,
            }}
          />
        ) : (
          <>
            <S.CoinProgressFooterIcon src={coinStar} fill alt="" />
            <FormattedMessage
              id="coin_wallet.progress.bundles.during.title"
              values={{
                count: formatNumber(max - current),
                strong: (...chunks: string[]) => <strong children={chunks} />,
              }}
            />
          </>
        )
      }
    />
  );
};

export const CoinBalance = Object.assign(CoinBalanceRoot, {
  ClassesProgress: CoinBalanceProgressClasses,
  CraftsProgress: CoinBalanceProgressCraft,
  BundlesProgress: CoinBalanceProgressBundles,
});
