import { useContext } from 'react';

import { AuthDispatchContext } from 'components/contexts/authProvider/AuthProvider';

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);

  if (!context) {
    throw new Error('useAuthDispatch should be used inside an AuthProvider');
  }

  return context;
};
