import Script from 'next/script';

import { useAuthState } from 'hooks/useAuthState/useAuthState';

export const ABTestUserInfo = () => {
  const { user } = useAuthState();

  if (!user) {
    return null;
  }

  return (
    <Script id="ab-test-user-info" strategy="afterInteractive">
      {`
        var cfUser = {
          signedIn: true,
          userID: '${user?.identification.id}',
          fullName: '${user?.identification.displayName}',
          hasSubscription: ${user.subscriptions.length > 0},
          isCustomer: ${user?.isCustomer},
          accountType: '${user?.identification.isDesigner ? 'designer' : 'user'}',
        };
      `}
    </Script>
  );
};
