import { styled, Box, CircularProgress, Typography } from '../../elements';

export const CommentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(1.25),
}));

export const CommentHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const Comment = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray.extraLight,
  padding: theme.spacing(1.5, 2),
  marginLeft: theme.spacing(1),
  borderRadius: theme.spacing(2.5),
  display: 'inline-flex',
  flexDirection: 'column',
}));

export const AuthorWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Author = styled(Box)(({ theme }) => ({
  // fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.black.main,
  textTransform: 'none',
  padding: theme.spacing(0),
}));

export const AuthorTag = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  background: theme.palette.primary.main,
  color: theme.palette.white.main,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.25, 0.5),
  marginLeft: theme.spacing(1),
  lineHeight: 'normal',
}));

export const OptionsWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const CommentText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.4),
  overflowWrap: 'anywhere',
  '& a': {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
}));

export const SmallText = styled(Typography)(() => ({
  fontSize: 12,
}));

export const CommentTime = styled(SmallText)(() => ({}));

export const CommentTimeSeparator = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

export const CommentReactionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  color: theme.palette.gray.main,
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(3),
}));

export const ViewRepliesButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.gray.main,
  fontSize: 12,
  cursor: 'pointer',
  margin: theme.spacing(1, 3),

  '& svg': {
    color: theme.palette.gray.light,
    fontSize: 20,
  },
}));

export const LoadingIcon = styled(CircularProgress)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

export const ReplyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(1, 0),
}));

export const ReplyButton = styled(Box)(() => ({
  display: 'flex',
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
