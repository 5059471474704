import React from 'react';

import { useDomEvent } from 'hooks/useDomEvent/useDomEvent';

export function useOnClickOutside(ref: React.MutableRefObject<HTMLElement>, callback: VoidFunction) {
  function handleClickOutside(event: Event) {
    if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
      callback();
    }
  }

  useDomEvent(() => document, 'mousedown', handleClickOutside);
}
