import { User as APIUser, UserAvatarSize } from 'api/requests/user/user.types';
import { Currency } from 'api/requests/site/site.types';
import { User as GQLUser } from 'generated/graphql';
import { paymentMethodsFull } from 'api/mocks/paymentMethodMocks';

type PartialGQLUser = Pick<GQLUser, 'id' | 'nickname' | 'firstName' | 'lastName' | 'email' | 'avatarUrl'>;

export const gqlUserToApiUser = (user?: PartialGQLUser | null): APIUser | undefined => {
  if (!user) {
    return undefined;
  }

  const displayName = user.nickname || [user.firstName, user.lastName].filter(Boolean).join(' ');

  return {
    identification: {
      id: parseInt(user.id),
      displayName,
      email: user.email,
      hasAllAccess: false,
      isAffiliate: false,
      isDesigner: false,
      isTeacher: false,
      shopName: '',
      shopSlug: '',
      userSlug: '',
      avatar: {
        [UserAvatarSize.FULL]: `${user.avatarUrl}`,
        [UserAvatarSize.LARGE]: `${user.avatarUrl}`,
        [UserAvatarSize.MEDIUM]: `${user.avatarUrl}`,
        [UserAvatarSize.SMALL]: `${user.avatarUrl}`,
      },
    },
    cartCount: 0,
    dealCount: 0,
    displayName: displayName,
    isNew: false,
    fromReferralLinkId: 0,
    wallet: {
      currency: {
        code: Currency.DOLLAR,
        symbol: '$',
      },
      display: '',
      value: 0,
    },
    isCustomer: false,
    localization: {
      country: 'us',
      languageCode: 'en',
      languageLabel: 'English',
    },
    settings: {
      hasAllAccess: false,
      visibility: {},
    },
    capabilities: [],
    isGAdsSignup: false,
    identifyUser: false,
    subscriptions: [],
    totalUsersFromReferralLink: 0,
    externalServices: {},
    currency: Currency.DOLLAR,
    paymentMethods: { isBanned: false, paymentMethods: paymentMethodsFull },
    trial: [],
  };
};
