import * as Sentry from '@sentry/browser';
import performanceNow from 'performance-now';

import { ApiEndpoint, Fetcher, RequestError } from 'api/fetcher.types';
import { getFetcherNamespace, getFetcherOptions } from 'api/fetcher.utils';
import { getErrorMessage } from 'utilities/getErrorMessage';
import { sentryUtils } from 'utilities/sentry.utils';
import { serverRequestsLog } from 'utilities/serverRequestsLog';

const sentry = sentryUtils('fetcher_data');

const publicApiUrl =
  process.env.ADD_STAGING_AUTH === 'true' && process.env.NEXT_PUBLIC_ENV === 'staging'
    ? process.env.NEXT_PUBLIC_API_URL?.replace(
        'staging',
        `${process.env.STAGING_USER}:${process.env.STAGING_PASSWORD}@staging`,
      )
    : process.env.NEXT_PUBLIC_API_URL;

export function getFetcherUrl(url: string) {
  const fetcherNamespace = getFetcherNamespace(url);
  const fetcherUrl = `${publicApiUrl}${fetcherNamespace}${url}`;

  return fetcherUrl;
}

export const fetcher: Fetcher = async params => {
  const { url, cookieString } = params;
  const options = 'options' in params ? params.options : undefined;
  const onError = 'onError' in params ? params.onError : undefined;
  const urlType = 'type' in params ? params.type : 'internal';
  const isExternalUrl = urlType === 'external';

  // ignore all failed fetches to the nonce endpoint (for now)
  // to attempt to ignore: https://tinyurl.com/2jb7ycd8
  const allowSentry = url !== ApiEndpoint.NONCE;

  const fetcherOptions = isExternalUrl ? options : getFetcherOptions(options, cookieString);
  const fetcherUrl = isExternalUrl ? url : getFetcherUrl(url);

  try {
    const span = sentry.log({
      tags: { fetcher: 'requests' },
      op: `Request ${url}`,
      description: `Request info for ${url}`,
    });

    const timestampBefore = performanceNow();
    let response;

    //If the url is external, we don't need to proxy it
    if (isExternalUrl) {
      response = await fetch(fetcherUrl, fetcherOptions);
    } else {
      const body = JSON.stringify({
        url: fetcherUrl,
        fetcherOptions: fetcherOptions,
      });

      response = await fetch(
        `${
          process.env.NEXT_PUBLIC_IS_LOCAL ? 'https://web.creativefabrica.com:3001' : process.env.NEXT_PUBLIC_API_URL
        }${ApiEndpoint.WP_REQUESTS_PROXY}`,
        {
          body: body,
          method: 'POST',
        },
      );
    }

    const timestampAfter = performanceNow();

    span.finish();

    const textResponse = await response.text();

    try {
      const data = JSON.parse(textResponse);

      if (
        process.env.NEXT_PUBLIC_ENV !== 'production' &&
        (fetcherOptions?.headers as Record<string, string>)['Content-Type'] === 'application/json'
      ) {
        serverRequestsLog.push({
          method: fetcherOptions?.method || 'GET',
          url,
          request: options?.body || null,
          status: response.status,
          response: data,
          time: timestampAfter - timestampBefore,
        });
      }

      if (!response.ok) {
        const message = getErrorMessage(data?.error || data?.errors?.[0]);
        const error: RequestError = new Error(message);
        error.status = response.status;

        if (allowSentry) {
          Sentry.captureException(new Error(error.message));
        }

        if (onError) {
          onError(error);
        }
      }

      return data;
    } catch (err) {
      if (response.ok) {
        return response;
      }

      const error: RequestError = new Error('The response received is not json');
      error.status = response.status;

      if (allowSentry) {
        Sentry.captureException(new Error(error.message));
      }

      if (onError) {
        onError(error);
      }
    }
  } catch (error) {
    if (allowSentry) {
      Sentry.captureException(new Error(error as string));
    }

    throw error;
  }
};

const mockApiHostnameMap: Record<string, string> = {
  'https://cf-wordpress-webserver.com:8080': 'https://web.cf-wordpress-webserver.com:3001',
  'https://staging.creativefabrica.com': 'https://web.creativefabrica.com:3001',
  'https://lab.creativefabrica.com': 'https://web.creativefabrica.com:3001',
  'https://www.creativefabrica.com': 'https://web.creativefabrica.com:3001',
};

// For fetching mocked data. Should be replaced with 'fetcher' when an endpoint is implemented
export const localFetcher: Fetcher = params => {
  const { url } = params;
  const apiUrl =
    process.env.NODE_ENV === 'development'
      ? mockApiHostnameMap[process.env.NEXT_PUBLIC_API_URL || '']
      : process.env.NEXT_PUBLIC_API_URL;
  const options = 'options' in params ? params.options : undefined;

  return fetch(`${apiUrl}/api${url}`, options).then(res => res.json());
};
