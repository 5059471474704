import Script from 'next/script';

import { useOptionalUser } from 'hooks/useAuthState/useAuthState';

const googleOneTapLoginClientId = process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_LOGIN_CLIENT_ID;
const googleOneTapLoginRedirectUrl = process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_LOGIN_REDIRECT_URL;

const scriptUrl = `https://accounts.google.com/gsi/client`;

export const GoogleOneTapLogin = () => {
  const user = useOptionalUser();

  if (user || !googleOneTapLoginClientId || !googleOneTapLoginRedirectUrl) {
    return null;
  }

  return (
    <>
      <div
        id="g_id_onload"
        data-use_fedcm_for_prompt="true"
        data-client_id={googleOneTapLoginClientId}
        data-login_uri={googleOneTapLoginRedirectUrl}
        data-cancel_on_tap_outside="false"
        data-itp_support="true"
      />
      <Script id="global-one-tap-login-script" src={scriptUrl} strategy="afterInteractive" />
    </>
  );
};
