import { Subscription } from 'api/requests/subscriptions/subscriptions.types';
import { PaymentMethodsData } from 'api/requests/paymentMethods/paymentMethods.types';
import { RequestErrorHandler } from 'hooks/useRequestErrorHandler/useRequestErrorHandler.types';
import { Currency } from 'api/requests/site/site.types';

export type UpdateUserFetcherParams = {
  id: number;
  cookieString?: string;
  properties: UpdateUserProperties;
  onError?: RequestErrorHandler;
};

export type UpdateUserProperties = {
  languageCode?: string;
  identifyUser?: boolean;
  isNew?: boolean;
  isGAdsSignup?: boolean;
};

export type UpdateLanguageAndCurrencyProperties = {
  currency: Currency;
  lang: string;
};

export type UpdateLanguageAndCurrency = {
  cookieString?: string;
  properties: UpdateLanguageAndCurrencyProperties;
  onError?: RequestErrorHandler;
};

export type CurrencyAndLanguageChange = {
  data: string[];
  message: string;
  status: number;
};

export type UserData = {
  user: User | null | undefined;
};

export enum UserAvatarSize {
  SMALL = '64',
  MEDIUM = '80',
  LARGE = '150',
  FULL = '300',
}

export type UserAvatar = {
  [key in UserAvatarSize]: string;
};

export type User = {
  affiliateInfo?: {
    affiliateId: number | string;
    affiliatePercentage: number;
  };
  capabilities: string[];
  cartCount: number;
  dealCount: number;
  displayName: string;
  currency: Currency;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalServices: any; // TODO: add correct type
  fromReferralLinkId: number;
  identification: {
    avatar: UserAvatar;
    displayName: string;
    email: string;
    hasAllAccess: boolean;
    id: number;
    isAffiliate: boolean;
    isDesigner: boolean;
    isTeacher: boolean;
    shopName: string;
    shopSlug: string;
    userSlug: string;
  };
  identifyUser: boolean;
  isCustomer: boolean;
  isGAdsSignup: boolean;
  isNew: boolean;
  localization: {
    country: string;
    languageCode: string;
    languageLabel: boolean | string;
  };
  paymentMethods: PaymentMethodsData;
  settings: {
    hasAllAccess: boolean;
    visibility: { [key: number]: string };
  };
  subscriptions: Subscription[];
  totalUsersFromReferralLink: number;
  trial: {
    orderStripe: string;
    subscriptionStripe: string;
  }[];
  wallet: {
    currency: {
      code: Currency;
      symbol: string;
    };
    display: string;
    value: number;
  };
};
