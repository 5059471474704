import { ButtonGroup, styled } from '@mui/material';

import { Button } from 'components/elements/atoms/button/Button';

export const StyledButtonGroup = styled(ButtonGroup)({
  // we are using our own divider so we need to remove the one from @mui.
  // !important as we need to overwrite the styles that come from .Mui-Disabled
  // and other variants
  '& .MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRight: 'none !important',
  },
});

export const StyledButtonOneClick = styled(Button)({
  flexGrow: 1,
  padding: '0px 10px',
});

export const StyledButton = styled(Button)({
  position: 'relative',
  padding: 0,
  '&.MuiButtonGroup-grouped': {
    minWidth: 34,
  },
});

export const Divider = styled('hr')({
  flexShrink: 0,
  borderWidth: 0,
  borderStyle: 'solid',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  borderRightWidth: 'thin',
  alignSelf: 'stretch',
  position: 'absolute',
  top: 5,
  bottom: 5,
  left: -1,
  height: 'auto',
});
