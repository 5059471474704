import { RequestErrorHandler } from 'hooks/useRequestErrorHandler/useRequestErrorHandler.types';

type FetcherUrlType = 'internal' | 'external';

type FetcherGetParams = {
  url: string;
  cookieString?: string;
  type?: FetcherUrlType;
};

type FetcherPostParams = {
  url: string;
  cookieString?: string;
  options: RequestInit;
  onError?: RequestErrorHandler;
  type?: FetcherUrlType;
};

export type Fetcher = <T>(params: FetcherGetParams | FetcherPostParams) => Promise<T>;

export type ShortBackendError = {
  code: string;
  message: string;
};

export type BackendError = {
  id: string;
  code: string;
  status: string;
  title: string;
  detail: string;
};

export type BackendErrorListData = {
  errors: ShortBackendError[];
};

export enum ApiEndpoint {
  WP_REQUESTS_PROXY = '/api/requests/',
  ME = '/cfusers/me',
  USERS = '/cfusers',
  LOGIN = '/login/',
  BANNER = '/sitewide_banner',
  SITE_INFO = '/site-info',
  SITE = '/site',
  NONCE = '/nonce/',
  POPUP = '/popup',
  HOMEPAGE_PRODUCTS = '/product/homepage',
  REGISTER = '/register/',
  RESET_PASSWORD = '/lostpassword/',
  UNDO_SUBSCRIPTION_CANCELLATION = 'action=undo_subscription_cancellation',
  FAVORITE = 'action=favorite_product_action',
  GDPR = '/gdpr',
  DOWNLOAD_IMAGE = '/download-image/',
  PAYMENT_METHODS = '/cfusers/me/payment_methods',
  ORDER = '/order/pay',
  ORDER_DRAFT = '/order/draft',
  AFFWP_TRACK_VISIT = 'action=affwp_track_visit',
  SWITCH_CURRENCY = 'action=cf_switch_lang_and_currency',
  CURRENCIES = '/cfusers/me/currencies',
  IMAGE_UPLOAD = 'section=imagemix',
  EXTEND_SUBSCRIPTION = '',
  RENEW_FREE_TRIAL = 'action=cf_renew_free_trial_subscription',
  REPORT_PROBLEM = 'action=cf_ajax_product_copyright_report',

  // mocked API endpoints
  BREADCRUMBS = '/breadcrumbs/',
  SEOTEXT = '/seotext/',
  TAGS = '/tags/',
}

export type RequestError = Error & {
  status?: number;
};
