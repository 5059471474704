import { User } from 'api/requests/user/user.types';
import { facebookEvent } from 'components/app/analytics/facebookPixel/FacebookPixel.utils';
import { FacebookEventName } from 'components/app/analytics/facebookPixel/FacebookPixel.types';
import { bingEvent } from 'components/app/analytics/bing/Bing.utils';
import { BingEventName } from 'components/app/analytics/bing/Bing.types';
import { updateUserFetcher } from 'api/requests/user/user';
import { GlobalSiteTagEventName } from 'components/app/analytics/globalSiteTag/GlobalSiteTag.types';
import { globalSiteTagEvent } from 'components/app/analytics/globalSiteTag/GlobalSiteTag.utils';
import { UpdateUserFetcherParams } from 'api/requests/user/user.types';

const globalSiteTagId = process.env.NEXT_PUBLIC_GLOBAL_SITE_TAG_ID;
const globalSiteTagRegistrationEventId = process.env.NEXT_PUBLIC_GLOBAL_SITE_TAG_REGISTRATION_EVENT_ID;

export const trackRegistration = async (user: User) => {
  const propsToUpdate: UpdateUserFetcherParams = { id: user.identification.id, properties: {} };

  if (user.isGAdsSignup) {
    if (globalSiteTagId && globalSiteTagRegistrationEventId) {
      globalSiteTagEvent(GlobalSiteTagEventName.CONVERSION, {
        send_to: `${globalSiteTagId}/${globalSiteTagRegistrationEventId}`,
      });
    }

    propsToUpdate.properties.isGAdsSignup = false;
  }

  if (user.isNew) {
    facebookEvent(FacebookEventName.COMPLETE_REGISTRATION);
    bingEvent(BingEventName.COMPLETE_REGISTRATION, {
      event_category: 'user',
      event_label: user.identification.id,
    });
    propsToUpdate.properties.isNew = false;
  }

  if (Object.keys(propsToUpdate.properties).length) {
    const updatedUser = await updateUserFetcher(propsToUpdate);

    return updatedUser;
  }

  return null;
};
