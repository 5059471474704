export const productsAlgoliaAppId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '';
export const productsAlgoliaApiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '';
export const productsAlgoliaIndex = process.env.NEXT_PUBLIC_ALGOLIA_INDEX || '';
export const productsAlgoliaIndexNewest = `${productsAlgoliaIndex}${
  productsAlgoliaIndex.includes('trending') ? '' : '_trending'
}_newest`;
export const productsAlgoliaIndexOldest = `${productsAlgoliaIndex}${
  productsAlgoliaIndex.includes('trending') ? '' : '_trending'
}_oldest`;

export const contentAlgoliaAppId = process.env.NEXT_PUBLIC_CONTENT_ALGOLIA_APP_ID || '';
export const contentAlgoliaApiKey = process.env.NEXT_PUBLIC_CONTENT_ALGOLIA_API_KEY || '';
export const contentAlgoliaIndex = process.env.NEXT_PUBLIC_CONTENT_ALGOLIA_INDEX || '';
export const contentAlgoliaIndexNewest = `${contentAlgoliaIndex}${
  contentAlgoliaIndex.includes('trending') ? '' : '_trending'
}_newest`;
export const contentAlgoliaIndexOldest = `${contentAlgoliaIndex}${
  contentAlgoliaIndex.includes('trending') ? '' : '_trending'
}_oldest`;

export const MIN_REFINEMENT_LENGTH = 3;
