import { IncomingMessage } from 'http';
import { DEFAULT_LOCALE, LOCALES } from 'translations/locales';

import { ServerRequestsLog } from 'utilities/serverRequestsLog';

export const isWhitelistedUrl = (url: string, whitelist?: string[]) => {
  if (!whitelist?.length) {
    return true;
  }

  const removeQueryParameters = (x: string) => x.split('?')[0];
  const removeTrailingSlash = (x: string) => (x.slice(-1) === '/' ? x.slice(0, -1) : x);

  const cleanUrl = removeTrailingSlash(removeQueryParameters(url));

  return whitelist.some(whitelistEntry => {
    if (whitelistEntry.indexOf('*') !== -1) {
      if (whitelistEntry.replace('*', '') === cleanUrl) {
        return true;
      }

      const regexp = new RegExp(`${whitelistEntry.replace(/\*/i, '.')}`, 'i');

      return regexp.test(cleanUrl);
    }

    return Object.keys(LOCALES).some(locale => {
      const localePrefix = locale === DEFAULT_LOCALE ? '' : `/${locale}`;

      return cleanUrl === removeTrailingSlash(`${localePrefix}${whitelistEntry}`);
    });
  });
};

export const getPathnameWithLocalePrefix = (pathname: string, locale: string) => {
  return locale === DEFAULT_LOCALE ? pathname : `/${locale}${pathname}`;
};

const addTrailingSlashToUrl = (url: string) => {
  return url.slice(-1) === '/' ? url : `${url}/`;
};

export const addQueryToUrl = (url: string, query: string) => {
  return query ? `${addTrailingSlashToUrl(url)}?${query}` : url;
};

export const buildAbsoluteUrl = (path: string) => {
  if (path.includes('http')) {
    return path;
  }

  return addTrailingSlashToUrl(`${process.env.NEXT_PUBLIC_API_URL}${path}`);
};

export const getRequestIp = (req: IncomingMessage) => {
  const forwardedHeaders = req?.headers['x-forwarded-for'];
  const forwardedList = Array.isArray(forwardedHeaders) ? forwardedHeaders[0] : forwardedHeaders;
  const ip = forwardedList ? forwardedList.split(',')[0] : req.socket.remoteAddress;

  return ip;
};

export const printServerRequests = (requestsLog: ServerRequestsLog) => {
  if (typeof window === 'undefined') {
    return;
  }

  /* eslint-disable no-console */
  console.groupCollapsed('🔵 %cNode.js Request Log', 'font-weight: normal');
  requestsLog.forEach(({ method, url, request, status, response, time }) => {
    const hasError = status >= 400;

    console.groupCollapsed(
      `%c${method} %c${url} %c${status} %c${time.toFixed(2)}ms`,
      'font-weight: normal; color: #969c9e',
      'color: #303942',
      `font-weight: normal; color: ${hasError ? '#ff0000' : '#1d8d7c'}`,
      'font-weight: normal; color: #c0c0c0',
    );

    if (request) {
      console.log('Request body', JSON.parse(request));
    }

    console.log('Response body', response);
    console.groupEnd();
  });
  console.groupEnd();
  /* eslint-enable no-console */
};
