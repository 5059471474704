import { Link as MuiLink, styled } from '../../../elements';

export const Link = styled(MuiLink)(({ theme }) => ({
  textDecorationColor: 'inherit',
  cursor: 'pointer',
  '&.blackColor': {
    color: theme.palette.black.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  '&.standardColor': {
    color: theme.palette.blue.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  '&.secondaryColor': {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  '&.whiteColor': {
    color: theme.palette.white.main,
  },
}));
