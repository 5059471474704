import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

import { ApiEndpoint, BackendError } from 'api/fetcher.types';

const cookieDomainNameMap: { [key: string]: string } = {
  'https://cf-wordpress-webserver.com:8080': '.cf-wordpress-webserver.com',
  'https://staging.creativefabrica.com': '.creativefabrica.com',
  'https://www.creativefabrica.com': '.creativefabrica.com',
};

export enum ApiNamespace {
  DEFAULT = '/wp-json/wc/v3',
  AUTH = '/cfsecure',
  ADMIN = '/wp-admin/admin-ajax.php?',
}

const AUTH_ENDPOINTS: string[] = [
  ApiEndpoint.LOGIN,
  ApiEndpoint.REGISTER,
  ApiEndpoint.RESET_PASSWORD,
  ApiEndpoint.NONCE,
  ApiEndpoint.BANNER,
];
const ADMIN_ENDPOINTS: string[] = [
  ApiEndpoint.UNDO_SUBSCRIPTION_CANCELLATION,
  ApiEndpoint.FAVORITE,
  ApiEndpoint.AFFWP_TRACK_VISIT,
  ApiEndpoint.SWITCH_CURRENCY,
  ApiEndpoint.IMAGE_UPLOAD,
  ApiEndpoint.EXTEND_SUBSCRIPTION,
  ApiEndpoint.RENEW_FREE_TRIAL,
];

export const isBackendError = (error: unknown): error is BackendError => {
  if (typeof error !== 'object' || error === null) {
    return false;
  }

  const errorObj = error as Record<string, unknown>;

  // TODO: add all properties to the array when BE adds them
  for (const prop of ['title']) {
    if (!(prop in errorObj)) {
      return false;
    }

    if (typeof errorObj[prop] !== 'string') {
      return false;
    }
  }

  return true;
};

export const getFetcherNamespace = (url: string): ApiNamespace => {
  let endpoint = url.split('?')[0];

  // admin endpoints are already a part of query parameters
  if (endpoint.indexOf('&') !== -1) {
    endpoint = endpoint.split('&')[0];
  }

  switch (true) {
    case AUTH_ENDPOINTS.includes(endpoint):
      return ApiNamespace.AUTH;
    case ADMIN_ENDPOINTS.includes(endpoint):
      return ApiNamespace.ADMIN;
    default:
      return ApiNamespace.DEFAULT;
  }
};

export const getFetcherOptions = (options: RequestInit = {}, cookieString?: string): RequestInit => {
  const isStaging = process.env.NEXT_PUBLIC_API_URL === 'https://staging.creativefabrica.com';
  const stagingAuth = process.env.NEXT_PUBLIC_STAGING_AUTH;
  const isServer = typeof window === 'undefined';

  return {
    ...options,
    credentials: 'include',
    headers: {
      ...options.headers,
      ...(cookieString ? { Cookie: cookieString } : {}),
      ...(isStaging && stagingAuth && !isServer ? { Authorization: `Basic ${stagingAuth}` } : {}),
    },
  };
};

export const getMainDomain = () => cookieDomainNameMap[process.env.NEXT_PUBLIC_API_URL || ''];

export const generateUID = (message: string, key: string) => {
  return Base64.stringify(HmacSHA256(message, key));
};
