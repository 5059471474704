import { styled, Box, Typography } from '../../elements';

export const MenuIcon = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-3),
  marginRight: theme.spacing(-2),
}));

export const VisibilityInfo = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  letterSpacing: 0,
  color: theme.palette.gray.main,
  '& svg': {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(0.5),
  },
}));

export const PinnedPost = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 2, 0),
  fontSize: '14px',
  color: theme.palette.primary.main,
  '& svg': {
    margin: theme.spacing(0.25, 0.5, 0, 0),
  },
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3, 2),
  position: 'relative',
}));

export const HeaderWrapperInfo = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
}));

export const TimeLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: 12,
  color: theme.palette.gray.main,
}));

export const HeaderWrapperMainColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SingleLine = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const AuthorName = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

export const AuthorAnchor = styled('a')(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 500,
  color: theme.palette.primary.main,
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const PostAction = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.extraDark,
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(0.2),
}));

export const HeaderWrapperRightColumn = styled(Box)(() => ({
  display: 'flex',
}));

export const ActionMenu = styled('div')({
  display: 'flex',
  position: 'absolute',
  right: 0,
  top: 0,
});
