import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { isWhitelistedUrl } from 'components/app/App.utils';

const routeWhitelistString = process.env.NEXT_PUBLIC_REACT_ROUTE_WHITELIST;
const routeWhitelistArray = routeWhitelistString?.length ? routeWhitelistString.split(',') : undefined;

export const useRouteWhitelistRedirect = () => {
  const router = useRouter();

  const checkRouteWhitelist = (url: string) => {
    if (!isWhitelistedUrl(url, routeWhitelistArray)) {
      if (process.env.NEXT_PUBLIC_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('🟡 Redirect to:', {
          to: url,
          from: window?.location?.href || '',
          trigger: 'useRouteWhitelistRedirect',
        });
      }

      window.location.assign(url);
    }
  };

  useEffect(() => {
    router.events.on('routeChangeStart', checkRouteWhitelist);

    return () => {
      router.events.off('routeChangeStart', checkRouteWhitelist);
    };
  }, [router.events]);
};
