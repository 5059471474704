import { ElementType } from 'react';

import { FutureImage } from 'components/elements/atoms/image/FutureImage';

import { styled, Typography, Link } from '../../elements';
import { Button as BaseButton } from '../atoms/button/Button';
import { Card } from '../Card/Card';

export const Button = styled(BaseButton)({
  position: 'relative',
});

export const PayWithCoinsCardText = styled(Typography)<{
  component?: ElementType;
}>({});

export const PayWithCoinsCardBody = Card.Body;

export const PayWithCoinsCardInsufficientCoins = styled(PayWithCoinsCardBody)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    rowGap: theme.spacing(2.5),
    justifyContent: 'space-between',
  })
);

export const PayWithCoinsCardReadyToSpend = styled(PayWithCoinsCardBody)(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: theme.spacing(2.5),
    justifyContent: 'space-between',
  })
);

export const PayWithCoinsCardConfirmed = styled(PayWithCoinsCardBody)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  })
);

export const PayWithCoinsCardCancelled = styled(PayWithCoinsCardBody)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
  })
);

export const PayWithCoinsCardTitle = styled(Typography)<{
  component?: ElementType;
}>(({ theme }) => ({
  ...theme.typography.h4,
  width: '100%',
  flexGrow: 1,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: 1.4,
  textTransform: 'uppercase',
}));

export const CoinBundleValue = styled(Typography)<{ component?: ElementType }>(
  ({ theme }) => ({
    ...theme.typography.h2,
    display: 'flex',
    lineHeight: 1.3,
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: theme.spacing(1.25),
  })
);

export const CoinIcon = styled(FutureImage)(({ theme }) => ({
  width: 22,
  height: 22,
  marginRight: theme.spacing(0.5),
  transform: 'translateY(-5%)',
  verticalAlign: 'middle',
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(0.625),
  },
}));

export const CoinIconSmall = styled(FutureImage)(({ theme }) => ({
  width: 13,
  height: 13,
  marginRight: theme.spacing(0.25),
  verticalAlign: 'middle',
  display: 'inline-flex',
}));

export const PayWithCoinsCardContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.25),
}));

export const Cancel = styled(Typography)<{ component?: ElementType }>(
  ({ theme }) => ({
    display: 'inline',
    '&[role="button"]': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.warning.main,
      '&:hover': {
        color: theme.palette.warning.dark,
      },
    },
  })
);

export const CancelText = styled(Typography)<{ component?: ElementType }>({
  display: 'inline',
  '&:before': {
    content: '" - "',
  },
});

export const PayWithCoinsCardLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecorationColor: theme.palette.blue.main,
}));

export const FloatingSpinner = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const HiddenText = styled('span')({
  opacity: 0,
  pointerEvents: 'none',
});

export const InsufficientCoinsButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
