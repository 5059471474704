import { styled } from '@mui/material';
import type { Breakpoint } from '@mui/system';

type SpacerProps = {
  size: number;
  when?: {
    [key in Breakpoint]?: number;
  };
  axis?: 'vertical' | 'horizontal';
};

function getHeight(axis: SpacerProps['axis'], size: SpacerProps['size']) {
  return axis === 'horizontal' ? 1 : size;
}

function getWidth(axis: SpacerProps['axis'], size: SpacerProps['size']) {
  return axis === 'vertical' ? 1 : size;
}

export const Spacer = styled('span')<SpacerProps>(
  ({ theme, when = {}, axis = 'vertical', size }) => {
    const styles: Record<string, string | number | Record<string, string>> = {
      display: 'block',
      height: getHeight(axis, size),
      minHeight: getHeight(axis, size),
      width: getWidth(axis, size),
      minWidth: getWidth(axis, size),
    };

    for (const [k, value] of Object.entries(when)) {
      const key = k as keyof typeof when;

      styles[theme.breakpoints.up(key)] = {
        height: `${value}px`,
        minHeight: `${value}px`,
      };
    }

    return styles;
  }
);
