import React, { Children, useState } from 'react';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faA,
  faDownload,
  faPaintBrush,
  faScissors,
} from '@fortawesome/free-solid-svg-icons';

import { callAllAsync } from '../../common/callAll';
import { PayWithCoinsCard } from '../PayWithCoinsCard/PayWithCoinsCard';
import {
  CardCoinSpendingBundleDetailProps,
  CardCoinSpendingBundleProps,
  CardCoinSpendingBundleState,
} from './CardCoinSpendingBundle.types';
import * as S from './CardCoinSpendingBundle.styles';

export const CardCoinSpendingBundleRoot = ({
  onSpend,
  onCancel,
  userBalance,
  bundleValue,
  state,
  canCancel,
  onDownload,
  image,
  details,
  downloadUrl,
  title,
  href,
}: CardCoinSpendingBundleProps) => {
  const { formatMessage } = useIntl();
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  async function onDownloadProxy() {
    setIsDownloading(true);
    try {
      await callAllAsync(onDownload, () => setHasDownloaded(true))();
    } catch {}
    setIsDownloading(false);
  }

  const hidePayWithCoinsCard =
    state === CardCoinSpendingBundleState.confirmed &&
    !canCancel &&
    Boolean(downloadUrl);

  return (
    <S.Wrapper>
      {href ? (
        <S.LinkImageWrapper href={href}>
          <S.Image src={image} />
        </S.LinkImageWrapper>
      ) : (
        <S.ImageWrapper>
          <S.Image src={image} />
        </S.ImageWrapper>
      )}
      <S.Contents>
        <S.BundleTitle as="h3">
          {href ? (
            <S.BundleTitleLink href={href}>{title}</S.BundleTitleLink>
          ) : (
            title
          )}
        </S.BundleTitle>
        {Children.count(details) > 0 && <S.Details>{details}</S.Details>}
      </S.Contents>
      {!hidePayWithCoinsCard && (
        <PayWithCoinsCard
          onSpend={onSpend}
          onCancel={onCancel}
          userBalance={userBalance}
          bundleValue={bundleValue}
          state={state}
          canCancel={!hasDownloaded && canCancel}
        />
      )}
      {state === CardCoinSpendingBundleState.confirmed && downloadUrl && (
        <S.DownloadButtonContainer>
          <S.DownloadButtonWrapper showborder={`${!hidePayWithCoinsCard}`}>
            <S.DownloadButton
              color="secondary"
              disabled={isDownloading}
              href={downloadUrl}
              onClick={onDownloadProxy}
              download={true}
              target="_blank"
            >
              {formatMessage(
                { id: 'card_coin_spending_bundle.button.download' },
                {
                  icon: <FontAwesomeIcon icon={faDownload} />,
                }
              )}
            </S.DownloadButton>
          </S.DownloadButtonWrapper>
        </S.DownloadButtonContainer>
      )}
    </S.Wrapper>
  );
};

const iconMap = {
  font: <FontAwesomeIcon icon={faA} aria-hidden="true" />,
  graphic: <FontAwesomeIcon icon={faPaintBrush} aria-hidden="true" />,
  design: <FontAwesomeIcon icon={faScissors} aria-hidden="true" />,
};

const CardCoinSpendingBundleDetail = ({
  variant,
  label,
}: CardCoinSpendingBundleDetailProps) => {
  return (
    <S.Detail>
      <div>{iconMap[variant] ?? iconMap.design}</div>
      <span>{label}</span>
    </S.Detail>
  );
};

export const CardCoinSpendingBundle = Object.assign(
  CardCoinSpendingBundleRoot,
  {
    Detail: CardCoinSpendingBundleDetail,
  }
);
