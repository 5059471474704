import { OneSignalEventCallback, OneSignalEventData, OneSignalEventName } from './OneSignalSDK.types';

export const oneSignalEvent = (name: OneSignalEventName, callback: OneSignalEventCallback) => {
  window.OneSignal = window.OneSignal || [];

  window.OneSignal.push([name, callback]);
};

export const oneSignalDataToAnalyticsData = (data: OneSignalEventData) => ({
  title: data.heading,
  description: data.content,
  image_url: data.icon,
  destination_url: data.url,
});
