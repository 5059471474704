import { useRouter } from 'next/router';

export enum Flags {
  USE_GRAPHQL_AUTH = 'USE_GRAPHQL_AUTH',
}

const DefaultFlags: Record<string, boolean> = {
  [Flags.USE_GRAPHQL_AUTH]: false,
};

export const useFlags = (...flags: Flags[]) => {
  const router = useRouter();

  if (!router) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('useFlags was used outside of a next/router context and will not return any flags');
    }

    return flags.map(() => undefined);
  }

  return flags.map(flag =>
    flag in router.query ? Boolean(router.query[flag]) : flag in DefaultFlags ? DefaultFlags[flag] : undefined,
  );
};
