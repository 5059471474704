import React from 'react';

import { IconButtonProps } from 'components/elements/atoms/iconButton/IconButton.types';
import * as S from 'components/elements/atoms/iconButton/IconButton.styles';
import { Icon } from 'components/elements/atoms/icon/Icon';

export const IconButton = ({
  className,
  icon,
  iconColor,
  iconSize,
  color = 'primary',
  size = 'medium',
  children,
  ...props
}: IconButtonProps) => (
  <S.IconButton className={className} color={color} size={size} {...props}>
    {children}
    <Icon className="icon" icon={icon} color={iconColor} size={iconSize} />
  </S.IconButton>
);
