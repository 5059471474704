import React from 'react';

import { Avatar } from '../Avatar/Avatar';
import { AvatarSize } from '../Avatar/Avatar.types';
import * as S from './SidebarProfile.styles';
import { SidebarProfileProps } from './SidebarProfile.types';

export const SidebarProfile = ({
  defaultAvatar,
  user: { name, description, url, avatar },
  anchorProps,
}: SidebarProfileProps) => {
  const avatarSrc = avatar ? avatar : defaultAvatar;

  return (
    <S.SidebarWrapper>
      <S.AvatarAnchor href={url} {...anchorProps}>
        <Avatar alt={name} src={avatarSrc} size={AvatarSize.LARGE} />
      </S.AvatarAnchor>
      <S.ProfileNameWrapper>
        <S.ProfileName>
          <S.ProfileNameAnchor href={url} {...anchorProps}>
            {name}
          </S.ProfileNameAnchor>
        </S.ProfileName>
        {description && (
          <S.ProfileDescription>{description}</S.ProfileDescription>
        )}
      </S.ProfileNameWrapper>
    </S.SidebarWrapper>
  );
};
