import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faHeart,
  faShoppingCart,
  faCheckCircle,
  faArrowLeft,
  faArrowRight,
  faBell,
  faWarning,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChevronDown,
  faChevronUp,
  faSearch,
  faBars,
  faTimes,
  faStar,
  faSpinner,
  faAngleDown,
  faCheck,
  faCopy,
  faLifeRing,
  faLightbulb,
  faPenNib,
  faCut,
  faShieldAlt,
  faUserShield,
  faUserCircle,
  faKey,
  faEnvelope,
  faPencilAlt,
  faVideo,
  faGraduationCap,
  faLock,
  faEye,
  faEyeSlash,
  faStore,
  faQuestionCircle,
  faRocket,
  faUserPlus,
  faCrown,
  faGlobe,
  faInfoCircle,
  faExclamationCircle,
  faGift,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faFireFlameCurved,
  faShareNodes,
  faBook,
  faCircle,
  faEllipsis,
  faImages,
  faChevronLeft,
  faChevronRight,
  faExternalLink,
  faPen,
  faLink,
  faShare,
  faPlus,
  faTrashCan,
  faWandMagicSparkles,
  faMinus,
  faWeight,
  faWeightHanging,
  faArrowUpRightFromSquare,
  faScrewdriverWrench,
  faWrench,
  faUpload,
  faCamera,
  faUpRightAndDownLeftFromCenter,
  faCircleCheck,
  faXmark,
  faCalendar,
  faCircleXmark,
  faBookmark,
  faPaintBrush,
  faBolt,
  faGem,
  faMicrochip,
  faRotate,
  faPassport,
  faGear,
  faCalendarDays,
} from '@fortawesome/free-solid-svg-icons';
import {
  faStar as farStar,
  faHeart as farHeart,
  faCreditCard as farCreditCard,
  faCheckCircle as farCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faPinterest,
  faFacebookF,
  faFacebook,
  faGoogle,
  faWindows,
  faApple,
  faLinux,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faDownload,
  faHeart,
  faShoppingCart,
  faCheckCircle,
  farCheckCircle,
  faArrowLeft,
  faArrowRight,
  faBell,
  faWarning,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChevronDown,
  faChevronUp,
  faSearch,
  faBars,
  faTimes,
  faStar,
  faSpinner,
  faAngleDown,
  faCheck,
  faCopy,
  faShieldAlt,
  faUserShield,
  farStar,
  farHeart,
  faLifeRing,
  faPenNib,
  faCut,
  faPinterest,
  faUserCircle,
  faKey,
  faEnvelope,
  faPencilAlt,
  faVideo,
  faGraduationCap,
  faLock,
  faEye,
  faEyeSlash,
  faFacebookF,
  faFacebook,
  faGoogle,
  faStore,
  faQuestionCircle,
  faRocket,
  faUserPlus,
  faCrown,
  faWindows,
  faApple,
  faLinux,
  faGlobe,
  faInfoCircle,
  faExclamationCircle,
  faGift,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  farCreditCard,
  faLightbulb,
  faFireFlameCurved,
  faShareNodes,
  faBook,
  faCircle,
  faCircleCheck,
  faXmark,
  faCircleXmark,
  faCalendar,
  faEllipsis,
  faImages,
  faPencilAlt,
  faChevronLeft,
  faChevronRight,
  faExternalLink,
  faPen,
  faLink,
  faShare,
  faTrashCan,
  faWandMagicSparkles,
  faPlus,
  faMinus,
  faWeightHanging,
  faArrowUpRightFromSquare,
  faScrewdriverWrench,
  faWeight,
  faWrench,
  faUpload,
  faCamera,
  faUpRightAndDownLeftFromCenter,
  faBookmark,
  faPaintBrush,
  faBolt,
  faGem,
  faMicrochip,
  faRotate,
  faPassport,
  faGear,
  faCalendarDays
);
