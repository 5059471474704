import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { callAll } from '../../common/callAll';
import * as S from './ActionMenu.styles';
import {
  ActionMenuButtonProps,
  ActionMenuLinkProps,
  ActionMenuProps,
} from './ActionMenu.types';

const ActionMenuRoot = ({
  children,
  initialOpen = false,
  isOpen: controlledIsOpen,
  setOpen: controlledSetOpen,
  onOpen,
  className,
}: ActionMenuProps) => {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null!);
  const [isMenuOpen, setMenuOpen] = useState(initialOpen);

  const setMenuOpenProxy = useCallback(
    (value: boolean) => {
      if (controlledSetOpen) {
        controlledSetOpen(value);
      } else {
        setMenuOpen(value);
      }
    },
    [controlledSetOpen, setMenuOpen]
  );

  const isMenuOpenValue = controlledIsOpen || isMenuOpen;

  useOnClickOutside(
    ref,
    useCallback(() => {
      setMenuOpenProxy(false);
    }, [setMenuOpenProxy])
  );

  return (
    <S.Container ref={ref} className={className}>
      <S.Button
        onClick={callAll(() => setMenuOpenProxy(!isMenuOpenValue), onOpen)}
        type="button"
      >
        <FontAwesomeIcon icon={faEllipsis} />
        <S.ButtonText>
          {formatMessage({ id: 'component.action_menu.open' })}
        </S.ButtonText>
      </S.Button>
      {isMenuOpenValue ? <S.Menu>{children}</S.Menu> : null}
    </S.Container>
  );
};

const ActionMenuLink = ({ icon, children, ...rest }: ActionMenuLinkProps) => {
  return (
    <S.ItemLink {...rest}>
      {icon}
      {children}
    </S.ItemLink>
  );
};

const ActionMenuButton = ({
  icon,
  children,
  ...rest
}: ActionMenuButtonProps) => {
  return (
    <S.ItemButton type="button" {...rest}>
      {icon}
      {children}
    </S.ItemButton>
  );
};

export const ActionMenu = Object.assign(ActionMenuRoot, {
  Link: ActionMenuLink,
  Button: ActionMenuButton,
});
