import { createContext, useState } from 'react';

import { loginFetcher } from 'api/requests/login/login';
import { AuthAction, AuthActionType } from 'components/contexts/authProvider/authReducer/authReducer.types';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useLoginMutation } from 'generated/graphql';
import { toShortBackendErrors } from 'generated/codegen.fetcher';
import { LoginChallenge, LoginChallengeGQL } from 'api/requests/login/login.types';
import { Flags, useFlags } from 'hooks/useFlags/useFlags';
import { gqlUserToApiUser } from 'components/contexts/requestStateProviders/mappings/gqlUserToApiUser';
import { setCurrentStoredToken, unsetCurrentStoredToken } from 'components/contexts/authProvider/AuthToken';

import { LoginProviderProps, LoginContextValue, LoginHandler, LoginState } from './LoginProvider.types';

export const LoginContext = createContext<LoginContextValue | null>(null);

const initialLoginState = {
  isLoading: false,
  errors: undefined,
  is2FARequested: false,
  isOTPRequested: false,
  debugOTP: undefined,
  verificationText: undefined,
};

export const LoginProvider = (props: LoginProviderProps) => {
  const { children } = props;
  const dispatch = useAuthDispatch();
  const [loginState, setLoginState] = useState<LoginState>(initialLoginState);

  const [USE_GRAPHQL_AUTH] = useFlags(Flags.USE_GRAPHQL_AUTH);

  const loginMutation = useLoginMutation();

  const loginWithWP: LoginHandler = async ({ email, password, code, otp, remember, onError, postLoginBehaviour }) => {
    setLoginState(prevState => ({ ...prevState, isLoading: true }));
    const response = await loginFetcher({ email, password, code, otp, remember, onError });

    // only refresh the page if we are do not have any login challenges to go through.
    // in short, is this the end of the line? has the user finished logging in?
    // if so, then refresh the page.
    const willRefreshPage =
      response.challenge !== LoginChallenge.TWO_FACTOR &&
      response.challenge !== LoginChallenge.ONE_TIME_PASSWORD &&
      postLoginBehaviour === 'refresh';

    setLoginState(prevState => ({
      // keep the form in a loading state whilst the page refreshes
      isLoading: willRefreshPage,
      errors: response.errors,
      is2FARequested: response.challenge === LoginChallenge.TWO_FACTOR,
      isOTPRequested: response.challenge === LoginChallenge.ONE_TIME_PASSWORD,
      debugOTP: response.otp,
      // also keep previous verification text if we're about to revisit the page
      // if none came through from the response
      verificationText: willRefreshPage ? response.text || prevState.verificationText : response.text,
    }));

    if (willRefreshPage) {
      window.location.reload();
    } else {
      if (response.user) {
        dispatch({ type: AuthActionType.LOGIN, user: response.user });
      }
    }
  };

  const loginWithGraphql: LoginHandler = async ({
    email,
    password,
    code,
    otp,
    remember,
    onError,
    postLoginBehaviour,
  }) => {
    setLoginState(prevState => ({ ...prevState, isLoading: true }));

    unsetCurrentStoredToken();
    const result = await loginMutation.mutateAsync({ input: { email, password, otp, code, remember } });

    // only refresh the page if we are do not have any login challenges to go through.
    // in short, is this the end of the line? has the user finished logging in?
    // if so, then refresh the page.
    const willRefreshPage =
      result.login?.challenge?.type !== LoginChallengeGQL.TWO_FACTOR &&
      result.login?.challenge?.type !== LoginChallengeGQL.ONE_TIME_PASSWORD &&
      postLoginBehaviour === 'refresh';

    setLoginState(prevState => ({
      // keep the form in a loading state whilst the page refreshes
      isLoading: willRefreshPage,
      errors: toShortBackendErrors(result.login?.errors || []),

      is2FARequested: result.login?.challenge?.type === LoginChallengeGQL.TWO_FACTOR,
      isOTPRequested: result.login?.challenge?.type === LoginChallengeGQL.ONE_TIME_PASSWORD,
      debugOTP: result.login?.challenge?.otp || undefined,
      // also keep previous verification text if we're about to revisit the page
      // if none came through from the response
      verificationText:
        (willRefreshPage
          ? result.login?.challenge?.text || prevState.verificationText
          : result.login?.challenge?.text) || undefined,
    }));

    if (!result.login || !result.login?.user || !result.login?.token) {
      return;
    }

    // TODO: Generate WP Cookie

    setCurrentStoredToken(result.login.token);

    if (willRefreshPage) {
      window.location.reload();
    } else {
      const loginAction: AuthAction = {
        type: AuthActionType.LOGIN,
        user: gqlUserToApiUser(result.login.user),
        token: result.login.token,
      };

      dispatch(loginAction);
      setLoginState(prevState => ({ ...prevState, isLoading: false }));
    }
  };

  const clearLoginState = () => {
    unsetCurrentStoredToken();
    setLoginState(initialLoginState);
  };

  const value = {
    loginState,
    login: USE_GRAPHQL_AUTH ? loginWithGraphql : loginWithWP,
    clearLoginState,
  };

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};
