import { useCallback, useRef, useState } from 'react';

import { useDomEvent } from 'hooks/useDomEvent/useDomEvent';

export function useOnHover<Element extends HTMLElement>() {
  const [value, setValue] = useState(false);
  const ref = useRef<Element>(null!);

  useDomEvent(
    ref,
    'mouseover',
    useCallback(() => setValue(true), []),
  );

  useDomEvent(
    ref,
    'mouseleave',
    useCallback(() => setValue(false), []),
  );

  return [ref, value] as const;
}
