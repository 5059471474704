import { MenuProps } from '@mui/material';
import React, { useState } from 'react';

import { Icon } from 'components/elements/atoms/icon/Icon';
import { callAll } from 'packages/cf-ui/common/callAll';

import * as S from './SplitButton.styles';
import {
  SplitButtonActionProps,
  SplitButtonOneClickProps,
  SplitButtonProps,
} from './SplitButton.types';

export function SplitButtonRoot({ children, ...props }: SplitButtonProps) {
  return (
    <S.StyledButtonGroup
      disableElevation
      disableRipple
      variant="contained"
      aria-label="primary button group"
      size="xsmall"
      {...props}
    >
      {children}
    </S.StyledButtonGroup>
  );
}

function SplitButtonOneClick({ children, ...rest }: SplitButtonOneClickProps) {
  return (
    <S.StyledButtonOneClick variant="contained" color="primary" {...rest}>
      {children}
    </S.StyledButtonOneClick>
  );
}

function SplitButtonAction({
  isOpen,
  children,
  ...rest
}: SplitButtonActionProps) {
  return (
    <S.StyledButton {...rest}>
      <S.Divider />
      <Icon icon={isOpen ? 'chevron-up' : 'chevron-down'} />
      {children}
    </S.StyledButton>
  );
}

export const SplitButton = Object.assign(SplitButtonRoot, {
  OneClick: SplitButtonOneClick,
  Action: SplitButtonAction,
});

export function useSplitButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  function onCloseMenu() {
    setAnchorEl(null);
  }

  function getButtonProps(
    props: Partial<SplitButtonActionProps> = {}
  ): SplitButtonActionProps {
    return {
      isOpen: isMenuOpen,
      ...props,
      onClick: callAll(props.onClick, (event) => {
        setAnchorEl(event.currentTarget);
      }),
    };
  }

  function getMenuProps(props: Partial<MenuProps> = {}): MenuProps {
    return {
      anchorEl,
      open: isMenuOpen,
      onClose: callAll(props.onClose, onCloseMenu),
      transformOrigin: props.transformOrigin ?? {
        horizontal: 'right',
        vertical: 'top',
      },
      anchorOrigin: props.anchorOrigin ?? {
        horizontal: 'right',
        vertical: 'bottom',
      },
      ...props,
    };
  }

  return { isMenuOpen, onCloseMenu, getButtonProps, getMenuProps };
}
