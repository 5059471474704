import { AuthActionType, AuthReducer } from './authReducer.types';

export const authReducer: AuthReducer = (state, action) => {
  switch (action.type) {
    case AuthActionType.LOGIN:
      if (!action.user) {
        throw new Error(`[${action.type}] action was called without a user object`);
      }

      if (state.USE_GRAPHQL_AUTH && !action.token) {
        throw new Error(`[${action.type}] action was called without a token`);
      }

      return {
        ...state,
        token: action.token || null,
        user: action.user,
        isAuthorized: true,
      };
    case AuthActionType.REFRESH_TOKEN:
      if (!action.token) {
        throw new Error(`[${action.type}] action was called without a token`);
      }

      return {
        ...state,
        token: action.token,
      };
    case AuthActionType.REFRESH_USER:
      if (!action.user) {
        throw new Error(`[${action.type}] action was called without a user object`);
      }

      return {
        ...state,
        user: action.user,
      };
    case AuthActionType.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuthorized: false,
      };
    default:
      throw new Error(`An incorrect action type was passed to authReducer: ${action.type}`);
  }
};
