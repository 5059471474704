import React from 'react';
import { useIntl } from 'react-intl';

import { ButtonProps } from '../Button.types';
import * as S from './FacebookButton.styles';

const loginUrl =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? 'https://www.creativefabrica.com/'
    : 'https://staging.creativefabrica.com/';

export const FacebookButton = (props: ButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <S.ButtonFacebook
      icon={['fab', 'facebook-f']}
      href={`${loginUrl}?wc-api=auth&start=facebook&return=${encodeURIComponent(
        loginUrl
      )}`}
      rel="nofollow"
      color="inherit"
      {...props}
    >
      {formatMessage({ id: 'component.facebook_button' })}
    </S.ButtonFacebook>
  );
};
