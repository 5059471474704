export type SiteFetcherParams = { cookieString?: string; userIp?: string };

//PROMO types
export type SiteInfoData = {
  promotion: Promotions | null;
  takeover: Takeover | null;
  yearly_promo: boolean;
};

type Promotions = {
  discount: number;
  name: string;
};

export type Takeover = {
  above_nav_banner_btn_color: string;
  above_nav_banner_btn_text_color: string;
  above_nav_banner_color: string;
  above_nav_banner_img: string;
  homepage_four_buttons_color: string;
  homepage_four_buttons_text_color: string;
  homepage_hero_btn_color: string;
  homepage_hero_btn_text: string;
  homepage_hero_btn_text_color: string;
  homepage_hero_btn_url: string;
  homepage_hero_img: string;
  homepage_hero_img_left_img: string;
  homepage_hero_img_right_img: string;
  homepage_hero_img_url: string;
  homepage_hero_mbl_img: string;
  homepage_hero_text: string;
  homepage_hero_text_color: string;
  is_active: boolean;
  nav_menu_item_color: string;
  title: string;
  website_logo_img: string;
};

//CURRENCY types
export enum Currency {
  DOLLAR = 'USD',
  EURO = 'EUR',
  POUNDS = 'GBP',
}

export const DEFAULT_CURRENCY = Currency.DOLLAR;
export const DEFAULT_CURRENCY_SYMBOL = '$';

type CurrencyResponse = {
  code: Currency;
  symbol: string;
};

export type PopupData = Popup | null;

//POPUP types
export enum PopupType {
  NEWSLETTER = 'newsletter',
  SITEWIDE = 'sitewide_popup',
  PROFILE_NUDGE = 'profile_nudge',
}

export type Popup = {
  type: string;
  title: string;
  text: string;
  textColor: string;
  backgroundColor: string;
  backgroundImageSrc: string;
  closeText: string;
  footerText: string;
  countdown?: {
    text: string;
    value: string;
  };
  button?: {
    link?: string;
    text: string;
    textColor?: string;
    hoverTextColor?: string;
    backgroundColor: string;
    hoverBackgroundColor?: string;
  };
};

export type CurrencyData = {
  currencies: CurrencyResponse[];
  activeCurrency: CurrencyResponse;
};

//BANNER types
export type BannerData = Banner | AllAccessYearlyBanner | AllAccessYearlyBannerExtend | null;

export enum BannerType {
  FREE_UNDO_CANCELLATION = 'cf_pending_cancel',
  PAID_UNDO_CANCELLATION = 'pending_cancel',
  ALL_ACCESS_YEARLY = 'all_access_yearly',
  ALL_ACCESS_YEARLY_EXTEND = 'all_access_yearly_extend',
  SPARK_FREE_TRIAL = 'spark_free_trial',
  ALL_ACCESS_LIMITED = 'all_access_limited',
}

export type AllAccessYearlyBanner = {
  backgroundImage: string;
  banner_above_nav: string;
  banner_above_nav_state: string;
  buttonText: string;
  buttonUrl: string;
  features: string[];
  headline: string;
  showInHeader: boolean;
  type: BannerType.ALL_ACCESS_YEARLY;
  yearly: boolean;
};

export type AllAccessLimitedBanner = {
  backgroundImage: string;
  banner_above_nav: string;
  banner_above_nav_state: string;
  buttonText: string;
  buttonUrl: string;
  features: string[];
  headline: string;
  showInHeader: boolean;
  type: BannerType.ALL_ACCESS_LIMITED;
  yearly: boolean;
};

export type AllAccessYearlyBannerExtend = {
  backgroundImage: string;
  banner_above_nav: string;
  banner_above_nav_state: string;
  buttonText: string;
  countdown: string;
  lastOrder: boolean;
  subscription: {
    order_type: string;
  };
  features: string[];
  headline: string;
  inProgressId: boolean;
  type: BannerType.ALL_ACCESS_YEARLY_EXTEND;
};

export type Banner = {
  type: BannerType;
  data?: {
    subscriptionId: string;
  };
  backgroundColor?: string;
  textColor?: string;
  text: string;
  button?: {
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    text?: string;
    textColor?: string;
    hoverTextColor?: string;
    link?: string;
  };
  countdown?: {
    text?: string;
    value: string;
  };
};

type ProductsCountData = {
  fonts: number;
  crafts: number;
  graphics: number;
  embroidery: number;
  classes: number;
};

export const DEFAULT_SITE_DATA: SiteData = {
  promo: null,
  currencies: {
    currencies: [],
    activeCurrency: { code: DEFAULT_CURRENCY, symbol: DEFAULT_CURRENCY_SYMBOL },
  },
  popup: null,
  banner: null,
  billing: undefined,
  product_counts: null,
};

export type SiteData = {
  promo: SiteInfoData | null;
  currencies: CurrencyData | null;
  popup: PopupData | null;
  banner: BannerData | null;
  billing: boolean | undefined;
  product_counts: ProductsCountData | null;
};
