import React from 'react';

import { AvatarProps } from './Avatar.types';
import * as S from './Avatar.styles';

export const DEFAULT_AVATAR =
  'https://www.creativefabrica.com/wp-content/themes/creativefabricanew/assets/images/avatar10.png';

export const Avatar = (props: AvatarProps) => {
  const avatarSrc: string = props.src ? props.src : DEFAULT_AVATAR;

  return (
    <S.Avatar
      variant="circular"
      src={avatarSrc}
      css={{ width: props.size, height: props.size }}
      alt={props.alt}
    />
  );
};
