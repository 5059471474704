// https://developers.google.com/tag-platform/gtagjs/reference/events
export enum GlobalSiteTagEventName {
  CONVERSION = 'conversion',
}

export type GlobalSiteTagEventOptions = { [key: string]: string } | void;
export type GlobalSiteTagEventFunction = (
  event: string,
  name: GlobalSiteTagEventName,
  options: GlobalSiteTagEventOptions,
) => void;

declare global {
  interface Window {
    gtag?: GlobalSiteTagEventFunction;
  }
}
