import { fetcher } from 'api/fetcher';
import { RegisterData, RegisterFetcherParams, VerificationData } from 'api/requests/register/register.types';
import { nonceFetcher } from 'api/requests/nonce/nonce';
import { NonceType } from 'api/requests/nonce/nonce.types';
import { ApiEndpoint, BackendErrorListData } from 'api/fetcher.types';

export const registerFetcher = async (params: RegisterFetcherParams) => {
  const { email, password, otp, terms, onError } = params;
  const { nonce } = await nonceFetcher({ nonceType: NonceType.REGISTER });
  const body = JSON.stringify({
    email,
    password,
    terms,
    //cf_web parameter is needed for the BE to differentiate between requests coming from NextJs and the ones from WP
    ...(otp ? { otp, cf_web: 1 } : {}),
    'woocommerce-register-nonce': nonce,
  });

  return fetcher<RegisterData & VerificationData & BackendErrorListData>({
    url: ApiEndpoint.REGISTER,
    options: {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' },
    },
    onError,
  });
};
