import { styled, Box, Typography } from '../../elements';

export const PostCardContentTextWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 2.5),
  fontSize: theme.spacing(1.75),
}));

export const ReadMoreButton = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  textDecoration: 'underline',
  fontSize: theme.spacing(1.5),
  paddingLeft: theme.spacing(0.5),
  cursor: 'pointer',
}));

export const PostCardContentHTML = styled(Box)(({ theme }) => ({
  overflowWrap: 'break-word',
  '& a': {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
}));
