import { styled } from '../../elements';
import { SrOnly } from '../atoms/SrOnly';

export const Container = styled('div')({
  position: 'relative',
});

export const Button = styled('button')(({ theme }) => ({
  outline: 0,
  background: 0,
  border: 0,
  cursor: 'pointer',
  color: theme.palette.gray.extraDark,
  '&:hover': {
    color: theme.palette.secondary.light,
  },
}));

export const Menu = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'absolute',
  right: 0,
  // top is calculated by:
  // size of the button + size of the arrow
  top: 26,
  display: 'flex',
  paddingTop: theme.spacing(0.625),
  paddingBottom: theme.spacing(0.625),
  flexDirection: 'column',
  alignItems: 'start',
  background: 'white',
  whiteSpace: 'nowrap',
  boxShadow: theme.shadows[7],
  // the white arrow at the top of the menu
  '&:before': {
    boxSizing: 'border-box',
    pointerEvents: 'none',
    content: '""',
    position: 'absolute',
    top: -20,
    height: 20,
    width: 0,
    left: 'auto',
    right: theme.spacing(0.25),
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid white',
  },
}));

export const ItemLink = styled('a')<{
  component?: string;
  variant?: 'primary' | 'warning';
}>(({ theme, variant }) => ({
  fontFamily: theme.typography.fontFamily,
  boxSizing: 'border-box',
  padding: theme.spacing(1.25),
  paddingTop: theme.spacing(0.625),
  paddingBottom: theme.spacing(0.625),
  textDecoration: 'none',
  outline: 0,
  display: 'grid',
  gridTemplateColumns: `${theme.spacing(1.5)} auto`,
  fontSize: theme.typography.subtext.fontSize,
  lineHeight: 1.2,
  textTransform: 'uppercase',
  gap: theme.spacing(0.625),
  width: '100%',
  textAlign: 'left',
  color:
    variant === 'warning' ? theme.palette.error.dark : theme.palette.gray.main,
  backgroundColor: theme.palette.white.main,
  '&:hover': {
    color: variant !== 'warning' && theme.palette.secondary.light,
  },
  '& > *:first-of-type': {
    // if you want to edit the width of the icon/svg
    // set it on the grid template columns above
    width: '100%',
  },
}));

export const ItemButton = styled(ItemLink.withComponent('button'))({
  border: 0,
  cursor: 'pointer',
});

export const ButtonText = styled(SrOnly)({});
