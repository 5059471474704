import { createContext } from 'react';

import { DEFAULT_CURRENCY, DEFAULT_SITE_DATA, DEFAULT_CURRENCY_SYMBOL } from 'api/requests/site/site.types';
import { useSiteDataFetcher } from 'api/requests/site/site';

import { CurrencyProviderProps, CurrencyState } from './CurrencyProvider.types';

export const CurrencyStateContext = createContext<CurrencyState>(null!);

const fallbackCurrencyData = {
  currencies: [],
  activeCurrency: { code: DEFAULT_CURRENCY, symbol: DEFAULT_CURRENCY_SYMBOL },
};

export const CurrencyProvider = (props: CurrencyProviderProps) => {
  const { children, initialCurrencyData } = props;
  const { data } = useSiteDataFetcher();
  const { currencies } = data || DEFAULT_SITE_DATA;

  return (
    <CurrencyStateContext.Provider value={initialCurrencyData || currencies || fallbackCurrencyData}>
      {children}
    </CurrencyStateContext.Provider>
  );
};
