import { styled, Box, Typography } from '../../elements';

export const ReactionPanelWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '& button:not(:first-child)': {
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.down('xs')]: {
    justifyContent: 'center',
  },
}));

export const ReactionCount = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  padding: theme.spacing(0, 0, 0, 1),
}));
