import { styled, Box } from '../../elements';

export const ImageStyled = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1.25, 0),
  position: 'relative',
}));

export const Thumbs50List = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 0),
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  borderBottomStyle: 'solid',
  borderBottomWidth: theme.spacing(0.25),
  borderBottomColor: theme.palette.white.main,
}));

export const Thumb50Styled = styled(Box)<{ first?: boolean }>(
  ({ theme, first }) => ({
    display: 'flex',
    width: '50%',
    height: theme.spacing(31.25),
    borderRightWidth: first ? theme.spacing(0.25) : 0,
    borderRightStyle: first ? 'solid' : 'none',
    borderRightColor: theme.palette.white.main,
  })
);

export const ThumbsList = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1.25, 0),
  position: 'relative',
  display: 'flex',
}));

export const Thumb25Styled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '25%',
  height: theme.spacing(11.25),
}));

export const Thumb33Styled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '33.3%',
  height: theme.spacing(18.75),
  position: 'relative',
}));

export const ThumbCounter = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: theme.spacing(0),
  left: theme.spacing(0),
  background: 'rgba(0,0,0,0.6)',
  color: theme.palette.white.main,
  fontSize: theme.spacing(4),
  textAlign: 'center',
  lineHeight: theme.spacing(17.5),
}));

export const ClassIconWrapper = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(2),
  position: 'absolute',
  color: theme.palette.black.main, //#2a1f3f
  top: theme.spacing(0.625),
  right: 0,
  background: theme.palette.white.main,
  padding: theme.spacing(0.375, 0.5),
  borderTopLeftRadius: theme.spacing(0.5),
  borderBottomLeftRadius: theme.spacing(0.5),
  height: theme.spacing(4),
  boxSizing: 'border-box',
}));

export const ImageAnchor = styled('a')(() => ({
  border: 0,
  textDecoration: 'none',
  display: 'flex',
  cursor: 'pointer',
}));

export const ProductCardWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2.5, 0.5),
}));
