import React from 'react';
import { useIntl } from 'react-intl';

import { Link } from 'components/elements/atoms/link/Link';
import coinImageProject from 'assets/icons/coin_image-project.svg';
import coinImageReview from 'assets/icons/coin_image-review.svg';
import coinFlowTip from 'assets/icons/coin_flow-tip.png';
import coinStar from 'assets/icons/coin_star.svg';

import * as S from './CoinEarningTeaser.styles';
import { CoinEarningTeaserProps } from './CoinEarningTeaser.types';

export const CoinEarningTeaserLeaveReview = ({
  amount,
  className,
}: CoinEarningTeaserProps) => {
  const { formatMessage, formatNumber } = useIntl();

  return (
    <S.Wrapper className={className}>
      <S.Icon src={coinImageProject} fill alt="" aria-hidden="true" />
      <S.Text as="div">
        {formatMessage(
          { id: 'coin_earning_teaser.leave_review.title' },
          { count: formatNumber(amount), strong: InlineCoin }
        )}
      </S.Text>
    </S.Wrapper>
  );
};

export const CoinEarningTeaserFlowTip = ({
  amount,
  className,
}: CoinEarningTeaserProps) => {
  const { formatMessage, formatNumber } = useIntl();

  return (
    <S.Wrapper className={className}>
      <S.Icon src={coinFlowTip} fill alt="" aria-hidden="true" />
      <S.Text as="div">
        {formatMessage(
          { id: 'coin_earning_teaser.flow_tip.title' },
          {
            count: formatNumber(amount),
            strong: InlineCoin,
            link: (...children: string[]) => (
              <Link
                className="whitespace-nowrap font-bold"
                href={process.env.NEXT_PUBLIC_SPARK_FLOW_LINK}
                target="_blank"
                underline="none"
                rel="noopener noreferrer"
              >
                {children}
              </Link>
            ),
          }
        )}
      </S.Text>
    </S.Wrapper>
  );
};

export const CoinEarningTeaserPostProject = ({
  amount,
  className,
}: CoinEarningTeaserProps) => {
  const { formatMessage, formatNumber } = useIntl();

  return (
    <S.Wrapper className={className}>
      <S.Icon src={coinImageReview} fill alt="" aria-hidden="true" />
      <S.Text as="div">
        {formatMessage(
          { id: 'coin_earning_teaser.post_project.title' },
          { count: formatNumber(amount), strong: InlineCoin }
        )}
      </S.Text>
    </S.Wrapper>
  );
};

const InlineCoin = (str: React.ReactNode) => {
  return (
    <>
      <S.CoinStar src={coinStar} fill alt="" aria-hidden="true" />
      <S.Strong>{str}</S.Strong>
    </>
  );
};
