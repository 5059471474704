import { styled, Box, CircularProgress } from '../../elements';

export const CommentsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LoadPastComments = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  color: theme.palette.black.main,
  margin: theme.spacing(2, 7),
}));

export const LoadingIcon = styled(CircularProgress)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));
