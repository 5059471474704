// https://developers.facebook.com/docs/facebook-pixel/reference
export enum FacebookEventName {
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  PAGE_VIEW = 'PageView',
  VIEW_CONTENT = 'ViewContent',
  SEARCH = 'Search',
}

export type FacebookEventOptions = { [key: string]: string | string[] } | void;
export type FacebookEventFunction = (track: 'track', name: FacebookEventName, options: FacebookEventOptions) => void;

declare global {
  interface Window {
    fbq?: FacebookEventFunction;
  }
}
