/* 🚨🚨🚨 DO NOT EDIT: This file has been auto generated by graphql-code-generator */
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  UseMutationOptions,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  QueryFunctionContext,
  QueryKey,
  QueryClient,
} from '@tanstack/react-query';
import { mutationFetcher } from './codegen.fetcher';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type EntireFieldWrapper<T> = T;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddFavoriteResponse = {
  __typename?: 'AddFavoriteResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type Article = {
  __typename?: 'Article';
  author: EntireFieldWrapper<PublicUserProfile>;
  title: EntireFieldWrapper<Scalars['String']>;
  url: EntireFieldWrapper<Scalars['String']>;
};

export type AspectRatio = {
  __typename?: 'AspectRatio';
  type: EntireFieldWrapper<ImageRatio>;
  value: EntireFieldWrapper<Scalars['Float']>;
};

export type AssetType =
  | 'downscaled'
  | 'original'
  | 'originalUnoptimized'
  | 'preview'
  | 'printable'
  | 'thumbnail'
  | 'unspecified'
  | 'upscaled';

export type AssetVariant = 'desktop' | 'large' | 'medium' | 'mobile' | 'small' | 'unspecified' | 'xlarge' | 'xsmall';

export type AuthChallenge = {
  __typename?: 'AuthChallenge';
  email?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  otp?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  text?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  type: EntireFieldWrapper<ChallengeType>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  token?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  user?: EntireFieldWrapper<Maybe<User>>;
};

export type AuthType = 'apple' | 'facebook' | 'google';

export type AuthUser = {
  __typename?: 'AuthUser';
  token?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  user?: EntireFieldWrapper<Maybe<User>>;
};

export type Bundle = IProduct & {
  __typename?: 'Bundle';
  author: EntireFieldWrapper<PublicUserProfile>;
  category: EntireFieldWrapper<ProductCategory>;
  coins?: EntireFieldWrapper<Maybe<ProductCoins>>;
  craftsCharacteristic?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  description: EntireFieldWrapper<Scalars['String']>;
  endDate?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  favoriteCount: EntireFieldWrapper<Scalars['Int']>;
  fontCharacteristic?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  graphicCharacteristic?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  image?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  isCoinRewardBundle?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  rating: EntireFieldWrapper<Scalars['Float']>;
  regularPrice: EntireFieldWrapper<Scalars['Float']>;
  salePrice: EntireFieldWrapper<Scalars['Float']>;
  slug: EntireFieldWrapper<Scalars['String']>;
  status: EntireFieldWrapper<ProductStatus>;
  title: EntireFieldWrapper<Scalars['String']>;
  type: EntireFieldWrapper<ProductType>;
  url: EntireFieldWrapper<Scalars['String']>;
  visibility: EntireFieldWrapper<ProductVisibility>;
};

export type BundleAuthor = {
  __typename?: 'BundleAuthor';
  avatar?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  id?: EntireFieldWrapper<Maybe<Scalars['ID']>>;
  name?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type BundlesResponse = {
  __typename?: 'BundlesResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  items: EntireFieldWrapper<Array<Bundle>>;
};

export type CacheControlScope = 'PRIVATE' | 'PUBLIC';

export type ChallengeType = 'challenge2fa' | 'challengeOtp';

export type ClassProduct = IProduct & {
  __typename?: 'ClassProduct';
  author: EntireFieldWrapper<PublicUserProfile>;
  category: EntireFieldWrapper<ProductCategory>;
  coins?: EntireFieldWrapper<Maybe<ProductCoins>>;
  description: EntireFieldWrapper<Scalars['String']>;
  endDate?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  favoriteCount: EntireFieldWrapper<Scalars['Int']>;
  id: EntireFieldWrapper<Scalars['ID']>;
  image?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  level?: EntireFieldWrapper<Maybe<ClassProductLevel>>;
  rating: EntireFieldWrapper<Scalars['Float']>;
  regularPrice: EntireFieldWrapper<Scalars['Float']>;
  salePrice: EntireFieldWrapper<Scalars['Float']>;
  slug: EntireFieldWrapper<Scalars['String']>;
  source?: EntireFieldWrapper<Maybe<ClassProductSource>>;
  status: EntireFieldWrapper<ProductStatus>;
  title: EntireFieldWrapper<Scalars['String']>;
  type: EntireFieldWrapper<ProductType>;
  url: EntireFieldWrapper<Scalars['String']>;
  visibility: EntireFieldWrapper<ProductVisibility>;
};

export type ClassProductLevel = 'Advanced' | 'Beginner' | 'Intermediate';

export type ClassProductSource = 'Original' | 'Sourced';

export type ClientType = 'android' | 'ios' | 'web';

export type CoinAvailableRewards = {
  __typename?: 'CoinAvailableRewards';
  projectPosts?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  reviews?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
};

export type CoinOrigin = {
  __typename?: 'CoinOrigin';
  action: EntireFieldWrapper<CoinTransactionOrigin>;
  product?: EntireFieldWrapper<Maybe<Product>>;
  user?: EntireFieldWrapper<Maybe<PublicUserProfile>>;
};

export type CoinRewardsSettings = {
  __typename?: 'CoinRewardsSettings';
  postProductReview: EntireFieldWrapper<Scalars['Int']>;
  postSocialProductProject: EntireFieldWrapper<Scalars['Int']>;
  registerAccount: EntireFieldWrapper<Scalars['Int']>;
};

export type CoinSettings = {
  __typename?: 'CoinSettings';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  rewards?: EntireFieldWrapper<Maybe<CoinRewardsSettings>>;
  spending?: EntireFieldWrapper<Maybe<CoinSpendingSettings>>;
};

export type CoinSpendingActionsSettings = {
  __typename?: 'CoinSpendingActionsSettings';
  tipPostAuthor: EntireFieldWrapper<Scalars['Int']>;
  tipUser: EntireFieldWrapper<Scalars['Int']>;
};

export type CoinSpendingProductsSettings = {
  __typename?: 'CoinSpendingProductsSettings';
  bundles: EntireFieldWrapper<Scalars['Int']>;
  classes: EntireFieldWrapper<Scalars['Int']>;
  communityGraphics: EntireFieldWrapper<Scalars['Int']>;
  crafts: EntireFieldWrapper<Scalars['Int']>;
};

export type CoinSpendingSettings = {
  __typename?: 'CoinSpendingSettings';
  actions: EntireFieldWrapper<CoinSpendingActionsSettings>;
  products: EntireFieldWrapper<CoinSpendingProductsSettings>;
};

export type CoinTransaction = {
  __typename?: 'CoinTransaction';
  amount: EntireFieldWrapper<Scalars['Int']>;
  id: EntireFieldWrapper<Scalars['ID']>;
  origin: EntireFieldWrapper<CoinOrigin>;
  parent?: EntireFieldWrapper<Maybe<CoinTransaction>>;
  reason?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  status: EntireFieldWrapper<CoinTransactionStatus>;
  type: EntireFieldWrapper<CoinTransactionType>;
};

export type CoinTransactionOrigin =
  | 'adminChange'
  | 'productDownloaded'
  | 'productProjectPosted'
  | 'productReviewed'
  | 'socialPostTipped'
  | 'userTipped';

export type CoinTransactionResponse = {
  __typename?: 'CoinTransactionResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  transaction?: EntireFieldWrapper<Maybe<CoinTransaction>>;
};

export type CoinTransactionStatus = 'cancelled' | 'confirmed' | 'pending';

export type CoinTransactionType = 'earn' | 'spend';

export type CoinTransactionsResponse = {
  __typename?: 'CoinTransactionsResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  pagination?: EntireFieldWrapper<Maybe<Pagination>>;
  transactions?: EntireFieldWrapper<Maybe<Array<Maybe<CoinTransaction>>>>;
};

export type CoinTransactionsResponseByProduct = {
  __typename?: 'CoinTransactionsResponseByProduct';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  transactions?: EntireFieldWrapper<Maybe<Array<Maybe<CoinTransaction>>>>;
};

export type CoinWallet = {
  __typename?: 'CoinWallet';
  availableRewards: EntireFieldWrapper<CoinAvailableRewards>;
  balance: EntireFieldWrapper<Scalars['Int']>;
  transactions: EntireFieldWrapper<CoinTransactionsResponse>;
};

export type CoinWalletTransactionsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type CommunityContentProduct = IProduct & {
  __typename?: 'CommunityContentProduct';
  author: EntireFieldWrapper<PublicUserProfile>;
  category: EntireFieldWrapper<ProductCategory>;
  coins?: EntireFieldWrapper<Maybe<ProductCoins>>;
  creationType: EntireFieldWrapper<CreationType>;
  description: EntireFieldWrapper<Scalars['String']>;
  endDate?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  favoriteCount: EntireFieldWrapper<Scalars['Int']>;
  id: EntireFieldWrapper<Scalars['ID']>;
  image?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  imageVariants?: EntireFieldWrapper<Maybe<ImageVariants>>;
  isUserFavorite: EntireFieldWrapper<Scalars['Boolean']>;
  prompt: EntireFieldWrapper<Scalars['String']>;
  rating: EntireFieldWrapper<Scalars['Float']>;
  regularPrice: EntireFieldWrapper<Scalars['Float']>;
  salePrice: EntireFieldWrapper<Scalars['Float']>;
  slug: EntireFieldWrapper<Scalars['String']>;
  status: EntireFieldWrapper<ProductStatus>;
  title: EntireFieldWrapper<Scalars['String']>;
  type: EntireFieldWrapper<ProductType>;
  url: EntireFieldWrapper<Scalars['String']>;
  visibility: EntireFieldWrapper<ProductVisibility>;
};

export type CommunityContentProductFeedMeta = {
  __typename?: 'CommunityContentProductFeedMeta';
  limit?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  nextOffset?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  offset?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  orderBy?: EntireFieldWrapper<Maybe<CommunityContentProductFeedResponseOrderBy>>;
  total?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
};

export type CommunityContentProductFeedResponse = {
  __typename?: 'CommunityContentProductFeedResponse';
  data?: EntireFieldWrapper<Maybe<Array<Maybe<CommunityContentProduct>>>>;
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  meta?: EntireFieldWrapper<Maybe<CommunityContentProductFeedMeta>>;
};

export type CommunityContentProductFeedResponseOrderBy =
  | 'date_asc'
  | 'date_desc'
  | 'popularity_asc'
  | 'popularity_desc'
  | 'rand';

export type Content = {
  __typename?: 'Content';
  _locale: EntireFieldWrapper<Scalars['String']>;
  alternativeSlugs: EntireFieldWrapper<Array<Maybe<AlternativeSlugs>>>;
  assets?: EntireFieldWrapper<Maybe<Array<ContentAsset>>>;
  authorId?: EntireFieldWrapper<Maybe<Scalars['ID']>>;
  category?: EntireFieldWrapper<Maybe<ContentCategory>>;
  categoryId?: EntireFieldWrapper<Maybe<Scalars['ID']>>;
  createdAt: EntireFieldWrapper<Scalars['DateTime']>;
  description?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  downloadsCount: EntireFieldWrapper<Scalars['Int']>;
  format: EntireFieldWrapper<ContentFormat>;
  id: EntireFieldWrapper<Scalars['ID']>;
  linkBlock?: EntireFieldWrapper<Maybe<ContentLinkBlock>>;
  preview?: EntireFieldWrapper<Maybe<ContentPreview>>;
  seo?: EntireFieldWrapper<Maybe<Seo>>;
  slug: EntireFieldWrapper<Scalars['String']>;
  status: EntireFieldWrapper<ContentStatus>;
  subcategory?: EntireFieldWrapper<Maybe<Array<Scalars['String']>>>;
  tags?: EntireFieldWrapper<Maybe<Array<Maybe<Scalars['String']>>>>;
  thumbnailUrl?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  title: EntireFieldWrapper<Scalars['String']>;
  type?: EntireFieldWrapper<Maybe<ContentType>>;
  updatedAt?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  viewsCount: EntireFieldWrapper<Scalars['Int']>;
};

export type ContentAsset = {
  __typename?: 'ContentAsset';
  contentId: EntireFieldWrapper<Scalars['ID']>;
  createdAt: EntireFieldWrapper<Scalars['DateTime']>;
  downloadLink?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  label: EntireFieldWrapper<Scalars['String']>;
  resolution?: EntireFieldWrapper<Maybe<Resolution>>;
  sizeBytes: EntireFieldWrapper<Scalars['Int']>;
  type: EntireFieldWrapper<AssetType>;
  updatedAt?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  variant: EntireFieldWrapper<AssetVariant>;
};

export type ContentCategory = {
  __typename?: 'ContentCategory';
  _locale: EntireFieldWrapper<Scalars['String']>;
  alternativeSlugs: EntireFieldWrapper<Array<Maybe<AlternativeSlugs>>>;
  ancestors: EntireFieldWrapper<Array<Scalars['String']>>;
  content_bottom?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  content_top?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  name: EntireFieldWrapper<Scalars['String']>;
  parent_id?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  root?: EntireFieldWrapper<Maybe<ContentCategory>>;
  seo: EntireFieldWrapper<ContentCategorySeo>;
  slug: EntireFieldWrapper<Scalars['String']>;
};

export type ContentCategorySeo = {
  __typename?: 'ContentCategorySEO';
  description: EntireFieldWrapper<Scalars['String']>;
  title: EntireFieldWrapper<Scalars['String']>;
};

export type ContentFormat =
  | 'landscape3by2'
  | 'landscape16by9'
  | 'portrait2by3'
  | 'portrait4by5'
  | 'portrait9by16'
  | 'square1by1'
  | 'unspecified';

export type ContentLinkBlock = {
  __typename?: 'ContentLinkBlock';
  id: EntireFieldWrapper<Scalars['ID']>;
  links?: EntireFieldWrapper<Maybe<Array<ContentLinkBlockLink>>>;
  productId?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  title: EntireFieldWrapper<Scalars['String']>;
};

export type ContentLinkBlockLink = {
  __typename?: 'ContentLinkBlockLink';
  anchor: EntireFieldWrapper<Scalars['String']>;
  url: EntireFieldWrapper<Scalars['String']>;
};

export type ContentPreview = {
  __typename?: 'ContentPreview';
  desktop: EntireFieldWrapper<Scalars['String']>;
  mobile: EntireFieldWrapper<Scalars['String']>;
};

export type ContentStatus = 'approved' | 'deleted' | 'pending' | 'unspecified';

export type ContentType = 'craft' | 'graphics' | 'unspecified';

export type CreateImageSignedDownloadUrlResponse = {
  __typename?: 'CreateImageSignedDownloadUrlResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  url?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type CreateSignedUploadUrlResponse = {
  __typename?: 'CreateSignedUploadUrlResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  url?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type CreateSparkColoringPagesPrompt = {
  __typename?: 'CreateSparkColoringPagesPrompt';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  prompt?: EntireFieldWrapper<Maybe<Prompt>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type CreateSparkCrystallinePrompt = {
  __typename?: 'CreateSparkCrystallinePrompt';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  prompt?: EntireFieldWrapper<Maybe<Prompt>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type CreateSparkPrompt = {
  __typename?: 'CreateSparkPrompt';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  prompt?: EntireFieldWrapper<Maybe<Prompt>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type CreationType = 'art' | 'coloringPage' | 'crystalline' | 'imagemix' | 'patterns' | 'sketch';

export type Currency = 'AUD' | 'CAD' | 'CNY' | 'DKK' | 'EUR' | 'GBP' | 'JPY' | 'PLN' | 'USD';

export type DateFilter = {
  month: Month;
  year: Scalars['Int'];
};

export type DateFilterResponse = {
  __typename?: 'DateFilterResponse';
  month: EntireFieldWrapper<Month>;
  year: EntireFieldWrapper<Scalars['Int']>;
};

export type DeactivateAccountInput = {
  otp?: InputMaybe<Scalars['String']>;
};

export type DeactivateAccountResponse = {
  __typename?: 'DeactivateAccountResponse';
  challenge?: EntireFieldWrapper<Maybe<AuthChallenge>>;
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
};

export type DeleteFavoriteResponse = {
  __typename?: 'DeleteFavoriteResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type Download = {
  __typename?: 'Download';
  id: EntireFieldWrapper<Scalars['ID']>;
  image: EntireFieldWrapper<Scalars['String']>;
  title: EntireFieldWrapper<Scalars['String']>;
  type: EntireFieldWrapper<Scalars['String']>;
  url: EntireFieldWrapper<Scalars['String']>;
};

export type DownloadMeta = {
  __typename?: 'DownloadMeta';
  total?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
};

export type DownloadsOrderBy = 'dateAsc' | 'dateDesc' | 'rand';

export type DownloadsResponse = {
  __typename?: 'DownloadsResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  items: EntireFieldWrapper<Array<Download>>;
  meta?: EntireFieldWrapper<Maybe<DownloadMeta>>;
};

export type ErrorCode = 'badRequest' | 'conflict' | 'forbidden' | 'internal' | 'notFound' | 'unauthorized';

export type ErrorMessage = {
  __typename?: 'ErrorMessage';
  code: EntireFieldWrapper<ErrorCode>;
  fields?: EntireFieldWrapper<Maybe<Array<Maybe<FieldError>>>>;
  message: EntireFieldWrapper<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  errors: EntireFieldWrapper<Array<Scalars['String']>>;
  field: EntireFieldWrapper<Scalars['String']>;
};

export type FollowUserResponse = {
  __typename?: 'FollowUserResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type GenerateAssetDownloadLinkResponse = {
  __typename?: 'GenerateAssetDownloadLinkResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  url?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type GetPriceCalculationsResponse = {
  __typename?: 'GetPriceCalculationsResponse';
  data?: EntireFieldWrapper<Maybe<PriceCalculationsResponse>>;
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
};

export type GetTrendingWordsResponse = {
  __typename?: 'GetTrendingWordsResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  words?: EntireFieldWrapper<Maybe<Array<Scalars['String']>>>;
};

export type GiveTipResponse = {
  __typename?: 'GiveTipResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  transactions?: EntireFieldWrapper<Maybe<Array<Maybe<CoinTransaction>>>>;
};

export type HealthCheckResponse = {
  __typename?: 'HealthCheckResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type IProduct = {
  author: EntireFieldWrapper<PublicUserProfile>;
  category: EntireFieldWrapper<ProductCategory>;
  coins?: EntireFieldWrapper<Maybe<ProductCoins>>;
  description: EntireFieldWrapper<Scalars['String']>;
  endDate?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  favoriteCount: EntireFieldWrapper<Scalars['Int']>;
  id: EntireFieldWrapper<Scalars['ID']>;
  image?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  rating: EntireFieldWrapper<Scalars['Float']>;
  regularPrice: EntireFieldWrapper<Scalars['Float']>;
  salePrice: EntireFieldWrapper<Scalars['Float']>;
  slug: EntireFieldWrapper<Scalars['String']>;
  status: EntireFieldWrapper<ProductStatus>;
  title: EntireFieldWrapper<Scalars['String']>;
  type: EntireFieldWrapper<ProductType>;
  url: EntireFieldWrapper<Scalars['String']>;
  visibility: EntireFieldWrapper<ProductVisibility>;
};

export type IPromptImage = {
  id: EntireFieldWrapper<Scalars['ID']>;
  source: EntireFieldWrapper<ImageSource>;
};

export type IPromptImageAsset = {
  original: EntireFieldWrapper<Image>;
  thumb: EntireFieldWrapper<Image>;
  upscaled?: EntireFieldWrapper<Maybe<Image>>;
};

export type IUpscaledImage = {
  downloaded?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  id: EntireFieldWrapper<Scalars['ID']>;
};

export type Image = {
  __typename?: 'Image';
  height?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  url: EntireFieldWrapper<Scalars['String']>;
  width?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
};

export type ImageFailed = IUpscaledImage & {
  __typename?: 'ImageFailed';
  downloaded?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  error?: EntireFieldWrapper<Maybe<PromptImageError>>;
  id: EntireFieldWrapper<Scalars['ID']>;
};

export type ImageFailedUpscaling = IUpscaledImage & {
  __typename?: 'ImageFailedUpscaling';
  downloaded?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  error?: EntireFieldWrapper<Maybe<PromptImageError>>;
  id: EntireFieldWrapper<Scalars['ID']>;
};

export type ImageRatio =
  | 'arbitrary'
  | 'fourToFive'
  | 'landscape'
  | 'nineToSixteen'
  | 'onePointNinetyOneToOne'
  | 'oneToOne'
  | 'portrait'
  | 'sixteenToNine'
  | 'square'
  | 'threeToTwo'
  | 'twoToThree';

export type ImageSource = 'dalle' | 'stabilityAi' | 'stableDiffusionCf';

export type ImageUpscaled = IUpscaledImage & {
  __typename?: 'ImageUpscaled';
  downloaded?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  image: EntireFieldWrapper<UpscaledImage>;
  upscalingTime?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
};

export type ImageUpscalerResponse = {
  __typename?: 'ImageUpscalerResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  item?: EntireFieldWrapper<Maybe<IUpscaledImage>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type ImageUpscaling = IUpscaledImage & {
  __typename?: 'ImageUpscaling';
  downloaded?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  id: EntireFieldWrapper<Scalars['ID']>;
};

export type ImageVariant = {
  __typename?: 'ImageVariant';
  height?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  image: EntireFieldWrapper<Scalars['String']>;
  mimeType?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  width?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
};

export type ImageVariants = {
  __typename?: 'ImageVariants';
  thumbnail?: EntireFieldWrapper<Maybe<ImageVariant>>;
};

export type Language = 'de' | 'en' | 'es' | 'fr' | 'it' | 'nl' | 'pl' | 'pt';

export type LoginInput = {
  code?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  otp?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  challenge?: EntireFieldWrapper<Maybe<AuthChallenge>>;
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  token?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  user?: EntireFieldWrapper<Maybe<User>>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  logout?: EntireFieldWrapper<Maybe<StatusMessage>>;
};

export type LostPasswordInput = {
  email: Scalars['String'];
};

export type LostPasswordResponse = {
  __typename?: 'LostPasswordResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type ModelTypeVersion = 'ml6_v01' | 'ml6_v02' | 'threed_v01' | 'threed_v02' | 'twod_v01' | 'twod_v02';

export type Month =
  | 'April'
  | 'August'
  | 'December'
  | 'February'
  | 'January'
  | 'July'
  | 'June'
  | 'March'
  | 'May'
  | 'November'
  | 'October'
  | 'September';

export type Mutation = {
  __typename?: 'Mutation';
  addFavorite?: EntireFieldWrapper<Maybe<AddFavoriteResponse>>;
  createImageSignedDownloadUrl: EntireFieldWrapper<CreateImageSignedDownloadUrlResponse>;
  createSignedUploadUrl: EntireFieldWrapper<CreateSignedUploadUrlResponse>;
  createSparkColoringPagesPrompt?: EntireFieldWrapper<Maybe<CreateSparkColoringPagesPrompt>>;
  /**
   * Create images based off a crystalline prompt.
   * Note that the images are not available immediately,
   * they are generated asynchronously. So this mutation will resolve, the
   * prompt with it's images will be saved, but the images will still be
   * generating.
   */
  createSparkCrystallinePrompt?: EntireFieldWrapper<Maybe<CreateSparkCrystallinePrompt>>;
  /**
   * Create images based off a prompt.
   * Note that the images are not available immediately,
   * they are generated asynchronously. So this mutation will resolve, the
   * prompt with it's images will be saved, but the images will still be
   * generating.
   */
  createSparkPrompt?: EntireFieldWrapper<Maybe<CreateSparkPrompt>>;
  deactivateAccount?: EntireFieldWrapper<Maybe<DeactivateAccountResponse>>;
  deleteFavorite?: EntireFieldWrapper<Maybe<DeleteFavoriteResponse>>;
  followUser?: EntireFieldWrapper<Maybe<FollowUserResponse>>;
  generateAssetDownloadLink: EntireFieldWrapper<GenerateAssetDownloadLinkResponse>;
  giveTip: EntireFieldWrapper<GiveTipResponse>;
  imageUpscaler?: EntireFieldWrapper<Maybe<ImageUpscalerResponse>>;
  login?: EntireFieldWrapper<Maybe<LoginResponse>>;
  loginWithSocial?: EntireFieldWrapper<Maybe<AuthResponse>>;
  logout?: EntireFieldWrapper<Maybe<LogoutResponse>>;
  lostPassword?: EntireFieldWrapper<Maybe<LostPasswordResponse>>;
  privateUpscale?: EntireFieldWrapper<Maybe<PrivateUpscaleResponse>>;
  refreshToken?: EntireFieldWrapper<Maybe<RefreshTokenResponse>>;
  register?: EntireFieldWrapper<Maybe<RegisterResponse>>;
  removeSparkPrompt?: EntireFieldWrapper<Maybe<RemoveSparkPrompt>>;
  resetPassword?: EntireFieldWrapper<Maybe<ResetPasswordResponse>>;
  skipProduct?: EntireFieldWrapper<Maybe<SkipProductResponse>>;
  spendCoinsOnProduct: EntireFieldWrapper<SpendCoinsResponse>;
  unfollowUser?: EntireFieldWrapper<Maybe<UnfollowUserResponse>>;
  updateCoinTransactionStatus: EntireFieldWrapper<UpdateCoinTransactionStatusResponse>;
  /**
   * Initialize the upscaling of a image, to make it larger and more detailed.
   * Note that the upscaling of an image is asynchronous, so the image will not be
   * available immediately after this mutation is called. You can check the status
   * of the upscaling by querying the prompt.
   */
  upscaleSparkImage?: EntireFieldWrapper<Maybe<UpscaleSparkImage>>;
};

export type MutationAddFavoriteArgs = {
  id: Scalars['Int'];
};

export type MutationCreateImageSignedDownloadUrlArgs = {
  imageId: Scalars['String'];
};

export type MutationCreateSignedUploadUrlArgs = {
  fileName: Scalars['String'];
};

export type MutationCreateSparkColoringPagesPromptArgs = {
  prompt: Scalars['String'];
  ratio: ImageRatio;
};

export type MutationCreateSparkCrystallinePromptArgs = {
  modelTypeVersion?: InputMaybe<ModelTypeVersion>;
  prompt: Scalars['String'];
  ratio: ImageRatio;
};

export type MutationCreateSparkPromptArgs = {
  creationType?: InputMaybe<CreationType>;
  prompt: Scalars['String'];
  ratio: ImageRatio;
};

export type MutationDeactivateAccountArgs = {
  otp?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteFavoriteArgs = {
  id: Scalars['Int'];
};

export type MutationFollowUserArgs = {
  id: Scalars['Int'];
};

export type MutationGenerateAssetDownloadLinkArgs = {
  assetId: Scalars['ID'];
  contentId: Scalars['ID'];
};

export type MutationGiveTipArgs = {
  recipientUserId: Scalars['String'];
};

export type MutationImageUpscalerArgs = {
  imagePath: Scalars['String'];
};

export type MutationLoginArgs = {
  input?: InputMaybe<LoginInput>;
};

export type MutationLoginWithSocialArgs = {
  accessToken: Scalars['String'];
  clientType: ClientType;
  type: AuthType;
};

export type MutationLostPasswordArgs = {
  input?: InputMaybe<LostPasswordInput>;
};

export type MutationPrivateUpscaleArgs = {
  itemId: Scalars['String'];
};

export type MutationRegisterArgs = {
  input?: InputMaybe<RegisterInput>;
};

export type MutationRemoveSparkPromptArgs = {
  promptId: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  input?: InputMaybe<ResetPasswordInput>;
};

export type MutationSkipProductArgs = {
  id: Scalars['Int'];
};

export type MutationSpendCoinsOnProductArgs = {
  productId: Scalars['String'];
};

export type MutationUnfollowUserArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateCoinTransactionStatusArgs = {
  id: Scalars['String'];
  status: CoinTransactionStatus;
};

export type MutationUpscaleSparkImageArgs = {
  hiddenPrompt?: InputMaybe<Scalars['Boolean']>;
  imageId: Scalars['String'];
};

export type Pagination = {
  __typename?: 'Pagination';
  itemsPerPage: EntireFieldWrapper<Scalars['Int']>;
  page: EntireFieldWrapper<Scalars['Int']>;
  totalItems: EntireFieldWrapper<Scalars['Int']>;
  totalPages: EntireFieldWrapper<Scalars['Int']>;
};

export type PopularResponse = {
  __typename?: 'PopularResponse';
  classes?: EntireFieldWrapper<Maybe<Array<ClassProduct>>>;
  crafts?: EntireFieldWrapper<Maybe<Array<Product>>>;
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
};

export type Post = {
  __typename?: 'Post';
  activityId: EntireFieldWrapper<Scalars['ID']>;
  article?: EntireFieldWrapper<Maybe<Article>>;
  author: EntireFieldWrapper<PublicUserProfile>;
  description?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  images?: EntireFieldWrapper<Maybe<Array<Maybe<Scalars['String']>>>>;
  mentionedProducts?: EntireFieldWrapper<Maybe<Array<Product>>>;
  postId: EntireFieldWrapper<Scalars['String']>;
  product?: EntireFieldWrapper<Maybe<Product>>;
  saved: EntireFieldWrapper<Scalars['Boolean']>;
  time: EntireFieldWrapper<Scalars['String']>;
  type: EntireFieldWrapper<PostType>;
  verb: EntireFieldWrapper<Scalars['String']>;
  visibility: EntireFieldWrapper<PostVisibility>;
};

export type PostType = 'article' | 'cfclass' | 'cfclassproject' | 'cfproductproject' | 'project' | 'simple';

export type PostVisibility = 'membersOnly' | 'public';

export type PriceCalculationsInput = {
  match?: InputMaybe<Scalars['Boolean']>;
  prices: Array<PricesToTransformInput>;
  targetCurrency: Currency;
};

export type PriceCalculationsResponse = {
  __typename?: 'PriceCalculationsResponse';
  prices?: EntireFieldWrapper<Maybe<Array<Maybe<TransformedPricesResponse>>>>;
  targetCurrency?: EntireFieldWrapper<Maybe<Currency>>;
};

export type PricesToTransformInput = {
  basePrice: Scalars['Int'];
};

export type PrivateImage = {
  __typename?: 'PrivateImage';
  aspectRatio: EntireFieldWrapper<AspectRatio>;
  creationType?: EntireFieldWrapper<Maybe<CreationType>>;
  image: EntireFieldWrapper<IPromptImage>;
  prompt: EntireFieldWrapper<Scalars['String']>;
  /** @deprecated Use aspectRatio.type instead */
  ratio: EntireFieldWrapper<ImageRatio>;
  userId?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type PrivateImagesResponse = {
  __typename?: 'PrivateImagesResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  items?: EntireFieldWrapper<Maybe<Array<PrivateImage>>>;
  pagination?: EntireFieldWrapper<Maybe<Pagination>>;
};

export type PrivateUpscaleResponse = {
  __typename?: 'PrivateUpscaleResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  item?: EntireFieldWrapper<Maybe<IPromptImage>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type PrivateUpscaledImageAsset = IPromptImageAsset & {
  __typename?: 'PrivateUpscaledImageAsset';
  /** @deprecated Use thumb.height instead */
  height?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  original: EntireFieldWrapper<Image>;
  thumb: EntireFieldWrapper<Image>;
  upscaled: EntireFieldWrapper<Image>;
  /** @deprecated Use upscaled.url instead */
  upscaledFile?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  /** @deprecated Use thumb.url instead */
  url: EntireFieldWrapper<Scalars['String']>;
  /** @deprecated Use thumb.width instead */
  width?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
};

export type Product = IProduct & {
  __typename?: 'Product';
  author: EntireFieldWrapper<PublicUserProfile>;
  category: EntireFieldWrapper<ProductCategory>;
  coins?: EntireFieldWrapper<Maybe<ProductCoins>>;
  description: EntireFieldWrapper<Scalars['String']>;
  endDate?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  favoriteCount: EntireFieldWrapper<Scalars['Int']>;
  id: EntireFieldWrapper<Scalars['ID']>;
  image?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  rating: EntireFieldWrapper<Scalars['Float']>;
  regularPrice: EntireFieldWrapper<Scalars['Float']>;
  salePrice: EntireFieldWrapper<Scalars['Float']>;
  slug: EntireFieldWrapper<Scalars['String']>;
  status: EntireFieldWrapper<ProductStatus>;
  title: EntireFieldWrapper<Scalars['String']>;
  type: EntireFieldWrapper<ProductType>;
  url: EntireFieldWrapper<Scalars['String']>;
  visibility: EntireFieldWrapper<ProductVisibility>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: EntireFieldWrapper<Scalars['ID']>;
  slug: EntireFieldWrapper<Scalars['String']>;
  title: EntireFieldWrapper<Scalars['String']>;
};

export type ProductCoins = {
  __typename?: 'ProductCoins';
  amount?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  spent?: EntireFieldWrapper<Maybe<CoinTransaction>>;
};

export type ProductFeedResponse = {
  __typename?: 'ProductFeedResponse';
  data?: EntireFieldWrapper<Maybe<Array<Maybe<Product>>>>;
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
};

export type ProductStatus = 'draft' | 'future' | 'pending' | 'publish' | 'removed' | 'trash' | 'unspecified';

export type ProductType = {
  __typename?: 'ProductType';
  id: EntireFieldWrapper<Scalars['ID']>;
  slug: EntireFieldWrapper<Scalars['String']>;
  title: EntireFieldWrapper<Scalars['String']>;
};

export type ProductVisibility = 'hidden' | 'visible';

export type Prompt = {
  __typename?: 'Prompt';
  aspectRatio: EntireFieldWrapper<AspectRatio>;
  author: EntireFieldWrapper<PublicUserProfile>;
  coverImage?: EntireFieldWrapper<Maybe<Image>>;
  createTime?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  creationType?: EntireFieldWrapper<Maybe<CreationType>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  images?: EntireFieldWrapper<Maybe<Array<IPromptImage>>>;
  modelTypeVersion?: EntireFieldWrapper<Maybe<ModelTypeVersion>>;
  prompt: EntireFieldWrapper<Scalars['String']>;
  /** @deprecated Use aspectRatio.type instead */
  ratio: EntireFieldWrapper<ImageRatio>;
};

export type PromptByIdResponse = {
  __typename?: 'PromptByIdResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  prompt?: EntireFieldWrapper<Maybe<Prompt>>;
};

export type PromptImageAsset = IPromptImageAsset & {
  __typename?: 'PromptImageAsset';
  /** @deprecated Use thumb.height instead */
  height?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  original: EntireFieldWrapper<Image>;
  thumb: EntireFieldWrapper<Image>;
  upscaled?: EntireFieldWrapper<Maybe<Image>>;
  /** @deprecated Use thumb.url instead */
  url: EntireFieldWrapper<Scalars['String']>;
  /** @deprecated Use thumb.width instead */
  width?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
};

export type PromptImageError =
  | 'invalidPrompt'
  | 'invalidUrl'
  | 'promptIsTooLong'
  | 'serverProcessingError'
  | 'serverTimeoutError'
  | 'unknownGeneratingError'
  | 'unknownUpscalingError'
  | 'unsafePrompt';

export type PromptImageFailedGenerating = IPromptImage & {
  __typename?: 'PromptImageFailedGenerating';
  error?: EntireFieldWrapper<Maybe<PromptImageError>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  source: EntireFieldWrapper<ImageSource>;
};

export type PromptImageFailedUpscaling = IPromptImage & {
  __typename?: 'PromptImageFailedUpscaling';
  error?: EntireFieldWrapper<Maybe<PromptImageError>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  source: EntireFieldWrapper<ImageSource>;
};

export type PromptImageGenerated = IPromptImage & {
  __typename?: 'PromptImageGenerated';
  id: EntireFieldWrapper<Scalars['ID']>;
  image: EntireFieldWrapper<PromptImageAsset>;
  source: EntireFieldWrapper<ImageSource>;
};

export type PromptImageGenerating = IPromptImage & {
  __typename?: 'PromptImageGenerating';
  id: EntireFieldWrapper<Scalars['ID']>;
  source: EntireFieldWrapper<ImageSource>;
};

export type PromptImagePrivateUpscaled = IPromptImage & {
  __typename?: 'PromptImagePrivateUpscaled';
  id: EntireFieldWrapper<Scalars['ID']>;
  image: EntireFieldWrapper<PrivateUpscaledImageAsset>;
  source: EntireFieldWrapper<ImageSource>;
  upscalingTime?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
};

export type PromptImagePrivateUpscaling = IPromptImage & {
  __typename?: 'PromptImagePrivateUpscaling';
  id: EntireFieldWrapper<Scalars['ID']>;
  image: EntireFieldWrapper<PrivateUpscaledImageAsset>;
  source: EntireFieldWrapper<ImageSource>;
};

export type PromptImageUpscaled = IPromptImage & {
  __typename?: 'PromptImageUpscaled';
  id: EntireFieldWrapper<Scalars['ID']>;
  image: EntireFieldWrapper<PrivateUpscaledImageAsset>;
  product: EntireFieldWrapper<IProduct>;
  source: EntireFieldWrapper<ImageSource>;
  submitTime?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  upscalingTime?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
};

export type PromptImageUpscaling = IPromptImage & {
  __typename?: 'PromptImageUpscaling';
  id: EntireFieldWrapper<Scalars['ID']>;
  image: EntireFieldWrapper<PrivateUpscaledImageAsset>;
  source: EntireFieldWrapper<ImageSource>;
};

export type PromptsFilter = {
  date?: InputMaybe<DateFilter>;
  prompt?: InputMaybe<Scalars['String']>;
};

export type PromptsFilterResponse = {
  __typename?: 'PromptsFilterResponse';
  date?: EntireFieldWrapper<Maybe<DateFilterResponse>>;
  prompt?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type PromptsQueueResponse = {
  __typename?: 'PromptsQueueResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  position?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  prompt?: EntireFieldWrapper<Maybe<Prompt>>;
};

export type PromptsResponse = {
  __typename?: 'PromptsResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  filter?: EntireFieldWrapper<Maybe<PromptsFilterResponse>>;
  items?: EntireFieldWrapper<Maybe<Array<Prompt>>>;
  pagination?: EntireFieldWrapper<Maybe<Pagination>>;
};

export type PublicUserProfile = {
  __typename?: 'PublicUserProfile';
  avatarUrl?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  displayName?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  firstName?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  isCreativeFabrica?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  isFollowed?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  isPremium?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  isVerified?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  lastName?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  profileUrls: EntireFieldWrapper<PublicUserProfileUrls>;
  shopName?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  slug?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type PublicUserProfileUrls = {
  __typename?: 'PublicUserProfileUrls';
  crafter?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  designer?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  teacher?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  theArtistry?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  allCoinTransactionsForProductId: EntireFieldWrapper<CoinTransactionsResponseByProduct>;
  bundles: EntireFieldWrapper<BundlesResponse>;
  coinTransactionForProductId: EntireFieldWrapper<CoinTransactionResponse>;
  coinTransactions: EntireFieldWrapper<CoinTransactionsResponse>;
  getCalculatedPrices: EntireFieldWrapper<GetPriceCalculationsResponse>;
  getContent?: EntireFieldWrapper<Maybe<Content>>;
  getContentCategories?: EntireFieldWrapper<Maybe<Array<Maybe<ContentCategory>>>>;
  getContentCategory?: EntireFieldWrapper<Maybe<ContentCategory>>;
  getContentLinkBlock?: EntireFieldWrapper<Maybe<ContentLinkBlock>>;
  getImageUpscalerStatus?: EntireFieldWrapper<Maybe<ImageUpscalerResponse>>;
  getMasterFeed: EntireFieldWrapper<SocialFeedResponse>;
  getMyFeed: EntireFieldWrapper<SocialFeedResponse>;
  getPersonalizedCommunityContentProductList: EntireFieldWrapper<CommunityContentProductFeedResponse>;
  getPopularProducts: EntireFieldWrapper<ProductFeedResponse>;
  getProductList: EntireFieldWrapper<CommunityContentProductFeedResponse>;
  getProductsById: EntireFieldWrapper<Array<Maybe<IProduct>>>;
  getPublicPromptById?: EntireFieldWrapper<Maybe<PromptByIdResponse>>;
  getSparkUserCredits: EntireFieldWrapper<SparkUserCredits>;
  getTrendingWords: EntireFieldWrapper<GetTrendingWordsResponse>;
  getUserFeed: EntireFieldWrapper<SocialFeedResponse>;
  healthCheck: EntireFieldWrapper<HealthCheckResponse>;
  listContentCategories?: EntireFieldWrapper<Maybe<Array<ContentCategory>>>;
  me?: EntireFieldWrapper<Maybe<AuthResponse>>;
  popular: EntireFieldWrapper<PopularResponse>;
  searchProducts: EntireFieldWrapper<SearchResponse>;
  settings: EntireFieldWrapper<Settings>;
  validateToken?: EntireFieldWrapper<Maybe<ValidateTokenResponse>>;
};

export type QueryAllCoinTransactionsForProductIdArgs = {
  productId: Scalars['String'];
};

export type QueryCoinTransactionForProductIdArgs = {
  productId: Scalars['String'];
};

export type QueryCoinTransactionsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCalculatedPricesArgs = {
  input: PriceCalculationsInput;
};

export type QueryGetContentArgs = {
  contentId: Scalars['ID'];
  increaseViewsCount?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Language>;
};

export type QueryGetContentCategoriesArgs = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Language>;
};

export type QueryGetContentCategoryArgs = {
  categoryId: Scalars['ID'];
  locale?: InputMaybe<Language>;
};

export type QueryGetContentLinkBlockArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Language>;
};

export type QueryGetImageUpscalerStatusArgs = {
  id: Scalars['String'];
};

export type QueryGetMasterFeedArgs = {
  idGte?: InputMaybe<Scalars['String']>;
  idLte?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryGetMyFeedArgs = {
  idGte?: InputMaybe<Scalars['String']>;
  idLte?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPersonalizedCommunityContentProductListArgs = {
  categoryId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommunityContentProductFeedResponseOrderBy>;
};

export type QueryGetPopularProductsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryGetProductListArgs = {
  authorId?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommunityContentProductFeedResponseOrderBy>;
};

export type QueryGetProductsByIdArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryGetPublicPromptByIdArgs = {
  promptId: Scalars['String'];
};

export type QueryGetTrendingWordsArgs = {
  locale?: InputMaybe<Language>;
};

export type QueryGetUserFeedArgs = {
  idGte?: InputMaybe<Scalars['String']>;
  idLte?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
  userSlug?: InputMaybe<Scalars['String']>;
};

export type QueryListContentCategoriesArgs = {
  locale?: InputMaybe<Language>;
  parentId: Scalars['ID'];
};

export type QuerySearchProductsArgs = {
  input?: InputMaybe<SearchInput>;
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  token?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type RegisterInput = {
  code?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  otp?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  terms: Scalars['Boolean'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  challenge?: EntireFieldWrapper<Maybe<AuthChallenge>>;
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  token?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  user?: EntireFieldWrapper<Maybe<User>>;
};

export type RemoveSparkPrompt = {
  __typename?: 'RemoveSparkPrompt';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  key: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type Resolution = {
  __typename?: 'Resolution';
  height: EntireFieldWrapper<Scalars['Int']>;
  width: EntireFieldWrapper<Scalars['Int']>;
};

export type Seo = {
  __typename?: 'SEO';
  description: EntireFieldWrapper<Scalars['String']>;
  h1: EntireFieldWrapper<Scalars['String']>;
  image_alt: EntireFieldWrapper<Scalars['String']>;
  title: EntireFieldWrapper<Scalars['String']>;
};

export type SearchCategory = {
  __typename?: 'SearchCategory';
  count: EntireFieldWrapper<Scalars['Int']>;
  title: EntireFieldWrapper<Scalars['String']>;
};

export type SearchInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  categoriesFilters?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>>;
  filters?: InputMaybe<Scalars['String']>;
  hitsPerPage: Scalars['Int'];
  language?: InputMaybe<Language>;
  order?: InputMaybe<SearchResultsOrder>;
  page: Scalars['Int'];
  query?: InputMaybe<Scalars['String']>;
  targetCurrency?: InputMaybe<Currency>;
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  search?: EntireFieldWrapper<Maybe<SearchResult>>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  categories: EntireFieldWrapper<Array<Maybe<SearchType>>>;
  filters?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  hitsPerPage: EntireFieldWrapper<Scalars['Int']>;
  language: EntireFieldWrapper<Language>;
  page: EntireFieldWrapper<Scalars['Int']>;
  products: EntireFieldWrapper<Array<Maybe<IProduct>>>;
  queryID: EntireFieldWrapper<Scalars['String']>;
  searchTime: EntireFieldWrapper<Scalars['Int']>;
  targetCurrency: EntireFieldWrapper<Currency>;
  totalPages: EntireFieldWrapper<Scalars['Int']>;
  totalProducts: EntireFieldWrapper<Scalars['Int']>;
};

export type SearchResultsOrder = 'mostRelevant' | 'newestFirst' | 'oldestFirst';

export type SearchType = {
  __typename?: 'SearchType';
  categories: EntireFieldWrapper<Array<Maybe<SearchCategory>>>;
  type: EntireFieldWrapper<Scalars['String']>;
};

export type Settings = {
  __typename?: 'Settings';
  coins: EntireFieldWrapper<CoinSettings>;
};

export type SkipProductResponse = {
  __typename?: 'SkipProductResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type SocialFeedResponse = {
  __typename?: 'SocialFeedResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  posts?: EntireFieldWrapper<Maybe<Array<Post>>>;
};

export type SparkUserCredits = {
  __typename?: 'SparkUserCredits';
  balance?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
};

export type SparkUserInfo = {
  __typename?: 'SparkUserInfo';
  balance: EntireFieldWrapper<Scalars['Int']>;
  privateImages: EntireFieldWrapper<PrivateImagesResponse>;
  prompts: EntireFieldWrapper<PromptsResponse>;
  queue: EntireFieldWrapper<PromptsQueueResponse>;
};

export type SparkUserInfoPrivateImagesArgs = {
  creationType?: InputMaybe<CreationType>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SparkUserInfoPromptsArgs = {
  creationType?: InputMaybe<CreationType>;
  filter?: InputMaybe<PromptsFilter>;
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type SpendCoinsResponse = {
  __typename?: 'SpendCoinsResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  transaction?: EntireFieldWrapper<Maybe<CoinTransaction>>;
};

export type StatusMessage = {
  __typename?: 'StatusMessage';
  message: EntireFieldWrapper<Scalars['String']>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type Subscriptions = {
  __typename?: 'Subscriptions';
  classes?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  crafts?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  embroidery?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  fonts?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  graphics?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
};

export type TransformedPricesResponse = {
  __typename?: 'TransformedPricesResponse';
  basePrice?: EntireFieldWrapper<Maybe<Scalars['Int']>>;
  currencyPrice: EntireFieldWrapper<Scalars['Int']>;
};

export type UnfollowUserResponse = {
  __typename?: 'UnfollowUserResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type UpdateCoinTransactionStatusResponse = {
  __typename?: 'UpdateCoinTransactionStatusResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  transaction?: EntireFieldWrapper<Maybe<CoinTransaction>>;
};

export type UpscaleSparkImage = {
  __typename?: 'UpscaleSparkImage';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  image?: EntireFieldWrapper<Maybe<IPromptImage>>;
  success: EntireFieldWrapper<Scalars['Boolean']>;
};

export type UpscaledImage = {
  __typename?: 'UpscaledImage';
  upscaledFile: EntireFieldWrapper<Scalars['String']>;
};

export type Upsell = {
  __typename?: 'Upsell';
  coupon: EntireFieldWrapper<Scalars['String']>;
  price: EntireFieldWrapper<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  avatarUrl?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  bio: EntireFieldWrapper<Scalars['String']>;
  coins: EntireFieldWrapper<CoinWallet>;
  contentInterests: EntireFieldWrapper<Array<Scalars['String']>>;
  craftingTypes: EntireFieldWrapper<Array<Scalars['String']>>;
  downloads?: EntireFieldWrapper<Maybe<DownloadsResponse>>;
  email: EntireFieldWrapper<Scalars['String']>;
  firstName?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  flags?: EntireFieldWrapper<Maybe<UserFlags>>;
  id: EntireFieldWrapper<Scalars['ID']>;
  lastName?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  login?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  machineTypes: EntireFieldWrapper<Array<Scalars['String']>>;
  nickname?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  registeredAt?: EntireFieldWrapper<Maybe<Scalars['DateTime']>>;
  roles?: EntireFieldWrapper<Maybe<Array<Maybe<Scalars['String']>>>>;
  slug?: EntireFieldWrapper<Maybe<Scalars['String']>>;
  spark: EntireFieldWrapper<SparkUserInfo>;
  subscriptions?: EntireFieldWrapper<Maybe<Subscriptions>>;
  url?: EntireFieldWrapper<Maybe<Scalars['String']>>;
};

export type UserDownloadsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<DownloadsOrderBy>;
};

export type UserFlags = {
  __typename?: 'UserFlags';
  hasNewUserNewsletter?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  isCreativeFabrica?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  isOnHoldSubscriber?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  isUserVerificationRequired?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
  upsell?: EntireFieldWrapper<Maybe<Upsell>>;
};

export type ValidateTokenResponse = {
  __typename?: 'ValidateTokenResponse';
  errors?: EntireFieldWrapper<Maybe<Array<ErrorMessage>>>;
  valid?: EntireFieldWrapper<Maybe<Scalars['Boolean']>>;
};

export type AlternativeSlugs = {
  __typename?: 'alternativeSlugs';
  lang: EntireFieldWrapper<Scalars['String']>;
  slug: EntireFieldWrapper<Scalars['String']>;
};

export type CreateSignedUploadUrlMutationVariables = Exact<{
  fileName: Scalars['String'];
}>;

export type CreateSignedUploadUrlMutation = {
  __typename?: 'Mutation';
  createSignedUploadUrl: {
    __typename?: 'CreateSignedUploadUrlResponse';
    url?: string | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  };
};

export type LoginMutationVariables = Exact<{
  input?: InputMaybe<LoginInput>;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'LoginResponse';
    token?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      login?: string | null;
      email: string;
      slug?: string | null;
      url?: string | null;
      nickname?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
      registeredAt?: any | null;
    } | null;
    challenge?: {
      __typename?: 'AuthChallenge';
      otp?: string | null;
      type: ChallengeType;
      text?: string | null;
      email?: string | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout?: {
    __typename?: 'LogoutResponse';
    logout?: { __typename?: 'StatusMessage'; success: boolean; message: string } | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  } | null;
};

export type RegisterMutationVariables = Exact<{
  input?: InputMaybe<RegisterInput>;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register?: {
    __typename?: 'RegisterResponse';
    token?: string | null;
    challenge?: {
      __typename?: 'AuthChallenge';
      type: ChallengeType;
      text?: string | null;
      email?: string | null;
      otp?: string | null;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      login?: string | null;
      email: string;
      slug?: string | null;
      url?: string | null;
      nickname?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
      registeredAt?: any | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  } | null;
};

export type LostPasswordMutationVariables = Exact<{
  input?: InputMaybe<LostPasswordInput>;
}>;

export type LostPasswordMutation = {
  __typename?: 'Mutation';
  lostPassword?: {
    __typename?: 'LostPasswordResponse';
    success: boolean;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  input?: InputMaybe<ResetPasswordInput>;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: {
    __typename?: 'ResetPasswordResponse';
    success: boolean;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  } | null;
};

export type GetMasterFeedQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  idLte?: InputMaybe<Scalars['String']>;
  idGte?: InputMaybe<Scalars['String']>;
}>;

export type GetMasterFeedQuery = {
  __typename?: 'Query';
  getMasterFeed: {
    __typename?: 'SocialFeedResponse';
    posts?: Array<{
      __typename?: 'Post';
      activityId: string;
      postId: string;
      description?: string | null;
      images?: Array<string | null> | null;
      type: PostType;
      time: string;
      verb: string;
      visibility: PostVisibility;
      saved: boolean;
      author: {
        __typename?: 'PublicUserProfile';
        id: string;
        slug?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        displayName?: string | null;
        shopName?: string | null;
        avatarUrl?: string | null;
        isCreativeFabrica?: boolean | null;
        isVerified?: boolean | null;
        isPremium?: boolean | null;
        profileUrls: {
          __typename?: 'PublicUserProfileUrls';
          crafter?: string | null;
          designer?: string | null;
          teacher?: string | null;
          theArtistry?: string | null;
        };
      };
      mentionedProducts?: Array<{
        __typename?: 'Product';
        id: string;
        title: string;
        description: string;
        url: string;
        slug: string;
        image?: string | null;
        status: ProductStatus;
        visibility: ProductVisibility;
        favoriteCount: number;
        rating: number;
        regularPrice: number;
        salePrice: number;
        type: { __typename?: 'ProductType'; id: string; slug: string; title: string };
        category: { __typename?: 'ProductCategory'; id: string; slug: string; title: string };
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      }> | null;
      product?: {
        __typename?: 'Product';
        id: string;
        title: string;
        description: string;
        url: string;
        slug: string;
        image?: string | null;
        status: ProductStatus;
        visibility: ProductVisibility;
        favoriteCount: number;
        rating: number;
        regularPrice: number;
        salePrice: number;
        type: { __typename?: 'ProductType'; id: string; slug: string; title: string };
        category: { __typename?: 'ProductCategory'; id: string; slug: string; title: string };
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      } | null;
      article?: {
        __typename?: 'Article';
        title: string;
        url: string;
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      } | null;
    }> | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  };
};

export type GetMyFeedQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  idLte?: InputMaybe<Scalars['String']>;
  idGte?: InputMaybe<Scalars['String']>;
}>;

export type GetMyFeedQuery = {
  __typename?: 'Query';
  getMyFeed: {
    __typename?: 'SocialFeedResponse';
    posts?: Array<{
      __typename?: 'Post';
      activityId: string;
      postId: string;
      description?: string | null;
      images?: Array<string | null> | null;
      type: PostType;
      time: string;
      verb: string;
      visibility: PostVisibility;
      saved: boolean;
      author: {
        __typename?: 'PublicUserProfile';
        id: string;
        slug?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        displayName?: string | null;
        shopName?: string | null;
        avatarUrl?: string | null;
        isCreativeFabrica?: boolean | null;
        isVerified?: boolean | null;
        isPremium?: boolean | null;
        profileUrls: {
          __typename?: 'PublicUserProfileUrls';
          crafter?: string | null;
          designer?: string | null;
          teacher?: string | null;
          theArtistry?: string | null;
        };
      };
      mentionedProducts?: Array<{
        __typename?: 'Product';
        id: string;
        title: string;
        description: string;
        url: string;
        slug: string;
        image?: string | null;
        status: ProductStatus;
        visibility: ProductVisibility;
        favoriteCount: number;
        rating: number;
        regularPrice: number;
        salePrice: number;
        type: { __typename?: 'ProductType'; id: string; slug: string; title: string };
        category: { __typename?: 'ProductCategory'; id: string; slug: string; title: string };
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      }> | null;
      product?: {
        __typename?: 'Product';
        id: string;
        title: string;
        description: string;
        url: string;
        slug: string;
        image?: string | null;
        status: ProductStatus;
        visibility: ProductVisibility;
        favoriteCount: number;
        rating: number;
        regularPrice: number;
        salePrice: number;
        type: { __typename?: 'ProductType'; id: string; slug: string; title: string };
        category: { __typename?: 'ProductCategory'; id: string; slug: string; title: string };
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      } | null;
      article?: {
        __typename?: 'Article';
        title: string;
        url: string;
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      } | null;
    }> | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  };
};

export type GetUserFeedQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
  userSlug?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  idLte?: InputMaybe<Scalars['String']>;
  idGte?: InputMaybe<Scalars['String']>;
}>;

export type GetUserFeedQuery = {
  __typename?: 'Query';
  getUserFeed: {
    __typename?: 'SocialFeedResponse';
    posts?: Array<{
      __typename?: 'Post';
      activityId: string;
      postId: string;
      description?: string | null;
      images?: Array<string | null> | null;
      type: PostType;
      time: string;
      verb: string;
      visibility: PostVisibility;
      saved: boolean;
      author: {
        __typename?: 'PublicUserProfile';
        id: string;
        slug?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        displayName?: string | null;
        shopName?: string | null;
        avatarUrl?: string | null;
        isCreativeFabrica?: boolean | null;
        isVerified?: boolean | null;
        isPremium?: boolean | null;
        profileUrls: {
          __typename?: 'PublicUserProfileUrls';
          crafter?: string | null;
          designer?: string | null;
          teacher?: string | null;
          theArtistry?: string | null;
        };
      };
      mentionedProducts?: Array<{
        __typename?: 'Product';
        id: string;
        title: string;
        description: string;
        url: string;
        slug: string;
        image?: string | null;
        status: ProductStatus;
        visibility: ProductVisibility;
        favoriteCount: number;
        rating: number;
        regularPrice: number;
        salePrice: number;
        type: { __typename?: 'ProductType'; id: string; slug: string; title: string };
        category: { __typename?: 'ProductCategory'; id: string; slug: string; title: string };
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      }> | null;
      product?: {
        __typename?: 'Product';
        id: string;
        title: string;
        description: string;
        url: string;
        slug: string;
        image?: string | null;
        status: ProductStatus;
        visibility: ProductVisibility;
        favoriteCount: number;
        rating: number;
        regularPrice: number;
        salePrice: number;
        type: { __typename?: 'ProductType'; id: string; slug: string; title: string };
        category: { __typename?: 'ProductCategory'; id: string; slug: string; title: string };
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      } | null;
      article?: {
        __typename?: 'Article';
        title: string;
        url: string;
        author: {
          __typename?: 'PublicUserProfile';
          id: string;
          slug?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          displayName?: string | null;
          shopName?: string | null;
          avatarUrl?: string | null;
          isCreativeFabrica?: boolean | null;
          isVerified?: boolean | null;
          isPremium?: boolean | null;
          profileUrls: {
            __typename?: 'PublicUserProfileUrls';
            crafter?: string | null;
            designer?: string | null;
            teacher?: string | null;
            theArtistry?: string | null;
          };
        };
      } | null;
    }> | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  };
};

export type CreateSparkPromptMutationVariables = Exact<{
  prompt: Scalars['String'];
  ratio: ImageRatio;
  creationType: CreationType;
}>;

export type CreateSparkPromptMutation = {
  __typename?: 'Mutation';
  createSparkPrompt?: {
    __typename?: 'CreateSparkPrompt';
    success: boolean;
    prompt?: {
      __typename?: 'Prompt';
      id: string;
      prompt: string;
      aspectRatio: { __typename?: 'AspectRatio'; type: ImageRatio; value: number };
    } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  } | null;
};

export type CreateSparkCrystallinePromptMutationVariables = Exact<{
  prompt: Scalars['String'];
  ratio: ImageRatio;
  modelTypeVersion: ModelTypeVersion;
}>;

export type CreateSparkCrystallinePromptMutation = {
  __typename?: 'Mutation';
  createSparkCrystallinePrompt?: {
    __typename?: 'CreateSparkCrystallinePrompt';
    success: boolean;
    prompt?: {
      __typename?: 'Prompt';
      id: string;
      prompt: string;
      modelTypeVersion?: ModelTypeVersion | null;
      aspectRatio: { __typename?: 'AspectRatio'; type: ImageRatio; value: number };
    } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  } | null;
};

export type PublicPromptByIdQueryVariables = Exact<{
  promptId: Scalars['String'];
}>;

export type PublicPromptByIdQuery = {
  __typename?: 'Query';
  getPublicPromptById?: {
    __typename?: 'PromptByIdResponse';
    prompt?: {
      __typename?: 'Prompt';
      id: string;
      prompt: string;
      createTime?: any | null;
      coverImage?: { __typename?: 'Image'; height?: number | null; url: string; width?: number | null } | null;
      aspectRatio: { __typename?: 'AspectRatio'; type: ImageRatio; value: number };
      author: {
        __typename?: 'PublicUserProfile';
        firstName?: string | null;
        lastName?: string | null;
        displayName?: string | null;
      };
      images?: Array<
        | {
            __typename: 'PromptImageFailedGenerating';
            error?: PromptImageError | null;
            id: string;
            source: ImageSource;
          }
        | { __typename: 'PromptImageFailedUpscaling'; error?: PromptImageError | null; id: string; source: ImageSource }
        | {
            __typename: 'PromptImageGenerated';
            id: string;
            source: ImageSource;
            image: {
              __typename?: 'PromptImageAsset';
              thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
            };
          }
        | { __typename: 'PromptImageGenerating'; id: string; source: ImageSource }
        | {
            __typename: 'PromptImagePrivateUpscaled';
            id: string;
            source: ImageSource;
            image: {
              __typename?: 'PrivateUpscaledImageAsset';
              thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
              upscaled: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
            };
          }
        | { __typename: 'PromptImagePrivateUpscaling'; id: string; source: ImageSource }
        | {
            __typename: 'PromptImageUpscaled';
            id: string;
            source: ImageSource;
            image: {
              __typename?: 'PrivateUpscaledImageAsset';
              thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
              upscaled: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
            };
            product:
              | { __typename?: 'Bundle'; url: string; status: ProductStatus; visibility: ProductVisibility }
              | { __typename?: 'ClassProduct'; url: string; status: ProductStatus; visibility: ProductVisibility }
              | {
                  __typename?: 'CommunityContentProduct';
                  url: string;
                  status: ProductStatus;
                  visibility: ProductVisibility;
                }
              | { __typename?: 'Product'; url: string; status: ProductStatus; visibility: ProductVisibility };
          }
        | { __typename: 'PromptImageUpscaling'; id: string; source: ImageSource }
      > | null;
    } | null;
  } | null;
};

type PromptImage_PromptImageFailedGenerating_Fragment = {
  __typename: 'PromptImageFailedGenerating';
  error?: PromptImageError | null;
  id: string;
  source: ImageSource;
};

type PromptImage_PromptImageFailedUpscaling_Fragment = {
  __typename: 'PromptImageFailedUpscaling';
  error?: PromptImageError | null;
  id: string;
  source: ImageSource;
};

type PromptImage_PromptImageGenerated_Fragment = {
  __typename: 'PromptImageGenerated';
  id: string;
  source: ImageSource;
  image: {
    __typename?: 'PromptImageAsset';
    thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
  };
};

type PromptImage_PromptImageGenerating_Fragment = {
  __typename: 'PromptImageGenerating';
  id: string;
  source: ImageSource;
};

type PromptImage_PromptImagePrivateUpscaled_Fragment = {
  __typename: 'PromptImagePrivateUpscaled';
  id: string;
  source: ImageSource;
  image: {
    __typename?: 'PrivateUpscaledImageAsset';
    thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
    upscaled: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
  };
};

type PromptImage_PromptImagePrivateUpscaling_Fragment = {
  __typename: 'PromptImagePrivateUpscaling';
  id: string;
  source: ImageSource;
};

type PromptImage_PromptImageUpscaled_Fragment = {
  __typename: 'PromptImageUpscaled';
  id: string;
  source: ImageSource;
  image: {
    __typename?: 'PrivateUpscaledImageAsset';
    thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
    upscaled: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
  };
  product:
    | { __typename?: 'Bundle'; url: string; status: ProductStatus; visibility: ProductVisibility }
    | { __typename?: 'ClassProduct'; url: string; status: ProductStatus; visibility: ProductVisibility }
    | { __typename?: 'CommunityContentProduct'; url: string; status: ProductStatus; visibility: ProductVisibility }
    | { __typename?: 'Product'; url: string; status: ProductStatus; visibility: ProductVisibility };
};

type PromptImage_PromptImageUpscaling_Fragment = {
  __typename: 'PromptImageUpscaling';
  id: string;
  source: ImageSource;
};

export type PromptImageFragment =
  | PromptImage_PromptImageFailedGenerating_Fragment
  | PromptImage_PromptImageFailedUpscaling_Fragment
  | PromptImage_PromptImageGenerated_Fragment
  | PromptImage_PromptImageGenerating_Fragment
  | PromptImage_PromptImagePrivateUpscaled_Fragment
  | PromptImage_PromptImagePrivateUpscaling_Fragment
  | PromptImage_PromptImageUpscaled_Fragment
  | PromptImage_PromptImageUpscaling_Fragment;

export type SparkQueryVariables = Exact<{ [key: string]: never }>;

export type SparkQuery = {
  __typename?: 'Query';
  healthCheck: { __typename?: 'HealthCheckResponse'; success: boolean };
};

export type SparkUserCreditsQueryVariables = Exact<{ [key: string]: never }>;

export type SparkUserCreditsQuery = {
  __typename?: 'Query';
  getSparkUserCredits: {
    __typename?: 'SparkUserCredits';
    balance?: number | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  };
};

export type SparkQueueQueryVariables = Exact<{ [key: string]: never }>;

export type SparkQueueQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'AuthResponse';
    user?: {
      __typename?: 'User';
      spark: {
        __typename?: 'SparkUserInfo';
        queue: {
          __typename?: 'PromptsQueueResponse';
          position?: number | null;
          prompt?: {
            __typename?: 'Prompt';
            id: string;
            prompt: string;
            createTime?: any | null;
            creationType?: CreationType | null;
            modelTypeVersion?: ModelTypeVersion | null;
            aspectRatio: { __typename?: 'AspectRatio'; type: ImageRatio; value: number };
            images?: Array<
              | {
                  __typename: 'PromptImageFailedGenerating';
                  error?: PromptImageError | null;
                  id: string;
                  source: ImageSource;
                }
              | { __typename: 'PromptImageFailedUpscaling'; id: string; source: ImageSource }
              | {
                  __typename: 'PromptImageGenerated';
                  id: string;
                  source: ImageSource;
                  image: {
                    __typename?: 'PromptImageAsset';
                    thumb: { __typename?: 'Image'; url: string };
                    original: { __typename?: 'Image'; url: string };
                  };
                }
              | { __typename: 'PromptImageGenerating'; id: string; source: ImageSource }
              | {
                  __typename: 'PromptImagePrivateUpscaled';
                  id: string;
                  source: ImageSource;
                  image: {
                    __typename?: 'PrivateUpscaledImageAsset';
                    thumb: { __typename?: 'Image'; url: string };
                    original: { __typename?: 'Image'; url: string };
                    upscaled: { __typename?: 'Image'; url: string };
                  };
                }
              | { __typename: 'PromptImagePrivateUpscaling'; id: string; source: ImageSource }
              | {
                  __typename: 'PromptImageUpscaled';
                  upscalingTime?: any | null;
                  submitTime?: any | null;
                  id: string;
                  source: ImageSource;
                  image: {
                    __typename?: 'PrivateUpscaledImageAsset';
                    thumb: { __typename?: 'Image'; url: string };
                    original: { __typename?: 'Image'; url: string };
                    upscaled: { __typename?: 'Image'; url: string };
                  };
                  product:
                    | { __typename?: 'Bundle'; url: string; status: ProductStatus; visibility: ProductVisibility }
                    | { __typename?: 'ClassProduct'; url: string; status: ProductStatus; visibility: ProductVisibility }
                    | {
                        __typename?: 'CommunityContentProduct';
                        url: string;
                        status: ProductStatus;
                        visibility: ProductVisibility;
                      }
                    | { __typename?: 'Product'; url: string; status: ProductStatus; visibility: ProductVisibility };
                }
              | { __typename: 'PromptImageUpscaling'; id: string; source: ImageSource }
            > | null;
          } | null;
          errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
        };
      };
    } | null;
  } | null;
};

export type ProductListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['Int'];
  authorId?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CommunityContentProductFeedResponseOrderBy>;
}>;

export type ProductListQuery = {
  __typename?: 'Query';
  getProductList: {
    __typename?: 'CommunityContentProductFeedResponse';
    data?: Array<{
      __typename?: 'CommunityContentProduct';
      id: string;
      prompt: string;
      favoriteCount: number;
      isUserFavorite: boolean;
      title: string;
      image?: string | null;
      status: ProductStatus;
      creationType: CreationType;
      visibility: ProductVisibility;
      url: string;
      author: {
        __typename?: 'PublicUserProfile';
        slug?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profileUrls: { __typename?: 'PublicUserProfileUrls'; crafter?: string | null };
      };
      imageVariants?: {
        __typename?: 'ImageVariants';
        thumbnail?: {
          __typename?: 'ImageVariant';
          image: string;
          height?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null> | null;
    meta?: {
      __typename?: 'CommunityContentProductFeedMeta';
      offset?: number | null;
      limit?: number | null;
      total?: number | null;
      nextOffset?: number | null;
      orderBy?: CommunityContentProductFeedResponseOrderBy | null;
    } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  };
};

export type PersonalizedCommunityContentProductListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['Int'];
  orderBy?: InputMaybe<CommunityContentProductFeedResponseOrderBy>;
}>;

export type PersonalizedCommunityContentProductListQuery = {
  __typename?: 'Query';
  getPersonalizedCommunityContentProductList: {
    __typename?: 'CommunityContentProductFeedResponse';
    data?: Array<{
      __typename?: 'CommunityContentProduct';
      id: string;
      favoriteCount: number;
      isUserFavorite: boolean;
      title: string;
      image?: string | null;
      status: ProductStatus;
      creationType: CreationType;
      visibility: ProductVisibility;
      url: string;
      prompt: string;
      author: {
        __typename?: 'PublicUserProfile';
        slug?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profileUrls: { __typename?: 'PublicUserProfileUrls'; crafter?: string | null };
      };
      imageVariants?: {
        __typename?: 'ImageVariants';
        thumbnail?: {
          __typename?: 'ImageVariant';
          image: string;
          height?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null> | null;
    meta?: {
      __typename?: 'CommunityContentProductFeedMeta';
      offset?: number | null;
      limit?: number | null;
      total?: number | null;
      nextOffset?: number | null;
      orderBy?: CommunityContentProductFeedResponseOrderBy | null;
    } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  };
};

export type AddFavoriteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type AddFavoriteMutation = {
  __typename?: 'Mutation';
  addFavorite?: {
    __typename?: 'AddFavoriteResponse';
    success: boolean;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  } | null;
};

export type DeleteFavoriteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteFavoriteMutation = {
  __typename?: 'Mutation';
  deleteFavorite?: {
    __typename?: 'DeleteFavoriteResponse';
    success: boolean;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  } | null;
};

export type ProductsByIdQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type ProductsByIdQuery = {
  __typename?: 'Query';
  getProductsById: Array<
    | { __typename?: 'Bundle'; id: string; status: ProductStatus }
    | { __typename?: 'ClassProduct'; id: string; status: ProductStatus }
    | { __typename?: 'CommunityContentProduct'; id: string; status: ProductStatus }
    | { __typename?: 'Product'; id: string; status: ProductStatus }
    | null
  >;
};

export type RemoveSparkPromptMutationVariables = Exact<{
  promptId: Scalars['String'];
}>;

export type RemoveSparkPromptMutation = {
  __typename?: 'Mutation';
  removeSparkPrompt?: {
    __typename?: 'RemoveSparkPrompt';
    success: boolean;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  } | null;
};

export type SparkPromptsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  creationType?: InputMaybe<CreationType>;
  filter?: InputMaybe<PromptsFilter>;
}>;

export type SparkPromptsQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'AuthResponse';
    user?: {
      __typename?: 'User';
      spark: {
        __typename?: 'SparkUserInfo';
        balance: number;
        prompts: {
          __typename?: 'PromptsResponse';
          errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
          pagination?: { __typename?: 'Pagination'; page: number; totalPages: number } | null;
          filter?: {
            __typename?: 'PromptsFilterResponse';
            prompt?: string | null;
            date?: { __typename?: 'DateFilterResponse'; month: Month; year: number } | null;
          } | null;
          items?: Array<{
            __typename?: 'Prompt';
            id: string;
            prompt: string;
            createTime?: any | null;
            creationType?: CreationType | null;
            modelTypeVersion?: ModelTypeVersion | null;
            aspectRatio: { __typename?: 'AspectRatio'; type: ImageRatio; value: number };
            coverImage?: { __typename?: 'Image'; url: string } | null;
            images?: Array<
              | {
                  __typename: 'PromptImageFailedGenerating';
                  error?: PromptImageError | null;
                  id: string;
                  source: ImageSource;
                }
              | { __typename: 'PromptImageFailedUpscaling'; id: string; source: ImageSource }
              | {
                  __typename: 'PromptImageGenerated';
                  id: string;
                  source: ImageSource;
                  image: {
                    __typename?: 'PromptImageAsset';
                    thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                    original: { __typename?: 'Image'; width?: number | null; height?: number | null; url: string };
                  };
                }
              | { __typename: 'PromptImageGenerating'; id: string; source: ImageSource }
              | {
                  __typename: 'PromptImagePrivateUpscaled';
                  id: string;
                  source: ImageSource;
                  image: {
                    __typename?: 'PrivateUpscaledImageAsset';
                    thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                    upscaled: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                    original: { __typename?: 'Image'; width?: number | null; height?: number | null; url: string };
                  };
                }
              | { __typename: 'PromptImagePrivateUpscaling'; id: string; source: ImageSource }
              | {
                  __typename: 'PromptImageUpscaled';
                  upscalingTime?: any | null;
                  submitTime?: any | null;
                  id: string;
                  source: ImageSource;
                  image: {
                    __typename?: 'PrivateUpscaledImageAsset';
                    thumb: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                    upscaled: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                    original: { __typename?: 'Image'; width?: number | null; height?: number | null; url: string };
                  };
                  product:
                    | { __typename?: 'Bundle'; url: string; status: ProductStatus; visibility: ProductVisibility }
                    | { __typename?: 'ClassProduct'; url: string; status: ProductStatus; visibility: ProductVisibility }
                    | {
                        __typename?: 'CommunityContentProduct';
                        url: string;
                        status: ProductStatus;
                        visibility: ProductVisibility;
                      }
                    | { __typename?: 'Product'; url: string; status: ProductStatus; visibility: ProductVisibility };
                }
              | { __typename: 'PromptImageUpscaling'; id: string; source: ImageSource }
            > | null;
          }> | null;
        };
      };
    } | null;
  } | null;
};

export type UpscaleSparkImageMutationVariables = Exact<{
  imageId: Scalars['String'];
  hiddenPrompt?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpscaleSparkImageMutation = {
  __typename?: 'Mutation';
  upscaleSparkImage?: {
    __typename?: 'UpscaleSparkImage';
    success: boolean;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
    image?:
      | { __typename?: 'PromptImageFailedGenerating' }
      | { __typename?: 'PromptImageFailedUpscaling' }
      | { __typename?: 'PromptImageGenerated' }
      | { __typename?: 'PromptImageGenerating' }
      | { __typename?: 'PromptImagePrivateUpscaled' }
      | { __typename?: 'PromptImagePrivateUpscaling' }
      | {
          __typename?: 'PromptImageUpscaled';
          product:
            | { __typename?: 'Bundle'; url: string }
            | { __typename?: 'ClassProduct'; url: string }
            | { __typename?: 'CommunityContentProduct'; url: string }
            | { __typename?: 'Product'; url: string };
        }
      | { __typename?: 'PromptImageUpscaling' }
      | null;
  } | null;
};

export type PrivateUpscaleMutationVariables = Exact<{
  itemId: Scalars['String'];
}>;

export type PrivateUpscaleMutation = {
  __typename?: 'Mutation';
  privateUpscale?: {
    __typename?: 'PrivateUpscaleResponse';
    success: boolean;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
    item?:
      | { __typename?: 'PromptImageFailedGenerating' }
      | { __typename?: 'PromptImageFailedUpscaling' }
      | { __typename?: 'PromptImageGenerated' }
      | { __typename?: 'PromptImageGenerating' }
      | {
          __typename?: 'PromptImagePrivateUpscaled';
          image: {
            __typename?: 'PrivateUpscaledImageAsset';
            original: { __typename?: 'Image'; url: string };
            upscaled: { __typename?: 'Image'; url: string };
          };
        }
      | { __typename?: 'PromptImagePrivateUpscaling' }
      | { __typename?: 'PromptImageUpscaled' }
      | { __typename?: 'PromptImageUpscaling' }
      | null;
  } | null;
};

export type PrivateImagesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  creationType?: InputMaybe<CreationType>;
}>;

export type PrivateImagesQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'AuthResponse';
    user?: {
      __typename?: 'User';
      spark: {
        __typename?: 'SparkUserInfo';
        privateImages: {
          __typename?: 'PrivateImagesResponse';
          errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
          pagination?: { __typename?: 'Pagination'; page: number; totalPages: number } | null;
          items?: Array<{
            __typename?: 'PrivateImage';
            prompt: string;
            creationType?: CreationType | null;
            aspectRatio: { __typename?: 'AspectRatio'; type: ImageRatio; value: number };
            image:
              | { __typename: 'PromptImageFailedGenerating'; id: string; source: ImageSource }
              | { __typename: 'PromptImageFailedUpscaling'; id: string; source: ImageSource }
              | { __typename: 'PromptImageGenerated'; id: string; source: ImageSource }
              | { __typename: 'PromptImageGenerating'; id: string; source: ImageSource }
              | {
                  __typename: 'PromptImagePrivateUpscaled';
                  id: string;
                  source: ImageSource;
                  image: {
                    __typename?: 'PrivateUpscaledImageAsset';
                    original: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                    upscaled: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                  };
                }
              | { __typename: 'PromptImagePrivateUpscaling'; id: string; source: ImageSource }
              | { __typename: 'PromptImageUpscaled'; id: string; source: ImageSource }
              | {
                  __typename: 'PromptImageUpscaling';
                  id: string;
                  source: ImageSource;
                  image: {
                    __typename?: 'PrivateUpscaledImageAsset';
                    original: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                    upscaled: { __typename?: 'Image'; url: string; width?: number | null; height?: number | null };
                  };
                };
          }> | null;
        };
      };
    } | null;
  } | null;
};

export type ImageUpscalerMutationVariables = Exact<{
  imagePath: Scalars['String'];
}>;

export type ImageUpscalerMutation = {
  __typename?: 'Mutation';
  imageUpscaler?: {
    __typename?: 'ImageUpscalerResponse';
    success: boolean;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
    item?:
      | { __typename: 'ImageFailed'; error?: PromptImageError | null; id: string; downloaded?: boolean | null }
      | { __typename: 'ImageFailedUpscaling'; error?: PromptImageError | null; id: string; downloaded?: boolean | null }
      | {
          __typename: 'ImageUpscaled';
          id: string;
          downloaded?: boolean | null;
          image: { __typename?: 'UpscaledImage'; upscaledFile: string };
        }
      | { __typename: 'ImageUpscaling'; id: string; downloaded?: boolean | null }
      | null;
  } | null;
};

export type GetImageUpscalerStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetImageUpscalerStatusQuery = {
  __typename?: 'Query';
  getImageUpscalerStatus?: {
    __typename?: 'ImageUpscalerResponse';
    success: boolean;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
    item?:
      | { __typename: 'ImageFailed'; error?: PromptImageError | null; id: string; downloaded?: boolean | null }
      | { __typename: 'ImageFailedUpscaling'; error?: PromptImageError | null; id: string; downloaded?: boolean | null }
      | {
          __typename: 'ImageUpscaled';
          id: string;
          downloaded?: boolean | null;
          image: { __typename?: 'UpscaledImage'; upscaledFile: string };
        }
      | { __typename: 'ImageUpscaling'; id: string; downloaded?: boolean | null }
      | null;
  } | null;
};

export type CreateImageSignedDownloadUrlMutationVariables = Exact<{
  imageId: Scalars['String'];
}>;

export type CreateImageSignedDownloadUrlMutation = {
  __typename?: 'Mutation';
  createImageSignedDownloadUrl: {
    __typename?: 'CreateImageSignedDownloadUrlResponse';
    url?: string | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  };
};

export type GetContentByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  locale: Language;
  increaseViewsCount: Scalars['Boolean'];
}>;

export type GetContentByIdQuery = {
  __typename?: 'Query';
  getContent?: {
    __typename?: 'Content';
    id: string;
    title: string;
    slug: string;
    downloadsCount: number;
    viewsCount: number;
    type?: ContentType | null;
    categoryId?: string | null;
    authorId?: string | null;
    format: ContentFormat;
    tags?: Array<string | null> | null;
    createdAt: any;
    updatedAt?: any | null;
    thumbnailUrl?: string | null;
    description?: string | null;
    status: ContentStatus;
    alternativeSlugs: Array<{ __typename?: 'alternativeSlugs'; lang: string; slug: string } | null>;
    seo?: { __typename?: 'SEO'; h1: string; title: string; description: string; image_alt: string } | null;
    category?: {
      __typename?: 'ContentCategory';
      id: string;
      name: string;
      slug: string;
      parent_id?: string | null;
      root?: { __typename?: 'ContentCategory'; id: string; slug: string } | null;
    } | null;
    preview?: { __typename?: 'ContentPreview'; desktop: string; mobile: string } | null;
    assets?: Array<{
      __typename?: 'ContentAsset';
      id: string;
      contentId: string;
      type: AssetType;
      variant: AssetVariant;
      sizeBytes: number;
      createdAt: any;
      updatedAt?: any | null;
      label: string;
      resolution?: { __typename?: 'Resolution'; width: number; height: number } | null;
    }> | null;
    linkBlock?: {
      __typename?: 'ContentLinkBlock';
      id: string;
      title: string;
      links?: Array<{ __typename?: 'ContentLinkBlockLink'; anchor: string; url: string }> | null;
    } | null;
  } | null;
};

export type GenerateAssetDownloadLinkMutationVariables = Exact<{
  id: Scalars['ID'];
  assetId: Scalars['ID'];
}>;

export type GenerateAssetDownloadLinkMutation = {
  __typename?: 'Mutation';
  generateAssetDownloadLink: {
    __typename?: 'GenerateAssetDownloadLinkResponse';
    url?: string | null;
    errors?: Array<{
      __typename?: 'ErrorMessage';
      code: ErrorCode;
      message: string;
      fields?: Array<{ __typename?: 'FieldError'; field: string; errors: Array<string> } | null> | null;
    }> | null;
  };
};

export type GetContentCategoryByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  locale: Language;
}>;

export type GetContentCategoryByIdQuery = {
  __typename?: 'Query';
  getContentCategory?: {
    __typename?: 'ContentCategory';
    id: string;
    parent_id?: string | null;
    name: string;
    slug: string;
    content_top?: string | null;
    content_bottom?: string | null;
    alternativeSlugs: Array<{ __typename?: 'alternativeSlugs'; lang: string; slug: string } | null>;
    seo: { __typename?: 'ContentCategorySEO'; title: string; description: string };
    root?: { __typename?: 'ContentCategory'; id: string; slug: string; parent_id?: string | null } | null;
  } | null;
};

export type GetContentCategoriesByIdQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  locale: Language;
}>;

export type GetContentCategoriesByIdQuery = {
  __typename?: 'Query';
  getContentCategories?: Array<{
    __typename?: 'ContentCategory';
    id: string;
    name: string;
    slug: string;
  } | null> | null;
};

export type GetContentCategoriesQueryVariables = Exact<{
  parentId: Scalars['ID'];
  locale: Language;
}>;

export type GetContentCategoriesQuery = {
  __typename?: 'Query';
  listContentCategories?: Array<{ __typename?: 'ContentCategory'; id: string; name: string; slug: string }> | null;
};

export type GetContentLinkBlockQueryVariables = Exact<{
  id: Scalars['ID'];
  locale: Language;
}>;

export type GetContentLinkBlockQuery = {
  __typename?: 'Query';
  getContentLinkBlock?: {
    __typename?: 'ContentLinkBlock';
    id: string;
    title: string;
    links?: Array<{ __typename?: 'ContentLinkBlockLink'; anchor: string; url: string }> | null;
  } | null;
};

export type GetTrendingWordsQueryVariables = Exact<{
  locale: Language;
}>;

export type GetTrendingWordsQuery = {
  __typename?: 'Query';
  getTrendingWords: {
    __typename?: 'GetTrendingWordsResponse';
    words?: Array<string> | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; code: ErrorCode; message: string }> | null;
  };
};

export type PriceCalculationQueryVariables = Exact<{
  targetCurrency: Currency;
  prices: Array<PricesToTransformInput> | PricesToTransformInput;
  match?: InputMaybe<Scalars['Boolean']>;
}>;

export type PriceCalculationQuery = {
  __typename?: 'Query';
  getCalculatedPrices: {
    __typename?: 'GetPriceCalculationsResponse';
    data?: {
      __typename?: 'PriceCalculationsResponse';
      targetCurrency?: Currency | null;
      prices?: Array<{
        __typename?: 'TransformedPricesResponse';
        basePrice?: number | null;
        currencyPrice: number;
      } | null> | null;
    } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  };
};

export type CoinsPageLoggedInQueryVariables = Exact<{ [key: string]: never }>;

export type CoinsPageLoggedInQuery = {
  __typename?: 'Query';
  settings: {
    __typename?: 'Settings';
    coins: {
      __typename?: 'CoinSettings';
      spending?: {
        __typename?: 'CoinSpendingSettings';
        products: { __typename?: 'CoinSpendingProductsSettings'; crafts: number; classes: number; bundles: number };
      } | null;
      rewards?: {
        __typename?: 'CoinRewardsSettings';
        postSocialProductProject: number;
        postProductReview: number;
        registerAccount: number;
      } | null;
      errors?: Array<{ __typename?: 'ErrorMessage'; code: ErrorCode; message: string }> | null;
    };
  };
  bundles: {
    __typename?: 'BundlesResponse';
    items: Array<{
      __typename?: 'Bundle';
      id: string;
      url: string;
      title: string;
      image?: string | null;
      craftsCharacteristic?: string | null;
      fontCharacteristic?: string | null;
      graphicCharacteristic?: string | null;
      type: { __typename?: 'ProductType'; title: string };
      coins?: {
        __typename?: 'ProductCoins';
        spent?: { __typename?: 'CoinTransaction'; id: string; status: CoinTransactionStatus } | null;
      } | null;
    }>;
  };
  me?: {
    __typename?: 'AuthResponse';
    user?: {
      __typename?: 'User';
      subscriptions?: { __typename?: 'Subscriptions'; classes?: boolean | null; crafts?: boolean | null } | null;
      downloads?: {
        __typename?: 'DownloadsResponse';
        items: Array<{ __typename?: 'Download'; id: string; image: string; title: string; url: string; type: string }>;
      } | null;
      coins: {
        __typename?: 'CoinWallet';
        balance: number;
        availableRewards: {
          __typename?: 'CoinAvailableRewards';
          projectPosts?: number | null;
          reviews?: number | null;
        };
        transactions: {
          __typename?: 'CoinTransactionsResponse';
          transactions?: Array<{
            __typename?: 'CoinTransaction';
            id: string;
            type: CoinTransactionType;
            amount: number;
            status: CoinTransactionStatus;
            reason?: string | null;
            origin: {
              __typename?: 'CoinOrigin';
              action: CoinTransactionOrigin;
              product?: { __typename?: 'Product'; title: string; url: string } | null;
              user?: {
                __typename?: 'PublicUserProfile';
                firstName?: string | null;
                lastName?: string | null;
                slug?: string | null;
              } | null;
            };
          } | null> | null;
        };
      };
    } | null;
  } | null;
  popular: {
    __typename?: 'PopularResponse';
    classes?: Array<{
      __typename?: 'ClassProduct';
      id: string;
      url: string;
      title: string;
      image?: string | null;
    }> | null;
    crafts?: Array<{ __typename?: 'Product'; id: string; url: string; title: string; image?: string | null }> | null;
  };
};

export type CoinsPageLoggedOutQueryVariables = Exact<{ [key: string]: never }>;

export type CoinsPageLoggedOutQuery = {
  __typename?: 'Query';
  popular: {
    __typename?: 'PopularResponse';
    classes?: Array<{
      __typename?: 'ClassProduct';
      id: string;
      url: string;
      title: string;
      image?: string | null;
    }> | null;
    crafts?: Array<{ __typename?: 'Product'; id: string; url: string; title: string; image?: string | null }> | null;
  };
};

export type CoinTransactionForProductIdQueryVariables = Exact<{
  productId: Scalars['String'];
}>;

export type CoinTransactionForProductIdQuery = {
  __typename?: 'Query';
  coinTransactionForProductId: {
    __typename?: 'CoinTransactionResponse';
    transaction?: {
      __typename?: 'CoinTransaction';
      id: string;
      type: CoinTransactionType;
      amount: number;
      status: CoinTransactionStatus;
    } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  };
};

export type UpdateCoinTransactionStatusMutationVariables = Exact<{
  id: Scalars['String'];
  status: CoinTransactionStatus;
}>;

export type UpdateCoinTransactionStatusMutation = {
  __typename?: 'Mutation';
  updateCoinTransactionStatus: {
    __typename?: 'UpdateCoinTransactionStatusResponse';
    transaction?: { __typename?: 'CoinTransaction'; id: string; status: CoinTransactionStatus } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  };
};

export type SpendCoinsOnProductMutationVariables = Exact<{
  productId: Scalars['String'];
}>;

export type SpendCoinsOnProductMutation = {
  __typename?: 'Mutation';
  spendCoinsOnProduct: {
    __typename?: 'SpendCoinsResponse';
    transaction?: { __typename?: 'CoinTransaction'; id: string } | null;
    errors?: Array<{ __typename?: 'ErrorMessage'; message: string }> | null;
  };
};

export type CoinsPageTransactionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type CoinsPageTransactionsQuery = {
  __typename?: 'Query';
  settings: {
    __typename?: 'Settings';
    coins: {
      __typename?: 'CoinSettings';
      rewards?: {
        __typename?: 'CoinRewardsSettings';
        postProductReview: number;
        postSocialProductProject: number;
      } | null;
    };
  };
  me?: {
    __typename?: 'AuthResponse';
    user?: {
      __typename?: 'User';
      coins: {
        __typename?: 'CoinWallet';
        transactions: {
          __typename?: 'CoinTransactionsResponse';
          pagination?: { __typename?: 'Pagination'; itemsPerPage: number; page: number; totalPages: number } | null;
          transactions?: Array<{
            __typename?: 'CoinTransaction';
            id: string;
            type: CoinTransactionType;
            amount: number;
            status: CoinTransactionStatus;
            reason?: string | null;
            origin: {
              __typename?: 'CoinOrigin';
              action: CoinTransactionOrigin;
              product?: { __typename?: 'Product'; title: string; url: string } | null;
              user?: {
                __typename?: 'PublicUserProfile';
                firstName?: string | null;
                lastName?: string | null;
                slug?: string | null;
              } | null;
            };
          } | null> | null;
        };
      };
    } | null;
  } | null;
};

export const PromptImageFragmentDoc = `
    fragment PromptImage on IPromptImage {
  __typename
  id
  source
  ... on PromptImageFailedGenerating {
    error
  }
  ... on PromptImageFailedUpscaling {
    error
  }
  ... on PromptImageUpscaled {
    image {
      thumb {
        url
        width
        height
      }
      upscaled {
        url
        width
        height
      }
    }
    product {
      url
      status
      visibility
    }
  }
  ... on PromptImageGenerated {
    image {
      thumb {
        url
        width
        height
      }
    }
  }
  ... on PromptImagePrivateUpscaled {
    image {
      thumb {
        url
        width
        height
      }
      upscaled {
        url
        width
        height
      }
    }
  }
}
    `;
export const CreateSignedUploadUrlDocument = `
    mutation CreateSignedUploadUrl($fileName: String!) {
  createSignedUploadUrl(fileName: $fileName) {
    url
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useCreateSignedUploadUrlMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateSignedUploadUrlMutation, TError, CreateSignedUploadUrlMutationVariables, TContext>,
) =>
  useMutation<CreateSignedUploadUrlMutation, TError, CreateSignedUploadUrlMutationVariables, TContext>(
    ['CreateSignedUploadUrl'],
    (variables?: CreateSignedUploadUrlMutationVariables) =>
      mutationFetcher<CreateSignedUploadUrlMutation, CreateSignedUploadUrlMutationVariables>(
        CreateSignedUploadUrlDocument,
        variables,
      )(),
    options,
  );
useCreateSignedUploadUrlMutation.fetcher = (
  variables: CreateSignedUploadUrlMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<CreateSignedUploadUrlMutation, CreateSignedUploadUrlMutationVariables>(
    CreateSignedUploadUrlDocument,
    variables,
    options,
  );
export const LoginDocument = `
    mutation login($input: LoginInput) {
  login(input: $input) {
    token
    user {
      id
      login
      email
      slug
      url
      nickname
      firstName
      lastName
      avatarUrl
      registeredAt
    }
    challenge {
      otp
      type
      text
      email
    }
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useLoginMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>,
) =>
  useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
    ['login'],
    (variables?: LoginMutationVariables) =>
      mutationFetcher<LoginMutation, LoginMutationVariables>(LoginDocument, variables)(),
    options,
  );
useLoginMutation.fetcher = (variables?: LoginMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<LoginMutation, LoginMutationVariables>(LoginDocument, variables, options);
export const LogoutDocument = `
    mutation logout {
  logout {
    logout {
      success
      message
    }
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useLogoutMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>,
) =>
  useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
    ['logout'],
    (variables?: LogoutMutationVariables) =>
      mutationFetcher<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables)(),
    options,
  );
useLogoutMutation.fetcher = (variables?: LogoutMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables, options);
export const RegisterDocument = `
    mutation register($input: RegisterInput) {
  register(input: $input) {
    challenge {
      type
      text
      email
      otp
    }
    token
    user {
      id
      login
      email
      slug
      url
      nickname
      firstName
      lastName
      avatarUrl
      registeredAt
    }
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useRegisterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<RegisterMutation, TError, RegisterMutationVariables, TContext>,
) =>
  useMutation<RegisterMutation, TError, RegisterMutationVariables, TContext>(
    ['register'],
    (variables?: RegisterMutationVariables) =>
      mutationFetcher<RegisterMutation, RegisterMutationVariables>(RegisterDocument, variables)(),
    options,
  );
useRegisterMutation.fetcher = (variables?: RegisterMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<RegisterMutation, RegisterMutationVariables>(RegisterDocument, variables, options);
export const LostPasswordDocument = `
    mutation lostPassword($input: LostPasswordInput) {
  lostPassword(input: $input) {
    success
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useLostPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<LostPasswordMutation, TError, LostPasswordMutationVariables, TContext>,
) =>
  useMutation<LostPasswordMutation, TError, LostPasswordMutationVariables, TContext>(
    ['lostPassword'],
    (variables?: LostPasswordMutationVariables) =>
      mutationFetcher<LostPasswordMutation, LostPasswordMutationVariables>(LostPasswordDocument, variables)(),
    options,
  );
useLostPasswordMutation.fetcher = (variables?: LostPasswordMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<LostPasswordMutation, LostPasswordMutationVariables>(LostPasswordDocument, variables, options);
export const ResetPasswordDocument = `
    mutation resetPassword($input: ResetPasswordInput) {
  resetPassword(input: $input) {
    success
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useResetPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>,
) =>
  useMutation<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>(
    ['resetPassword'],
    (variables?: ResetPasswordMutationVariables) =>
      mutationFetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, variables)(),
    options,
  );
useResetPasswordMutation.fetcher = (variables?: ResetPasswordMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, variables, options);
export const GetMasterFeedDocument = `
    query getMasterFeed($offset: Int, $limit: Int, $idLte: String, $idGte: String) {
  getMasterFeed(offset: $offset, limit: $limit, idLte: $idLte, idGte: $idGte) {
    posts {
      activityId
      postId
      author {
        id
        slug
        firstName
        lastName
        displayName
        shopName
        avatarUrl
        isCreativeFabrica
        isVerified
        isPremium
        profileUrls {
          crafter
          designer
          teacher
          theArtistry
        }
      }
      description
      images
      type
      time
      verb
      visibility
      saved
      mentionedProducts {
        id
        title
        description
        url
        slug
        image
        status
        visibility
        type {
          id
          slug
          title
        }
        category {
          id
          slug
          title
        }
        favoriteCount
        rating
        regularPrice
        salePrice
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
      product {
        id
        title
        description
        url
        slug
        image
        status
        visibility
        type {
          id
          slug
          title
        }
        category {
          id
          slug
          title
        }
        favoriteCount
        rating
        regularPrice
        salePrice
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
      article {
        title
        url
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
    }
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useGetMasterFeedQuery = <TData = GetMasterFeedQuery, TError = unknown>(
  variables?: GetMasterFeedQueryVariables,
  options?: UseQueryOptions<GetMasterFeedQuery, TError, TData>,
) =>
  useQuery<GetMasterFeedQuery, TError, TData>(
    variables === undefined ? ['getMasterFeed'] : ['getMasterFeed', variables],
    mutationFetcher<GetMasterFeedQuery, GetMasterFeedQueryVariables>(GetMasterFeedDocument, variables),
    options,
  );
export const useInfiniteGetMasterFeedQuery = <TData = GetMasterFeedQuery, TError = unknown>(
  pageParamKey: keyof GetMasterFeedQueryVariables,
  variables?: GetMasterFeedQueryVariables,
  options?: UseInfiniteQueryOptions<GetMasterFeedQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetMasterFeedQuery, TError, TData>(
    variables === undefined ? ['getMasterFeed.infinite'] : ['getMasterFeed.infinite', variables],
    metaData =>
      mutationFetcher<GetMasterFeedQuery, GetMasterFeedQueryVariables>(GetMasterFeedDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteGetMasterFeedQuery.fetcher = (variables?: GetMasterFeedQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<GetMasterFeedQuery, GetMasterFeedQueryVariables>(
    GetMasterFeedDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetMasterFeedQuery.prefetch = (
  variables?: GetMasterFeedQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['getMasterFeed.infinite'] : ['getMasterFeed.infinite', variables],
    useGetMasterFeedQuery.fetcher(variables, options),
  ];
};
useInfiniteGetMasterFeedQuery.key = (variables?: GetMasterFeedQueryVariables) =>
  variables === undefined ? ['getMasterFeed.infinite'] : ['getMasterFeed.infinite', variables];
useGetMasterFeedQuery.fetcher = (variables?: GetMasterFeedQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<GetMasterFeedQuery, GetMasterFeedQueryVariables>(GetMasterFeedDocument, variables, options);
useGetMasterFeedQuery.prefetch = (
  variables?: GetMasterFeedQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['getMasterFeed'] : ['getMasterFeed', variables],
    useGetMasterFeedQuery.fetcher(variables, options),
  ];
};
useGetMasterFeedQuery.key = (variables?: GetMasterFeedQueryVariables) =>
  variables === undefined ? ['getMasterFeed'] : ['getMasterFeed', variables];
export const GetMyFeedDocument = `
    query getMyFeed($offset: Int, $limit: Int, $idLte: String, $idGte: String) {
  getMyFeed(offset: $offset, limit: $limit, idLte: $idLte, idGte: $idGte) {
    posts {
      activityId
      postId
      author {
        id
        slug
        firstName
        lastName
        displayName
        shopName
        avatarUrl
        isCreativeFabrica
        isVerified
        isPremium
        profileUrls {
          crafter
          designer
          teacher
          theArtistry
        }
      }
      description
      images
      type
      time
      verb
      visibility
      saved
      mentionedProducts {
        id
        title
        description
        url
        slug
        image
        status
        visibility
        type {
          id
          slug
          title
        }
        category {
          id
          slug
          title
        }
        favoriteCount
        rating
        regularPrice
        salePrice
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
      product {
        id
        title
        description
        url
        slug
        image
        status
        visibility
        type {
          id
          slug
          title
        }
        category {
          id
          slug
          title
        }
        favoriteCount
        rating
        regularPrice
        salePrice
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
      article {
        title
        url
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
    }
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useGetMyFeedQuery = <TData = GetMyFeedQuery, TError = unknown>(
  variables?: GetMyFeedQueryVariables,
  options?: UseQueryOptions<GetMyFeedQuery, TError, TData>,
) =>
  useQuery<GetMyFeedQuery, TError, TData>(
    variables === undefined ? ['getMyFeed'] : ['getMyFeed', variables],
    mutationFetcher<GetMyFeedQuery, GetMyFeedQueryVariables>(GetMyFeedDocument, variables),
    options,
  );
export const useInfiniteGetMyFeedQuery = <TData = GetMyFeedQuery, TError = unknown>(
  pageParamKey: keyof GetMyFeedQueryVariables,
  variables?: GetMyFeedQueryVariables,
  options?: UseInfiniteQueryOptions<GetMyFeedQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetMyFeedQuery, TError, TData>(
    variables === undefined ? ['getMyFeed.infinite'] : ['getMyFeed.infinite', variables],
    metaData =>
      mutationFetcher<GetMyFeedQuery, GetMyFeedQueryVariables>(GetMyFeedDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteGetMyFeedQuery.fetcher = (variables?: GetMyFeedQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<GetMyFeedQuery, GetMyFeedQueryVariables>(
    GetMyFeedDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetMyFeedQuery.prefetch = (
  variables?: GetMyFeedQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['getMyFeed.infinite'] : ['getMyFeed.infinite', variables],
    useGetMyFeedQuery.fetcher(variables, options),
  ];
};
useInfiniteGetMyFeedQuery.key = (variables?: GetMyFeedQueryVariables) =>
  variables === undefined ? ['getMyFeed.infinite'] : ['getMyFeed.infinite', variables];
useGetMyFeedQuery.fetcher = (variables?: GetMyFeedQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<GetMyFeedQuery, GetMyFeedQueryVariables>(GetMyFeedDocument, variables, options);
useGetMyFeedQuery.prefetch = (
  variables?: GetMyFeedQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['getMyFeed'] : ['getMyFeed', variables],
    useGetMyFeedQuery.fetcher(variables, options),
  ];
};
useGetMyFeedQuery.key = (variables?: GetMyFeedQueryVariables) =>
  variables === undefined ? ['getMyFeed'] : ['getMyFeed', variables];
export const GetUserFeedDocument = `
    query getUserFeed($userId: String, $userSlug: String, $offset: Int, $limit: Int, $idLte: String, $idGte: String) {
  getUserFeed(
    userId: $userId
    userSlug: $userSlug
    offset: $offset
    limit: $limit
    idLte: $idLte
    idGte: $idGte
  ) {
    posts {
      activityId
      postId
      author {
        id
        slug
        firstName
        lastName
        displayName
        shopName
        avatarUrl
        isCreativeFabrica
        isVerified
        isPremium
        profileUrls {
          crafter
          designer
          teacher
          theArtistry
        }
      }
      description
      images
      type
      time
      verb
      visibility
      saved
      mentionedProducts {
        id
        title
        description
        url
        slug
        image
        status
        visibility
        type {
          id
          slug
          title
        }
        category {
          id
          slug
          title
        }
        favoriteCount
        rating
        regularPrice
        salePrice
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
      product {
        id
        title
        description
        url
        slug
        image
        status
        visibility
        type {
          id
          slug
          title
        }
        category {
          id
          slug
          title
        }
        favoriteCount
        rating
        regularPrice
        salePrice
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
      article {
        title
        url
        author {
          id
          slug
          firstName
          lastName
          displayName
          shopName
          avatarUrl
          isCreativeFabrica
          isVerified
          isPremium
          profileUrls {
            crafter
            designer
            teacher
            theArtistry
          }
        }
      }
    }
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useGetUserFeedQuery = <TData = GetUserFeedQuery, TError = unknown>(
  variables?: GetUserFeedQueryVariables,
  options?: UseQueryOptions<GetUserFeedQuery, TError, TData>,
) =>
  useQuery<GetUserFeedQuery, TError, TData>(
    variables === undefined ? ['getUserFeed'] : ['getUserFeed', variables],
    mutationFetcher<GetUserFeedQuery, GetUserFeedQueryVariables>(GetUserFeedDocument, variables),
    options,
  );
export const useInfiniteGetUserFeedQuery = <TData = GetUserFeedQuery, TError = unknown>(
  pageParamKey: keyof GetUserFeedQueryVariables,
  variables?: GetUserFeedQueryVariables,
  options?: UseInfiniteQueryOptions<GetUserFeedQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetUserFeedQuery, TError, TData>(
    variables === undefined ? ['getUserFeed.infinite'] : ['getUserFeed.infinite', variables],
    metaData =>
      mutationFetcher<GetUserFeedQuery, GetUserFeedQueryVariables>(GetUserFeedDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteGetUserFeedQuery.fetcher = (variables?: GetUserFeedQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<GetUserFeedQuery, GetUserFeedQueryVariables>(
    GetUserFeedDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetUserFeedQuery.prefetch = (
  variables?: GetUserFeedQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['getUserFeed.infinite'] : ['getUserFeed.infinite', variables],
    useGetUserFeedQuery.fetcher(variables, options),
  ];
};
useInfiniteGetUserFeedQuery.key = (variables?: GetUserFeedQueryVariables) =>
  variables === undefined ? ['getUserFeed.infinite'] : ['getUserFeed.infinite', variables];
useGetUserFeedQuery.fetcher = (variables?: GetUserFeedQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<GetUserFeedQuery, GetUserFeedQueryVariables>(GetUserFeedDocument, variables, options);
useGetUserFeedQuery.prefetch = (
  variables?: GetUserFeedQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['getUserFeed'] : ['getUserFeed', variables],
    useGetUserFeedQuery.fetcher(variables, options),
  ];
};
useGetUserFeedQuery.key = (variables?: GetUserFeedQueryVariables) =>
  variables === undefined ? ['getUserFeed'] : ['getUserFeed', variables];
export const CreateSparkPromptDocument = `
    mutation CreateSparkPrompt($prompt: String!, $ratio: ImageRatio!, $creationType: CreationType!) {
  createSparkPrompt(prompt: $prompt, ratio: $ratio, creationType: $creationType) {
    success
    prompt {
      id
      prompt
      aspectRatio {
        type
        value
      }
    }
    errors {
      message
    }
  }
}
    `;
export const useCreateSparkPromptMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CreateSparkPromptMutation, TError, CreateSparkPromptMutationVariables, TContext>,
) =>
  useMutation<CreateSparkPromptMutation, TError, CreateSparkPromptMutationVariables, TContext>(
    ['CreateSparkPrompt'],
    (variables?: CreateSparkPromptMutationVariables) =>
      mutationFetcher<CreateSparkPromptMutation, CreateSparkPromptMutationVariables>(
        CreateSparkPromptDocument,
        variables,
      )(),
    options,
  );
useCreateSparkPromptMutation.fetcher = (
  variables: CreateSparkPromptMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<CreateSparkPromptMutation, CreateSparkPromptMutationVariables>(
    CreateSparkPromptDocument,
    variables,
    options,
  );
export const CreateSparkCrystallinePromptDocument = `
    mutation CreateSparkCrystallinePrompt($prompt: String!, $ratio: ImageRatio!, $modelTypeVersion: ModelTypeVersion!) {
  createSparkCrystallinePrompt(
    prompt: $prompt
    ratio: $ratio
    modelTypeVersion: $modelTypeVersion
  ) {
    success
    prompt {
      id
      prompt
      modelTypeVersion
      aspectRatio {
        type
        value
      }
    }
    errors {
      message
    }
  }
}
    `;
export const useCreateSparkCrystallinePromptMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateSparkCrystallinePromptMutation,
    TError,
    CreateSparkCrystallinePromptMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateSparkCrystallinePromptMutation, TError, CreateSparkCrystallinePromptMutationVariables, TContext>(
    ['CreateSparkCrystallinePrompt'],
    (variables?: CreateSparkCrystallinePromptMutationVariables) =>
      mutationFetcher<CreateSparkCrystallinePromptMutation, CreateSparkCrystallinePromptMutationVariables>(
        CreateSparkCrystallinePromptDocument,
        variables,
      )(),
    options,
  );
useCreateSparkCrystallinePromptMutation.fetcher = (
  variables: CreateSparkCrystallinePromptMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<CreateSparkCrystallinePromptMutation, CreateSparkCrystallinePromptMutationVariables>(
    CreateSparkCrystallinePromptDocument,
    variables,
    options,
  );
export const PublicPromptByIdDocument = `
    query PublicPromptById($promptId: String!) {
  getPublicPromptById(promptId: $promptId) {
    prompt {
      id
      prompt
      createTime
      coverImage {
        height
        url
        width
      }
      aspectRatio {
        type
        value
      }
      author {
        firstName
        lastName
        displayName
      }
      images {
        ...PromptImage
      }
    }
  }
}
    ${PromptImageFragmentDoc}`;
export const usePublicPromptByIdQuery = <TData = PublicPromptByIdQuery, TError = unknown>(
  variables: PublicPromptByIdQueryVariables,
  options?: UseQueryOptions<PublicPromptByIdQuery, TError, TData>,
) =>
  useQuery<PublicPromptByIdQuery, TError, TData>(
    ['PublicPromptById', variables],
    mutationFetcher<PublicPromptByIdQuery, PublicPromptByIdQueryVariables>(PublicPromptByIdDocument, variables),
    options,
  );
export const useInfinitePublicPromptByIdQuery = <TData = PublicPromptByIdQuery, TError = unknown>(
  pageParamKey: keyof PublicPromptByIdQueryVariables,
  variables: PublicPromptByIdQueryVariables,
  options?: UseInfiniteQueryOptions<PublicPromptByIdQuery, TError, TData>,
) => {
  return useInfiniteQuery<PublicPromptByIdQuery, TError, TData>(
    ['PublicPromptById.infinite', variables],
    metaData =>
      mutationFetcher<PublicPromptByIdQuery, PublicPromptByIdQueryVariables>(PublicPromptByIdDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfinitePublicPromptByIdQuery.fetcher = (
  variables: PublicPromptByIdQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<PublicPromptByIdQuery, PublicPromptByIdQueryVariables>(
    PublicPromptByIdDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfinitePublicPromptByIdQuery.prefetch = (
  variables: PublicPromptByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['PublicPromptById.infinite', variables], usePublicPromptByIdQuery.fetcher(variables, options)];
};
useInfinitePublicPromptByIdQuery.key = (variables: PublicPromptByIdQueryVariables) => [
  'PublicPromptById.infinite',
  variables,
];
usePublicPromptByIdQuery.fetcher = (variables: PublicPromptByIdQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<PublicPromptByIdQuery, PublicPromptByIdQueryVariables>(PublicPromptByIdDocument, variables, options);
usePublicPromptByIdQuery.prefetch = (
  variables: PublicPromptByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['PublicPromptById', variables], usePublicPromptByIdQuery.fetcher(variables, options)];
};
usePublicPromptByIdQuery.key = (variables: PublicPromptByIdQueryVariables) => ['PublicPromptById', variables];
export const SparkDocument = `
    query Spark {
  healthCheck {
    success
  }
}
    `;
export const useSparkQuery = <TData = SparkQuery, TError = unknown>(
  variables?: SparkQueryVariables,
  options?: UseQueryOptions<SparkQuery, TError, TData>,
) =>
  useQuery<SparkQuery, TError, TData>(
    variables === undefined ? ['Spark'] : ['Spark', variables],
    mutationFetcher<SparkQuery, SparkQueryVariables>(SparkDocument, variables),
    options,
  );
export const useInfiniteSparkQuery = <TData = SparkQuery, TError = unknown>(
  pageParamKey: keyof SparkQueryVariables,
  variables?: SparkQueryVariables,
  options?: UseInfiniteQueryOptions<SparkQuery, TError, TData>,
) => {
  return useInfiniteQuery<SparkQuery, TError, TData>(
    variables === undefined ? ['Spark.infinite'] : ['Spark.infinite', variables],
    metaData =>
      mutationFetcher<SparkQuery, SparkQueryVariables>(SparkDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteSparkQuery.fetcher = (variables?: SparkQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<SparkQuery, SparkQueryVariables>(
    SparkDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteSparkQuery.prefetch = (
  variables?: SparkQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['Spark.infinite'] : ['Spark.infinite', variables],
    useSparkQuery.fetcher(variables, options),
  ];
};
useInfiniteSparkQuery.key = (variables?: SparkQueryVariables) =>
  variables === undefined ? ['Spark.infinite'] : ['Spark.infinite', variables];
useSparkQuery.fetcher = (variables?: SparkQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<SparkQuery, SparkQueryVariables>(SparkDocument, variables, options);
useSparkQuery.prefetch = (
  variables?: SparkQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [variables === undefined ? ['Spark'] : ['Spark', variables], useSparkQuery.fetcher(variables, options)];
};
useSparkQuery.key = (variables?: SparkQueryVariables) => (variables === undefined ? ['Spark'] : ['Spark', variables]);
export const SparkUserCreditsDocument = `
    query SparkUserCredits {
  getSparkUserCredits {
    balance
    errors {
      message
    }
  }
}
    `;
export const useSparkUserCreditsQuery = <TData = SparkUserCreditsQuery, TError = unknown>(
  variables?: SparkUserCreditsQueryVariables,
  options?: UseQueryOptions<SparkUserCreditsQuery, TError, TData>,
) =>
  useQuery<SparkUserCreditsQuery, TError, TData>(
    variables === undefined ? ['SparkUserCredits'] : ['SparkUserCredits', variables],
    mutationFetcher<SparkUserCreditsQuery, SparkUserCreditsQueryVariables>(SparkUserCreditsDocument, variables),
    options,
  );
export const useInfiniteSparkUserCreditsQuery = <TData = SparkUserCreditsQuery, TError = unknown>(
  pageParamKey: keyof SparkUserCreditsQueryVariables,
  variables?: SparkUserCreditsQueryVariables,
  options?: UseInfiniteQueryOptions<SparkUserCreditsQuery, TError, TData>,
) => {
  return useInfiniteQuery<SparkUserCreditsQuery, TError, TData>(
    variables === undefined ? ['SparkUserCredits.infinite'] : ['SparkUserCredits.infinite', variables],
    metaData =>
      mutationFetcher<SparkUserCreditsQuery, SparkUserCreditsQueryVariables>(SparkUserCreditsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteSparkUserCreditsQuery.fetcher = (
  variables?: SparkUserCreditsQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<SparkUserCreditsQuery, SparkUserCreditsQueryVariables>(
    SparkUserCreditsDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteSparkUserCreditsQuery.prefetch = (
  variables?: SparkUserCreditsQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['SparkUserCredits.infinite'] : ['SparkUserCredits.infinite', variables],
    useSparkUserCreditsQuery.fetcher(variables, options),
  ];
};
useInfiniteSparkUserCreditsQuery.key = (variables?: SparkUserCreditsQueryVariables) =>
  variables === undefined ? ['SparkUserCredits.infinite'] : ['SparkUserCredits.infinite', variables];
useSparkUserCreditsQuery.fetcher = (variables?: SparkUserCreditsQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<SparkUserCreditsQuery, SparkUserCreditsQueryVariables>(SparkUserCreditsDocument, variables, options);
useSparkUserCreditsQuery.prefetch = (
  variables?: SparkUserCreditsQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['SparkUserCredits'] : ['SparkUserCredits', variables],
    useSparkUserCreditsQuery.fetcher(variables, options),
  ];
};
useSparkUserCreditsQuery.key = (variables?: SparkUserCreditsQueryVariables) =>
  variables === undefined ? ['SparkUserCredits'] : ['SparkUserCredits', variables];
export const SparkQueueDocument = `
    query SparkQueue {
  me {
    user {
      spark {
        queue {
          position
          prompt {
            id
            prompt
            aspectRatio {
              type
              value
            }
            createTime
            creationType
            modelTypeVersion
            images {
              __typename
              id
              source
              ... on PromptImageFailedGenerating {
                error
              }
              ... on PromptImageUpscaled {
                upscalingTime
                submitTime
                image {
                  thumb {
                    url
                  }
                  original {
                    url
                  }
                  upscaled {
                    url
                  }
                }
                product {
                  url
                  status
                  visibility
                }
              }
              ... on PromptImageGenerated {
                image {
                  thumb {
                    url
                  }
                  original {
                    url
                  }
                }
              }
              ... on PromptImagePrivateUpscaled {
                image {
                  thumb {
                    url
                  }
                  original {
                    url
                  }
                  upscaled {
                    url
                  }
                }
              }
            }
          }
          errors {
            message
          }
        }
      }
    }
  }
}
    `;
export const useSparkQueueQuery = <TData = SparkQueueQuery, TError = unknown>(
  variables?: SparkQueueQueryVariables,
  options?: UseQueryOptions<SparkQueueQuery, TError, TData>,
) =>
  useQuery<SparkQueueQuery, TError, TData>(
    variables === undefined ? ['SparkQueue'] : ['SparkQueue', variables],
    mutationFetcher<SparkQueueQuery, SparkQueueQueryVariables>(SparkQueueDocument, variables),
    options,
  );
export const useInfiniteSparkQueueQuery = <TData = SparkQueueQuery, TError = unknown>(
  pageParamKey: keyof SparkQueueQueryVariables,
  variables?: SparkQueueQueryVariables,
  options?: UseInfiniteQueryOptions<SparkQueueQuery, TError, TData>,
) => {
  return useInfiniteQuery<SparkQueueQuery, TError, TData>(
    variables === undefined ? ['SparkQueue.infinite'] : ['SparkQueue.infinite', variables],
    metaData =>
      mutationFetcher<SparkQueueQuery, SparkQueueQueryVariables>(SparkQueueDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteSparkQueueQuery.fetcher = (variables?: SparkQueueQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<SparkQueueQuery, SparkQueueQueryVariables>(
    SparkQueueDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteSparkQueueQuery.prefetch = (
  variables?: SparkQueueQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['SparkQueue.infinite'] : ['SparkQueue.infinite', variables],
    useSparkQueueQuery.fetcher(variables, options),
  ];
};
useInfiniteSparkQueueQuery.key = (variables?: SparkQueueQueryVariables) =>
  variables === undefined ? ['SparkQueue.infinite'] : ['SparkQueue.infinite', variables];
useSparkQueueQuery.fetcher = (variables?: SparkQueueQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<SparkQueueQuery, SparkQueueQueryVariables>(SparkQueueDocument, variables, options);
useSparkQueueQuery.prefetch = (
  variables?: SparkQueueQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['SparkQueue'] : ['SparkQueue', variables],
    useSparkQueueQuery.fetcher(variables, options),
  ];
};
useSparkQueueQuery.key = (variables?: SparkQueueQueryVariables) =>
  variables === undefined ? ['SparkQueue'] : ['SparkQueue', variables];
export const ProductListDocument = `
    query ProductList($offset: Int, $limit: Int, $categoryId: Int!, $authorId: Int, $orderBy: CommunityContentProductFeedResponseOrderBy) {
  getProductList(
    offset: $offset
    limit: $limit
    categoryId: $categoryId
    authorId: $authorId
    orderBy: $orderBy
  ) {
    data {
      id
      author {
        slug
        firstName
        lastName
        profileUrls {
          crafter
        }
      }
      prompt
      favoriteCount
      isUserFavorite
      title
      image
      status
      creationType
      visibility
      url
      imageVariants {
        thumbnail {
          image
          height
          width
        }
      }
    }
    meta {
      offset
      limit
      total
      nextOffset
      orderBy
    }
    errors {
      message
    }
  }
}
    `;
export const useProductListQuery = <TData = ProductListQuery, TError = unknown>(
  variables: ProductListQueryVariables,
  options?: UseQueryOptions<ProductListQuery, TError, TData>,
) =>
  useQuery<ProductListQuery, TError, TData>(
    ['ProductList', variables],
    mutationFetcher<ProductListQuery, ProductListQueryVariables>(ProductListDocument, variables),
    options,
  );
export const useInfiniteProductListQuery = <TData = ProductListQuery, TError = unknown>(
  pageParamKey: keyof ProductListQueryVariables,
  variables: ProductListQueryVariables,
  options?: UseInfiniteQueryOptions<ProductListQuery, TError, TData>,
) => {
  return useInfiniteQuery<ProductListQuery, TError, TData>(
    ['ProductList.infinite', variables],
    metaData =>
      mutationFetcher<ProductListQuery, ProductListQueryVariables>(ProductListDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteProductListQuery.fetcher = (variables: ProductListQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<ProductListQuery, ProductListQueryVariables>(
    ProductListDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteProductListQuery.prefetch = (
  variables: ProductListQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['ProductList.infinite', variables], useProductListQuery.fetcher(variables, options)];
};
useInfiniteProductListQuery.key = (variables: ProductListQueryVariables) => ['ProductList.infinite', variables];
useProductListQuery.fetcher = (variables: ProductListQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<ProductListQuery, ProductListQueryVariables>(ProductListDocument, variables, options);
useProductListQuery.prefetch = (
  variables: ProductListQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['ProductList', variables], useProductListQuery.fetcher(variables, options)];
};
useProductListQuery.key = (variables: ProductListQueryVariables) => ['ProductList', variables];
export const PersonalizedCommunityContentProductListDocument = `
    query PersonalizedCommunityContentProductList($offset: Int, $limit: Int, $categoryId: Int!, $orderBy: CommunityContentProductFeedResponseOrderBy) {
  getPersonalizedCommunityContentProductList(
    offset: $offset
    limit: $limit
    categoryId: $categoryId
    orderBy: $orderBy
  ) {
    data {
      id
      author {
        slug
        firstName
        lastName
        profileUrls {
          crafter
        }
      }
      favoriteCount
      isUserFavorite
      title
      image
      status
      creationType
      visibility
      url
      prompt
      imageVariants {
        thumbnail {
          image
          height
          width
        }
      }
    }
    meta {
      offset
      limit
      total
      nextOffset
      orderBy
    }
    errors {
      message
    }
  }
}
    `;
export const usePersonalizedCommunityContentProductListQuery = <
  TData = PersonalizedCommunityContentProductListQuery,
  TError = unknown
>(
  variables: PersonalizedCommunityContentProductListQueryVariables,
  options?: UseQueryOptions<PersonalizedCommunityContentProductListQuery, TError, TData>,
) =>
  useQuery<PersonalizedCommunityContentProductListQuery, TError, TData>(
    ['PersonalizedCommunityContentProductList', variables],
    mutationFetcher<
      PersonalizedCommunityContentProductListQuery,
      PersonalizedCommunityContentProductListQueryVariables
    >(PersonalizedCommunityContentProductListDocument, variables),
    options,
  );
export const useInfinitePersonalizedCommunityContentProductListQuery = <
  TData = PersonalizedCommunityContentProductListQuery,
  TError = unknown
>(
  pageParamKey: keyof PersonalizedCommunityContentProductListQueryVariables,
  variables: PersonalizedCommunityContentProductListQueryVariables,
  options?: UseInfiniteQueryOptions<PersonalizedCommunityContentProductListQuery, TError, TData>,
) => {
  return useInfiniteQuery<PersonalizedCommunityContentProductListQuery, TError, TData>(
    ['PersonalizedCommunityContentProductList.infinite', variables],
    metaData =>
      mutationFetcher<
        PersonalizedCommunityContentProductListQuery,
        PersonalizedCommunityContentProductListQueryVariables
      >(PersonalizedCommunityContentProductListDocument, { ...variables, ...(metaData.pageParam ?? {}) })(),
    options,
  );
};

useInfinitePersonalizedCommunityContentProductListQuery.fetcher = (
  variables: PersonalizedCommunityContentProductListQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<PersonalizedCommunityContentProductListQuery, PersonalizedCommunityContentProductListQueryVariables>(
    PersonalizedCommunityContentProductListDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfinitePersonalizedCommunityContentProductListQuery.prefetch = (
  variables: PersonalizedCommunityContentProductListQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    ['PersonalizedCommunityContentProductList.infinite', variables],
    usePersonalizedCommunityContentProductListQuery.fetcher(variables, options),
  ];
};
useInfinitePersonalizedCommunityContentProductListQuery.key = (
  variables: PersonalizedCommunityContentProductListQueryVariables,
) => ['PersonalizedCommunityContentProductList.infinite', variables];
usePersonalizedCommunityContentProductListQuery.fetcher = (
  variables: PersonalizedCommunityContentProductListQueryVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<PersonalizedCommunityContentProductListQuery, PersonalizedCommunityContentProductListQueryVariables>(
    PersonalizedCommunityContentProductListDocument,
    variables,
    options,
  );
usePersonalizedCommunityContentProductListQuery.prefetch = (
  variables: PersonalizedCommunityContentProductListQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    ['PersonalizedCommunityContentProductList', variables],
    usePersonalizedCommunityContentProductListQuery.fetcher(variables, options),
  ];
};
usePersonalizedCommunityContentProductListQuery.key = (
  variables: PersonalizedCommunityContentProductListQueryVariables,
) => ['PersonalizedCommunityContentProductList', variables];
export const AddFavoriteDocument = `
    mutation addFavorite($id: Int!) {
  addFavorite(id: $id) {
    success
    errors {
      message
    }
  }
}
    `;
export const useAddFavoriteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<AddFavoriteMutation, TError, AddFavoriteMutationVariables, TContext>,
) =>
  useMutation<AddFavoriteMutation, TError, AddFavoriteMutationVariables, TContext>(
    ['addFavorite'],
    (variables?: AddFavoriteMutationVariables) =>
      mutationFetcher<AddFavoriteMutation, AddFavoriteMutationVariables>(AddFavoriteDocument, variables)(),
    options,
  );
useAddFavoriteMutation.fetcher = (variables: AddFavoriteMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<AddFavoriteMutation, AddFavoriteMutationVariables>(AddFavoriteDocument, variables, options);
export const DeleteFavoriteDocument = `
    mutation deleteFavorite($id: Int!) {
  deleteFavorite(id: $id) {
    success
    errors {
      message
    }
  }
}
    `;
export const useDeleteFavoriteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<DeleteFavoriteMutation, TError, DeleteFavoriteMutationVariables, TContext>,
) =>
  useMutation<DeleteFavoriteMutation, TError, DeleteFavoriteMutationVariables, TContext>(
    ['deleteFavorite'],
    (variables?: DeleteFavoriteMutationVariables) =>
      mutationFetcher<DeleteFavoriteMutation, DeleteFavoriteMutationVariables>(DeleteFavoriteDocument, variables)(),
    options,
  );
useDeleteFavoriteMutation.fetcher = (variables: DeleteFavoriteMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<DeleteFavoriteMutation, DeleteFavoriteMutationVariables>(DeleteFavoriteDocument, variables, options);
export const ProductsByIdDocument = `
    query ProductsById($ids: [String!]!) {
  getProductsById(ids: $ids) {
    id
    status
  }
}
    `;
export const useProductsByIdQuery = <TData = ProductsByIdQuery, TError = unknown>(
  variables: ProductsByIdQueryVariables,
  options?: UseQueryOptions<ProductsByIdQuery, TError, TData>,
) =>
  useQuery<ProductsByIdQuery, TError, TData>(
    ['ProductsById', variables],
    mutationFetcher<ProductsByIdQuery, ProductsByIdQueryVariables>(ProductsByIdDocument, variables),
    options,
  );
export const useInfiniteProductsByIdQuery = <TData = ProductsByIdQuery, TError = unknown>(
  pageParamKey: keyof ProductsByIdQueryVariables,
  variables: ProductsByIdQueryVariables,
  options?: UseInfiniteQueryOptions<ProductsByIdQuery, TError, TData>,
) => {
  return useInfiniteQuery<ProductsByIdQuery, TError, TData>(
    ['ProductsById.infinite', variables],
    metaData =>
      mutationFetcher<ProductsByIdQuery, ProductsByIdQueryVariables>(ProductsByIdDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteProductsByIdQuery.fetcher = (variables: ProductsByIdQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<ProductsByIdQuery, ProductsByIdQueryVariables>(
    ProductsByIdDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteProductsByIdQuery.prefetch = (
  variables: ProductsByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['ProductsById.infinite', variables], useProductsByIdQuery.fetcher(variables, options)];
};
useInfiniteProductsByIdQuery.key = (variables: ProductsByIdQueryVariables) => ['ProductsById.infinite', variables];
useProductsByIdQuery.fetcher = (variables: ProductsByIdQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<ProductsByIdQuery, ProductsByIdQueryVariables>(ProductsByIdDocument, variables, options);
useProductsByIdQuery.prefetch = (
  variables: ProductsByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['ProductsById', variables], useProductsByIdQuery.fetcher(variables, options)];
};
useProductsByIdQuery.key = (variables: ProductsByIdQueryVariables) => ['ProductsById', variables];
export const RemoveSparkPromptDocument = `
    mutation removeSparkPrompt($promptId: String!) {
  removeSparkPrompt(promptId: $promptId) {
    success
    errors {
      message
    }
  }
}
    `;
export const useRemoveSparkPromptMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<RemoveSparkPromptMutation, TError, RemoveSparkPromptMutationVariables, TContext>,
) =>
  useMutation<RemoveSparkPromptMutation, TError, RemoveSparkPromptMutationVariables, TContext>(
    ['removeSparkPrompt'],
    (variables?: RemoveSparkPromptMutationVariables) =>
      mutationFetcher<RemoveSparkPromptMutation, RemoveSparkPromptMutationVariables>(
        RemoveSparkPromptDocument,
        variables,
      )(),
    options,
  );
useRemoveSparkPromptMutation.fetcher = (
  variables: RemoveSparkPromptMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<RemoveSparkPromptMutation, RemoveSparkPromptMutationVariables>(
    RemoveSparkPromptDocument,
    variables,
    options,
  );
export const SparkPromptsDocument = `
    query SparkPrompts($page: Int, $creationType: CreationType, $filter: PromptsFilter) {
  me {
    user {
      spark {
        balance
        prompts(
          page: $page
          itemsPerPage: 15
          creationType: $creationType
          filter: $filter
        ) {
          errors {
            message
          }
          pagination {
            page
            totalPages
          }
          filter {
            date {
              month
              year
            }
            prompt
          }
          items {
            id
            prompt
            aspectRatio {
              type
              value
            }
            createTime
            creationType
            modelTypeVersion
            coverImage {
              url
            }
            images {
              __typename
              id
              source
              ... on PromptImageFailedGenerating {
                error
              }
              ... on PromptImageUpscaled {
                upscalingTime
                submitTime
                image {
                  thumb {
                    url
                    width
                    height
                  }
                  upscaled {
                    url
                    width
                    height
                  }
                  original {
                    width
                    height
                    url
                  }
                }
                product {
                  url
                  status
                  visibility
                }
              }
              ... on PromptImageGenerated {
                image {
                  thumb {
                    url
                    width
                    height
                  }
                  original {
                    width
                    height
                    url
                  }
                }
              }
              ... on PromptImagePrivateUpscaled {
                image {
                  thumb {
                    url
                    width
                    height
                  }
                  upscaled {
                    url
                    width
                    height
                  }
                  original {
                    width
                    height
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useSparkPromptsQuery = <TData = SparkPromptsQuery, TError = unknown>(
  variables?: SparkPromptsQueryVariables,
  options?: UseQueryOptions<SparkPromptsQuery, TError, TData>,
) =>
  useQuery<SparkPromptsQuery, TError, TData>(
    variables === undefined ? ['SparkPrompts'] : ['SparkPrompts', variables],
    mutationFetcher<SparkPromptsQuery, SparkPromptsQueryVariables>(SparkPromptsDocument, variables),
    options,
  );
export const useInfiniteSparkPromptsQuery = <TData = SparkPromptsQuery, TError = unknown>(
  pageParamKey: keyof SparkPromptsQueryVariables,
  variables?: SparkPromptsQueryVariables,
  options?: UseInfiniteQueryOptions<SparkPromptsQuery, TError, TData>,
) => {
  return useInfiniteQuery<SparkPromptsQuery, TError, TData>(
    variables === undefined ? ['SparkPrompts.infinite'] : ['SparkPrompts.infinite', variables],
    metaData =>
      mutationFetcher<SparkPromptsQuery, SparkPromptsQueryVariables>(SparkPromptsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteSparkPromptsQuery.fetcher = (variables?: SparkPromptsQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<SparkPromptsQuery, SparkPromptsQueryVariables>(
    SparkPromptsDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteSparkPromptsQuery.prefetch = (
  variables?: SparkPromptsQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['SparkPrompts.infinite'] : ['SparkPrompts.infinite', variables],
    useSparkPromptsQuery.fetcher(variables, options),
  ];
};
useInfiniteSparkPromptsQuery.key = (variables?: SparkPromptsQueryVariables) =>
  variables === undefined ? ['SparkPrompts.infinite'] : ['SparkPrompts.infinite', variables];
useSparkPromptsQuery.fetcher = (variables?: SparkPromptsQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<SparkPromptsQuery, SparkPromptsQueryVariables>(SparkPromptsDocument, variables, options);
useSparkPromptsQuery.prefetch = (
  variables?: SparkPromptsQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['SparkPrompts'] : ['SparkPrompts', variables],
    useSparkPromptsQuery.fetcher(variables, options),
  ];
};
useSparkPromptsQuery.key = (variables?: SparkPromptsQueryVariables) =>
  variables === undefined ? ['SparkPrompts'] : ['SparkPrompts', variables];
export const UpscaleSparkImageDocument = `
    mutation UpscaleSparkImage($imageId: String!, $hiddenPrompt: Boolean) {
  upscaleSparkImage(imageId: $imageId, hiddenPrompt: $hiddenPrompt) {
    success
    errors {
      message
    }
    image {
      ... on PromptImageUpscaled {
        product {
          url
        }
      }
    }
  }
}
    `;
export const useUpscaleSparkImageMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpscaleSparkImageMutation, TError, UpscaleSparkImageMutationVariables, TContext>,
) =>
  useMutation<UpscaleSparkImageMutation, TError, UpscaleSparkImageMutationVariables, TContext>(
    ['UpscaleSparkImage'],
    (variables?: UpscaleSparkImageMutationVariables) =>
      mutationFetcher<UpscaleSparkImageMutation, UpscaleSparkImageMutationVariables>(
        UpscaleSparkImageDocument,
        variables,
      )(),
    options,
  );
useUpscaleSparkImageMutation.fetcher = (
  variables: UpscaleSparkImageMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<UpscaleSparkImageMutation, UpscaleSparkImageMutationVariables>(
    UpscaleSparkImageDocument,
    variables,
    options,
  );
export const PrivateUpscaleDocument = `
    mutation PrivateUpscale($itemId: String!) {
  privateUpscale(itemId: $itemId) {
    success
    errors {
      message
    }
    item {
      ... on PromptImagePrivateUpscaled {
        image {
          original {
            url
          }
          upscaled {
            url
          }
        }
      }
    }
  }
}
    `;
export const usePrivateUpscaleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<PrivateUpscaleMutation, TError, PrivateUpscaleMutationVariables, TContext>,
) =>
  useMutation<PrivateUpscaleMutation, TError, PrivateUpscaleMutationVariables, TContext>(
    ['PrivateUpscale'],
    (variables?: PrivateUpscaleMutationVariables) =>
      mutationFetcher<PrivateUpscaleMutation, PrivateUpscaleMutationVariables>(PrivateUpscaleDocument, variables)(),
    options,
  );
usePrivateUpscaleMutation.fetcher = (variables: PrivateUpscaleMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<PrivateUpscaleMutation, PrivateUpscaleMutationVariables>(PrivateUpscaleDocument, variables, options);
export const PrivateImagesDocument = `
    query PrivateImages($page: Int, $creationType: CreationType) {
  me {
    user {
      spark {
        privateImages(page: $page, itemsPerPage: 15, creationType: $creationType) {
          errors {
            message
          }
          pagination {
            page
            totalPages
          }
          items {
            prompt
            aspectRatio {
              type
              value
            }
            creationType
            image {
              __typename
              id
              source
              ... on PromptImageUpscaling {
                image {
                  original {
                    url
                    width
                    height
                  }
                  upscaled {
                    url
                    width
                    height
                  }
                }
              }
              ... on PromptImagePrivateUpscaled {
                image {
                  original {
                    url
                    width
                    height
                  }
                  upscaled {
                    url
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const usePrivateImagesQuery = <TData = PrivateImagesQuery, TError = unknown>(
  variables?: PrivateImagesQueryVariables,
  options?: UseQueryOptions<PrivateImagesQuery, TError, TData>,
) =>
  useQuery<PrivateImagesQuery, TError, TData>(
    variables === undefined ? ['PrivateImages'] : ['PrivateImages', variables],
    mutationFetcher<PrivateImagesQuery, PrivateImagesQueryVariables>(PrivateImagesDocument, variables),
    options,
  );
export const useInfinitePrivateImagesQuery = <TData = PrivateImagesQuery, TError = unknown>(
  pageParamKey: keyof PrivateImagesQueryVariables,
  variables?: PrivateImagesQueryVariables,
  options?: UseInfiniteQueryOptions<PrivateImagesQuery, TError, TData>,
) => {
  return useInfiniteQuery<PrivateImagesQuery, TError, TData>(
    variables === undefined ? ['PrivateImages.infinite'] : ['PrivateImages.infinite', variables],
    metaData =>
      mutationFetcher<PrivateImagesQuery, PrivateImagesQueryVariables>(PrivateImagesDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfinitePrivateImagesQuery.fetcher = (variables?: PrivateImagesQueryVariables, options?: RequestInit['headers']) => (
  metaData: QueryFunctionContext<QueryKey, any>,
) =>
  mutationFetcher<PrivateImagesQuery, PrivateImagesQueryVariables>(
    PrivateImagesDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfinitePrivateImagesQuery.prefetch = (
  variables?: PrivateImagesQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['PrivateImages.infinite'] : ['PrivateImages.infinite', variables],
    usePrivateImagesQuery.fetcher(variables, options),
  ];
};
useInfinitePrivateImagesQuery.key = (variables?: PrivateImagesQueryVariables) =>
  variables === undefined ? ['PrivateImages.infinite'] : ['PrivateImages.infinite', variables];
usePrivateImagesQuery.fetcher = (variables?: PrivateImagesQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<PrivateImagesQuery, PrivateImagesQueryVariables>(PrivateImagesDocument, variables, options);
usePrivateImagesQuery.prefetch = (
  variables?: PrivateImagesQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['PrivateImages'] : ['PrivateImages', variables],
    usePrivateImagesQuery.fetcher(variables, options),
  ];
};
usePrivateImagesQuery.key = (variables?: PrivateImagesQueryVariables) =>
  variables === undefined ? ['PrivateImages'] : ['PrivateImages', variables];
export const ImageUpscalerDocument = `
    mutation ImageUpscaler($imagePath: String!) {
  imageUpscaler(imagePath: $imagePath) {
    success
    errors {
      message
    }
    item {
      id
      downloaded
      __typename
      ... on ImageUpscaled {
        image {
          upscaledFile
        }
      }
      ... on ImageFailed {
        error
      }
      ... on ImageFailedUpscaling {
        error
      }
    }
  }
}
    `;
export const useImageUpscalerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ImageUpscalerMutation, TError, ImageUpscalerMutationVariables, TContext>,
) =>
  useMutation<ImageUpscalerMutation, TError, ImageUpscalerMutationVariables, TContext>(
    ['ImageUpscaler'],
    (variables?: ImageUpscalerMutationVariables) =>
      mutationFetcher<ImageUpscalerMutation, ImageUpscalerMutationVariables>(ImageUpscalerDocument, variables)(),
    options,
  );
useImageUpscalerMutation.fetcher = (variables: ImageUpscalerMutationVariables, options?: RequestInit['headers']) =>
  mutationFetcher<ImageUpscalerMutation, ImageUpscalerMutationVariables>(ImageUpscalerDocument, variables, options);
export const GetImageUpscalerStatusDocument = `
    query getImageUpscalerStatus($id: String!) {
  getImageUpscalerStatus(id: $id) {
    success
    errors {
      message
    }
    item {
      id
      downloaded
      __typename
      ... on ImageUpscaled {
        image {
          upscaledFile
        }
      }
      ... on ImageFailed {
        error
      }
      ... on ImageFailedUpscaling {
        error
      }
    }
  }
}
    `;
export const useGetImageUpscalerStatusQuery = <TData = GetImageUpscalerStatusQuery, TError = unknown>(
  variables: GetImageUpscalerStatusQueryVariables,
  options?: UseQueryOptions<GetImageUpscalerStatusQuery, TError, TData>,
) =>
  useQuery<GetImageUpscalerStatusQuery, TError, TData>(
    ['getImageUpscalerStatus', variables],
    mutationFetcher<GetImageUpscalerStatusQuery, GetImageUpscalerStatusQueryVariables>(
      GetImageUpscalerStatusDocument,
      variables,
    ),
    options,
  );
export const useInfiniteGetImageUpscalerStatusQuery = <TData = GetImageUpscalerStatusQuery, TError = unknown>(
  pageParamKey: keyof GetImageUpscalerStatusQueryVariables,
  variables: GetImageUpscalerStatusQueryVariables,
  options?: UseInfiniteQueryOptions<GetImageUpscalerStatusQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetImageUpscalerStatusQuery, TError, TData>(
    ['getImageUpscalerStatus.infinite', variables],
    metaData =>
      mutationFetcher<GetImageUpscalerStatusQuery, GetImageUpscalerStatusQueryVariables>(
        GetImageUpscalerStatusDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

useInfiniteGetImageUpscalerStatusQuery.fetcher = (
  variables: GetImageUpscalerStatusQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<GetImageUpscalerStatusQuery, GetImageUpscalerStatusQueryVariables>(
    GetImageUpscalerStatusDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetImageUpscalerStatusQuery.prefetch = (
  variables: GetImageUpscalerStatusQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['getImageUpscalerStatus.infinite', variables], useGetImageUpscalerStatusQuery.fetcher(variables, options)];
};
useInfiniteGetImageUpscalerStatusQuery.key = (variables: GetImageUpscalerStatusQueryVariables) => [
  'getImageUpscalerStatus.infinite',
  variables,
];
useGetImageUpscalerStatusQuery.fetcher = (
  variables: GetImageUpscalerStatusQueryVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<GetImageUpscalerStatusQuery, GetImageUpscalerStatusQueryVariables>(
    GetImageUpscalerStatusDocument,
    variables,
    options,
  );
useGetImageUpscalerStatusQuery.prefetch = (
  variables: GetImageUpscalerStatusQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['getImageUpscalerStatus', variables], useGetImageUpscalerStatusQuery.fetcher(variables, options)];
};
useGetImageUpscalerStatusQuery.key = (variables: GetImageUpscalerStatusQueryVariables) => [
  'getImageUpscalerStatus',
  variables,
];
export const CreateImageSignedDownloadUrlDocument = `
    mutation createImageSignedDownloadUrl($imageId: String!) {
  createImageSignedDownloadUrl(imageId: $imageId) {
    url
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useCreateImageSignedDownloadUrlMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateImageSignedDownloadUrlMutation,
    TError,
    CreateImageSignedDownloadUrlMutationVariables,
    TContext
  >,
) =>
  useMutation<CreateImageSignedDownloadUrlMutation, TError, CreateImageSignedDownloadUrlMutationVariables, TContext>(
    ['createImageSignedDownloadUrl'],
    (variables?: CreateImageSignedDownloadUrlMutationVariables) =>
      mutationFetcher<CreateImageSignedDownloadUrlMutation, CreateImageSignedDownloadUrlMutationVariables>(
        CreateImageSignedDownloadUrlDocument,
        variables,
      )(),
    options,
  );
useCreateImageSignedDownloadUrlMutation.fetcher = (
  variables: CreateImageSignedDownloadUrlMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<CreateImageSignedDownloadUrlMutation, CreateImageSignedDownloadUrlMutationVariables>(
    CreateImageSignedDownloadUrlDocument,
    variables,
    options,
  );
export const GetContentByIdDocument = `
    query GetContentById($id: ID!, $locale: Language!, $increaseViewsCount: Boolean!) {
  getContent(
    contentId: $id
    locale: $locale
    increaseViewsCount: $increaseViewsCount
  ) {
    id
    title
    slug
    alternativeSlugs {
      lang
      slug
    }
    seo {
      h1
      title
      description
      image_alt
    }
    downloadsCount
    viewsCount
    type
    categoryId
    category {
      id
      name
      slug
      parent_id
      root {
        id
        slug
      }
    }
    authorId
    format
    tags
    createdAt
    updatedAt
    preview {
      desktop
      mobile
    }
    thumbnailUrl
    assets {
      id
      contentId
      type
      variant
      sizeBytes
      createdAt
      updatedAt
      label
      resolution {
        width
        height
      }
    }
    description
    status
    linkBlock {
      id
      title
      links {
        anchor
        url
      }
    }
  }
}
    `;
export const useGetContentByIdQuery = <TData = GetContentByIdQuery, TError = unknown>(
  variables: GetContentByIdQueryVariables,
  options?: UseQueryOptions<GetContentByIdQuery, TError, TData>,
) =>
  useQuery<GetContentByIdQuery, TError, TData>(
    ['GetContentById', variables],
    mutationFetcher<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, variables),
    options,
  );
export const useInfiniteGetContentByIdQuery = <TData = GetContentByIdQuery, TError = unknown>(
  pageParamKey: keyof GetContentByIdQueryVariables,
  variables: GetContentByIdQueryVariables,
  options?: UseInfiniteQueryOptions<GetContentByIdQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetContentByIdQuery, TError, TData>(
    ['GetContentById.infinite', variables],
    metaData =>
      mutationFetcher<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteGetContentByIdQuery.fetcher = (
  variables: GetContentByIdQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<GetContentByIdQuery, GetContentByIdQueryVariables>(
    GetContentByIdDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetContentByIdQuery.prefetch = (
  variables: GetContentByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentById.infinite', variables], useGetContentByIdQuery.fetcher(variables, options)];
};
useInfiniteGetContentByIdQuery.key = (variables: GetContentByIdQueryVariables) => [
  'GetContentById.infinite',
  variables,
];
useGetContentByIdQuery.fetcher = (variables: GetContentByIdQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, variables, options);
useGetContentByIdQuery.prefetch = (
  variables: GetContentByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentById', variables], useGetContentByIdQuery.fetcher(variables, options)];
};
useGetContentByIdQuery.key = (variables: GetContentByIdQueryVariables) => ['GetContentById', variables];
export const GenerateAssetDownloadLinkDocument = `
    mutation GenerateAssetDownloadLink($id: ID!, $assetId: ID!) {
  generateAssetDownloadLink(contentId: $id, assetId: $assetId) {
    url
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
    `;
export const useGenerateAssetDownloadLinkMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    GenerateAssetDownloadLinkMutation,
    TError,
    GenerateAssetDownloadLinkMutationVariables,
    TContext
  >,
) =>
  useMutation<GenerateAssetDownloadLinkMutation, TError, GenerateAssetDownloadLinkMutationVariables, TContext>(
    ['GenerateAssetDownloadLink'],
    (variables?: GenerateAssetDownloadLinkMutationVariables) =>
      mutationFetcher<GenerateAssetDownloadLinkMutation, GenerateAssetDownloadLinkMutationVariables>(
        GenerateAssetDownloadLinkDocument,
        variables,
      )(),
    options,
  );
useGenerateAssetDownloadLinkMutation.fetcher = (
  variables: GenerateAssetDownloadLinkMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<GenerateAssetDownloadLinkMutation, GenerateAssetDownloadLinkMutationVariables>(
    GenerateAssetDownloadLinkDocument,
    variables,
    options,
  );
export const GetContentCategoryByIdDocument = `
    query GetContentCategoryById($id: ID!, $locale: Language!) {
  getContentCategory(categoryId: $id, locale: $locale) {
    id
    parent_id
    name
    slug
    alternativeSlugs {
      lang
      slug
    }
    content_top
    content_bottom
    seo {
      title
      description
    }
    root {
      id
      slug
      parent_id
    }
  }
}
    `;
export const useGetContentCategoryByIdQuery = <TData = GetContentCategoryByIdQuery, TError = unknown>(
  variables: GetContentCategoryByIdQueryVariables,
  options?: UseQueryOptions<GetContentCategoryByIdQuery, TError, TData>,
) =>
  useQuery<GetContentCategoryByIdQuery, TError, TData>(
    ['GetContentCategoryById', variables],
    mutationFetcher<GetContentCategoryByIdQuery, GetContentCategoryByIdQueryVariables>(
      GetContentCategoryByIdDocument,
      variables,
    ),
    options,
  );
export const useInfiniteGetContentCategoryByIdQuery = <TData = GetContentCategoryByIdQuery, TError = unknown>(
  pageParamKey: keyof GetContentCategoryByIdQueryVariables,
  variables: GetContentCategoryByIdQueryVariables,
  options?: UseInfiniteQueryOptions<GetContentCategoryByIdQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetContentCategoryByIdQuery, TError, TData>(
    ['GetContentCategoryById.infinite', variables],
    metaData =>
      mutationFetcher<GetContentCategoryByIdQuery, GetContentCategoryByIdQueryVariables>(
        GetContentCategoryByIdDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

useInfiniteGetContentCategoryByIdQuery.fetcher = (
  variables: GetContentCategoryByIdQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<GetContentCategoryByIdQuery, GetContentCategoryByIdQueryVariables>(
    GetContentCategoryByIdDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetContentCategoryByIdQuery.prefetch = (
  variables: GetContentCategoryByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentCategoryById.infinite', variables], useGetContentCategoryByIdQuery.fetcher(variables, options)];
};
useInfiniteGetContentCategoryByIdQuery.key = (variables: GetContentCategoryByIdQueryVariables) => [
  'GetContentCategoryById.infinite',
  variables,
];
useGetContentCategoryByIdQuery.fetcher = (
  variables: GetContentCategoryByIdQueryVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<GetContentCategoryByIdQuery, GetContentCategoryByIdQueryVariables>(
    GetContentCategoryByIdDocument,
    variables,
    options,
  );
useGetContentCategoryByIdQuery.prefetch = (
  variables: GetContentCategoryByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentCategoryById', variables], useGetContentCategoryByIdQuery.fetcher(variables, options)];
};
useGetContentCategoryByIdQuery.key = (variables: GetContentCategoryByIdQueryVariables) => [
  'GetContentCategoryById',
  variables,
];
export const GetContentCategoriesByIdDocument = `
    query GetContentCategoriesById($ids: [ID!], $locale: Language!) {
  getContentCategories(categoryIds: $ids, locale: $locale) {
    id
    name
    slug
  }
}
    `;
export const useGetContentCategoriesByIdQuery = <TData = GetContentCategoriesByIdQuery, TError = unknown>(
  variables: GetContentCategoriesByIdQueryVariables,
  options?: UseQueryOptions<GetContentCategoriesByIdQuery, TError, TData>,
) =>
  useQuery<GetContentCategoriesByIdQuery, TError, TData>(
    ['GetContentCategoriesById', variables],
    mutationFetcher<GetContentCategoriesByIdQuery, GetContentCategoriesByIdQueryVariables>(
      GetContentCategoriesByIdDocument,
      variables,
    ),
    options,
  );
export const useInfiniteGetContentCategoriesByIdQuery = <TData = GetContentCategoriesByIdQuery, TError = unknown>(
  pageParamKey: keyof GetContentCategoriesByIdQueryVariables,
  variables: GetContentCategoriesByIdQueryVariables,
  options?: UseInfiniteQueryOptions<GetContentCategoriesByIdQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetContentCategoriesByIdQuery, TError, TData>(
    ['GetContentCategoriesById.infinite', variables],
    metaData =>
      mutationFetcher<GetContentCategoriesByIdQuery, GetContentCategoriesByIdQueryVariables>(
        GetContentCategoriesByIdDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

useInfiniteGetContentCategoriesByIdQuery.fetcher = (
  variables: GetContentCategoriesByIdQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<GetContentCategoriesByIdQuery, GetContentCategoriesByIdQueryVariables>(
    GetContentCategoriesByIdDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetContentCategoriesByIdQuery.prefetch = (
  variables: GetContentCategoriesByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    ['GetContentCategoriesById.infinite', variables],
    useGetContentCategoriesByIdQuery.fetcher(variables, options),
  ];
};
useInfiniteGetContentCategoriesByIdQuery.key = (variables: GetContentCategoriesByIdQueryVariables) => [
  'GetContentCategoriesById.infinite',
  variables,
];
useGetContentCategoriesByIdQuery.fetcher = (
  variables: GetContentCategoriesByIdQueryVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<GetContentCategoriesByIdQuery, GetContentCategoriesByIdQueryVariables>(
    GetContentCategoriesByIdDocument,
    variables,
    options,
  );
useGetContentCategoriesByIdQuery.prefetch = (
  variables: GetContentCategoriesByIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentCategoriesById', variables], useGetContentCategoriesByIdQuery.fetcher(variables, options)];
};
useGetContentCategoriesByIdQuery.key = (variables: GetContentCategoriesByIdQueryVariables) => [
  'GetContentCategoriesById',
  variables,
];
export const GetContentCategoriesDocument = `
    query GetContentCategories($parentId: ID!, $locale: Language!) {
  listContentCategories(parentId: $parentId, locale: $locale) {
    id
    name
    slug
  }
}
    `;
export const useGetContentCategoriesQuery = <TData = GetContentCategoriesQuery, TError = unknown>(
  variables: GetContentCategoriesQueryVariables,
  options?: UseQueryOptions<GetContentCategoriesQuery, TError, TData>,
) =>
  useQuery<GetContentCategoriesQuery, TError, TData>(
    ['GetContentCategories', variables],
    mutationFetcher<GetContentCategoriesQuery, GetContentCategoriesQueryVariables>(
      GetContentCategoriesDocument,
      variables,
    ),
    options,
  );
export const useInfiniteGetContentCategoriesQuery = <TData = GetContentCategoriesQuery, TError = unknown>(
  pageParamKey: keyof GetContentCategoriesQueryVariables,
  variables: GetContentCategoriesQueryVariables,
  options?: UseInfiniteQueryOptions<GetContentCategoriesQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetContentCategoriesQuery, TError, TData>(
    ['GetContentCategories.infinite', variables],
    metaData =>
      mutationFetcher<GetContentCategoriesQuery, GetContentCategoriesQueryVariables>(GetContentCategoriesDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteGetContentCategoriesQuery.fetcher = (
  variables: GetContentCategoriesQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<GetContentCategoriesQuery, GetContentCategoriesQueryVariables>(
    GetContentCategoriesDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetContentCategoriesQuery.prefetch = (
  variables: GetContentCategoriesQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentCategories.infinite', variables], useGetContentCategoriesQuery.fetcher(variables, options)];
};
useInfiniteGetContentCategoriesQuery.key = (variables: GetContentCategoriesQueryVariables) => [
  'GetContentCategories.infinite',
  variables,
];
useGetContentCategoriesQuery.fetcher = (
  variables: GetContentCategoriesQueryVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<GetContentCategoriesQuery, GetContentCategoriesQueryVariables>(
    GetContentCategoriesDocument,
    variables,
    options,
  );
useGetContentCategoriesQuery.prefetch = (
  variables: GetContentCategoriesQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentCategories', variables], useGetContentCategoriesQuery.fetcher(variables, options)];
};
useGetContentCategoriesQuery.key = (variables: GetContentCategoriesQueryVariables) => [
  'GetContentCategories',
  variables,
];
export const GetContentLinkBlockDocument = `
    query GetContentLinkBlock($id: ID!, $locale: Language!) {
  getContentLinkBlock(id: $id, locale: $locale) {
    id
    title
    links {
      anchor
      url
    }
  }
}
    `;
export const useGetContentLinkBlockQuery = <TData = GetContentLinkBlockQuery, TError = unknown>(
  variables: GetContentLinkBlockQueryVariables,
  options?: UseQueryOptions<GetContentLinkBlockQuery, TError, TData>,
) =>
  useQuery<GetContentLinkBlockQuery, TError, TData>(
    ['GetContentLinkBlock', variables],
    mutationFetcher<GetContentLinkBlockQuery, GetContentLinkBlockQueryVariables>(
      GetContentLinkBlockDocument,
      variables,
    ),
    options,
  );
export const useInfiniteGetContentLinkBlockQuery = <TData = GetContentLinkBlockQuery, TError = unknown>(
  pageParamKey: keyof GetContentLinkBlockQueryVariables,
  variables: GetContentLinkBlockQueryVariables,
  options?: UseInfiniteQueryOptions<GetContentLinkBlockQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetContentLinkBlockQuery, TError, TData>(
    ['GetContentLinkBlock.infinite', variables],
    metaData =>
      mutationFetcher<GetContentLinkBlockQuery, GetContentLinkBlockQueryVariables>(GetContentLinkBlockDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteGetContentLinkBlockQuery.fetcher = (
  variables: GetContentLinkBlockQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<GetContentLinkBlockQuery, GetContentLinkBlockQueryVariables>(
    GetContentLinkBlockDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetContentLinkBlockQuery.prefetch = (
  variables: GetContentLinkBlockQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentLinkBlock.infinite', variables], useGetContentLinkBlockQuery.fetcher(variables, options)];
};
useInfiniteGetContentLinkBlockQuery.key = (variables: GetContentLinkBlockQueryVariables) => [
  'GetContentLinkBlock.infinite',
  variables,
];
useGetContentLinkBlockQuery.fetcher = (
  variables: GetContentLinkBlockQueryVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<GetContentLinkBlockQuery, GetContentLinkBlockQueryVariables>(
    GetContentLinkBlockDocument,
    variables,
    options,
  );
useGetContentLinkBlockQuery.prefetch = (
  variables: GetContentLinkBlockQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetContentLinkBlock', variables], useGetContentLinkBlockQuery.fetcher(variables, options)];
};
useGetContentLinkBlockQuery.key = (variables: GetContentLinkBlockQueryVariables) => ['GetContentLinkBlock', variables];
export const GetTrendingWordsDocument = `
    query GetTrendingWords($locale: Language!) {
  getTrendingWords(locale: $locale) {
    words
    errors {
      code
      message
    }
  }
}
    `;
export const useGetTrendingWordsQuery = <TData = GetTrendingWordsQuery, TError = unknown>(
  variables: GetTrendingWordsQueryVariables,
  options?: UseQueryOptions<GetTrendingWordsQuery, TError, TData>,
) =>
  useQuery<GetTrendingWordsQuery, TError, TData>(
    ['GetTrendingWords', variables],
    mutationFetcher<GetTrendingWordsQuery, GetTrendingWordsQueryVariables>(GetTrendingWordsDocument, variables),
    options,
  );
export const useInfiniteGetTrendingWordsQuery = <TData = GetTrendingWordsQuery, TError = unknown>(
  pageParamKey: keyof GetTrendingWordsQueryVariables,
  variables: GetTrendingWordsQueryVariables,
  options?: UseInfiniteQueryOptions<GetTrendingWordsQuery, TError, TData>,
) => {
  return useInfiniteQuery<GetTrendingWordsQuery, TError, TData>(
    ['GetTrendingWords.infinite', variables],
    metaData =>
      mutationFetcher<GetTrendingWordsQuery, GetTrendingWordsQueryVariables>(GetTrendingWordsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteGetTrendingWordsQuery.fetcher = (
  variables: GetTrendingWordsQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<GetTrendingWordsQuery, GetTrendingWordsQueryVariables>(
    GetTrendingWordsDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteGetTrendingWordsQuery.prefetch = (
  variables: GetTrendingWordsQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetTrendingWords.infinite', variables], useGetTrendingWordsQuery.fetcher(variables, options)];
};
useInfiniteGetTrendingWordsQuery.key = (variables: GetTrendingWordsQueryVariables) => [
  'GetTrendingWords.infinite',
  variables,
];
useGetTrendingWordsQuery.fetcher = (variables: GetTrendingWordsQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<GetTrendingWordsQuery, GetTrendingWordsQueryVariables>(GetTrendingWordsDocument, variables, options);
useGetTrendingWordsQuery.prefetch = (
  variables: GetTrendingWordsQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['GetTrendingWords', variables], useGetTrendingWordsQuery.fetcher(variables, options)];
};
useGetTrendingWordsQuery.key = (variables: GetTrendingWordsQueryVariables) => ['GetTrendingWords', variables];
export const PriceCalculationDocument = `
    query PriceCalculation($targetCurrency: Currency!, $prices: [PricesToTransformInput!]!, $match: Boolean) {
  getCalculatedPrices(
    input: {targetCurrency: $targetCurrency, prices: $prices, match: $match}
  ) {
    data {
      targetCurrency
      prices {
        basePrice
        currencyPrice
      }
    }
    errors {
      message
    }
  }
}
    `;
export const usePriceCalculationQuery = <TData = PriceCalculationQuery, TError = unknown>(
  variables: PriceCalculationQueryVariables,
  options?: UseQueryOptions<PriceCalculationQuery, TError, TData>,
) =>
  useQuery<PriceCalculationQuery, TError, TData>(
    ['PriceCalculation', variables],
    mutationFetcher<PriceCalculationQuery, PriceCalculationQueryVariables>(PriceCalculationDocument, variables),
    options,
  );
export const useInfinitePriceCalculationQuery = <TData = PriceCalculationQuery, TError = unknown>(
  pageParamKey: keyof PriceCalculationQueryVariables,
  variables: PriceCalculationQueryVariables,
  options?: UseInfiniteQueryOptions<PriceCalculationQuery, TError, TData>,
) => {
  return useInfiniteQuery<PriceCalculationQuery, TError, TData>(
    ['PriceCalculation.infinite', variables],
    metaData =>
      mutationFetcher<PriceCalculationQuery, PriceCalculationQueryVariables>(PriceCalculationDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfinitePriceCalculationQuery.fetcher = (
  variables: PriceCalculationQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<PriceCalculationQuery, PriceCalculationQueryVariables>(
    PriceCalculationDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfinitePriceCalculationQuery.prefetch = (
  variables: PriceCalculationQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['PriceCalculation.infinite', variables], usePriceCalculationQuery.fetcher(variables, options)];
};
useInfinitePriceCalculationQuery.key = (variables: PriceCalculationQueryVariables) => [
  'PriceCalculation.infinite',
  variables,
];
usePriceCalculationQuery.fetcher = (variables: PriceCalculationQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<PriceCalculationQuery, PriceCalculationQueryVariables>(PriceCalculationDocument, variables, options);
usePriceCalculationQuery.prefetch = (
  variables: PriceCalculationQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['PriceCalculation', variables], usePriceCalculationQuery.fetcher(variables, options)];
};
usePriceCalculationQuery.key = (variables: PriceCalculationQueryVariables) => ['PriceCalculation', variables];
export const CoinsPageLoggedInDocument = `
    query CoinsPageLoggedIn {
  settings {
    coins {
      spending {
        products {
          crafts
          classes
          bundles
        }
      }
      rewards {
        postSocialProductProject
        postProductReview
        registerAccount
      }
      errors {
        code
        message
      }
    }
  }
  bundles {
    items {
      id
      url
      title
      image
      type {
        title
      }
      coins {
        spent {
          id
          status
        }
      }
      craftsCharacteristic
      fontCharacteristic
      graphicCharacteristic
    }
  }
  me {
    user {
      subscriptions {
        classes
        crafts
      }
      downloads(limit: 5) {
        items {
          id
          image
          title
          url
          type
        }
      }
      coins {
        availableRewards {
          projectPosts
          reviews
        }
        balance
        transactions(page: 1, itemsPerPage: 5) {
          transactions {
            id
            type
            amount
            status
            reason
            origin {
              action
              product {
                title
                url
              }
              user {
                firstName
                lastName
                slug
              }
            }
          }
        }
      }
    }
  }
  popular {
    classes {
      id
      url
      title
      image
    }
    crafts {
      id
      url
      title
      image
    }
  }
}
    `;
export const useCoinsPageLoggedInQuery = <TData = CoinsPageLoggedInQuery, TError = unknown>(
  variables?: CoinsPageLoggedInQueryVariables,
  options?: UseQueryOptions<CoinsPageLoggedInQuery, TError, TData>,
) =>
  useQuery<CoinsPageLoggedInQuery, TError, TData>(
    variables === undefined ? ['CoinsPageLoggedIn'] : ['CoinsPageLoggedIn', variables],
    mutationFetcher<CoinsPageLoggedInQuery, CoinsPageLoggedInQueryVariables>(CoinsPageLoggedInDocument, variables),
    options,
  );
export const useInfiniteCoinsPageLoggedInQuery = <TData = CoinsPageLoggedInQuery, TError = unknown>(
  pageParamKey: keyof CoinsPageLoggedInQueryVariables,
  variables?: CoinsPageLoggedInQueryVariables,
  options?: UseInfiniteQueryOptions<CoinsPageLoggedInQuery, TError, TData>,
) => {
  return useInfiniteQuery<CoinsPageLoggedInQuery, TError, TData>(
    variables === undefined ? ['CoinsPageLoggedIn.infinite'] : ['CoinsPageLoggedIn.infinite', variables],
    metaData =>
      mutationFetcher<CoinsPageLoggedInQuery, CoinsPageLoggedInQueryVariables>(CoinsPageLoggedInDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteCoinsPageLoggedInQuery.fetcher = (
  variables?: CoinsPageLoggedInQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<CoinsPageLoggedInQuery, CoinsPageLoggedInQueryVariables>(
    CoinsPageLoggedInDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteCoinsPageLoggedInQuery.prefetch = (
  variables?: CoinsPageLoggedInQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['CoinsPageLoggedIn.infinite'] : ['CoinsPageLoggedIn.infinite', variables],
    useCoinsPageLoggedInQuery.fetcher(variables, options),
  ];
};
useInfiniteCoinsPageLoggedInQuery.key = (variables?: CoinsPageLoggedInQueryVariables) =>
  variables === undefined ? ['CoinsPageLoggedIn.infinite'] : ['CoinsPageLoggedIn.infinite', variables];
useCoinsPageLoggedInQuery.fetcher = (variables?: CoinsPageLoggedInQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<CoinsPageLoggedInQuery, CoinsPageLoggedInQueryVariables>(
    CoinsPageLoggedInDocument,
    variables,
    options,
  );
useCoinsPageLoggedInQuery.prefetch = (
  variables?: CoinsPageLoggedInQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['CoinsPageLoggedIn'] : ['CoinsPageLoggedIn', variables],
    useCoinsPageLoggedInQuery.fetcher(variables, options),
  ];
};
useCoinsPageLoggedInQuery.key = (variables?: CoinsPageLoggedInQueryVariables) =>
  variables === undefined ? ['CoinsPageLoggedIn'] : ['CoinsPageLoggedIn', variables];
export const CoinsPageLoggedOutDocument = `
    query CoinsPageLoggedOut {
  popular {
    classes {
      id
      url
      title
      image
    }
    crafts {
      id
      url
      title
      image
    }
  }
}
    `;
export const useCoinsPageLoggedOutQuery = <TData = CoinsPageLoggedOutQuery, TError = unknown>(
  variables?: CoinsPageLoggedOutQueryVariables,
  options?: UseQueryOptions<CoinsPageLoggedOutQuery, TError, TData>,
) =>
  useQuery<CoinsPageLoggedOutQuery, TError, TData>(
    variables === undefined ? ['CoinsPageLoggedOut'] : ['CoinsPageLoggedOut', variables],
    mutationFetcher<CoinsPageLoggedOutQuery, CoinsPageLoggedOutQueryVariables>(CoinsPageLoggedOutDocument, variables),
    options,
  );
export const useInfiniteCoinsPageLoggedOutQuery = <TData = CoinsPageLoggedOutQuery, TError = unknown>(
  pageParamKey: keyof CoinsPageLoggedOutQueryVariables,
  variables?: CoinsPageLoggedOutQueryVariables,
  options?: UseInfiniteQueryOptions<CoinsPageLoggedOutQuery, TError, TData>,
) => {
  return useInfiniteQuery<CoinsPageLoggedOutQuery, TError, TData>(
    variables === undefined ? ['CoinsPageLoggedOut.infinite'] : ['CoinsPageLoggedOut.infinite', variables],
    metaData =>
      mutationFetcher<CoinsPageLoggedOutQuery, CoinsPageLoggedOutQueryVariables>(CoinsPageLoggedOutDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteCoinsPageLoggedOutQuery.fetcher = (
  variables?: CoinsPageLoggedOutQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<CoinsPageLoggedOutQuery, CoinsPageLoggedOutQueryVariables>(
    CoinsPageLoggedOutDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteCoinsPageLoggedOutQuery.prefetch = (
  variables?: CoinsPageLoggedOutQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['CoinsPageLoggedOut.infinite'] : ['CoinsPageLoggedOut.infinite', variables],
    useCoinsPageLoggedOutQuery.fetcher(variables, options),
  ];
};
useInfiniteCoinsPageLoggedOutQuery.key = (variables?: CoinsPageLoggedOutQueryVariables) =>
  variables === undefined ? ['CoinsPageLoggedOut.infinite'] : ['CoinsPageLoggedOut.infinite', variables];
useCoinsPageLoggedOutQuery.fetcher = (variables?: CoinsPageLoggedOutQueryVariables, options?: RequestInit['headers']) =>
  mutationFetcher<CoinsPageLoggedOutQuery, CoinsPageLoggedOutQueryVariables>(
    CoinsPageLoggedOutDocument,
    variables,
    options,
  );
useCoinsPageLoggedOutQuery.prefetch = (
  variables?: CoinsPageLoggedOutQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['CoinsPageLoggedOut'] : ['CoinsPageLoggedOut', variables],
    useCoinsPageLoggedOutQuery.fetcher(variables, options),
  ];
};
useCoinsPageLoggedOutQuery.key = (variables?: CoinsPageLoggedOutQueryVariables) =>
  variables === undefined ? ['CoinsPageLoggedOut'] : ['CoinsPageLoggedOut', variables];
export const CoinTransactionForProductIdDocument = `
    query coinTransactionForProductId($productId: String!) {
  coinTransactionForProductId(productId: $productId) {
    transaction {
      id
      type
      amount
      status
    }
    errors {
      message
    }
  }
}
    `;
export const useCoinTransactionForProductIdQuery = <TData = CoinTransactionForProductIdQuery, TError = unknown>(
  variables: CoinTransactionForProductIdQueryVariables,
  options?: UseQueryOptions<CoinTransactionForProductIdQuery, TError, TData>,
) =>
  useQuery<CoinTransactionForProductIdQuery, TError, TData>(
    ['coinTransactionForProductId', variables],
    mutationFetcher<CoinTransactionForProductIdQuery, CoinTransactionForProductIdQueryVariables>(
      CoinTransactionForProductIdDocument,
      variables,
    ),
    options,
  );
export const useInfiniteCoinTransactionForProductIdQuery = <TData = CoinTransactionForProductIdQuery, TError = unknown>(
  pageParamKey: keyof CoinTransactionForProductIdQueryVariables,
  variables: CoinTransactionForProductIdQueryVariables,
  options?: UseInfiniteQueryOptions<CoinTransactionForProductIdQuery, TError, TData>,
) => {
  return useInfiniteQuery<CoinTransactionForProductIdQuery, TError, TData>(
    ['coinTransactionForProductId.infinite', variables],
    metaData =>
      mutationFetcher<CoinTransactionForProductIdQuery, CoinTransactionForProductIdQueryVariables>(
        CoinTransactionForProductIdDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
      )(),
    options,
  );
};

useInfiniteCoinTransactionForProductIdQuery.fetcher = (
  variables: CoinTransactionForProductIdQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<CoinTransactionForProductIdQuery, CoinTransactionForProductIdQueryVariables>(
    CoinTransactionForProductIdDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteCoinTransactionForProductIdQuery.prefetch = (
  variables: CoinTransactionForProductIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    ['coinTransactionForProductId.infinite', variables],
    useCoinTransactionForProductIdQuery.fetcher(variables, options),
  ];
};
useInfiniteCoinTransactionForProductIdQuery.key = (variables: CoinTransactionForProductIdQueryVariables) => [
  'coinTransactionForProductId.infinite',
  variables,
];
useCoinTransactionForProductIdQuery.fetcher = (
  variables: CoinTransactionForProductIdQueryVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<CoinTransactionForProductIdQuery, CoinTransactionForProductIdQueryVariables>(
    CoinTransactionForProductIdDocument,
    variables,
    options,
  );
useCoinTransactionForProductIdQuery.prefetch = (
  variables: CoinTransactionForProductIdQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [['coinTransactionForProductId', variables], useCoinTransactionForProductIdQuery.fetcher(variables, options)];
};
useCoinTransactionForProductIdQuery.key = (variables: CoinTransactionForProductIdQueryVariables) => [
  'coinTransactionForProductId',
  variables,
];
export const UpdateCoinTransactionStatusDocument = `
    mutation updateCoinTransactionStatus($id: String!, $status: CoinTransactionStatus!) {
  updateCoinTransactionStatus(id: $id, status: $status) {
    transaction {
      id
      status
    }
    errors {
      message
    }
  }
}
    `;
export const useUpdateCoinTransactionStatusMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateCoinTransactionStatusMutation,
    TError,
    UpdateCoinTransactionStatusMutationVariables,
    TContext
  >,
) =>
  useMutation<UpdateCoinTransactionStatusMutation, TError, UpdateCoinTransactionStatusMutationVariables, TContext>(
    ['updateCoinTransactionStatus'],
    (variables?: UpdateCoinTransactionStatusMutationVariables) =>
      mutationFetcher<UpdateCoinTransactionStatusMutation, UpdateCoinTransactionStatusMutationVariables>(
        UpdateCoinTransactionStatusDocument,
        variables,
      )(),
    options,
  );
useUpdateCoinTransactionStatusMutation.fetcher = (
  variables: UpdateCoinTransactionStatusMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<UpdateCoinTransactionStatusMutation, UpdateCoinTransactionStatusMutationVariables>(
    UpdateCoinTransactionStatusDocument,
    variables,
    options,
  );
export const SpendCoinsOnProductDocument = `
    mutation spendCoinsOnProduct($productId: String!) {
  spendCoinsOnProduct(productId: $productId) {
    transaction {
      id
    }
    errors {
      message
    }
  }
}
    `;
export const useSpendCoinsOnProductMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<SpendCoinsOnProductMutation, TError, SpendCoinsOnProductMutationVariables, TContext>,
) =>
  useMutation<SpendCoinsOnProductMutation, TError, SpendCoinsOnProductMutationVariables, TContext>(
    ['spendCoinsOnProduct'],
    (variables?: SpendCoinsOnProductMutationVariables) =>
      mutationFetcher<SpendCoinsOnProductMutation, SpendCoinsOnProductMutationVariables>(
        SpendCoinsOnProductDocument,
        variables,
      )(),
    options,
  );
useSpendCoinsOnProductMutation.fetcher = (
  variables: SpendCoinsOnProductMutationVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<SpendCoinsOnProductMutation, SpendCoinsOnProductMutationVariables>(
    SpendCoinsOnProductDocument,
    variables,
    options,
  );
export const CoinsPageTransactionsDocument = `
    query CoinsPageTransactions($page: Int, $pageSize: Int) {
  settings {
    coins {
      rewards {
        postProductReview
        postSocialProductProject
      }
    }
  }
  me {
    user {
      coins {
        transactions(page: $page, itemsPerPage: $pageSize) {
          pagination {
            itemsPerPage
            page
            totalPages
          }
          transactions {
            id
            type
            amount
            status
            reason
            origin {
              action
              product {
                title
                url
              }
              user {
                firstName
                lastName
                slug
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useCoinsPageTransactionsQuery = <TData = CoinsPageTransactionsQuery, TError = unknown>(
  variables?: CoinsPageTransactionsQueryVariables,
  options?: UseQueryOptions<CoinsPageTransactionsQuery, TError, TData>,
) =>
  useQuery<CoinsPageTransactionsQuery, TError, TData>(
    variables === undefined ? ['CoinsPageTransactions'] : ['CoinsPageTransactions', variables],
    mutationFetcher<CoinsPageTransactionsQuery, CoinsPageTransactionsQueryVariables>(
      CoinsPageTransactionsDocument,
      variables,
    ),
    options,
  );
export const useInfiniteCoinsPageTransactionsQuery = <TData = CoinsPageTransactionsQuery, TError = unknown>(
  pageParamKey: keyof CoinsPageTransactionsQueryVariables,
  variables?: CoinsPageTransactionsQueryVariables,
  options?: UseInfiniteQueryOptions<CoinsPageTransactionsQuery, TError, TData>,
) => {
  return useInfiniteQuery<CoinsPageTransactionsQuery, TError, TData>(
    variables === undefined ? ['CoinsPageTransactions.infinite'] : ['CoinsPageTransactions.infinite', variables],
    metaData =>
      mutationFetcher<CoinsPageTransactionsQuery, CoinsPageTransactionsQueryVariables>(CoinsPageTransactionsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteCoinsPageTransactionsQuery.fetcher = (
  variables?: CoinsPageTransactionsQueryVariables,
  options?: RequestInit['headers'],
) => (metaData: QueryFunctionContext<QueryKey, any>) =>
  mutationFetcher<CoinsPageTransactionsQuery, CoinsPageTransactionsQueryVariables>(
    CoinsPageTransactionsDocument,
    { ...variables, ...(metaData.pageParam ?? {}) },
    options,
  )();
useInfiniteCoinsPageTransactionsQuery.prefetch = (
  variables?: CoinsPageTransactionsQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['CoinsPageTransactions.infinite'] : ['CoinsPageTransactions.infinite', variables],
    useCoinsPageTransactionsQuery.fetcher(variables, options),
  ];
};
useInfiniteCoinsPageTransactionsQuery.key = (variables?: CoinsPageTransactionsQueryVariables) =>
  variables === undefined ? ['CoinsPageTransactions.infinite'] : ['CoinsPageTransactions.infinite', variables];
useCoinsPageTransactionsQuery.fetcher = (
  variables?: CoinsPageTransactionsQueryVariables,
  options?: RequestInit['headers'],
) =>
  mutationFetcher<CoinsPageTransactionsQuery, CoinsPageTransactionsQueryVariables>(
    CoinsPageTransactionsDocument,
    variables,
    options,
  );
useCoinsPageTransactionsQuery.prefetch = (
  variables?: CoinsPageTransactionsQueryVariables,
  options?: RequestInit['headers'],
): Parameters<QueryClient['prefetchQuery']> => {
  return [
    variables === undefined ? ['CoinsPageTransactions'] : ['CoinsPageTransactions', variables],
    useCoinsPageTransactionsQuery.fetcher(variables, options),
  ];
};
useCoinsPageTransactionsQuery.key = (variables?: CoinsPageTransactionsQueryVariables) =>
  variables === undefined ? ['CoinsPageTransactions'] : ['CoinsPageTransactions', variables];

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateSignedUploadUrlMutation((req, res, ctx) => {
 *   const { fileName } = req.variables;
 *   return res(
 *     ctx.data({ createSignedUploadUrl })
 *   )
 * })
 */
export const mockCreateSignedUploadUrlMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateSignedUploadUrlMutationVariables>,
    GraphQLContext<CreateSignedUploadUrlMutation>,
    any
  >,
) =>
  graphql.mutation<CreateSignedUploadUrlMutation, CreateSignedUploadUrlMutationVariables>(
    'CreateSignedUploadUrl',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLoginMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ login })
 *   )
 * })
 */
export const mockLoginMutation = (
  resolver: ResponseResolver<GraphQLRequest<LoginMutationVariables>, GraphQLContext<LoginMutation>, any>,
) => graphql.mutation<LoginMutation, LoginMutationVariables>('login', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLogoutMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ logout })
 *   )
 * })
 */
export const mockLogoutMutation = (
  resolver: ResponseResolver<GraphQLRequest<LogoutMutationVariables>, GraphQLContext<LogoutMutation>, any>,
) => graphql.mutation<LogoutMutation, LogoutMutationVariables>('logout', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRegisterMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ register })
 *   )
 * })
 */
export const mockRegisterMutation = (
  resolver: ResponseResolver<GraphQLRequest<RegisterMutationVariables>, GraphQLContext<RegisterMutation>, any>,
) => graphql.mutation<RegisterMutation, RegisterMutationVariables>('register', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockLostPasswordMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ lostPassword })
 *   )
 * })
 */
export const mockLostPasswordMutation = (
  resolver: ResponseResolver<GraphQLRequest<LostPasswordMutationVariables>, GraphQLContext<LostPasswordMutation>, any>,
) => graphql.mutation<LostPasswordMutation, LostPasswordMutationVariables>('lostPassword', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockResetPasswordMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ resetPassword })
 *   )
 * })
 */
export const mockResetPasswordMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ResetPasswordMutationVariables>,
    GraphQLContext<ResetPasswordMutation>,
    any
  >,
) => graphql.mutation<ResetPasswordMutation, ResetPasswordMutationVariables>('resetPassword', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMasterFeedQuery((req, res, ctx) => {
 *   const { offset, limit, idLte, idGte } = req.variables;
 *   return res(
 *     ctx.data({ getMasterFeed })
 *   )
 * })
 */
export const mockGetMasterFeedQuery = (
  resolver: ResponseResolver<GraphQLRequest<GetMasterFeedQueryVariables>, GraphQLContext<GetMasterFeedQuery>, any>,
) => graphql.query<GetMasterFeedQuery, GetMasterFeedQueryVariables>('getMasterFeed', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMyFeedQuery((req, res, ctx) => {
 *   const { offset, limit, idLte, idGte } = req.variables;
 *   return res(
 *     ctx.data({ getMyFeed })
 *   )
 * })
 */
export const mockGetMyFeedQuery = (
  resolver: ResponseResolver<GraphQLRequest<GetMyFeedQueryVariables>, GraphQLContext<GetMyFeedQuery>, any>,
) => graphql.query<GetMyFeedQuery, GetMyFeedQueryVariables>('getMyFeed', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetUserFeedQuery((req, res, ctx) => {
 *   const { userId, userSlug, offset, limit, idLte, idGte } = req.variables;
 *   return res(
 *     ctx.data({ getUserFeed })
 *   )
 * })
 */
export const mockGetUserFeedQuery = (
  resolver: ResponseResolver<GraphQLRequest<GetUserFeedQueryVariables>, GraphQLContext<GetUserFeedQuery>, any>,
) => graphql.query<GetUserFeedQuery, GetUserFeedQueryVariables>('getUserFeed', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateSparkPromptMutation((req, res, ctx) => {
 *   const { prompt, ratio, creationType } = req.variables;
 *   return res(
 *     ctx.data({ createSparkPrompt })
 *   )
 * })
 */
export const mockCreateSparkPromptMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateSparkPromptMutationVariables>,
    GraphQLContext<CreateSparkPromptMutation>,
    any
  >,
) => graphql.mutation<CreateSparkPromptMutation, CreateSparkPromptMutationVariables>('CreateSparkPrompt', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateSparkCrystallinePromptMutation((req, res, ctx) => {
 *   const { prompt, ratio, modelTypeVersion } = req.variables;
 *   return res(
 *     ctx.data({ createSparkCrystallinePrompt })
 *   )
 * })
 */
export const mockCreateSparkCrystallinePromptMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateSparkCrystallinePromptMutationVariables>,
    GraphQLContext<CreateSparkCrystallinePromptMutation>,
    any
  >,
) =>
  graphql.mutation<CreateSparkCrystallinePromptMutation, CreateSparkCrystallinePromptMutationVariables>(
    'CreateSparkCrystallinePrompt',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPublicPromptByIdQuery((req, res, ctx) => {
 *   const { promptId } = req.variables;
 *   return res(
 *     ctx.data({ getPublicPromptById })
 *   )
 * })
 */
export const mockPublicPromptByIdQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<PublicPromptByIdQueryVariables>,
    GraphQLContext<PublicPromptByIdQuery>,
    any
  >,
) => graphql.query<PublicPromptByIdQuery, PublicPromptByIdQueryVariables>('PublicPromptById', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSparkQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ healthCheck })
 *   )
 * })
 */
export const mockSparkQuery = (
  resolver: ResponseResolver<GraphQLRequest<SparkQueryVariables>, GraphQLContext<SparkQuery>, any>,
) => graphql.query<SparkQuery, SparkQueryVariables>('Spark', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSparkUserCreditsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ getSparkUserCredits })
 *   )
 * })
 */
export const mockSparkUserCreditsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<SparkUserCreditsQueryVariables>,
    GraphQLContext<SparkUserCreditsQuery>,
    any
  >,
) => graphql.query<SparkUserCreditsQuery, SparkUserCreditsQueryVariables>('SparkUserCredits', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSparkQueueQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ me })
 *   )
 * })
 */
export const mockSparkQueueQuery = (
  resolver: ResponseResolver<GraphQLRequest<SparkQueueQueryVariables>, GraphQLContext<SparkQueueQuery>, any>,
) => graphql.query<SparkQueueQuery, SparkQueueQueryVariables>('SparkQueue', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockProductListQuery((req, res, ctx) => {
 *   const { offset, limit, categoryId, authorId, orderBy } = req.variables;
 *   return res(
 *     ctx.data({ getProductList })
 *   )
 * })
 */
export const mockProductListQuery = (
  resolver: ResponseResolver<GraphQLRequest<ProductListQueryVariables>, GraphQLContext<ProductListQuery>, any>,
) => graphql.query<ProductListQuery, ProductListQueryVariables>('ProductList', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPersonalizedCommunityContentProductListQuery((req, res, ctx) => {
 *   const { offset, limit, categoryId, orderBy } = req.variables;
 *   return res(
 *     ctx.data({ getPersonalizedCommunityContentProductList })
 *   )
 * })
 */
export const mockPersonalizedCommunityContentProductListQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<PersonalizedCommunityContentProductListQueryVariables>,
    GraphQLContext<PersonalizedCommunityContentProductListQuery>,
    any
  >,
) =>
  graphql.query<PersonalizedCommunityContentProductListQuery, PersonalizedCommunityContentProductListQueryVariables>(
    'PersonalizedCommunityContentProductList',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddFavoriteMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ addFavorite })
 *   )
 * })
 */
export const mockAddFavoriteMutation = (
  resolver: ResponseResolver<GraphQLRequest<AddFavoriteMutationVariables>, GraphQLContext<AddFavoriteMutation>, any>,
) => graphql.mutation<AddFavoriteMutation, AddFavoriteMutationVariables>('addFavorite', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteFavoriteMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ deleteFavorite })
 *   )
 * })
 */
export const mockDeleteFavoriteMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<DeleteFavoriteMutationVariables>,
    GraphQLContext<DeleteFavoriteMutation>,
    any
  >,
) => graphql.mutation<DeleteFavoriteMutation, DeleteFavoriteMutationVariables>('deleteFavorite', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockProductsByIdQuery((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ getProductsById })
 *   )
 * })
 */
export const mockProductsByIdQuery = (
  resolver: ResponseResolver<GraphQLRequest<ProductsByIdQueryVariables>, GraphQLContext<ProductsByIdQuery>, any>,
) => graphql.query<ProductsByIdQuery, ProductsByIdQueryVariables>('ProductsById', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRemoveSparkPromptMutation((req, res, ctx) => {
 *   const { promptId } = req.variables;
 *   return res(
 *     ctx.data({ removeSparkPrompt })
 *   )
 * })
 */
export const mockRemoveSparkPromptMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<RemoveSparkPromptMutationVariables>,
    GraphQLContext<RemoveSparkPromptMutation>,
    any
  >,
) => graphql.mutation<RemoveSparkPromptMutation, RemoveSparkPromptMutationVariables>('removeSparkPrompt', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSparkPromptsQuery((req, res, ctx) => {
 *   const { page, creationType, filter } = req.variables;
 *   return res(
 *     ctx.data({ me })
 *   )
 * })
 */
export const mockSparkPromptsQuery = (
  resolver: ResponseResolver<GraphQLRequest<SparkPromptsQueryVariables>, GraphQLContext<SparkPromptsQuery>, any>,
) => graphql.query<SparkPromptsQuery, SparkPromptsQueryVariables>('SparkPrompts', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpscaleSparkImageMutation((req, res, ctx) => {
 *   const { imageId, hiddenPrompt } = req.variables;
 *   return res(
 *     ctx.data({ upscaleSparkImage })
 *   )
 * })
 */
export const mockUpscaleSparkImageMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpscaleSparkImageMutationVariables>,
    GraphQLContext<UpscaleSparkImageMutation>,
    any
  >,
) => graphql.mutation<UpscaleSparkImageMutation, UpscaleSparkImageMutationVariables>('UpscaleSparkImage', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPrivateUpscaleMutation((req, res, ctx) => {
 *   const { itemId } = req.variables;
 *   return res(
 *     ctx.data({ privateUpscale })
 *   )
 * })
 */
export const mockPrivateUpscaleMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<PrivateUpscaleMutationVariables>,
    GraphQLContext<PrivateUpscaleMutation>,
    any
  >,
) => graphql.mutation<PrivateUpscaleMutation, PrivateUpscaleMutationVariables>('PrivateUpscale', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPrivateImagesQuery((req, res, ctx) => {
 *   const { page, creationType } = req.variables;
 *   return res(
 *     ctx.data({ me })
 *   )
 * })
 */
export const mockPrivateImagesQuery = (
  resolver: ResponseResolver<GraphQLRequest<PrivateImagesQueryVariables>, GraphQLContext<PrivateImagesQuery>, any>,
) => graphql.query<PrivateImagesQuery, PrivateImagesQueryVariables>('PrivateImages', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockImageUpscalerMutation((req, res, ctx) => {
 *   const { imagePath } = req.variables;
 *   return res(
 *     ctx.data({ imageUpscaler })
 *   )
 * })
 */
export const mockImageUpscalerMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<ImageUpscalerMutationVariables>,
    GraphQLContext<ImageUpscalerMutation>,
    any
  >,
) => graphql.mutation<ImageUpscalerMutation, ImageUpscalerMutationVariables>('ImageUpscaler', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetImageUpscalerStatusQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ getImageUpscalerStatus })
 *   )
 * })
 */
export const mockGetImageUpscalerStatusQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetImageUpscalerStatusQueryVariables>,
    GraphQLContext<GetImageUpscalerStatusQuery>,
    any
  >,
) =>
  graphql.query<GetImageUpscalerStatusQuery, GetImageUpscalerStatusQueryVariables>('getImageUpscalerStatus', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCreateImageSignedDownloadUrlMutation((req, res, ctx) => {
 *   const { imageId } = req.variables;
 *   return res(
 *     ctx.data({ createImageSignedDownloadUrl })
 *   )
 * })
 */
export const mockCreateImageSignedDownloadUrlMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<CreateImageSignedDownloadUrlMutationVariables>,
    GraphQLContext<CreateImageSignedDownloadUrlMutation>,
    any
  >,
) =>
  graphql.mutation<CreateImageSignedDownloadUrlMutation, CreateImageSignedDownloadUrlMutationVariables>(
    'createImageSignedDownloadUrl',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContentByIdQuery((req, res, ctx) => {
 *   const { id, locale, increaseViewsCount } = req.variables;
 *   return res(
 *     ctx.data({ getContent })
 *   )
 * })
 */
export const mockGetContentByIdQuery = (
  resolver: ResponseResolver<GraphQLRequest<GetContentByIdQueryVariables>, GraphQLContext<GetContentByIdQuery>, any>,
) => graphql.query<GetContentByIdQuery, GetContentByIdQueryVariables>('GetContentById', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGenerateAssetDownloadLinkMutation((req, res, ctx) => {
 *   const { id, assetId } = req.variables;
 *   return res(
 *     ctx.data({ generateAssetDownloadLink })
 *   )
 * })
 */
export const mockGenerateAssetDownloadLinkMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<GenerateAssetDownloadLinkMutationVariables>,
    GraphQLContext<GenerateAssetDownloadLinkMutation>,
    any
  >,
) =>
  graphql.mutation<GenerateAssetDownloadLinkMutation, GenerateAssetDownloadLinkMutationVariables>(
    'GenerateAssetDownloadLink',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContentCategoryByIdQuery((req, res, ctx) => {
 *   const { id, locale } = req.variables;
 *   return res(
 *     ctx.data({ getContentCategory })
 *   )
 * })
 */
export const mockGetContentCategoryByIdQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetContentCategoryByIdQueryVariables>,
    GraphQLContext<GetContentCategoryByIdQuery>,
    any
  >,
) =>
  graphql.query<GetContentCategoryByIdQuery, GetContentCategoryByIdQueryVariables>('GetContentCategoryById', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContentCategoriesByIdQuery((req, res, ctx) => {
 *   const { ids, locale } = req.variables;
 *   return res(
 *     ctx.data({ getContentCategories })
 *   )
 * })
 */
export const mockGetContentCategoriesByIdQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetContentCategoriesByIdQueryVariables>,
    GraphQLContext<GetContentCategoriesByIdQuery>,
    any
  >,
) =>
  graphql.query<GetContentCategoriesByIdQuery, GetContentCategoriesByIdQueryVariables>(
    'GetContentCategoriesById',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContentCategoriesQuery((req, res, ctx) => {
 *   const { parentId, locale } = req.variables;
 *   return res(
 *     ctx.data({ listContentCategories })
 *   )
 * })
 */
export const mockGetContentCategoriesQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetContentCategoriesQueryVariables>,
    GraphQLContext<GetContentCategoriesQuery>,
    any
  >,
) => graphql.query<GetContentCategoriesQuery, GetContentCategoriesQueryVariables>('GetContentCategories', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetContentLinkBlockQuery((req, res, ctx) => {
 *   const { id, locale } = req.variables;
 *   return res(
 *     ctx.data({ getContentLinkBlock })
 *   )
 * })
 */
export const mockGetContentLinkBlockQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetContentLinkBlockQueryVariables>,
    GraphQLContext<GetContentLinkBlockQuery>,
    any
  >,
) => graphql.query<GetContentLinkBlockQuery, GetContentLinkBlockQueryVariables>('GetContentLinkBlock', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetTrendingWordsQuery((req, res, ctx) => {
 *   const { locale } = req.variables;
 *   return res(
 *     ctx.data({ getTrendingWords })
 *   )
 * })
 */
export const mockGetTrendingWordsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<GetTrendingWordsQueryVariables>,
    GraphQLContext<GetTrendingWordsQuery>,
    any
  >,
) => graphql.query<GetTrendingWordsQuery, GetTrendingWordsQueryVariables>('GetTrendingWords', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPriceCalculationQuery((req, res, ctx) => {
 *   const { targetCurrency, prices, match } = req.variables;
 *   return res(
 *     ctx.data({ getCalculatedPrices })
 *   )
 * })
 */
export const mockPriceCalculationQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<PriceCalculationQueryVariables>,
    GraphQLContext<PriceCalculationQuery>,
    any
  >,
) => graphql.query<PriceCalculationQuery, PriceCalculationQueryVariables>('PriceCalculation', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCoinsPageLoggedInQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ settings, bundles, me, popular })
 *   )
 * })
 */
export const mockCoinsPageLoggedInQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<CoinsPageLoggedInQueryVariables>,
    GraphQLContext<CoinsPageLoggedInQuery>,
    any
  >,
) => graphql.query<CoinsPageLoggedInQuery, CoinsPageLoggedInQueryVariables>('CoinsPageLoggedIn', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCoinsPageLoggedOutQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ popular })
 *   )
 * })
 */
export const mockCoinsPageLoggedOutQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<CoinsPageLoggedOutQueryVariables>,
    GraphQLContext<CoinsPageLoggedOutQuery>,
    any
  >,
) => graphql.query<CoinsPageLoggedOutQuery, CoinsPageLoggedOutQueryVariables>('CoinsPageLoggedOut', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCoinTransactionForProductIdQuery((req, res, ctx) => {
 *   const { productId } = req.variables;
 *   return res(
 *     ctx.data({ coinTransactionForProductId })
 *   )
 * })
 */
export const mockCoinTransactionForProductIdQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<CoinTransactionForProductIdQueryVariables>,
    GraphQLContext<CoinTransactionForProductIdQuery>,
    any
  >,
) =>
  graphql.query<CoinTransactionForProductIdQuery, CoinTransactionForProductIdQueryVariables>(
    'coinTransactionForProductId',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateCoinTransactionStatusMutation((req, res, ctx) => {
 *   const { id, status } = req.variables;
 *   return res(
 *     ctx.data({ updateCoinTransactionStatus })
 *   )
 * })
 */
export const mockUpdateCoinTransactionStatusMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<UpdateCoinTransactionStatusMutationVariables>,
    GraphQLContext<UpdateCoinTransactionStatusMutation>,
    any
  >,
) =>
  graphql.mutation<UpdateCoinTransactionStatusMutation, UpdateCoinTransactionStatusMutationVariables>(
    'updateCoinTransactionStatus',
    resolver,
  );

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSpendCoinsOnProductMutation((req, res, ctx) => {
 *   const { productId } = req.variables;
 *   return res(
 *     ctx.data({ spendCoinsOnProduct })
 *   )
 * })
 */
export const mockSpendCoinsOnProductMutation = (
  resolver: ResponseResolver<
    GraphQLRequest<SpendCoinsOnProductMutationVariables>,
    GraphQLContext<SpendCoinsOnProductMutation>,
    any
  >,
) =>
  graphql.mutation<SpendCoinsOnProductMutation, SpendCoinsOnProductMutationVariables>('spendCoinsOnProduct', resolver);

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCoinsPageTransactionsQuery((req, res, ctx) => {
 *   const { page, pageSize } = req.variables;
 *   return res(
 *     ctx.data({ settings, me })
 *   )
 * })
 */
export const mockCoinsPageTransactionsQuery = (
  resolver: ResponseResolver<
    GraphQLRequest<CoinsPageTransactionsQueryVariables>,
    GraphQLContext<CoinsPageTransactionsQuery>,
    any
  >,
) => graphql.query<CoinsPageTransactionsQuery, CoinsPageTransactionsQueryVariables>('CoinsPageTransactions', resolver);
