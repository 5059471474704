import { useQuery } from '@tanstack/react-query';

import { ApiEndpoint } from 'api/fetcher.types';
import { fetcher } from 'api/fetcher';
import { nonceFetcher } from 'api/requests/nonce/nonce';
import { NonceType } from 'api/requests/nonce/nonce.types';

import { SiteData, SiteFetcherParams } from './site.types';

export const siteDataFetcher = async (params?: SiteFetcherParams) => {
  const { cookieString, userIp } = params || {};
  const { nonce } = await nonceFetcher({ nonceType: NonceType.DEFAULT, cookieString });

  return fetcher<SiteData>({
    url: `${ApiEndpoint.SITE}?_wpnonce=${nonce}`,
    options: {
      headers: { 'Content-Type': 'application/json', 'x-forwarded-for': userIp || '' },
    },
    cookieString,
  });
};

export const siteFetcherQueryKey = [ApiEndpoint.SITE];

export const useSiteDataFetcher = () => {
  return useQuery({ queryKey: siteFetcherQueryKey, queryFn: () => siteDataFetcher(), staleTime: Infinity });
};
