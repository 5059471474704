import { styled } from '../../elements';

export const Progress = styled('progress')<{ component?: string }>({
  position: 'absolute',
  margin: -1,
  padding: 0,
  width: 1,
  height: 1,
  overflow: 'hidden',
  clip: 'rect(0,0,0,0)',
  border: 0,
  whiteSpace: 'nowrap',
});

export const Bar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'progress',
})<{
  progress: number;
}>(({ progress, theme }) => ({
  boxSizing: 'border-box',
  background: theme.palette.gray.extraLight,
  position: 'relative',
  border: `1px solid ${theme.palette.gray.light}`,
  boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.1)',
  height: 29,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${progress}%`,
    height: '100%',
    background: progress === 100 ? '#7EB16D' : '#6676BD',
  },
}));

export const BarIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'progress',
})<{
  progress: number;
}>(({ progress }) => ({
  position: 'absolute',
  top: '50%',
  left: `${progress}%`,
  width: 'max-content',
}));

export const Footer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  textAlign: 'center',
  fontSize: theme.spacing(1.75),
  lineHeight: `170%`,
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(1.25),
  },
}));
