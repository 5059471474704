import { Box, BoxProps, styled } from '@mui/material';

export const BoxRoot = styled(Box, {
  shouldForwardProp: prop => !['isLoaded', 'enableAnimation'].includes(prop as string),
})<BoxProps & { isLoaded: boolean; enableAnimation: boolean }>(({ isLoaded, enableAnimation }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...(isLoaded || !enableAnimation
    ? {
        opacity: 1,
        transition: 'opacity .75s',
      }
    : {
        opacity: 0,
      }),
}));
