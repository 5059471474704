import { PaymentMethod, ProviderCode } from 'api/requests/paymentMethods/paymentMethods.types';

export const paymentMethodsMin: PaymentMethod[] = [
  {
    id: '1',
    isDefault: true,
    isSubscriptionPayment: false,
    providerCode: ProviderCode.PAYPAL,
    providerName: 'PayPal',
    paymentMethodInfo: null,
  },
  {
    id: '2',
    isDefault: false,
    isSubscriptionPayment: false,
    providerCode: ProviderCode.STORE_CREDIT,
    providerName: 'Store Credit',
    paymentMethodInfo: {
      amount: 0,
    },
  },
];

export const paymentMethodsFull: PaymentMethod[] = [
  {
    id: '1',
    isDefault: false,
    isSubscriptionPayment: false,
    providerCode: ProviderCode.PAYPAL,
    providerName: 'PayPal',
    paymentMethodInfo: null,
  },
  {
    id: '2',
    isDefault: false,
    isSubscriptionPayment: false,
    providerCode: ProviderCode.STRIPE,
    providerName: 'Credit Card',
    paymentMethodInfo: {
      token: '1',
      brand: 'Visa',
      last4: '1234',
      exp_month: '01',
      exp_year: '24',
    },
  },
  {
    id: '3',
    isDefault: true,
    isSubscriptionPayment: false,
    providerCode: ProviderCode.STRIPE,
    providerName: 'Credit Card',
    paymentMethodInfo: {
      token: '2',
      brand: 'Mastercard',
      last4: '4242',
      exp_month: '08',
      exp_year: '25',
    },
  },
  {
    id: '4',
    isDefault: false,
    isSubscriptionPayment: false,
    providerCode: ProviderCode.STORE_CREDIT,
    providerName: 'Store Credit',
    paymentMethodInfo: {
      amount: 40,
    },
  },
];
