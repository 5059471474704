import { Alert as MuiAlert } from '@mui/material';
import { styled } from '@mui/material';

export const Alert = styled(MuiAlert)(({ theme }) => ({
  padding: theme.spacing(0.125, 2.5),
  '&.MuiAlert-filledInfo': {
    backgroundColor: theme.palette.blue.main,
  },
  '&.MuiAlert-filledSuccess': {
    backgroundColor: theme.palette.secondary.main,
  },
  '&.MuiAlert-filledWarning': {
    backgroundColor: theme.palette.yellow.dark,
  },
  '&.MuiAlert-filledError': {
    backgroundColor: theme.palette.red.dark,
  },
  '& .MuiAlert-icon': {
    fontSize: 24,
    opacity: 1,
  },
  '& .MuiAlert-message': {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  '& .MuiAlert-action': {
    paddingTop: 0,
    alignItems: 'center',
  },
}));
