import parser from 'ua-parser-js';
import mediaQuery from 'css-mediaquery';

export const getUserAgentMatchMedia = (userAgent?: string) => {
  if (!userAgent) {
    return;
  }

  const deviceType = parser(userAgent).device.type || 'desktop';
  const userAgentMatchMedia = (query: string) => ({
    matches: mediaQuery.match(query, {
      width: deviceType === 'mobile' ? '0px' : '1024px',
    }),
  });

  return userAgentMatchMedia;
};
