import { FutureImage } from 'components/elements/atoms/image/FutureImage';

import { Link, styled, Typography } from '../../elements';

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  lineHeight: 1.4,
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
    lineHeight: 1.3,
  },
}));

export const List = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Item = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: `1fr minmax(${theme.spacing(9.25)}, auto)`,
  gap: theme.spacing(2.5),
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  borderBottom: `1px solid ${theme.palette.gray.light}`,
  '&:first-of-type': {
    paddingTop: 0,
  },
}));

export const ListFooter = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.main,
  lineHeight: 1.7,
  '&:not(:only-child)': {
    paddingTop: theme.spacing(1.25),
  },
}));

export const ListFooterLink = styled(ListFooter.withComponent(Link))(
  ({ theme }) => ({
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  })
);

export const ItemLink = styled(Link)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecoration: 'none',
  fontWeight: theme.typography.fontWeightBold,
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const ItemText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  lineHeight: 1.7,
}));

export const ItemValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontWeight: theme.typography.fontWeightBold,
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: theme.spacing(0.375),
}));

export const Icon = styled(FutureImage)({
  width: 11,
  height: 11,
  transform: 'translateY(-1px)',
});
