import { styled, Box } from '../../elements';

export const Wrapper = styled(Box)(() => ({}));
export const Title = styled('h3')(({ theme }) => ({
  margin: theme.spacing(0),
  padding: theme.spacing(1.25, 2.5, 0),
  fontSize: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.black.main,
}));

export const TitleAnchor = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  fontSize: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.black.main,
  width: '100%',
  boxSizing: 'border-box',
  cursor: 'pointer',
}));

export const DescriptionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0),
  padding: theme.spacing(1.25, 2.5, 0),
  alignItems: 'center',
  justifyContent: 'space-between',
  fontStyle: 'italic',
  color: theme.palette.gray.dark,
  fontSize: theme.spacing(1.75),
}));

export const AuthorWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const AuthorAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.black.main,
  fontSize: theme.spacing(1.75),
  textDecoration: 'none',
  cursor: 'pointer',
}));
export const AuthorProfile = styled('p')(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 0.625),
}));

export const ViewButton = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  padding: theme.spacing(0, 2.5),
  borderRadius: theme.spacing(0.5),
  letterSpacing: theme.spacing(0.1125),
  textTransform: 'uppercase',
  fontStyle: 'normal',
  cursor: 'pointer',
  boxSizing: 'border-box',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#e5def5',
  color: '#493675',
  fontSize: theme.spacing(1.75),
  height: theme.spacing(3.75),
  fontWeight: theme.typography.fontWeightBold,
  whiteSpace: 'nowrap',
  '&:hover': {
    background: '#dbd3f1',
    color: '#493675',
  },
}));
