import {
  styled,
  Button,
  IconFavoriteOutlined,
  Typography,
  IconFavorite,
} from '../../elements';

export const Icon = styled(IconFavoriteOutlined)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.gray.main,
  fontSize: 20,
  marginRight: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '&.marked': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '&.small': {
    fontSize: 20,
    marginRight: theme.spacing(0.2),
  },
}));

export const MarkedIcon = styled(IconFavorite)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  fontSize: 20,
  marginRight: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '&.small': {
    fontSize: 20,
    marginRight: theme.spacing(0.2),
  },
}));

export const IconButton = styled(Button)(({ theme }) => ({
  background: 'transparent',
  height: 'inherit',
  minWidth: '40px',
  padding: theme.spacing(0, 1, 0, 0),
  margin: 0,
  '&:hover': {
    background: 'transparent',
    boxShadow: 'none',
  },
}));

export const Count = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.main,
  '&.small': {
    fontSize: 12,
    color: theme.palette.gray.main,
  },
}));
