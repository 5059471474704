import * as Sentry from '@sentry/browser';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Key = string | readonly unknown[];

type UseLocalStorage = <T>(key: Key, initialValue?: T | null) => [T | null, Dispatch<SetStateAction<T | null>>];

function parseKey(key: Key): string {
  if (Array.isArray(key)) {
    return key.join('.');
  }

  return String(key);
}

export const parseLocalStorageValue = (value: string | null) => {
  if (value === null) {
    return null;
  }

  if (value === 'undefined') {
    return undefined;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const useLocalStorage: UseLocalStorage = (key, initialValue) => {
  const parsedKey = parseKey(key);

  const [storedValue, setValue] = useState(() => {
    try {
      if (typeof window === 'undefined') {
        return initialValue;
      }

      if (typeof initialValue !== 'undefined') {
        window.localStorage.setItem(parsedKey, JSON.stringify(initialValue));

        return initialValue;
      }

      const currentValue = window.localStorage.getItem(parsedKey);

      return parseLocalStorageValue(currentValue);
    } catch (error) {
      Sentry.captureException(new Error(error as string));

      return initialValue;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(parsedKey, JSON.stringify(storedValue));
    } catch (error) {
      Sentry.captureException(new Error(error as string));
    }
  }, [parsedKey, storedValue]);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      const isLocalStorageEvent = e.storageArea === window.localStorage;
      const isClearEvent = e.key === null;
      const isTargetKey = e.key === parsedKey;

      if (!isLocalStorageEvent) return;

      if (!isTargetKey && !isClearEvent) return;

      try {
        setValue(e.newValue ? parseLocalStorageValue(e.newValue) : undefined);
      } catch (error) {
        Sentry.captureException(new Error(error as string));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, [parsedKey]);

  return [storedValue, setValue];
};
