import { fetcher } from 'api/fetcher';
import { LoginData, LoginFetcherParams, TwoFAData, VerificationData } from 'api/requests/login/login.types';
import { nonceFetcher } from 'api/requests/nonce/nonce';
import { NonceType } from 'api/requests/nonce/nonce.types';
import { ApiEndpoint, BackendErrorListData } from 'api/fetcher.types';

export const loginFetcher = async (params: LoginFetcherParams) => {
  const { email, password, code, otp, remember, onError } = params;
  const { nonce } = await nonceFetcher({ nonceType: NonceType.LOGIN });
  const body = JSON.stringify({
    username: email,
    password,
    code,
    otp,
    'woocommerce-login-nonce': nonce,
    ...(remember ? { rememberme: remember } : {}),
  });

  return fetcher<LoginData & TwoFAData & VerificationData & BackendErrorListData>({
    url: ApiEndpoint.LOGIN,
    options: {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' },
    },
    onError,
  });
};
