import React from 'react';

import * as S from './CoinEarning.styles';

export enum CoinEarningState {
  SharedEverything = 'shared_everything',
  NothingToReview = 'nothing_to_review',
  DailyLimitReached = 'daily_limit_reached',
  ReadyToEarn = 'ready_to_earn',
}

export type CoinEarningRootProps = {
  children?: React.ReactNode;
  header: React.ReactNode;
  state: CoinEarningState;
  downloadHistoryUrl: string;
};

export type CoinEarningContextType = {
  state: CoinEarningState;
};

export type CoinEarningHeaderProps = {
  children: React.ReactNode;
};

export type CoinEarningItemProps = {
  image: string;
  title: string;
  href: string;
  tag: string;
  actions?: React.ReactNode;
};

export type CoinEarningButtonProps = React.ComponentProps<typeof S.Button>;
export type CoinEarningFreebiesButtonProps = React.ComponentProps<
  typeof S.FreebiesButton
> & {
  href: string;
};
